import React, { Fragment } from "react";
import moment from "moment";
import classNames from "classnames";
import customClasses from "./DatePicker.module.css";
import { makeStyles } from "@material-ui/core/styles";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import {
  Grid,
  Box,
  InputLabel,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Dialog,
  FormControl,
  Typography,
  Divider,
  FormHelperText,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { green } from "@material-ui/core/colors";
import { Formik, Form, FastField, ErrorMessage } from "formik";
import { TextField } from "formik-material-ui";
import { object, date, array, number, ref } from "yup";
import ServiceLocationDropdown from "./ServiceLocationDropdown";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    border: "1px solid #e8e8e8",
  },
  inputLabel: {
    margin: theme.spacing("auto", "auto", 1, "auto"),
  },
  title: {
    backgroundColor: "#FAE700",
  },
  titleText: {
    color: "rgb(63,81,181) !important",
    fontWeight: "bold !important",
  },
  button: {
    backgroundColor: green[500],
    color: "#fff",
    "&:hover": {
      backgroundColor: green[600],
    },
  },
  paper: {
    width: "700px",
    maxWidth: "700px",
  },
  add: {
    backgroundColor: green[500],
    color: "#fff",
    "&:hover": {
      backgroundColor: green[600],
    },
  },
}));

const useStyles2 = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  form: {
    width: 800,
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    minWidth: 60,
  },
  type: {
    fontSize: "14px",
    fontWeight: "600",
  },
  formControl: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
      marginRight: theme.spacing(0),
      marginBottom: theme.spacing(1),
    },
  },
  search: {
    maxWidth: 130,
  },
  input: {
    textAlign: "center !important",
  },
}));

const ScheduleAdd = React.memo((props) => {
  const classes = useStyles();
  const classes2 = useStyles2();

  const { open, onOpen, onClose, handleSubmitSchedule } = props;

  const handleOpen = () => {
    onOpen();
  };

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = (values, actions) => {
    handleSubmitSchedule(values, actions);
  };
  const initialValues = {
    schedule_from: new Date(),
    schedule_to: new Date(),
    schedule_times: [
      {
        label: "8:00AM",
        value: moment()
          .set({
            hour: 8,
            minute: 0,
            second: 0,
            millisecond: 0,
          })
          .format("HH:mm:ss"),
        max_slots: 0,
      },
      {
        label: "9:00AM",
        value: moment()
          .set({
            hour: 9,
            minute: 0,
            second: 0,
            millisecond: 0,
          })
          .format("HH:mm:ss"),
        max_slots: 0,
      },
      {
        label: "10:00AM",
        value: moment()
          .set({
            hour: 10,
            minute: 0,
            second: 0,
            millisecond: 0,
          })
          .format("HH:mm:ss"),
        max_slots: 0,
      },
      {
        label: "11:00AM",
        value: moment()
          .set({
            hour: 11,
            minute: 0,
            second: 0,
            millisecond: 0,
          })
          .format("HH:mm:ss"),
        max_slots: 0,
      },
      {
        label: "12:00PM",
        value: moment()
          .set({
            hour: 12,
            minute: 0,
            second: 0,
            millisecond: 0,
          })
          .format("HH:mm:ss"),
        max_slots: 0,
      },
      {
        label: "1:00PM",
        value: moment()
          .set({
            hour: 13,
            minute: 0,
            second: 0,
            millisecond: 0,
          })
          .format("HH:mm:ss"),
        max_slots: 0,
      },
      {
        label: "2:00PM",
        value: moment()
          .set({
            hour: 14,
            minute: 0,
            second: 0,
            millisecond: 0,
          })
          .format("HH:mm:ss"),
        max_slots: 0,
      },
      {
        label: "3:00PM",
        value: moment()
          .set({
            hour: 15,
            minute: 0,
            second: 0,
            millisecond: 0,
          })
          .format("HH:mm:ss"),
        max_slots: 0,
      },
      {
        label: "4:00PM",
        value: moment()
          .set({
            hour: 16,
            minute: 0,
            second: 0,
            millisecond: 0,
          })
          .format("HH:mm:ss"),
        max_slots: 0,
      },
    ],
    garage_id: "",
  };

  const validationSchema = object().shape({
    schedule_from: date()
      .typeError("Schedule from time is required.")
      .required("Schedule from time is required."),
    schedule_to: date()
      .typeError("Schedule from time is required.")
      .min(
        ref("schedule_from"),
        "End date shall not be earlier than Start date."
      )
      .required("Schedule to time is required."),
    schedule_times: array(
      object({
        max_slots: number()
          .typeError("Max slots must be valid.")
          .min(0, "Invalid minimum.")
          .required("Required field."),
      })
    ).required(),
    garage_id: number().required("Garage is required"),
  });

  return (
    <Fragment>
      <div>
        <Box mb={2} display="flex" flexDirection="row-reverse">
          <Button
            onClick={handleOpen}
            className={classes.button}
            variant="contained"
            size="medium"
            startIcon={<AddIcon />}
          >
            Add Schedule
          </Button>
        </Box>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          disableBackdropClick
          classes={{ paper: classes.paper }}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              setFieldValue,
              setSubmitting,
              isSubmitting,
            }) => (
              <Form
                autoComplete="off"
                style={{
                  display: "contents",
                }}
              >
                <DialogTitle
                  id="alert-dialog-title"
                  className={`${classes.title}`}
                >
                  <span className={classes.titleText}>
                    {"ADD SCHEDULE FORM"}
                  </span>
                </DialogTitle>
                <DialogContent dividers>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <Grid item xs={12} style={{ marginBottom: "1.5rem" }}>
                        <FastField
                          label="Garage"
                          component={ServiceLocationDropdown}
                          name="garage_id"
                          type="text"
                          variant="outlined"
                          size="small"
                        />
                        <ErrorMessage
                          name="garage_id"
                          component="div"
                          style={{ color: "red" }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Box>
                          <InputLabel
                            htmlFor="my-input"
                            className={classes.inputLabel}
                          >
                            Date From
                          </InputLabel>
                          <DatePicker
                            minDate={new Date()}
                            selected={values.schedule_from}
                            onChange={(date) => {
                              setFieldValue(`schedule_from`, date);
                            }}
                            showMonthDropdown
                            selectsStart
                            startDate={values.schedule_from}
                            endDate={values.schedule_to}
                            popperProps={{
                              strategy: "fixed",
                            }}
                            popperClassName={`${customClasses["react-datepicker-popper"]}`}
                            className={`${customClasses["react-datepicker"]} ${customClasses["react-datepicker__current-month"]} ${customClasses["react-datepicker__header"]} ${customClasses["react-datepicker__navigation"]} ${customClasses["react-datepicker__day-name"]} ${customClasses["react-datepicker__day"]}`}
                          />
                          {errors.schedule_from && (
                            <FormHelperText
                              id="component-error-text"
                              style={{ color: "#f44336" }}
                            >
                              {errors.schedule_from}
                            </FormHelperText>
                          )}
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box mt={2}>
                          <InputLabel
                            htmlFor="my-input"
                            className={classes.inputLabel}
                          >
                            Date To
                          </InputLabel>
                          <DatePicker
                            selected={values.schedule_to}
                            onChange={(date) => {
                              setFieldValue(`schedule_to`, date);
                            }}
                            showMonthDropdown
                            selectsEnd
                            startDate={values.schedule_from}
                            endDate={values.schedule_to}
                            minDate={values.schedule_from}
                            popperProps={{
                              strategy: "fixed",
                            }}
                            popperClassName={`${customClasses["react-datepicker-popper"]}`}
                            className={`${customClasses["react-datepicker"]} ${customClasses["react-datepicker__current-month"]} ${customClasses["react-datepicker__header"]} ${customClasses["react-datepicker__navigation"]} ${customClasses["react-datepicker__day-name"]} ${customClasses["react-datepicker__day"]}`}
                          />
                          {errors.schedule_to && (
                            <FormHelperText
                              id="component-error-text"
                              style={{ color: "#f44336" }}
                            >
                              {errors.schedule_to}
                            </FormHelperText>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                    <Divider
                      orientation="vertical"
                      flexItem
                      style={{ marginRight: "-1px" }}
                    />
                    <Grid item xs={8}>
                      <Box mb={2}>
                        <InputLabel
                          htmlFor="my-input"
                          className={classes.inputLabel}
                        >
                          Schedule slots
                        </InputLabel>
                      </Box>
                      <Grid container item xs={12}>
                        <Grid item xs={6}>
                          {values.schedule_times.map((value, index) => {
                            if (index <= 4 && index >= 0) {
                              return (
                                <Grid item xs={12} key={index}>
                                  <Box mb={2}>
                                    <div className={classes2.root}>
                                      <div className={classes2.form}>
                                        <FormControl
                                          className={classNames(
                                            classes2.formControl,
                                            classes2.text
                                          )}
                                        >
                                          <Typography
                                            variant="body1"
                                            className={classes2.type}
                                          >
                                            {value.label}
                                          </Typography>
                                        </FormControl>
                                        <FormControl
                                          className={classNames(
                                            classes2.formControl,
                                            classes2.search
                                          )}
                                        >
                                          <FastField
                                            component={TextField}
                                            name={`schedule_times.${index}.max_slots`}
                                            variant="outlined"
                                            size="small"
                                            type="number"
                                            inputProps={{
                                              style: {
                                                textAlign: "center",
                                                height: "8px",
                                              },
                                            }}
                                          />
                                        </FormControl>
                                      </div>
                                    </div>
                                  </Box>
                                </Grid>
                              );
                            } else {
                              return null;
                            }
                          })}
                        </Grid>
                        <Grid item xs={6}>
                          {values.schedule_times.map((value, index) => {
                            if (index >= 5) {
                              return (
                                <Grid item xs={12} key={index}>
                                  <Box mb={2}>
                                    <div className={classes2.root}>
                                      <div className={classes2.form}>
                                        <FormControl
                                          className={classNames(
                                            classes2.formControl,
                                            classes2.text
                                          )}
                                        >
                                          <Typography
                                            variant="body1"
                                            className={classes2.type}
                                          >
                                            {value.label}
                                          </Typography>
                                        </FormControl>
                                        <FormControl
                                          className={classNames(
                                            classes2.formControl,
                                            classes2.search
                                          )}
                                        >
                                          <FastField
                                            component={TextField}
                                            name={`schedule_times.${index}.max_slots`}
                                            variant="outlined"
                                            size="small"
                                            type="number"
                                            inputProps={{
                                              style: {
                                                textAlign: "center",
                                                height: "8px",
                                              },
                                            }}
                                          />
                                        </FormControl>
                                      </div>
                                    </div>
                                  </Box>
                                </Grid>
                              );
                            } else {
                              return null;
                            }
                          })}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* <pre>{JSON.stringify({ values, errors }, null, 4)}</pre> */}
                </DialogContent>
                <DialogActions>
                  <Button
                    disabled={isSubmitting}
                    onClick={handleClose}
                    size="small"
                    color="default"
                  >
                    Close
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    color="primary"
                    variant="contained"
                    autoFocus
                    size="small"
                    endIcon={
                      isSubmitting ? (
                        <CheckCircleIcon size="0.9rem" />
                      ) : undefined
                    }
                  >
                    {isSubmitting ? "Submitting" : "Submit"}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>
      </div>
    </Fragment>
  );
});
export default ScheduleAdd;
