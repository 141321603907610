import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: "#FAE700",
  },
  titleText: {
    color: "rgb(63,81,181) !important",
    fontWeight: "bold !important",
  },
  ul: {
    listStyleType: "none",
    padding: "0px",
    marginTop: "3px !important",
  },
  question: {
    marginBottom: "12px !important",
  },
  label: {
    marginRight: "5px",
    fontSize: "15px !important",
    color: "rgba(0, 0, 0, 0.54)",
    fontWeight: "600",
  },
  dataQuestion: {
    fontWeight: "600",
    fontSize: "15px !important",
  },
  data: {
    fontWeight: "600",
    textTransform: "capitalize",
    fontSize: "15px !important",
  },
}));

const PendingSubJODialog = (props) => {
  const classes = useStyles();

  const { data, open, handleConfirmClose, handleConfirm, isLoading } = props;

  const handleSubmit = () => {
    handleConfirm(data.id);
  };

  if (Array.isArray(data) && data.length === 0) {
    return null;
  }
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleConfirmClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent dividers>
          <ul className={classes.ul}>
            <li className={classes.question}>
              <div style={{ display: "flex" }}>
                <div className={classes.dataQuestion}>
                  You have pending additional services, do you want to proceed?
                </div>
                <div className={classes.data}></div>
              </div>
            </li>
            <li>
              <div style={{ display: "flex" }}>
                <div className={classes.label}>
                  Invoice amount of added services will not be added to the
                  total invoice of this booking{" "}
                </div>
              </div>
            </li>
          </ul>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleConfirmClose}
            color="secondary"
            startIcon={<CancelIcon />}
            size="small"
          >
            CANCEL
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            color="primary"
            autoFocus
            endIcon={<CheckCircleIcon />}
            size="small"
            disabled={isLoading}
          >
            CONFIRM{" "}
            {isLoading && (
              <CircularProgress size={20} style={{ marginLeft: "3px" }} />
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PendingSubJODialog;
