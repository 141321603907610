import { Fragment, useState, useEffect } from "react";
import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { MenuItem, TextField } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { Formik, Form, FastField } from "formik";
import * as Yup from "yup";
import { DateTimePicker, TimePicker } from "formik-material-ui-pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";

const MenuItemStyle = withStyles({
  root: {
    justifyContent: "center !important",
  },
})(MenuItem);

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: "#FAE700",
  },
  titleText: {
    color: "rgb(63,81,181) !important",
    fontWeight: "bold !important",
  },
  button: {
    backgroundColor: green[500],
    color: "#fff",
    "&:hover": {
      backgroundColor: green[600],
    },
  },
  paper: { maxWidth: "20%" },
}));

const AddOvertime = React.memo((props) => {
  const classes = useStyles();

  const { open, handleAddClose, data } = props;

  const [initialValues, setInitialValues] = useState({
    mechanic_id: null,
    time_in: null,
    time_from: null,
    time_to: null,
    reason: "",
  });

  useEffect(() => {
    if (data) {
      const values = {
        mechanic_id: data?.mechanic_id,
        time_in: moment(data?.time_in).format("YYYY-MM-DD"),
        time_from: null,
        time_to: null,
        reason: "",
      };
      setInitialValues(values);
    }
  }, [data]);

  const handleClose = () => {
    handleAddClose();
  };

  // Define validation schema
  const validationSchema = Yup.object({
    reason: Yup.string().required("Reason of leave is required"),
    time_from: Yup.date()
      .required("Date from is required")
      .nullable()
      .typeError("Invalid date"),
    time_to: Yup.date()
      .required("Date to is required")
      .nullable()
      .typeError("Invalid date"),
  });

  const handleSubmit = (values) => {
    // Handle form submission
    props.handleAddSubmit(values);
  };

  return (
    <Fragment>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          classes={{ paper: classes.paper }}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, errors, isSubmitting, handleChange }) => (
              <Form autoComplete="off">
                <DialogTitle
                  id="alert-dialog-title"
                  className={`${classes.title}`}
                >
                  <span className={classes.titleText}>
                    {"ADD MECHANIC OVERTIME"}
                  </span>
                </DialogTitle>
                <input type="hidden" name="id" />
                <DialogContent dividers>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <FastField
                          fullWidth
                          label="Time From"
                          component={TimePicker}
                          size="small"
                          inputVariant="outlined"
                          name="time_from"
                          variant="inline"
                          autoOk
                          inputProps={{
                            style: { textAlign: "center" },
                          }}
                          views={["hours", "minutes"]}
                          format="hh:mm a"
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <FastField
                          fullWidth
                          label="Time To"
                          component={DateTimePicker}
                          size="small"
                          inputVariant="outlined"
                          name="time_to"
                          variant="inline"
                          autoOk
                          inputProps={{
                            style: { textAlign: "center" },
                          }}
                          views={["hours", "minutes"]}
                          format="hh:mm a"
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <TextField
                        fullWidth
                        label="Reason"
                        multiline
                        size="small"
                        name="reason"
                        variant="outlined"
                        rows={2}
                        rowsMax={3}
                        value={values.reason} // Manually set the value
                        onChange={handleChange} // Manually handle change
                      />
                    </Grid>
                  </Grid>

                  <DialogActions>
                    <Button onClick={handleClose} size="small" color="default">
                      Close
                    </Button>
                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      color="primary"
                      variant="contained"
                      autoFocus
                      size="small"
                      endIcon={
                        isSubmitting ? (
                          <CheckCircleIcon size="0.9rem" />
                        ) : undefined
                      }
                    >
                      {isSubmitting ? "Submitting" : "Submit"}
                    </Button>
                  </DialogActions>
                </DialogContent>
              </Form>
            )}
          </Formik>
        </Dialog>
      </div>
    </Fragment>
  );
});

export default AddOvertime;
