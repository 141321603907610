import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { MenuItem, TextField } from "@material-ui/core";

const MenuItemStyle = withStyles({
  root: {
    justifyContent: "center",
  },
})(MenuItem);

const CustomTransactionTypeComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const transactionTypes = [
    { value: "Home Service", label: "Home Service" },
    { value: "Hub Service", label: "Hub Service" },
  ];
  return (
    <TextField
      fullWidth
      select
      error={errors.transaction_type && touched.transaction_type}
      helperText={
        errors.transaction_type && touched.transaction_type
          ? errors.transaction_type
          : ""
      }
      {...field}
      {...props}
    >
      {transactionTypes.map((option) => (
        <MenuItemStyle key={option.value} value={option.label}>
          {option.label}
        </MenuItemStyle>
      ))}
    </TextField>
  );
};
export default CustomTransactionTypeComponent;
