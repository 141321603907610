import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    border: "1px solid #e8e8e8",
    borderTop: "none",
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  grid: {
    position: "relative",
  },
  text: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    textAlign: "left",
    marginTop: "20px !important",
    marginBottom: "20px !important",
    fontSize: "0.845rem !important",
    whiteSpace: "normal",
    letterSpacing: "0.02857em",
    fontWeight: "bold !important",
    color: "#3f51b5 !important",
  },
  dateCustom: {
    textAlign: "center !important",
  },
  button: {
    marginTop: theme.spacing(3),
  },
}));

const TotalResultEntries = () => {
  const classes = useStyles();

  const totalResultEntries = useSelector(
    (state) => state.bookFilter.totalResultEntries
  );

  return (
    <Fragment>
      <div className={classes.root}>
        <Grid
          container
          spacing={1}
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          <Grid container item xs={12} spacing={3}>
            {/*1st*/}
            <Grid item xs={3} className={classes.grid}>
              <Box ml={2}>
                <Typography className={classes.text} paragraph={true}>
                  Showing result of {totalResultEntries} entries
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Fragment>
  );
};

export default TotalResultEntries;
