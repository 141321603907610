import React from "react";
import { StyleSheet, View, Text } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  boxHeader: {
    color: "#fff",
    backgroundColor: "#000b8c",
    padding: 6,
  },
  boxBody: {
    borderWidth: 1,
    borderColor: "#000b8c",
    borderTop: 0,
    padding: 6,
  },
  bold: {
    fontFamily: "Helvetica-Bold",
  },
});

const MechanicRemarks = ({ content }) => {
  return (
    <>
      <View break style={[styles.boxHeader, styles.bold]}>
        <Text>Technician Comments/Remarks</Text>
      </View>
      <View style={styles.boxBody}>
        <Text style={[styles.bold, { minHeight: 9 }]}>{content}</Text>
      </View>
    </>
  );
};

export default MechanicRemarks;
