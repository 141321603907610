import React from "react";
import axios from "axios";
import config from "../../utils/config";
import AsyncSelect from "react-select/async";

const SelectUser = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, values, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const token = localStorage.getItem("token");

  const loadOptions = async (inputValue) => {
    try {
      // Making an Axios GET request to retrieve data from gulong
      const { data: data } = await axios.get(
        `${config.api}/users?search=${inputValue}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Create a list of parts based on the API response
      const users = data.data.data.map((item) => {
        return {
          id: item.id,
          name: item.name,
          lastname: item.lastname,
        };
      });
      return users;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <AsyncSelect
      name="created_by"
      value={values?.created_by ? values.created_by : ""}
      menuPosition="fixed"
      cacheOptions
      isClearable
      defaultOptions
      placeholder="Search user"
      hideSelectedOptions
      onChange={(newValue) => {
        setFieldValue("created_by", newValue);
      }}
      loadOptions={loadOptions}
      getOptionLabel={(option) => `${option.name} ${option.lastname || ""}`}
    />
  );
};
export default SelectUser;
