import { Fragment } from 'react';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { green } from '@material-ui/core/colors';
import { Formik, Field, Form, FieldArray, FastField } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'formik-material-ui';
import DropdownField from './DropdownField';

const CheckboxField = ({ field, form, ...props }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={field.value}
          onChange={e => form.setFieldValue(field.name, e.target.checked)}
          onBlur={field.onBlur}
          color="primary"
          {...props}
        />
      }
      label={props.label}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: '#FAE700',
  },
  titleText: {
    color: 'rgb(63,81,181) !important',
    fontWeight: 'bold !important',
  },
  button: {
    backgroundColor: green[500],
    color: '#fff',
    '&:hover': {
      backgroundColor: green[600],
    },
  },
  paper: { maxWidth: '80%' },
}));

const AddIncentive = React.memo((props) => {
  const classes = useStyles();

  const { open, onOpen, onClose } = props;

  const handleOpen = () => {
    onOpen();
  };

  const handleClose = () => {
    onClose();
  };

  // Define validation schema
  const validationSchema = Yup.object({
    month: Yup.string().min(1, 'Month must be between 1 and 12').max(12, 'Month must be between 1 and 12').required('Required'),
    year: Yup.number().min(1900, 'Year must be at least 1900').max(new Date().getFullYear(), 'Year cannot be in the future').required('Required'),
    incentives: Yup.array().of(
      Yup.object({
        target_in_percentage: Yup.number().required('Required').min(80, 'Value must be at least 80').max(200, 'Value must be at most 200'),
        no_bookings: Yup.string().required('Required'),
        bookings_incentive: Yup.string().required('Required'),
        net_sales: Yup.string().required('Required'),
        net_sales_incentive: Yup.string().required('Required'),
      })
    )
  });

  // Initial form values
  const initialValues = {
    month: '',
    year: '',
    incentives: [
      { target_in_percentage: '', no_bookings: '', bookings_incentive: '', net_sales: '', net_sales_incentive: '' }
    ]
  };

  const handleSubmit = (values) => {
    // Handle form submission
    props.handleSubmit(values);
  };

  return (
    <Fragment>
      <div>
        <Box mb={2} display="flex" flexDirection="row-reverse">
          <Button
            onClick={handleOpen}
            className={classes.button}
            variant="contained"
            size="medium"
            startIcon={<AddIcon />}
          >
            Add Incentives
          </Button>
        </Box>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          classes={{ paper: classes.paper }}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, isSubmitting }) => (
              <Form autoComplete="off">
                <DialogTitle
                  id="alert-dialog-title"
                  className={`${classes.title}`}
                >
                  <span className={classes.titleText}>
                    {'MECHANIC INCENTIVES SETUP'}
                  </span>
                </DialogTitle>
                <DialogContent dividers>
                {/* Generic Fields */}
                <Grid container spacing={3}>
                  <Grid item xs={3}>
                      <FastField
                        label="Month"
                        component={DropdownField}
                        name="month"
                        type="text"
                        variant="outlined"
                        size="small"
                        fullWidth
                        inputProps={{
                          style: {
                            textAlign: 'center',
                            textTransform: 'capitalize',
                          },
                        }}
                      />
                  </Grid>
                  <Grid item xs={2}>
                    <FastField
                        label="Year"
                        component={DropdownField}
                        name="year"
                        type="text"
                        variant="outlined"
                        size="small"
                        fullWidth
                        inputProps={{
                          style: {
                            textAlign: 'center',
                            textTransform: 'capitalize',
                          },
                        }}
                      />
                  </Grid>
                  <Grid item xs={2}>
                    <FastField
                      name="regular_mechanics"
                      label="Regular Mechanics"
                      component={CheckboxField}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <FastField
                      name="trainee_mechanics"
                      label="Trainee Mechanics"
                      component={CheckboxField}
                    />
                  </Grid>
                </Grid>

                {/* Dynamic Fields */}
                <FieldArray name="incentives">
                  {({ insert, remove, push }) => (
                    <div>
                      {values.incentives.map((incentive, index) => (
                        <Grid container spacing={3}>
                          <Grid item xs={3}>
                            <FastField
                              label="Target Achievement (%)"
                              component={TextField}
                              name={`incentives.${index}.target_in_percentage`}
                              type="number"
                              variant="outlined"
                              size="small"
                              fullWidth
                              inputProps={{
                                style: {
                                  textAlign: 'center',
                                  textTransform: 'capitalize',
                                },
                              }}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <FastField
                              label="# of Bookings"
                              component={TextField}
                              name={`incentives.${index}.no_bookings`}
                              type="text"
                              variant="outlined"
                              size="small"
                              fullWidth
                              inputProps={{
                                style: {
                                  textAlign: 'center',
                                  textTransform: 'capitalize',
                                },
                              }}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <FastField
                              label="Booking Incentive"
                              component={TextField}
                              name={`incentives.${index}.bookings_incentive`}
                              type="text"
                              variant="outlined"
                              size="small"
                              fullWidth
                              inputProps={{
                                style: {
                                  textAlign: 'center',
                                  textTransform: 'capitalize',
                                },
                              }}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <FastField
                              label="Net Sales"
                              component={TextField}
                              name={`incentives.${index}.net_sales`}
                              type="text"
                              variant="outlined"
                              size="small"
                              fullWidth
                              inputProps={{
                                style: {
                                  textAlign: 'center',
                                  textTransform: 'capitalize',
                                },
                              }}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <FastField
                              label="Net Sales Incentive"
                              component={TextField}
                              name={`incentives.${index}.net_sales_incentive`}
                              type="text"
                              variant="outlined"
                              size="small"
                              fullWidth
                              inputProps={{
                                style: {
                                  textAlign: 'center',
                                  textTransform: 'capitalize',
                                },
                              }}
                            />
                          </Grid>
                          {values.incentives?.length > 1 ?
                            <Grid item xs={1}>
                              <Button onClick={() => remove(index)} size="small" variant="contained" color="primary" style={{ marginTop: '5px'}}>
                                Remove
                              </Button>
                            </Grid>
                          : <Grid item xs={1}/>}
                        </Grid>
                      ))}
                      <Button onClick={() => push({ target_in_percentage: '', no_bookings: '', bookings_incentive: '', net_sales: '', net_sales_incentive: '' })} size="small" variant="contained" color="primary" style={{ marginTop: '10px'}}>
                          Add Target
                      </Button>
                    </div>
                  )}
                </FieldArray>
                <DialogActions>
                  <Button onClick={handleClose} size="small" color="default">
                    Close
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    color="primary"
                    variant="contained"
                    autoFocus
                    size="small"
                    endIcon={
                      isSubmitting ? (
                        <CheckCircleIcon size="0.9rem" />
                      ) : undefined
                    }
                  >
                    {isSubmitting ? 'Submitting' : 'Submit'}
                  </Button>
                </DialogActions>
                </DialogContent>
              </Form>
            )}
          </Formik>
        </Dialog>
      </div>
    </Fragment>
  );
});

export default AddIncentive;
