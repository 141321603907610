import { Fragment } from "react";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import AddIcon from "@material-ui/icons/Add";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { green } from "@material-ui/core/colors";
import { Formik, Form, FastField } from "formik";
import { TextField } from "formik-material-ui";
import { object, string } from "yup";
import CustomSelectFuelType from "../customSelectFuelType/CustomSelectFuelType";

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: "#FAE700",
  },
  titleText: {
    color: "rgb(63,81,181) !important",
    fontWeight: "bold !important",
  },
  button: {
    backgroundColor: green[500],
    color: "#fff",
    "&:hover": {
      backgroundColor: green[600],
    },
  },
  paper: {
    width: "350px",
    maxWidth: "350px",
  },
  add: {
    backgroundColor: green[500],
    color: "#fff",
    "&:hover": {
      backgroundColor: green[600],
    },
  },
}));

const UpdatePackage = React.memo((props) => {
  const classes = useStyles();

  const { open, onClose, data, onUpdate } = props;

  if (Array.isArray(data) && data.length === 0) {
    return null;
  }

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = (values, actions) => {
    onUpdate(values, actions);
  };

  const initialValues = {
    id: data && data.id,
    name: data && data.name,
    fuel_type: data && data.fuel_type,
    pos: data && data.pos,
    quantity: data && data.quantity,
  };

  const validationSchema = object().shape({
    name: string().required("Service item is a required field."),
    fuel_type: string().required("Fuel Type is a required field."),
    pos: string().required("Position is a required field."),
    quantity: string().required("Quantity is a required field."),
  });

  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
        classes={{ paper: classes.paper }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, errors, setFieldValue, setSubmitting, isSubmitting }) => (
            <Form autoComplete="off">
              <DialogTitle
                id="alert-dialog-title"
                className={`${classes.title}`}
              >
                <span className={classes.titleText}>
                  {"UPDATE SERVICE ITEM"}
                </span>
              </DialogTitle>
              <DialogContent dividers>
                  <Grid container spacing={3}>
                      <Grid item xs={12}>
                          <FastField
                              name="fuel_type"
                              label="Fuel Type"
                              variant="outlined"
                              size="small"
                              component={CustomSelectFuelType}
                              fullWidth
                              />
                      </Grid>
                      <Grid item xs={12}>
                          <FastField
                              label="Service Item"
                              component={TextField}
                              name="name"
                              type="text"
                              variant="outlined"
                              size="small"
                              fullWidth
                              inputProps={{
                                  style: {
                                      textAlign: "center",
                                  },
                              }}
                              />
                      </Grid>
                      <Grid item xs={12}>
                        <FastField
                          label="Quantity"
                          component={TextField}
                          name="quantity"
                          type="number"
                          variant="outlined"
                          size="small"
                          fullWidth
                          inputProps={{
                            style: {
                              textAlign: "center",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FastField
                          label="Position"
                          component={TextField}
                          name="pos"
                          type="number"
                          variant="outlined"
                          size="small"
                          fullWidth
                          inputProps={{
                            style: {
                              textAlign: "center",
                            },
                          }}
                        />
                      </Grid>
                  </Grid>
                {/* <pre>{JSON.stringify({ values, errors }, null, 4)}</pre> */}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} size="small" color="default">
                  Close
                </Button>
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  color="primary"
                  variant="contained"
                  autoFocus
                  size="small"
                  endIcon={
                    isSubmitting ? <CheckCircleIcon size="0.9rem" /> : undefined
                  }
                >
                  {isSubmitting ? "Submitting" : "Submit"}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </Fragment>
  );
});
export default UpdatePackage;
