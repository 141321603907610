import { Fragment, useState, useEffect } from 'react';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { green } from '@material-ui/core/colors';
import { Formik, Field, Form, FieldArray, FastField } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'formik-material-ui';
import DropdownField from './DropdownField';

function isInArray(arr, obj) {
    return arr.some(item => item.id === obj.id);
}

const CheckboxField = ({ field, form, ...props }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={field.value}
          onChange={e => form.setFieldValue(field.name, e.target.checked)}
          onBlur={field.onBlur}
          color="primary"
          {...props}
        />
      }
      label={props.label}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: '#FAE700',
  },
  titleText: {
    color: 'rgb(63,81,181) !important',
    fontWeight: 'bold !important',
  },
  button: {
    backgroundColor: green[500],
    color: '#fff',
    '&:hover': {
      backgroundColor: green[600],
    },
  },
  paper: { maxWidth: '80%' },
}));

const ViewIncentive = React.memo((props) => {
  const classes = useStyles();

  const { open, handleViewClose, data } = props;

  const [initialValues, setInitialValues] = useState({
    month: '',
    year: '',
    regular_mechanics: false,
    trainee_mechanics: false,
    incentives: [
      { id: '', target_in_percentage: '', no_bookings: '', bookings_incentive: '', net_sales: '', net_sales_incentive: '' }
    ]
  })

  useEffect(() => {
    if (data) {
        const values = {
            month: data?.month,
            year: data?.year,
            regular_mechanics: data?.regular_mechanics,
            trainee_mechanics: data?.trainee_mechanics,
            incentives: props?.data.values
        }
        setInitialValues(values)
    }
  }, [data])

  const handleClose = () => {
    handleViewClose();
  };

 
  return (
    <Fragment>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          classes={{ paper: classes.paper }}
        >
          <Formik
            initialValues={initialValues}
          >
            {({ values }) => (
              <Form autoComplete="off">
                <DialogTitle
                  id="alert-dialog-title"
                  className={`${classes.title}`}
                >
                  <span className={classes.titleText}>
                    {'MECHANIC INCENTIVES'}
                  </span>
                </DialogTitle>
                <DialogContent dividers>
                {/* Generic Fields */}
                <Grid container spacing={3}>
                  <Grid item xs={3}>
                      <FastField
                        label="Month"
                        component={DropdownField}
                        name="month"
                        type="text"
                        variant="outlined"
                        size="small"
                        fullWidth
                        inputProps={{
                          style: {
                            textAlign: 'center',
                            textTransform: 'capitalize',
                          },
                        }}
                        disabled
                      />
                  </Grid>
                  <Grid item xs={2}>
                    <FastField
                        label="Year"
                        component={DropdownField}
                        name="year"
                        type="text"
                        variant="outlined"
                        size="small"
                        fullWidth
                        inputProps={{
                          style: {
                            textAlign: 'center',
                            textTransform: 'capitalize',
                          },
                        }}
                        disabled
                      />
                  </Grid>
                  <Grid item xs={2}>
                    <FastField
                      name="regular_mechanics"
                      label="Regular Mechanics"
                      component={CheckboxField}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <FastField
                      name="trainee_mechanics"
                      label="Trainee Mechanics"
                      component={CheckboxField}
                      disabled
                    />
                  </Grid>
                </Grid>

                {/* Dynamic Fields */}
                <FieldArray name="incentives">
                    <div>
                      {values.incentives.map((incentive, index) => (
                        <Grid container spacing={3}>
                          <Grid item xs={3}>
                            <FastField
                              label="Target Achievement (%)"
                              component={TextField}
                              name={`incentives.${index}.target_in_percentage`}
                              type="text"
                              variant="outlined"
                              size="small"
                              fullWidth
                              inputProps={{
                                style: {
                                  textAlign: 'center',
                                  textTransform: 'capitalize',
                                },
                              }}
                              disabled
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <FastField
                              label="# of Bookings"
                              component={TextField}
                              name={`incentives.${index}.no_bookings`}
                              type="text"
                              variant="outlined"
                              size="small"
                              fullWidth
                              inputProps={{
                                style: {
                                  textAlign: 'center',
                                  textTransform: 'capitalize',
                                },
                              }}
                              disabled
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <FastField
                              label="Booking Incentive"
                              component={TextField}
                              name={`incentives.${index}.bookings_incentive`}
                              type="text"
                              variant="outlined"
                              size="small"
                              fullWidth
                              inputProps={{
                                style: {
                                  textAlign: 'center',
                                  textTransform: 'capitalize',
                                },
                              }}
                              disabled
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <FastField
                              label="Net Sales"
                              component={TextField}
                              name={`incentives.${index}.net_sales`}
                              type="text"
                              variant="outlined"
                              size="small"
                              fullWidth
                              inputProps={{
                                style: {
                                  textAlign: 'center',
                                  textTransform: 'capitalize',
                                },
                              }}
                              disabled
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <FastField
                              label="Net Sales Incentive"
                              component={TextField}
                              name={`incentives.${index}.net_sales_incentive`}
                              type="text"
                              variant="outlined"
                              size="small"
                              fullWidth
                              inputProps={{
                                style: {
                                  textAlign: 'center',
                                  textTransform: 'capitalize',
                                },
                              }}
                              disabled
                            />
                          </Grid>
                        </Grid>
                      ))}
                    </div>
                </FieldArray>
                <DialogActions>
                  <Button onClick={handleClose} size="small" color="primary" variant="contained">
                    Close
                  </Button>
                </DialogActions>
                </DialogContent>
              </Form>
            )}
          </Formik>
        </Dialog>
      </div>
    </Fragment>
  );
});

export default ViewIncentive;
