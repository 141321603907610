import React, { Fragment, useRef, useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  IconButton,
  Typography,
  Slide,
  Toolbar,
  AppBar,
  Dialog,
  Button,
  Box,
} from "@material-ui/core";
import moment from 'moment'
import MuiDialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import CancelIcon from "@material-ui/icons/Cancel";
import PrintIcon from "@material-ui/icons/Print";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import MaterialTable from "material-table";
import { green } from "@material-ui/core/colors";
// import PrintLayout from "./PrintLayout";
// import './print.css';
import { tableIcons } from "./TableIcons";
import config from "../../utils/config";
import useModal from "../../hooks/use-modal";
// components
import ConfirmDialog from "./confirmDialog/ConfirmDialog";
import CompleteDialog from "./completeDialog/CompleteDialog";
import CancelDialog from "./cancelDialog/CancelDialog";
import DeleteDialog from "./deleteDialog/DeleteDialog";
import AddPaymentDialog from "./addPaymentDialog/AddPaymentDialog";
import DialogConfirmation from "./dialogConfirmation/DialogConfirmation";
import AlertDialog from "../alertDialog/AlertDialog";
import SubJOPrintDialog from "./print/SubJOPrintDialog";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  paper: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(4),
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  button: {
    color: theme.palette.grey[900],
  },
  appBar: {
    position: "relative",
    backgroundColor: "#FAE700",
    color: "#000",
  },
  icon: {
    display: 'flex',
    alignItems: "center"
  },
  subJOsButton: {
    marginLeft: '1rem',
    backgroundColor: green[500],
    color: "#fff",
    "&:hover": {
      backgroundColor: green[600],
    },
  },
  add: {
    color: green[500],
    "&:hover": {
      backgroundColor: "rgba(232, 245, 233,1.0)",
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
      timeout={{ appear: 100, enter: 500, exit: 500 }}
    />
  );
});

const SubJOTable = (props) => {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [dataAddPayment, setDataAddPayment] = useState([]);
  const [openDialogConfirm, setDialogConfirm] = useState(false);

  // ==============MODAL STATES==============
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    typeAlert: "success",
  });

  const { data, open, handleCloseSubJO } = props;

  const tableRef = useRef();
  const token = localStorage.getItem("token");

  // Confirm Dialog
  const {
    modalState: modalStateConfirm,
    modalData: modalDataConfirm,
    openHandler: modalOpenConfirm,
    closeHandler: modalCloseConfirm,
  } = useModal();

  // Add payment dialog
  const {
    modalState: modalStateAdd,
    modalData: modalDataAdd,
    openHandler: modalOpenAddPayment,
    closeHandler: modalCloseAdd,
  } = useModal();

  // Complete Dialog
  const {
    modalState: modalStateComplete,
    modalData: modalDataComplete,
    openHandler: modalOpenComplete,
    closeHandler: modalCloseComplete,
  } = useModal();

  // Cancel Dialog
  const {
    modalState: modalStateCancel,
    modalData: modalDataCancel,
    openHandler: modalOpenCancel,
    closeHandler: modalCloseCancel,
  } = useModal();

  // Print Dialog
  const {
    modalState: modalStatePrint,
    modalData: modalDataPrint,
    openHandler: modalOpenPrint,
    closeHandler: modalClosePrint,
  } = useModal();

  // Delete Dialog
  const {
    modalState: modalStateDelete,
    modalData: modalDataDelete,
    openHandler: modalOpenDelete,
    closeHandler: modalCloseDelete,
  } = useModal();


  const handleConfirm = async (id) => {
    try {
      setIsLoading(true)
      await axios.patch(`${config.api}/appointments/post-dispatch/${id}`, {status: 2}, {
        headers: { Authorization: `Bearer ${token}` },
      })
      setIsLoading(false);
      modalCloseConfirm();
      setAlert({
        ...alert,
        open: true,
        message: "Successfully Confirmed",
        typeAlert: "success",
      });
      tableRef.current && tableRef.current.onQueryChange();
    } catch (error) {
      
    }
  }

  const handleComplete = async (data, id) => {
    try {
      setIsLoading(true)
      await axios.patch(`${config.api}/appointments/post-dispatch/${id}`, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      setIsLoading(false);
      modalCloseComplete();
      setAlert({
        ...alert,
        open: true,
        message: "Successfully Completed",
        typeAlert: "success",
      });
      tableRef.current && tableRef.current.onQueryChange();
    } catch (error) {
      
    }
  }

  const handleCancel = useCallback(
    (data) => {
      let values = {
        id: data.id,
        cancellation_type: data.cancellation_type,
        reason_for_cancellation:
          data.reason_for_cancellation === "others"
            ? data.other_inputs
            : data.reason_for_cancellation,
        status: 6,
      };

      axios
        .patch(`${config.api}/appointments/post-dispatch/${data.id}`, values, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          modalCloseCancel();
          setAlert({
            ...alert,
            open: true,
            message: "Successfully Cancelled",
            typeAlert: "success",
          });
          tableRef.current && tableRef.current.onQueryChange();
        })
        .catch((error) => {
          modalCloseCancel();
          setAlert({
            ...alert,
            open: true,
            message: "Something went wrong please try again.",
            typeAlert: "error",
          });
        });
    },
    [alert, modalCloseCancel, tableRef, token]
  );

  const handleDelete = useCallback(
    (id) => {
      setIsLoading(true);
      axios
        .delete(`${config.api}/appointments/post-dispatch/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setIsLoading(false);
          modalCloseDelete();
          setAlert({
            ...alert,
            open: true,
            message: "Successfully Deleted",
            typeAlert: "success",
          });
          tableRef.current && tableRef.current.onQueryChange();
        })
        .catch((error) => {
          setIsLoading(false);
          modalCloseDelete();
          setAlert({
            ...alert,
            open: true,
            message: "Something went wrong please try again.",
            typeAlert: "error",
          });
        });
    },
    [alert, modalCloseDelete, tableRef, token]
  );

  const handleAddPayment = useCallback((data) => {
    setDataAddPayment(data);
    setDialogConfirm(true);
  }, []);

  const handleCloseDialogConfirm = useCallback(() => {
    setDialogConfirm(false);
    setIsLoading(false);
  }, []);

  // Dialog Confirmation - (Add Payment)
  const handleClickOpenDialog = useCallback(() => {
    setIsLoading(true);

    let payment_status = 3;
    if (dataAddPayment.outstandingBalance > 0) {
      payment_status = 2;
    }

    let data = {
      payment_status: payment_status,
      outstanding_balance: dataAddPayment.outstandingBalance,
      payment: {
        payment_mode: dataAddPayment.modeOfPayment
          ? dataAddPayment.modeOfPayment.replace(/ /g, "_")
          : "",
        payment_ref: dataAddPayment.paymentRefNo,
        payment_date: dataAddPayment.paymentDate,
        amount_paid: dataAddPayment.amountPaid ? dataAddPayment.amountPaid : "",
      },
      discount: dataAddPayment.discount,
    };
    axios
      .patch(`${config.api}/appointments/post-dispatch/${dataAddPayment.id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setTimeout(() => {
          setIsLoading(false);
          setDataAddPayment([]);
          modalCloseAdd();
          setDialogConfirm(false);
          setAlert({
            ...alert,
            open: true,
            message: "Successfully Added",
            typeAlert: "success",
          });
          tableRef.current && tableRef.current.onQueryChange();
        }, 2500);
      })
      .catch((error) => {
        setDataAddPayment([]);
        setIsLoading(false);
        modalCloseAdd();
        setDialogConfirm(false);
        setAlert({
          ...alert,
          open: true,
          message: "Something went wrong please try again.",
          typeAlert: "error",
        });
      });
  }, [
    alert,
    dataAddPayment.amountPaid,
    dataAddPayment.discount,
    dataAddPayment.id,
    dataAddPayment.modeOfPayment,
    dataAddPayment.outstandingBalance,
    dataAddPayment.paymentDate,
    dataAddPayment.paymentRefNo,
    modalCloseAdd,
    tableRef,
    token,
  ]);

  // Alert Dialog
  const handleCloseAlert = useCallback(
    (event, reason) => {
      if (reason === "clickaway") {
        return;
      }

      setAlert({
        ...alert,
        open: false,
        message: "",
      });
    },
    [alert]
  );

  return (
    <Fragment>

      {/* Alert Dialog */}
      <AlertDialog
        open={alert.open}
        typeAlert={alert.typeAlert}
        message={alert.message}
        handleCloseAlert={handleCloseAlert}
      />

      {/* =================Confirm Dialog - (MODAL)================= */}
      <ConfirmDialog
        data={modalDataConfirm}
        isLoading={isLoading}
        open={modalStateConfirm}
        handleConfirm={handleConfirm}
        handleConfirmClose={modalCloseConfirm}
      />

      {/* =================Add Payment Dialog - (MODAL)================= */}
      <AddPaymentDialog
        token={token}
        handleAddPayment={handleAddPayment}
        data={modalDataAdd}
        isLoading={isLoading}
        open={modalStateAdd}
        handleAddPaymentClose={modalCloseAdd}
      />

      {/* =================Complete Dialog- (MODAL)================= */}
      <CompleteDialog
        handleComplete={handleComplete}
        data={modalDataComplete}
        isLoading={isLoading}
        open={modalStateComplete}
        handleCompleteClose={modalCloseComplete}
      />

      {/* =================Dialog Confirmation for Add Payment Dialog- (MODAL)================= */}
      <DialogConfirmation
        openDialogConfirm={openDialogConfirm}
        handleClickOpenDialog={handleClickOpenDialog}
        handleCloseDialogConfirm={handleCloseDialogConfirm}
        isLoading={isLoading}
      />

      {/* =================Cancel Dialog- (MODAL)================= */}
      <CancelDialog
        data={modalDataCancel}
        isLoading={isLoading}
        open={modalStateCancel}
        handleCancel={handleCancel}
        handleCancelClose={modalCloseCancel}
      />

      {/* =================Print Dialog- (MODAL)================= */}
      <SubJOPrintDialog
        token={token}
        data={modalDataPrint}
        open={modalStatePrint}
        handleClosePrint={modalClosePrint}
      />

      {/* =================Delete Dialog- (MODAL)================= */}
      <DeleteDialog
        data={modalDataDelete}
        isLoading={isLoading}
        open={modalStateDelete}
        handleDelete={handleDelete}
        handleDeleteClose={modalCloseDelete}
      />

      <div>
        <Dialog
          fullScreen
          open={open}
          onClose={handleCloseSubJO}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar style={{ justifyContent: 'space-between'}}>
              <Box display={'flex'} alignItems={'center'}>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleCloseSubJO}
                  aria-label="close"
                >
                  <CloseIcon className={classes.button} />
                </IconButton>
                <Typography variant="h5" className={classes.icon}>
                  LINKED JOB ORDERS
                </Typography>
              </Box>
              <Button onClick={handleCloseSubJO}>CLOSE</Button>
            </Toolbar>
          </AppBar>
          <MuiDialogContent dividers>
            <MaterialTable
                icons={tableIcons}
                title=""
                tableRef={tableRef}
                columns={[
                  {
                    title: "SUB JO BS #",
                    field: 'bs_number'
                  },
                  {
                    title: "Date and Time ",
                    render: (data) => moment(data.appointment_date).format("MM-DD-YY hh:mm A")
                  },
                  {
                    title: "Status",
                    field: 'status.name'
                  },
                  {
                    title: "Invoice Amount",
                    field: 'invoice_amount'
                  },
                ]}
                data={(query) =>
                  new Promise((resolve, reject) => {
                    let url = config.api + `/appointments/post-dispatch/${data.id}?`;
                    url += `page=${query.page + 1}`;
                    if (query.pageSize) {
                      url += `&per_page=${query.pageSize}`;
                    }
                    const options = {
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                      },
                    };
                    fetch(url, options)
                      .then((response) => response.json())
                      .then((result) => {
                        resolve({
                          data: result.data.data,
                          page: result.data.current_page - 1 || 0,
                          totalCount: result.data.total,
                        });
                      });
                  })
                }
                actions={[
                  {
                    icon: "confirm",
                    tooltip: "Confirm",
                    onClick: (event, rowData) => {
                      modalOpenConfirm(rowData);
                    },
                  },
                  {
                    icon: "add",
                    tooltip: "Add Payment",
                    onClick: (event, rowData) => {
                      modalOpenAddPayment(rowData);
                    },
                  },
                  {
                    icon: "complete",
                    tooltip: "Complete",
                    onClick: (event, rowData) => {
                        modalOpenComplete(rowData);
                    },
                  },
                  {
                    icon: "cancel",
                    tooltip: "Cancel",
                    onClick: (event, rowData) => {
                      modalOpenCancel(rowData);
                    },
                  },
                  {
                    icon: "print",
                    tooltip: "print",
                    onClick: (event, rowData) => {
                      modalOpenPrint(rowData);
                    },
                  },
                  {
                    icon: "delete",
                    tooltip: "Delete",
                    onClick: (event, rowData) => {
                      modalOpenDelete(rowData);
                    },
                  },
                ]}
                components={{
                  Action: (props) => {
                    // =====================Confirm=====================
                    if (props.action.icon === "confirm") {
                      return (
                        <Tooltip title="Confirm">
                          <IconButton
                            aria-label="confirm"
                            color="primary"
                            size="small"
                            onClick={(event) => props.action.onClick(event, props.data)}
                            disabled={props.data?.status?.id !== 1}
                          >
                            <CheckCircleIcon />
                          </IconButton>
                        </Tooltip>
                      );
                    }
                    // =====================ADD=====================
                    if (props.action.icon === "add") {
                      return (
                        <Tooltip title="Add Payment">
                          <IconButton
                            aria-label="add payment"
                            className={classes.add}
                            size="small"
                            onClick={(event) => props.action.onClick(event, props.data)}
                            disabled={
                              props.data?.status?.id === 4 ||
                              props.data?.status?.id === 6 ||
                              props.data.payment_status_id === 3
                              // props.data.payment_method === "payment_paypal"
                            }
                          >
                            <MonetizationOnIcon />
                          </IconButton>
                        </Tooltip>
                      );
                    }
                    // =====================Complete=====================
                    if (props.action.icon === "complete") {
                      return (
                        <Tooltip title="Complete">
                          <IconButton
                            aria-label="complete"
                            className={classes.add}
                            size="small"
                            onClick={(event) => props.action.onClick(event, props.data)}
                            disabled={
                              props.data?.status?.id === 1 ||
                              props.data?.status?.id === 5 ||
                              props.data?.status?.id === 4 ||
                              props.data?.status?.id === 6
                            }
                          >
                            <DoneAllIcon />
                          </IconButton>
                        </Tooltip>
                      );
                    }
                    // =====================Cancel=====================
                    if (props.action.icon === "cancel") {
                      return (
                        <Tooltip title="Cancel">
                          <IconButton
                            aria-label="cancel"
                            color="secondary"
                            size="small"
                            onClick={(event) => props.action.onClick(event, props.data)}
                            disabled={
                              props.data?.status?.id === 4 ||
                              props.data?.status?.id === 6
                            }
                          >
                            <CancelIcon />
                          </IconButton>
                        </Tooltip>
                      );
                    }
                    // =====================Print=====================
                    if (props.action.icon === "print") {
                      return (
                        <Tooltip title="Print">
                          <IconButton
                            aria-label="print"
                            color="primary"
                            size="small"
                            onClick={(event) => props.action.onClick(event, props.data)}
                          >
                            <PrintIcon />
                          </IconButton>
                        </Tooltip>
                      );
                    }
                    // =====================Delete=====================
                    if (props.action.icon === "delete") {
                      return (
                        <Tooltip title="Delete">
                          <IconButton
                            aria-label="delete"
                            color="secondary"
                            size="small"
                            className={classes.delete}
                            onClick={(event) =>
                              props.action.onClick(event, props.data)
                            }
                          >
                            <DeleteOutlineOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      );
                    }
                  },
                }}
                options={{
                  headerStyle: {
                    fontWeight: "bold",
                  },
                  rowStyle: {
                    fontSize: ".75rem",
                    padding: "0px !important",
                  },
                  actionsCellStyle: {
                    justifyContent: "center",
                    padding: "8px",
                    marginBottom: "-1px",
                  },
                  pageSize: 10,
                  pageSizeOptions: [],
                  actionsColumnIndex: -1,
                  tableLayout: "auto",
                  search: false,
                }}
              />
          </MuiDialogContent>
        </Dialog>
      </div>
    </Fragment>
  );
};

export default SubJOTable;
