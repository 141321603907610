import React, { Fragment, useState, useCallback } from "react";
import MaterialTable from "material-table";
import moment from "moment";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { tableIcons } from "../booking/TableIcons";
import { green, yellow } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import useModal from "../../hooks/use-modal";
import AlertDialog from "../booking/AlertDialog";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import CancelIcon from "@material-ui/icons/Cancel";
import UserUpdate from "./userUpdate/UserUpdate";
import UserDelete from "./userDelete/UserDelete";

// Api config
import config from "../../utils/config";

const useStyles = makeStyles((theme) => ({
  update: {
    color: yellow["A200"],
    "&:hover": {
      background: "rgba(255, 253, 231,1.0)",
    },
  },
  sizeSmall: {
    height: "18px",
    fontSize: "12px",
  },
}));

const UsersTable = React.memo((props) => {
  const classes = useStyles();

  const { tableRef, token } = props;

  // ==============MODAL STATES==============
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [typeAlert, setTypeAlert] = useState("success");

  const {
    modalState: modalStateUpdate,
    modalData: modalDataUpdate,
    openHandler: modalOpenUpdate,
    closeHandler: modalCloseUpdate,
  } = useModal();

  const {
    modalState: modalStateDelete,
    modalData: modalDataDelete,
    openHandler: modalOpenDelete,
    closeHandler: modalCloseDelete,
  } = useModal();

  // =================Update User - (Function)=================
  const handleUpdateUser = useCallback((values, actions) => {
    console.log(values, actions);
    let data = values;
    axios
    .patch(`${config.api}/users/${data.id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      modalCloseUpdate();
      setAlert(true);
      setMessage("Successfully Updated");
      setTypeAlert("success");
      tableRef.current && tableRef.current.onQueryChange();
    })
    .catch((error) => {
      modalCloseUpdate();
      setAlert(true);
      setTypeAlert("error");
      setMessage("Something went wrong please try again.");
      tableRef.current && tableRef.current.onQueryChange();
    });
  }, [modalCloseUpdate, tableRef, token]);

  // =================Delete User - (Function)=================
  const handleDeleteUser = useCallback(
    async (id) => {
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        const resp = await axios.delete(
          `${config.api}/users/${id}`,
          options
        );
        tableRef.current && tableRef.current.onQueryChange();
        modalCloseDelete();
        setAlert(true);
        setMessage("Successfully Deleted");
        setTypeAlert("success");
      } catch (err) {
        // Handle Error Here
        console.error(err);
        tableRef.current && tableRef.current.onQueryChange();
        modalCloseDelete();
        setAlert(true);
        setTypeAlert("error");
        setMessage("Something went wrong please try again.");
      }
    },
    [modalCloseDelete, tableRef, token]
  );

  // =================ALERT - (MODAL)=================
  const handleCloseAlert = useCallback(() => {
    setAlert(false);
    setMessage("");
    setTypeAlert("success");
  }, []);

  return (
    <Fragment>
      <AlertDialog
        open={alert}
        typeAlert={typeAlert}
        message={message}
        handleCloseAlert={handleCloseAlert}
      />

      {/* =================Update User - (MODAL)================= */}
      <UserUpdate
        open={modalStateUpdate}
        data={modalDataUpdate}
        onClose={modalCloseUpdate}
        onUpdate={handleUpdateUser}
      />

      {/* =================Delete User - (MODAL)================= */}
      <UserDelete
        open={modalStateDelete}
        data={modalDataDelete}
        onClose={modalCloseDelete}
        onDelete={handleDeleteUser}
      />

      <MaterialTable
        icons={tableIcons}
        title={`Users Table`}
        tableRef={tableRef}
        columns={[
          { title: "Name", field: "name" },
          { title: "Email", field: "email" },
          { title: "Phone", field: "phone" },
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            let url = config.api + "/users?";
            url += `&page=${query.page + 1}`;
            if (query.pageSize) {
              url += `&per_page=${query.pageSize}`;
            }
            if (query.search) {
              url += `&search=${query.search}`;
            }
            const options = {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            };

            fetch(url, options)
              .then((response) => response.json())
              .then((result) => {
                resolve({
                  data: result.data.data,
                  page: result.data.current_page - 1 || 0,
                  totalCount: result.data.total,
                });
              });
          })
        }
        actions={[
          {
            icon: "update",
            tooltip: "Update",
            onClick: (event, rowData) => {
              modalOpenUpdate(rowData);
            },
          },
          {
            icon: "delete",
            tooltip: "Delete",
            onClick: (event, rowData) => {
              modalOpenDelete(rowData);
            },
          },
        ]}
        components={{
          Action: (props) => {
            // =====================View=====================
            if (props.action.icon === "update") {
              return (
                <Tooltip title="Update">
                  <IconButton
                    aria-label="update"
                    className={classes.update}
                    size="small"
                    onClick={(event) => props.action.onClick(event, props.data)}
                  >
                    <EditOutlinedIcon />
                  </IconButton>
                </Tooltip>
              );
            }
            // =====================Delete=====================
            if (props.action.icon === "delete") {
              return (
                <Tooltip title="Delete">
                  <IconButton
                    aria-label="delete"
                    color="secondary"
                    size="small"
                    onClick={(event) => props.action.onClick(event, props.data)}
                  >
                    <CancelIcon />
                  </IconButton>
                </Tooltip>
              );
            }
          },
        }}
        options={{
          headerStyle: {
            fontWeight: "bold",
          },
          rowStyle: {
            fontSize: ".75rem",
            padding: "0px !important",
          },
          actionsCellStyle: {
            justifyContent: "center",
            padding: "4px",
            marginBottom: "-1px",
          },
          pageSize: 20,
          pageSizeOptions: [],
          actionsColumnIndex: -1,
          tableLayout: "auto",
          sorting: false
        }}
      />
    </Fragment>
  );
});

export default UsersTable;
