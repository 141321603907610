import React, { useState } from "react";
import {
  ButtonGroup,
  Button,
  Box,
  Popper,
  Paper,
  Grow,
  ClickAwayListener,
  MenuList,
  MenuItem,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import { useSelector } from "react-redux";
import axios from "axios";
import config from "../../../utils/config";
import moment from "moment";

const ExportAppointment = () => {
  const [open, setOpen] = useState(false);
  const anchorRef = React.useRef(null);

  const bookFilter = useSelector((state) => state.bookFilter);
  const token = localStorage.getItem("token");
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleMenuItemClick = (type) => {
    let url = config.api + "/appointments/export?";
    if (type === "pdf") {
      url += `format_type=${type}`;
    } else {
      url += `format_type=${type}`;
    }
    if (bookFilter && bookFilter.orderStatus !== "all") {
      url += `&status=${bookFilter.orderStatus}`;
    }
    if (bookFilter && bookFilter.paymentStatus !== "all") {
      url += `&payment_status=${bookFilter.paymentStatus}`;
    }
    if (
      bookFilter &&
      bookFilter.search &&
      bookFilter.bookingDateFrom &&
      bookFilter.bookingDateTo
    ) {
      url += `&booking_date_from=${bookFilter.bookingDateFrom}&booking_date_to=${bookFilter.bookingDateTo}`;
    }

    if (
      bookFilter &&
      bookFilter.search &&
      bookFilter.appointmentDateFrom &&
      bookFilter.appointmentDateTo
    ) {
      url += `&appointment_date_from=${bookFilter.appointmentDateFrom}&appointment_date_to=${bookFilter.appointmentDateTo}`;
    }

    if (bookFilter && bookFilter.search && bookFilter.appointmentTime) {
      const time = moment(bookFilter.appointmentTime).format("HH:mm:ss");
      url += `&appointment_time=${time}`;
    }
    if (bookFilter && bookFilter.search && bookFilter.serviceLocation.length) {
      url += `&service_location=${bookFilter.serviceLocation}`;
    }
    if (bookFilter && bookFilter.search && bookFilter.transactionType.length) {
      url += `&transaction_type=${bookFilter.transactionType}`;
    }
    if (bookFilter && bookFilter.search && bookFilter.mechanic) {
      url += `&mechanic_id=${bookFilter.mechanic}`;
    }
    if (bookFilter && bookFilter.search && bookFilter.salesAgent) {
      url += `&sales_agent_id=${bookFilter.salesAgent}`;
    }

    exportAppointments(type, url);
  };

  const exportAppointments = async (type, url) => {
    try {
      const response = await axios.get(`${url}`, {
        responseType: "blob", // Important to get the response as a Blob
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Create a Blob from the response data
      const blobOptions =
        type === "pdf"
          ? { type: "application/pdf" }
          : {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            };

      const blob = new Blob([response.data], blobOptions);

      // Create a link element
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);

      // Set the download attribute with a dynamic filename
      const timestamp = new Date()
        .toISOString()
        .replace(/[:\-T.]/g, "")
        .slice(0, 14);
      link.setAttribute(
        "download",
        `appointments-${timestamp}.${type === "pdf" ? "pdf" : "xlsx"}`
      );

      // Append the link to the document body
      document.body.appendChild(link);

      // Trigger the download by simulating a click
      link.click();

      // Clean up by removing the link element
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading the Excel file", error);
    }
  };

  return (
    <Box>
      <ButtonGroup
        variant="contained"
        color="primary"
        ref={anchorRef}
        aria-label="split button"
      >
        <Button
          color="primary"
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          Export
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: 1300 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  <MenuItem key={0} onClick={() => handleMenuItemClick("xlsx")}>
                    XLSX
                  </MenuItem>
                  <MenuItem key={1} onClick={() => handleMenuItemClick("pdf")}>
                    PDF
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};

export default ExportAppointment;
