import React, { useState, useEffect } from "react";
import { TextField } from "@material-ui/core";
import config from "../../utils/config";
import axios from "axios";
import Autocomplete from "@material-ui/lab/Autocomplete";

const SelectServices = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, values, setFieldValue },
  ...props
}) => {
  const token = localStorage.getItem("token");
  const [services, setServices] = useState([]);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    async function fetchData() {
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const {
        data: { data },
      } = await axios.get(`${config.api}/services/all?limit=10000`, options);
      setInputValue(values?.service_name);
      setServices(data.data);
    }
    fetchData();
  }, []);

  return (
    <Autocomplete
      id="service"
      size="small"
      options={services}
      getOptionLabel={(option) => option.name}
      value={
        values.service !== undefined && values.service !== null
          ? values.service
          : null
      }
      clearOnEscape
      onChange={(event, value) => {
        if (value) {
          setFieldValue("service", value);
          setInputValue(value.name);
        } else {
          setFieldValue("service", null); // Clear the field value when Autocomplete is cleared
          setInputValue("");
        }
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        if (newInputValue) {
          setInputValue(newInputValue ?? values.service_name);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Service"
          variant="outlined"
          error={typeof errors.service === "string"}
          helperText={typeof errors.service === "string" ? errors.service : ""}
        />
      )}
    />
  );
};
export default SelectServices;
