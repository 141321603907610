import React, { Fragment, useCallback, useState } from "react";
import axios from "axios";
import MaterialTable from "material-table";
import { makeStyles } from "@material-ui/core/styles";
import { tableIcons } from "../booking/TableIcons";
import useModal from "../../hooks/use-modal";
import AlertDialog from "../alertDialog/AlertDialog";
import ScheduleSubTable from "./scheduleSubTable/ScheduleSubTable";
import ScheduleUpdate from "./scheduleUpdate/ScheduleUpdate";
import ScheduleDelete from "./scheduleDelete/ScheduleDelete";
import IconButton from "@material-ui/core/IconButton";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import CancelIcon from "@material-ui/icons/Cancel";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Tooltip from "@material-ui/core/Tooltip";
import { green, yellow } from "@material-ui/core/colors";

import ViewSchedule from "./viewSchedule/viewSchedule";

// Api config
import config from "../../utils/config";

const useStyles = makeStyles((theme) => ({
  view: {
    color: yellow["000"],
    maxHeight: "2px !important",
    "&:hover": {
      background: "none !important",
    },
  },
  update: {
    color: yellow["A200"],
    maxHeight: "2px !important",
    "&:hover": {
      background: "none !important",
    },
  },
  delete: {
    maxHeight: "2px !important",
    "&:hover": {
      background: "none !important",
    },
  },
  add: {
    color: green[500],
    "&:hover": {
      backgroundColor: "rgba(232, 245, 233,1.0)",
    },
  },
  leave: {
    maxHeight: "2px !important",
    "&:hover": {
      background: "none !important",
    },
  },
  sizeSmall: {
    height: "18px",
    fontSize: "12px",
  },
}));

const ScheduleTable = React.memo((props) => {
  const classes = useStyles();
  const { tableRef, token, hubId } = props;

  // ==============MODAL STATES==============
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    typeAlert: "success",
  });

  const {
    modalState: modalStateView,
    modalData: modalDataView,
    openHandler: modalOpenView,
    closeHandler: modalCloseView,
  } = useModal();

  const {
    modalState: modalStateUpdate,
    modalData: modalDataUpdate,
    openHandler: modalOpenUpdate,
    closeHandler: modalCloseUpdate,
  } = useModal();

  const {
    modalState: modalStateDelete,
    modalData: modalDataDelete,
    openHandler: modalOpenDelete,
    closeHandler: modalCloseDelete,
  } = useModal();

  // Update Dialog
  const handleUpdateSchedule = useCallback(
    (data, actions) => {
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      axios
        .put(`${config.api}/schedules/${data.id}`, data, options)
        .then((response) => {
          modalCloseView();
          setAlert({
            ...alert,
            open: true,
            message: "Successfully Updated",
            typeAlert: "success",
          });
          tableRef.current && tableRef.current.onQueryChange();
        })
        .catch((error) => {
          actions.setSubmitting(false);
          modalCloseView();
          setAlert({
            ...alert,
            open: true,
            message: "Something went wrong please try again.",
            typeAlert: "error",
          });
        });
    },
    [alert, tableRef, modalCloseView, token]
  );

  // =================Delete - (MODAL)=================
  const handleDeleteSchedule = useCallback(
    async (id) => {
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        const resp = await axios.delete(
          `${config.api}/schedules/${id}`,
          options
        );
        tableRef.current && tableRef.current.onQueryChange();
        if (resp.status === 201) {
          modalCloseDelete();
          setAlert({
            ...alert,
            open: true,
            message: "Successfully Deleted",
            typeAlert: "success",
          });
        }
      } catch (err) {
        modalCloseDelete();
        tableRef.current && tableRef.current.onQueryChange();
        setAlert({
          ...alert,
          open: true,
          message: "Something went wrong please try again.",
          typeAlert: "error",
        });
      }
    },
    [alert, modalCloseDelete, tableRef, token]
  );

  // Alert Dialog
  const handleCloseAlert = useCallback(
    (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setAlert({
        ...alert,
        open: false,
        message: "",
      });
    },
    [alert]
  );

  return (
    <Fragment>
      {/* Alert Dialog */}
      <AlertDialog
        open={alert.open}
        typeAlert={alert.typeAlert}
        message={alert.message}
        handleCloseAlert={handleCloseAlert}
      />

      {/* =================View Schedule - (MODAL)================= */}
      <ViewSchedule
        open={modalStateView}
        data={modalDataView}
        onClose={modalCloseView}
        onUpdate={handleUpdateSchedule}
      />

      {/* =================Update Schedule - (MODAL)================= */}
      {/* <ScheduleUpdate
        open={modalStateUpdate}
        data={modalDataUpdate}
        onClose={modalCloseUpdate}
        onUpdate={handleUpdateSchedule}
      /> */}

      {/* =================Delete - (MODAL)================= */}
      <ScheduleDelete
        open={modalStateDelete}
        data={modalDataDelete}
        onClose={modalCloseDelete}
        onDelete={handleDeleteSchedule}
      />

      <MaterialTable
        icons={tableIcons}
        title={`Schedules Table`}
        tableRef={tableRef}
        columns={[
          {
            title: "Schedule From",
            render: (client) => {
              let schedule_from = `${client.schedule_from}`;
              return schedule_from;
            },
          },
          {
            title: "Schedule To",
            render: (client) => {
              let schedule_to = `${client.schedule_to}`;
              return schedule_to;
            },
          },
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            let url = config.api + "/schedules?";
            url += `page=${query.page + 1}`;
            if (query.pageSize) {
              url += `&per_page=${query.pageSize}`;
            }
            if (query.search) {
              url += `&search=${query.search}`;
            }
            url += `&hubId=${hubId}`;
            const options = {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            };
            fetch(url, options)
              .then((response) => response.json())
              .then((result) => {
                resolve({
                  data: result.data.data,
                  page: result.data.current_page - 1 || 0,
                  totalCount: result.data.total,
                });
              });
          })
        }
        actions={[
          // {
          //   icon: "view",
          //   tooltip: "View",
          //   onClick: (event, rowData) => {
          //     modalOpenView(rowData);
          //   },
          // },
          {
            icon: "update",
            tooltip: "Update",
            onClick: (event, rowData) => {
              modalOpenView(rowData);
            },
          },
          {
            icon: "delete",
            tooltip: "Delete",
            onClick: (event, rowData) => {
              modalOpenDelete(rowData);
            },
          },
        ]}
        components={{
          Action: (props) => {
            // =====================View=====================
            if (props.action.icon === "view") {
              return (
                <Tooltip title="View">
                  <IconButton
                    aria-label="view"
                    className={classes.view}
                    size="small"
                    onClick={(event) => props.action.onClick(event, props.data)}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>
              );
            }
            // =====================Update=====================
            if (props.action.icon === "update") {
              return (
                <Tooltip title="Update">
                  <IconButton
                    aria-label="update"
                    className={classes.update}
                    size="small"
                    onClick={(event) => props.action.onClick(event, props.data)}
                  >
                    <EditOutlinedIcon />
                  </IconButton>
                </Tooltip>
              );
            }
            // =====================Delete=====================
            if (props.action.icon === "delete") {
              return (
                <Tooltip title="Delete">
                  <IconButton
                    aria-label="delete"
                    color="secondary"
                    size="small"
                    className={classes.delete}
                    onClick={(event) => props.action.onClick(event, props.data)}
                  >
                    <CancelIcon />
                  </IconButton>
                </Tooltip>
              );
            }
          },
        }}
        options={{
          headerStyle: {
            fontWeight: "bold",
          },
          rowStyle: {
            fontSize: ".75rem",
            padding: "0px !important",
          },
          actionsCellStyle: {
            justifyContent: "center",
            padding: "24px",
            marginBottom: "-1px",
          },
          pageSize: 10,
          pageSizeOptions: [],
          actionsColumnIndex: -1,
          tableLayout: "auto",
          search: false,
          sorting: false,
        }}
        detailPanel={(rowData) => (
          <ScheduleSubTable tableRef={tableRef} token={token} data={rowData} />
        )}
      />
    </Fragment>
  );
});

export default ScheduleTable;
