import { MenuItem, TextField } from "@material-ui/core";
import { useField } from "formik";

const SelectWrapper = ({ name, options, ...props }) => {
  const [field, meta] = useField(name);

  return (
    <TextField
      select
      variant="outlined"
      size="small"
      fullWidth
      name={name}
      {...field}
      {...props}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      style={{ textAlign: "center" }}
      FormHelperTextProps={{
        style: {
          textAlign: "center",
        },
      }}
    >
      {options.map((option) => (
        <MenuItem
          value={option.value}
          style={{ justifyContent: "center" }}
          key={option.value}
        >
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default SelectWrapper;
