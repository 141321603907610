// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#AppointmentTimePicker_appointment_time__2kZ6i {\n  text-align: center !important;\n}\n", "",{"version":3,"sources":["webpack://src/components/booking/appointmentTimePicker/AppointmentTimePicker.module.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;AAC/B","sourcesContent":["#appointment_time {\n  text-align: center !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appointment_time": "AppointmentTimePicker_appointment_time__2kZ6i"
};
export default ___CSS_LOADER_EXPORT___;
