import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { MenuItem, TextField } from "@material-ui/core";

const MenuItemStyle = withStyles({
  root: {
    justifyContent: "center",
  },
})(MenuItem);

const CustomPaymentMethodComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, values }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const modes = [
    { value: "paynamics", label: "Paynamics" },
    { value: "paypal", label: "Paypal" },
    { value: "Gcash/Paymaya", label: "Gcash/Paymaya" },
    { value: "fund_transfer", label: "Fund Transfer" },
    { value: "cash_after_service", label: "Cash After Service" },
    { value: "Credit_Card_(via_POS)", label: "Credit Card (via POS)" },
    {
      value: "Credit_Card_Installment_3mos(via_POS)",
      label: "Installment (via POS) 3mos",
    },
    {
      value: "Credit_Card_Installment_6mos(via_POS)",
      label: "Installment (via POS) 6mos",
    },
    {
      value: "purchase_order",
      label: "Purchase Order",
    },
    {
      value: "payment_link_(2c2p)",
      label: "Payment Link (2c2p)",
    },
  ];
  return (
    <TextField
      fullWidth
      select
      error={errors.payment_method && touched.payment_method}
      {...field}
      {...props}
    >
      {modes.map((option) => (
        <MenuItemStyle key={option.value} value={option.value}>
          {option.label}
        </MenuItemStyle>
      ))}
    </TextField>
  );
};
export default CustomPaymentMethodComponent;
