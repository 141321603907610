import React, { Fragment } from "react";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Field, ErrorMessage, FieldArray } from "formik";
import {
  Grid,
  FormControl,
  MenuItem,
  TextField as MuiTextField,
} from "@material-ui/core";
import { TextField } from "formik-material-ui";

const MenuItemStyle = withStyles({
  root: {
    justifyContent: "center",
  },
})(MenuItem);

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center !important",
  },
  disabledRoot: {
    "& .MuiOutlinedInput-input": {
      color: "#000",
    },
  },
}));

const DatePickerField = ({ field, form, ...other }) => {
  const currentError = form.errors[field.name];
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        label="Payment Date"
        size="small"
        name={field.name}
        value={field.value}
        format="dd/MM/yyyy"
        helperText={currentError}
        error={Boolean(currentError)}
        variant="inline"
        inputVariant="outlined"
        onChange={(stringDate, date) => {
          let dateData = moment(stringDate).format("yyyy-MM-DD HH:mm:ss");
          form.setFieldValue("paymentDate", dateData);
        }}
        inputProps={{ readOnly: true, style: { textAlign: "center" } }}
        {...other}
      />
    </MuiPickersUtilsProvider>
  );
};

const CustomModeOfPaymentComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const classes = useStyles();

  const modes = [
    { value: "paypal", label: "Paypal" },
    { value: "Gcash/Paymaya", label: "Gcash/Paymaya" },
    { value: "fund_transfer", label: "Fund Transfer" },
    { value: "cash_after_service", label: "Cash After Service" },
    { value: "Credit Card (via POS)", label: "Credit Card (via POS)" },
    {
      value: "Credit_Card_Installment_3mos(via_POS)",
      label: "Installment (via POS) 3mos",
    },
    {
      value: "Credit_Card_Installment_6mos(via_POS)",
      label: "Installment (via POS) 6mos",
    },
    {
      value: "purchase_order",
      label: "Purchase Order",
    },
    {
      value: "payment_link_(2c2p)",
      label: "Payment Link (2c2p)",
    },
  ];
  return (
    <div className={classes.root}>
      <MuiTextField
        select
        error={errors.modeOfPayment && touched.modeOfPayment}
        helperText={errors && errors.modeOfPayment}
        {...field}
        {...props}
        inputProps={{
          style: {
            textAlign: "center",
          },
        }}
      >
        {modes.map((option) => (
          <MenuItemStyle key={option.value} value={option.value}>
            {option.label}
          </MenuItemStyle>
        ))}
      </MuiTextField>
    </div>
  );
};

const AddPaymentForm = (props) => {
  const classes = useStyles();
  const { errors, touched, handleChangePayment, payment, values } = props;
  return (
    <Fragment>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <Field
              component={TextField}
              name="invoiceAmount"
              size="small"
              fullWidth
              label="Invoice Amount"
              variant="outlined"
              error={errors.invoiceAmount && touched.invoiceAmount}
              helperText={<ErrorMessage name="invoiceAmount" />}
              disabled
              InputProps={{
                classes: {
                  root: classes.disabledRoot,
                },
              }}
              inputProps={{ style: { textAlign: "center" } }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <Field
              component={TextField}
              name="outstandingBalance"
              size="small"
              fullWidth
              label="Outstanding Balance"
              variant="outlined"
              error={
                errors.outstandingBalance || errors.balanceCheck ? true : false
              }
              helperText={
                errors.outstandingBalance ? (
                  <ErrorMessage name="outstandingBalance" />
                ) : errors.balanceCheck ? (
                  "Outstanding reached the limit."
                ) : (
                  ""
                )
              }
              disabled
              InputProps={{
                classes: {
                  root: classes.disabledRoot,
                },
              }}
              inputProps={{ style: { textAlign: "center" } }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <Field
              name="modeOfPayment"
              component={CustomModeOfPaymentComponent}
              variant="outlined"
              size="small"
              fullWidth
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <Field name="paymentDate" component={DatePickerField} required />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <Field
              component={TextField}
              name="paymentRefNo"
              size="small"
              fullWidth
              label="Payment Ref. No"
              variant="outlined"
              error={errors.paymentRefNo && touched.paymentRefNo}
              helperText={<ErrorMessage name="paymentRefNo" />}
              inputProps={{ style: { textAlign: "center" } }}
              required
              // disabled
              InputProps={{
                classes: {
                  root: classes.disabledRoot,
                },
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <Field
              component={TextField}
              name="amountPaid"
              size="small"
              fullWidth
              label="Amount Paid"
              variant="outlined"
              error={errors.amountPaid && touched.amountPaid}
              helperText={<ErrorMessage name="amountPaid" />}
              onChange={handleChangePayment}
              value={payment}
              inputProps={{ style: { textAlign: "center" } }}
            />
          </FormControl>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default AddPaymentForm;
