import React, {
  useEffect,
  useState,
  Fragment,
  useRef,
  useCallback,
} from "react";
import moment from "moment";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import { makeStyles } from "@material-ui/core/styles";
import {
  IconButton,
  Typography,
  Slide,
  Toolbar,
  AppBar,
  Dialog,
  Button,
  Box,
  Paper,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  TextField,
} from "@material-ui/core";
import AlertDialog from "../../alertDialog/AlertDialog";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MuiDialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Tooltip from "@material-ui/core/Tooltip";
import { green } from "@material-ui/core/colors";
import { Formik, Form, FieldArray } from "formik";
import { object, array, string, date, number } from "yup";

// Api config
import config from "../../../utils/config";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  paper: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(4),
  },
  appBar: {
    position: "relative",
    backgroundColor: "#FAE700",
    color: "#000",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  button: {
    color: theme.palette.grey[900],
  },
  icon: {
    position: "absolute",
    top: "18px",
    marginLeft: "3px",
  },
  titleText: {
    fontWeight: "bold",
  },
  text: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    fontSize: "14px !important",
  },
  add: {
    backgroundColor: green[500],
    color: "#fff",
    "&:hover": {
      backgroundColor: green[600],
    },
  },
  largeIcon: {
    width: "30px !important",
    height: "30px !important",
  },
  timepicker: {
    "&.error": {
      "& .MuiInput-underline:before": {
        borderBottomColor: "red",
        borderWidth: "2px",
      },
      "& .MuiInput-underline:after": { borderBottomColor: "red" },
    },
    "& input": {
      fontSize: "0.875rem",
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
      timeout={{ appear: 100, enter: 500, exit: 500 }}
    />
  );
});

const checkAvailability = async (
  id,
  start_datetime,
  checkAvailable,
  token,
  appointment_id
) => {
  let options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  let request = await axios.get(config.api + "/mechanics", options);
  let data = request.data.data.data;

  let check = null;

  let mechanics = data.filter((mechanic) => mechanic.id === id);
  let current_mechanic = mechanics.length > 0 ? mechanics[0] : null;

  let checkAssignments;

  if (current_mechanic) {
    checkAssignments = current_mechanic.assignments.filter(
      (value) => value.appointment_id !== appointment_id
    );
  } else {
    checkAssignments = [];
  }

  if (checkAssignments.length > 0) {
    let assignments = checkAssignments;

    let startTime = Date.parse(start_datetime);
    let checkDate = Date.parse(checkAvailable); // end time

    assignments.forEach((assignment) => {
      var existingDateStart = Date.parse(assignment.start_datetime); // existing start time
      var existingDateEnd = Date.parse(assignment.end_datetime); // existing end time

      if (
        (startTime <= existingDateStart && checkDate <= existingDateStart) ||
        (startTime >= existingDateEnd && checkDate >= existingDateEnd)
      ) {
        check = true;
      } else {
        check = false;
      }
    });
  } else {
    check = true;
  }
  return check;
};

const AssignDialog = (props) => {
  const classes = useStyles();

  const {
    data,
    open,
    handleAssignClose,
    handleAssignUpdate,
    isLoading,
    token,
  } = props;

  const ref0 = useRef();
  const [mechanics, setMechanics] = useState([]);
  const [selected, setSelected] = useState(null);

  // ==============MODAL STATES==============
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    typeAlert: "success",
  });

  let date_appointment = new Date(data.appointment_date);
  let hours_min = date_appointment.getHours();

  // Alert Dialog
  const handleCloseAlert = useCallback(
    (event, reason) => {
      if (reason === "clickaway") {
        return;
      }

      setAlert({
        ...alert,
        open: false,
        message: "",
      });
    },
    [alert]
  );

  const dataVal =
    data &&
    data.appointment_mechanics &&
    data.appointment_mechanics.map((value) => {
      let end_time = new Date(data.appointment_date);

      var year = end_time.getFullYear();
      var month = end_time.getMonth();
      var day = end_time.getDate();
      var hour = end_time.getHours();
      const new_date = new Date(year, month, day, hour, 0, 0, 0);
      let hoursVal = moment(new_date).format("yyyy-MM-DD HH:mm:ss");
      return {
        id: value.mechanic.id,
        mobile: value.mechanic.mobile,
        name: `${value.mechanic.firstname} ${value.mechanic.lastname}`,
        garage_name: value.mechanic.garage.name,
        start_datetime: value.start_datetime ? value.start_datetime : hoursVal,
        end_datetime: value.end_datetime ? value.end_datetime : "",
        assignments: [],
      };
    });

  const initialValues = {
    id: data.id,
    mechanics: dataVal,
  };

  const validationSchema = object().shape({
    mechanics: array(
      object({
        id: number().required("Required id data."),
        mobile: string().required("Required mobile data."),
        name: string().required("Required name data."),
        garage_name: string().required("Required garage_name data."),
        start_datetime: date().required(),
        assignments: array(object({})).nullable(),
      })
    )
      .min(1, "Please fill up at least 1 mechanic.")
      .required(),
  });

  // refactor this to use yup conditional to require end_datetime
  const validationSchema2 = object().shape({
    mechanics: array(
      object({
        id: number().required("Required id data."),
        mobile: string().required("Required mobile data."),
        name: string().required("Required name data."),
        garage_name: string().required("Required garage_name data."),
        start_datetime: date().required(),
        end_datetime: date().required(),
        assignments: array(object({})).nullable(),
      })
    )
      .min(1, "Please fill up at least 1 mechanic.")
      .required(),
  });

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      async function fetchData() {
        let appointment = data && data.appointment_date;
        const garage = data.service_location;
        const request = await axios.get(
          `${config.api}/mechanics?per_page=1000&start_datetime=${appointment}&garage_id=${garage}`,
          options
        );
        const dataMechanic = request.data.data.data;
        setMechanics(dataMechanic);
        return request;
      }
      fetchData();
    }
  }, [open, token, data]);

  const onHandleAssignUpdate = (formValues) => {
    handleAssignUpdate({
      ...formValues,
      toComplete: data?.toComplete || false,
    });
  };

  return (
    <Fragment>
      <AlertDialog
        open={alert.open}
        typeAlert={alert.typeAlert}
        message={alert.message}
        handleCloseAlert={handleCloseAlert}
      />
      <Dialog
        fullScreen
        open={open}
        onClose={handleAssignClose}
        TransitionComponent={Transition}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={
            data?.toComplete ? validationSchema2 : validationSchema
          }
          onSubmit={onHandleAssignUpdate}
        >
          {({ values, errors, isSubmitting, setFieldValue }) => (
            <Form autoComplete="off">
              <FieldArray name="mechanics">
                {({ push, remove }) => (
                  <React.Fragment>
                    <AppBar className={classes.appBar}>
                      <Toolbar>
                        <IconButton
                          edge="start"
                          color="inherit"
                          onClick={handleAssignClose}
                          aria-label="close"
                        >
                          <CloseIcon className={classes.button} />
                        </IconButton>
                        <Typography variant="h5" className={classes.title}>
                          Assign Mechanic
                          <AssignmentIndIcon className={classes.icon} />
                        </Typography>
                        <Button
                          disabled={isLoading}
                          type="submit"
                          startIcon={
                            isLoading ? (
                              <CircularProgress size="0.9rem" />
                            ) : undefined
                          }
                        >
                          {isLoading ? "Saving" : "Save"}
                        </Button>
                      </Toolbar>
                    </AppBar>
                    <MuiDialogContent dividers>
                      {Object.keys(data).length > 0 ? (
                        <div className={classes.root}>
                          <Paper className={classes.paper}>
                            <Box mb={2}>
                              <Typography
                                variant="h6"
                                className={classes.titleText}
                              >
                                MECHANIC
                              </Typography>
                            </Box>
                            <Grid
                              container
                              direction="row"
                              justify="flex-start"
                              spacing={2}
                            >
                              <Grid item xs={12} sm={3}>
                                <Autocomplete
                                  ref={ref0}
                                  size="small"
                                  options={mechanics}
                                  getOptionLabel={(option) =>
                                    `${option.firstname} ${option.lastname}`
                                  }
                                  getOptionSelected={(option, value) =>
                                    option.id === selected.id
                                  }
                                  style={{ width: 300 }}
                                  onChange={(event, newValue) => {
                                    setSelected(newValue);
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      size="small"
                                      error={
                                        typeof errors.mechanics === "string"
                                          ? true
                                          : false
                                      }
                                      helperText={
                                        typeof errors.mechanics === "string"
                                          ? errors.mechanics
                                          : ""
                                      }
                                      {...params}
                                      label=""
                                      variant="outlined"
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={12} sm="auto">
                                <Button
                                  disabled={isLoading || !selected}
                                  onClick={() => {
                                    if (selected) {
                                      const ele =
                                        ref0.current.getElementsByClassName(
                                          "MuiAutocomplete-clearIndicator"
                                        )[0];
                                      ele.click();
                                      var mechanicsCollection =
                                        values.mechanics;
                                      var filtered = mechanicsCollection.filter(
                                        (c) => c.id === selected.id
                                      );
                                      if (filtered.length === 0) {
                                        let end_time = new Date(
                                          data.appointment_date
                                        );

                                        var year = end_time.getFullYear();
                                        var month = end_time.getMonth();
                                        var day = end_time.getDate();
                                        var hour = end_time.getHours();
                                        const new_date = new Date(
                                          year,
                                          month,
                                          day,
                                          hour,
                                          0,
                                          0,
                                          0
                                        );
                                        let hoursVal = moment(new_date).format(
                                          "yyyy-MM-DD HH:mm:ss"
                                        );

                                        let data_values = {
                                          id: selected.id,
                                          mobile: selected.mobile,
                                          name: `${selected.firstname} ${selected.lastname}`,
                                          garage_name: selected.garage.name,
                                          start_datetime: hoursVal,
                                          end_datetime: "",
                                          assignments:
                                            selected.assignments.length > 0
                                              ? selected.assignments
                                              : [],
                                        };
                                        push(data_values);
                                      } else {
                                        setAlert({
                                          ...alert,
                                          open: true,
                                          message:
                                            "The mechanic already exists",
                                          typeAlert: "error",
                                        });
                                      }
                                    } else {
                                      setAlert({
                                        ...alert,
                                        open: true,
                                        message:
                                          "The mechanics field must have at least 1 items.",
                                        typeAlert: "error",
                                      });
                                      return false;
                                    }
                                  }}
                                  variant="contained"
                                  className={(classes.button, classes.add)}
                                  startIcon={<AddCircleIcon />}
                                >
                                  ADD
                                </Button>
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <TableContainer component={Paper}>
                                  <Table size="small" aria-label="simple table">
                                    <TableHead>
                                      <TableRow>
                                        <TableCell
                                          style={{ fontWeight: "bold" }}
                                        >
                                          NAME
                                        </TableCell>
                                        <TableCell
                                          style={{ fontWeight: "bold" }}
                                        >
                                          CONTACT NO
                                        </TableCell>
                                        <TableCell
                                          style={{ fontWeight: "bold" }}
                                        >
                                          BRANCH
                                        </TableCell>
                                        <TableCell
                                          style={{ fontWeight: "bold" }}
                                        >
                                          START TIME
                                        </TableCell>
                                        <TableCell
                                          style={{ fontWeight: "bold" }}
                                        >
                                          END TIME
                                        </TableCell>
                                        <TableCell
                                          style={{ fontWeight: "bold" }}
                                        >
                                          ACTIONS
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {values.mechanics.length > 0 ? (
                                        <React.Fragment>
                                          {values.mechanics.map(
                                            (value, index) => (
                                              <TableRow key={index}>
                                                <TableCell
                                                  scope="row"
                                                  style={{ width: "15%" }}
                                                >
                                                  {value.name}
                                                </TableCell>
                                                <TableCell
                                                  scope="row"
                                                  style={{ width: "15%" }}
                                                >
                                                  {value.mobile}
                                                </TableCell>
                                                <TableCell
                                                  scope="row"
                                                  style={{ width: "20%" }}
                                                >
                                                  {value.garage_name}
                                                </TableCell>
                                                <TableCell
                                                  scope="row"
                                                  style={{ width: "20%" }}
                                                >
                                                  <div>
                                                    {moment(
                                                      data.appointment_date
                                                    ).format("MMMM DD, YYYY")}
                                                  </div>
                                                  <TextField
                                                    type="time"
                                                    defaultValue={moment(
                                                      value.start_datetime
                                                    ).format("HH:mm")}
                                                    className={`${
                                                      classes.timepicker
                                                    } ${
                                                      errors.mechanics &&
                                                      errors.mechanics[index]
                                                        ?.start_datetime
                                                        ? "error"
                                                        : ""
                                                    }`}
                                                    onChange={async (e) => {
                                                      let appointment_date =
                                                        moment(
                                                          data.appointment_date
                                                        ).format("YYYY-MM-DD");
                                                      let timeVal =
                                                        e.target.value + ":00";
                                                      let start_datetime =
                                                        moment(
                                                          appointment_date +
                                                            " " +
                                                            timeVal
                                                        ).format(
                                                          "YYYY-MM-DD HH:mm:ss"
                                                        );

                                                      // let checkData =
                                                      //   await checkAvailability(
                                                      //     value.id,
                                                      //     data.appointment_date,
                                                      //     start_datetime,
                                                      //     token,
                                                      //     data.id
                                                      //   );

                                                      // if (checkData) {
                                                      setFieldValue(
                                                        `mechanics.${index}.start_datetime`,
                                                        start_datetime
                                                      );
                                                      // } else {
                                                      //   setFieldValue(
                                                      //     `mechanics.${index}.start_datetime`,
                                                      //     null
                                                      //   );
                                                      //   setAlert({
                                                      //     ...alert,
                                                      //     open: true,
                                                      //     message:
                                                      //       "This mechanic is not available for selected time.",
                                                      //     typeAlert: "error",
                                                      //   });
                                                      // }
                                                    }}
                                                  />
                                                  <span
                                                    style={{ color: "red" }}
                                                  >
                                                    <strong>
                                                      {errors.mechanics &&
                                                      typeof errors.mechanics !==
                                                        "string" &&
                                                      errors.mechanics[index]
                                                        ?.start_datetime
                                                        ? "*"
                                                        : ""}
                                                    </strong>
                                                  </span>
                                                </TableCell>
                                                <TableCell
                                                  scope="row"
                                                  style={{
                                                    width: "20%",
                                                    verticalAlign: "bottom",
                                                  }}
                                                >
                                                  <TextField
                                                    type="time"
                                                    defaultValue={
                                                      value.end_datetime &&
                                                      moment(
                                                        value.end_datetime
                                                      ).format("HH:mm")
                                                    }
                                                    className={`${
                                                      classes.timepicker
                                                    } ${
                                                      errors.mechanics &&
                                                      errors.mechanics[index]
                                                        ?.end_datetime
                                                        ? "error"
                                                        : ""
                                                    }`}
                                                    onChange={async (e) => {
                                                      let appointment_date =
                                                        moment(
                                                          data.appointment_date
                                                        ).format("YYYY-MM-DD");
                                                      let timeVal =
                                                        e.target.value + ":00";
                                                      let end_datetime = moment(
                                                        appointment_date +
                                                          " " +
                                                          timeVal
                                                      ).format(
                                                        "YYYY-MM-DD HH:mm:ss"
                                                      );

                                                      let checkData =
                                                        await checkAvailability(
                                                          value.id,
                                                          data.appointment_date,
                                                          end_datetime,
                                                          token,
                                                          data.id
                                                        );

                                                      if (checkData) {
                                                        setFieldValue(
                                                          `mechanics.${index}.end_datetime`,
                                                          end_datetime
                                                        );
                                                      } else {
                                                        setFieldValue(
                                                          `mechanics.${index}.end_datetime`,
                                                          null
                                                        );
                                                        setAlert({
                                                          ...alert,
                                                          open: true,
                                                          message:
                                                            "This mechanic is not available for selected time.",
                                                          typeAlert: "error",
                                                        });
                                                      }
                                                    }}
                                                  />
                                                  <span
                                                    style={{ color: "red" }}
                                                  >
                                                    <strong>
                                                      {errors.mechanics &&
                                                      typeof errors.mechanics !==
                                                        "string" &&
                                                      errors.mechanics[index]
                                                        ?.end_datetime
                                                        ? "*"
                                                        : ""}
                                                    </strong>
                                                  </span>
                                                </TableCell>
                                                <TableCell
                                                  scope="row"
                                                  style={{ width: "10%" }}
                                                >
                                                  <Tooltip title="Remove">
                                                    <IconButton
                                                      color="secondary"
                                                      aria-label="remove"
                                                      disabled={isLoading}
                                                      onClick={() =>
                                                        remove(index)
                                                      }
                                                    >
                                                      <HighlightOffIcon
                                                        className={
                                                          classes.largeIcon
                                                        }
                                                      />
                                                    </IconButton>
                                                  </Tooltip>
                                                </TableCell>
                                              </TableRow>
                                            )
                                          )}
                                        </React.Fragment>
                                      ) : (
                                        <TableRow>
                                          <TableCell
                                            scope="row"
                                            colSpan={6}
                                            align="center"
                                          >
                                            No records found...
                                          </TableCell>
                                        </TableRow>
                                      )}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Grid>
                            </Grid>
                          </Paper>
                        </div>
                      ) : (
                        <div></div>
                      )}
                      {/* <pre>{JSON.stringify({ values, errors }, null, 4)}</pre> */}
                    </MuiDialogContent>
                  </React.Fragment>
                )}
              </FieldArray>
            </Form>
          )}
        </Formik>
      </Dialog>
    </Fragment>
  );
};

export default AssignDialog;
