import React from "react";
import moment from "moment";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { MenuItem, TextField } from "@material-ui/core";

const MenuItemStyle = withStyles({
  root: {
    justifyContent: "center !important",
  },
})(MenuItem);

const useStyles = makeStyles((theme) => ({
  center: {
    textAlign: "center !important",
  },
}));

const DropdownField = ({ 
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const classes = useStyles();
  let options = []

  if (field?.name === 'month') {
    const months = moment.months()
    if (months?.length > 0) {
        months.map((month, i) => {
            options.push({ id: i, name: month})
        })
    }
  }
  if (field?.name === 'year') {
    options = [
        { id: 1, name: '2024'}
    ]
  }

  return (
    <TextField
      select
       error={errors[field?.name] && touched[field?.name]}
      {...field}
      {...props}
      classes={{
        root: classes.center,
      }}
    >
      {options?.map((option) => (
        <MenuItemStyle key={option.id} value={option.name}>
          {option.name}
        </MenuItemStyle>
      ))}
    </TextField>
  );
};
export default DropdownField;
