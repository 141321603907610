import { Fragment } from "react";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: "#FAE700",
  },
  titleText: {
    color: "rgb(63,81,181) !important",
    fontWeight: "bold !important",
  },
  titleTextData: {
    color: "rgb(63,81,181) !important",
    fontWeight: "bold !important",
  },
  paper: { maxWidth: "550px" },
}));

const VoucherDelete = React.memo((props) => {
  const classes = useStyles();

  const { data, open, onClose, onDelete } = props;

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = () => {
    onDelete(data.id);
  };

  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
        classes={{ paper: classes.paper }}
      >
        <DialogTitle id="alert-dialog-title" className={`${classes.title}`}>
          <span className={classes.titleText}>{`VOUCHER - `}</span>
          <span className={classes.titleTextData}>{`${data.name}`}</span>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this voucher?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} size="small" color="default">
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            size="small"
            variant="contained"
            color="secondary"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
});
export default VoucherDelete;
