import React from "react";
import { StyleSheet, View, Text, Image } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  column: {
    width: "59.2%",
    marginLeft: 4,
  },
  boxHeader: {
    color: "#fff",
    backgroundColor: "#000b8c",
    padding: 6,
  },
  boxBody: {
    borderWidth: 1,
    borderColor: "#000b8c",
    borderTop: 0,
    padding: 6,
  },
  bold: {
    fontFamily: "Helvetica-Bold",
  },
  image: {
    width: 221,
    height: 185,
  },
});

const CustomerAgreement = ({ content }) => {
  return (
    <>
      <View
        style={[
          styles.row,
          styles.boxHeader,
          styles.bold,
          { justifyContent: "center" },
        ]}
      >
        <Text>Customer Agreement</Text>
      </View>
      <View style={styles.boxBody}>
        <View style={[styles.row, { marginLeft: -4 }]}>
          <View style={styles.column}>
            <Text style={{ marginBottom: 4 }}>
              1. I hereby ackowledged that the findings and recommendations have
              been discussed with me and will not hold the establishment liable
              for recommendations on repairs replacement of findings not allowed
              by me and those uncontrolled hidden defects.
            </Text>
            <Text style={{ marginBottom: 4 }}>
              2. The above articles/vehicles are received in good condition &
              inspection have been made to my satisfaction.
            </Text>
            <Text>
              3. It is the customer's responsibility to disclose all concerns of
              the vehicle prior to availing our services.
            </Text>
          </View>
          <View style={[styles.column, { width: "39.2%" }]}>
            {content && <Image style={styles.image} src={content} />}
          </View>
        </View>
      </View>
    </>
  );
};

export default CustomerAgreement;
