import { Fragment } from "react";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { green } from "@material-ui/core/colors";
import { Formik, Form, FastField } from "formik";
import { TextField } from "formik-material-ui";
import { object, string } from "yup";
import RoleComponent from "./RoleComponent";

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: "#FAE700",
  },
  titleText: {
    color: "rgb(63,81,181) !important",
    fontWeight: "bold !important",
  },
  button: {
    backgroundColor: green[500],
    color: "#fff",
    "&:hover": {
      backgroundColor: green[600],
    },
  },
  paper: {
    width: "450px",
    maxWidth: "450px",
  },
  add: {
    backgroundColor: green[500],
    color: "#fff",
    "&:hover": {
      backgroundColor: green[600],
    },
  },
}));

const UserUpdate = React.memo((props) => {
  const classes = useStyles();

  const { open, data, onClose, onUpdate } = props;

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = (values, actions) => {
    console.log("values: ", values);
    onUpdate(values, actions);
  };

  const initialValues = {
    id: data.id,
    name: data.name,
    email: data.email,
    phone: data.phone,
    user_type: data.user_type,
    password: "",
    password_confirmation: "",
  };

  const validationSchema = object().shape({
    name: string().required("Name is required field."),
    email: string()
      .email("Invalid email format.")
      .required("Email is required field."),
    phone: string()
      .required("Phone is a required field.")
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(11, "Must be exactly 11 digits.")
      .max(11, "Must be exactly 11 digits."),
    password: string().nullable(),
    password_confirmation: string().test(
      "passwords-match",
      "Passwords must match.",
      function (value) {
        return this.parent.password === value;
      }
    ),
  });

  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
        classes={{ paper: classes.paper }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, errors, setFieldValue, setSubmitting, isSubmitting }) => (
            <Form autoComplete="off">
              <DialogTitle
                id="alert-dialog-title"
                className={`${classes.title}`}
              >
                <span className={classes.titleText}>{"UPDATE USER FORM"}</span>
              </DialogTitle>
              <DialogContent dividers>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FastField
                      label="Name"
                      component={TextField}
                      name="name"
                      type="text"
                      variant="outlined"
                      size="small"
                      fullWidth
                      inputProps={{
                        style: {
                          textAlign: "center",
                          textTransform: "capitalize",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FastField
                      label="Email"
                      component={TextField}
                      name="email"
                      type="text"
                      variant="outlined"
                      size="small"
                      fullWidth
                      inputProps={{
                        style: {
                          textAlign: "center",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FastField
                      label="Phone"
                      component={TextField}
                      name="phone"
                      type="text"
                      variant="outlined"
                      size="small"
                      fullWidth
                      inputProps={{
                        style: {
                          textAlign: "center",
                        },
                      }}
                    />
                  </Grid>
                  {/* <Grid item xs={12}>
                    <FastField
                      label="Password"
                      component={TextField}
                      name="password"
                      type="password"
                      variant="outlined"
                      size="small"
                      fullWidth
                      autoComplete="off"
                      inputProps={{
                        style: {
                          textAlign: 'center',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FastField
                      label="Confirm Password"
                      component={TextField}
                      name="password_confirmation"
                      type="password"
                      variant="outlined"
                      size="small"
                      fullWidth
                      autoComplete="off"
                      inputProps={{
                        style: {
                          textAlign: 'center',
                        },
                      }}
                    />
                  </Grid> */}
                  <Grid item xs={12}>
                    <FastField
                      label="Role"
                      component={RoleComponent}
                      name="user_type"
                      type="text"
                      variant="outlined"
                      size="small"
                      fullWidth
                      inputProps={{
                        style: {
                          textAlign: "center",
                        },
                      }}
                    />
                  </Grid>
                </Grid>
                {/* <pre>{JSON.stringify({ values, errors }, null, 4)}</pre> */}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} size="small" color="default">
                  Close
                </Button>
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  color="primary"
                  variant="contained"
                  autoFocus
                  size="small"
                  endIcon={
                    isSubmitting ? <CheckCircleIcon size="0.9rem" /> : undefined
                  }
                >
                  {isSubmitting ? "Submitting" : "Submit"}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </Fragment>
  );
});
export default UserUpdate;
