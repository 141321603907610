import { Fragment } from "react";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import AddIcon from "@material-ui/icons/Add";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { green } from "@material-ui/core/colors";
import { Formik, Form, FastField } from "formik";
import { TextField } from "formik-material-ui";
import { object, string, number } from "yup";
import CustomSelectFuelType from "../customSelectFuelType/CustomSelectFuelType";
import CustomSelectLaborOnly from "../customSelectLaborOnly/CustomSelectLaborOnly";
import CustomSelectServices from "../customSelectServices/CustomSelectServices";

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: "#FAE700",
  },
  titleText: {
    color: "rgb(63,81,181) !important",
    fontWeight: "bold !important",
  },
  button: {
    backgroundColor: green[500],
    color: "#fff",
    "&:hover": {
      backgroundColor: green[600],
    },
  },
  paper: {
    width: "350px",
    maxWidth: "350px",
  },
  add: {
    backgroundColor: green[500],
    color: "#fff",
    "&:hover": {
      backgroundColor: green[600],
    },
  },
}));

const AddPackageInclusion = React.memo((props) => {
  const classes = useStyles();

  const { open, onOpen, onClose, package_id, handleSubmitAdd } = props;

  const handleOpen = () => {
    onOpen();
  };

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = (values, actions) => {
    if (values.fuel_type === "None") {
      values.fuel_type = "";
    }
    handleSubmitAdd(values, actions);
  };

  const initialValues = {
    package_id: package_id,
    service_id: 0,
    fuel_type: "",
    pos: 0,
    quantity: 1,
    labor_only: 0,
    description: '',
    is_active: 1
  };

  const validationSchema = object().shape({
    service_id: number().test(
      "nonZero",
      "Service is a required field.",
      value => value !== 0
    ),
    pos: number().required("Position is a required field."),
    quantity: string().required("Quantity is a required field."),
  });

  return (
    <Fragment>
      <div>
        <Box my={2} mr={2} display="flex" flexDirection="row-reverse">
          <Button
            onClick={handleOpen}
            className={classes.button}
            variant="contained"
            size="medium"
            startIcon={<AddIcon />}
          >
            Add Service
          </Button>
        </Box>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          disableBackdropClick
          classes={{ paper: classes.paper }}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form autoComplete="off">
                <DialogTitle
                  id="alert-dialog-title"
                  className={`${classes.title}`}
                >
                  <span className={classes.titleText}>
                    {"ADD NEW SERVICE ITEMS"}
                  </span>
                </DialogTitle>
                <DialogContent dividers>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FastField
                          name="fuel_type"
                          label="Fuel Type"
                          variant="outlined"
                          size="small"
                          component={CustomSelectFuelType}
                          fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                      <FastField
                        label="Service"
                        component={CustomSelectServices}
                        name="service_id"
                        type="text"
                        variant="outlined"
                        size="small"
                        fullWidth
                        inputProps={{
                          style: {
                            textAlign: "center",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FastField
                        label="Quantity"
                        component={TextField}
                        name="quantity"
                        type="number"
                        variant="outlined"
                        size="small"
                        fullWidth
                        inputProps={{
                          style: {
                            textAlign: "center",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FastField
                        label="Position"
                        component={TextField}
                        name="pos"
                        type="number"
                        variant="outlined"
                        size="small"
                        fullWidth
                        inputProps={{
                          style: {
                            textAlign: "center",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                        <FastField
                          name="labor_only"
                          label="Labor Only"
                          variant="outlined"
                          size="small"
                          component={CustomSelectLaborOnly}
                          fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                      <FastField
                        label="Description"
                        component={TextField}
                        name="description"
                        type="text"
                        variant="outlined"
                        size="small"
                        fullWidth
                        inputProps={{
                          style: {
                            textAlign: "center",
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} size="small" color="default">
                    Close
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    color="primary"
                    variant="contained"
                    autoFocus
                    size="small"
                    endIcon={
                      isSubmitting ? (
                        <CheckCircleIcon size="0.9rem" />
                      ) : undefined
                    }
                  >
                    {isSubmitting ? "Submitting" : "Submit"}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>
      </div>
    </Fragment>
  );
});
export default AddPackageInclusion;
