import { Fragment, useRef, useState, useEffect } from "react";
import axios from "axios";
// mui
import { Box } from '@material-ui/core';
// component
import MechanicsAttendanceTable from "../components/attendance/MechanicsAttendanceTable";
// Api config
import config from "../utils/config";

const MechanicAttendance = (props) => {
  const { token } = props;
  const tableRef = useRef();

  const [mechanics, setMechanics] = useState([]);

  useEffect(() => {
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      async function fetchData() {
        const request = await axios.get(
          `${config.api}/mechanics?per_page=1000`,
          options
        );
        const dataMechanic = request.data.data.data;
        setMechanics(dataMechanic);
        return request;
      }
      fetchData();
  }, [token]);

  return (
    <Fragment>
        <Box mb={1}>
            <MechanicsAttendanceTable token={token} tableRef={tableRef} mechanics={mechanics} />
        </Box>
    </Fragment>
  )
}


export default MechanicAttendance;