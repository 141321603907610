import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { green, red } from '@material-ui/core/colors';
import {
  Typography,
  Grid,
  FormControlLabel,
  RadioGroup,
  Radio,
  Paper
} from '@material-ui/core';
// External
import 'react-photo-view/dist/react-photo-view.css';
const useStyles = makeStyles(() => ({
  error: {
    color: red[500],
  },
  icon: {
    position: 'relative',
    width: '2.5rem',
    height: '2.5rem',
    backgroundColor: 'currentColor',
    borderRadius: '50%',
  },
  checkmark: {
    position: 'absolute',
    top: '8px',
    left: '16px',
    width: '8px',
    height: '20px',
    border: 'solid #fff',
    borderWidth: '0 3px 3px 0',
    transform: 'rotate(45deg)',
  },
}));

function sum(obj) {
    if (!obj) return
    return Object.keys(obj).reduce((sum, key) => sum + Number(obj[key] || 0), 0)
}

const AssistanceInspection = ({ type, data, summary, inspection_scores }) => {
  const classes = useStyles();
  const services = data?.filter((item) => item?.type === type);

  const [scores, setScores] = React.useState(null)
  
  React.useEffect(() => {
    if (inspection_scores) {
        setScores(JSON.parse(inspection_scores))
    }
  }, [inspection_scores])

  if (summary) {
    return (
        <div>
            <Paper variant="outlined" style={{ marginBottom: 20 }}>
                <Grid container>
                    <Grid item md={12}> 
                       <Typography
                            variant="h5"
                            style={{ paddingLeft: 10, paddingTop: 10 }}
                        >
                            Summary
                        </Typography>
                        <div style={{ display: 'flex', paddingLeft: 10 }}>
                            <div style={{ width: '15%' }}>
                                <p>Category</p>
                                <div style={{ marginVertical: 10 }}>
                                    <p>Mechanical</p>
                                    <p>Equipment & Functions</p>
                                    <p>Suspension & Underchassis</p>
                                    <p>Brakes & Tires</p>
                                    <p>Interior</p>
                                    <p>Exterior & Frame</p>
                                    <p>Documents</p>
                                    <p>Others</p>
                                    <p><strong>Total</strong></p>
                                </div>
                            </div>

                            <div style={{ width: '15%' }}>
                                <p>Score</p>
                                <div style={{ marginVertical: 10 }}>
                                    <p>{`${scores?.mechanicalScore} / 30`}</p>
                                    <p>{`${scores?.equipmentAndFunctionsScore} / 37`}</p>
                                    <p>{`${scores?.suspensionAndUderchassisScore} / 19`}</p>
                                    <p>{`${scores?.brakesAndTiresScore} / 11`}</p>
                                    <p>{`${scores?.interiorScore} / 32`}</p>
                                    <p>{`${scores?.exteriorScore} / 38`}</p>
                                    <p>{`${scores?.documentsScore} / 12`}</p>
                                    <p>{`${scores?.othersScore} / 2`}</p>
                                    <p><strong>{sum(scores)}</strong></p>
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Paper> 
        </div>
    )
  }

  return (
    <div>
      {services?.map((service) => (
        <Paper variant="outlined" style={{ marginBottom: 20 }}>
          <Grid container>
            <Grid item md={6}> 
              <p style={{ paddingLeft: 10, marginBottom: 0}}>Questions:</p>
              <Typography
                variant="body"
                style={{ paddingLeft: 10, paddingTop: 20 }}
              >
                {service?.text}
              </Typography>
            </Grid>
            <Grid item md={2}>
              <p style={{ paddingLeft: 20, marginBottom: 0 }}>{service?.selected}</p>
              <RadioGroup row style={{ justifyContent: 'start' }}>
                <Grid item xs={3} style={{ textAlign: 'center' }}>
                  <FormControlLabel
                    checked={['Yes', 'Available', 'Good', 'Working', 'Registered', 'Match'].includes(service?.selected)}
                    control={
                      <Radio
                        icon={<span className={classes.icon} />}
                        checkedIcon={
                          <span className={classes.icon}>
                            <span className={classes.checkmark} />
                          </span>
                        }
                        style={{ color: green[500] }}
                        onChange={() => console.log()}
                      />
                    }
                    style={{ margin: '0' }}
                  />
                </Grid>

                <Grid item xs={3} style={{ textAlign: 'center' }}>
                  <FormControlLabel
                    checked={['No', 'Not Available', 'Not Good', 'Not Working', 'Not Registered', 'Not Match'].includes(service?.selected)}
                    control={
                      <Radio
                        icon={<span className={classes.icon} />}
                        checkedIcon={
                          <span className={classes.icon}>
                            <span className={classes.checkmark} />
                          </span>
                        }
                        style={{ color: red[500] }}
                        onChange={() => console.log()}
                      />
                    }
                    style={{ margin: '0' }}
                  />
                </Grid>

              </RadioGroup>
            </Grid>
            <Grid item md={4}>
              <p style={{ paddingBottom: 0 }}>Remarks:</p>
              <Typography
                variant="body"
                style={{ paddingLeft: 10, paddingTop: 20 }}
              >
                {service?.note}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      ))}
    </div>
  );
};

export default AssistanceInspection;
