import { Fragment, useRef, useState, useCallback } from "react";
import axios from "axios";
// component
import MechanicIncentivesTable from "../components/incentives/MechanicIncentivesTable";
import AddIncentive from "../components/incentives/AddIncentive";
import AlertDialog from "../components/booking/AlertDialog";
// Api config
import config from "../utils/config";

const MechanicIncentives = (props) => {
  const { token } = props;
  const tableRef = useRef();

  // ==============ADD Mechanic STATES==============
  const [openAdd, setOpenAdd] = useState(false);

  // ==============MODAL STATES==============
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [typeAlert, setTypeAlert] = useState("success");

  const handleOpenAdd = useCallback(() => {
    setOpenAdd(true);
  }, []);
 
  const handleCloseAdd = useCallback(() => {
    setOpenAdd(false);
  }, []);

  // =================ALERT - (MODAL)=================
  const handleCloseAlert = useCallback(() => {
    setAlert(false);
    setMessage("");
    setTypeAlert("success");
  }, []);

  const handleSubmit = useCallback(
    (values) => {
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      let payload = []
      values?.incentives?.map((value) => {
        payload.push({
          month: values?.month,
          year: values?.year, 
          regular_mechanics: values?.regular_mechanics || 0,
          trainee_mechanics: values?.trainee_mechanics || 0,
          ...value
        })
      })
      axios
        .post(`${config.api}/mechanics/incentives`, payload, options)
        .then(() => {
          setOpenAdd(false);
          setTimeout(() => {
            setAlert(true);
            setMessage("Successfully Added");
            setTypeAlert("success");
          }, 1500);
          
          tableRef.current && tableRef.current.onQueryChange();
        })
        .catch((error) => {
          setOpenAdd(false);
          setAlert(true);
          setTypeAlert("error");
          setMessage("Something went wrong please try again.");
        });
    },
    [token]
  );

  return (
    <Fragment>
        <AlertDialog
          open={alert}
          typeAlert={typeAlert}
          message={message}
          handleCloseAlert={handleCloseAlert}
        />
        <AddIncentive 
          open={openAdd}
          onOpen={handleOpenAdd}
          onClose={handleCloseAdd}
          handleSubmit={handleSubmit}
        />
        <MechanicIncentivesTable token={token} tableRef={tableRef} />
    </Fragment>
  )
}

export default MechanicIncentives;