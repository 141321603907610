import { Fragment } from "react";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import AddIcon from "@material-ui/icons/Add";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { green } from "@material-ui/core/colors";
import { Formik, Form, FastField } from "formik";
import { object, string } from "yup";
import { TextField } from "formik-material-ui";
import CustomSelectVehicleType from "./customSelectVehicleType/CustomSelectVehicleType";
import CustomSelectFuelType from "./customSelectFuelType/CustomSelectFuelType";
import CustomSelectLiterOfOil from "./customSelectLiterOfOil/CustomSelectLiterOfOil";

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: "#FAE700",
  },
  titleText: {
    color: "rgb(63,81,181) !important",
    fontWeight: "bold !important",
  },
  button: {
    backgroundColor: green[500],
    color: "#fff",
    "&:hover": {
      backgroundColor: green[600],
    },
  },
  paper: { maxWidth: "350px" },
}));

const VehiclesUpdate = React.memo((props) => {
  const classes = useStyles();

  const { open, data, onClose, onUpdate } = props;
 
  const handleClose = () => {
    onClose();
  };

  const handleSubmit = (values, actions) => {
    // Capitalize the 'make' property
    values.make = values.make.toUpperCase();
    
    // Capitalize the 'model' property
    values.model = values.model.toUpperCase();
    onUpdate(values, actions);
  };

  const initialValues = {
    id: data.id,
    make: data.make,
    model: data.model,
    classification: data.classification,
    fuel_type: data.fuel_type,
    liter_of_oil: data.liter_of_oil,
  };

  const validationSchema = object().shape({
    make: string().required("Car make is required."),
    model: string().required("Car model is required."),
    classification: string().required("Vehicle type is required."),
    fuel_type: string().required("Fuel type is required."),
    liter_of_oil: string().required("Liter of oil is required."),
  });

  return (
    <Fragment>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          disableBackdropClick
          classes={{ paper: classes.paper }}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, errors, setFieldValue, isSubmitting }) => (
              <Form autoComplete="off">
                <DialogTitle
                  id="alert-dialog-title"
                  className={`${classes.title}`}
                >
                  <span className={classes.titleText}>
                    {"UPDATE VEHICLE FORM"}
                  </span>
                </DialogTitle>
                <DialogContent dividers>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <FastField
                        label="Car Make"
                        component={TextField}
                        name="make"
                        type="text"
                        variant="outlined"
                        size="small"
                        fullWidth
                        inputProps={{
                          style: {
                            textAlign: "center",
                            textTransform: "uppercase",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FastField
                        label="Car Model"
                        component={TextField}
                        name="model"
                        type="text"
                        variant="outlined"
                        size="small"
                        fullWidth
                        inputProps={{
                          style: {
                            textAlign: "center",
                            textTransform: "uppercase",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FastField
                        name="classification"
                        label="Vehicle Type"
                        variant="outlined"
                        size="small"
                        component={CustomSelectVehicleType}
                        fullWidth
                        onChange={async (e) => {
                          const { value } = e.target;
                          setFieldValue("classification", value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FastField
                        name="fuel_type"
                        label="Fuel Type"
                        variant="outlined"
                        size="small"
                        component={CustomSelectFuelType}
                        fullWidth
                        onChange={async (e) => {
                          const { value } = e.target;
                          setFieldValue("fuel_type", value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FastField
                        name="liter_of_oil"
                        label="Liter of Oil"
                        variant="outlined"
                        size="small"
                        component={CustomSelectLiterOfOil}
                        fullWidth
                        onChange={async (e) => {
                          const { value } = e.target;
                          setFieldValue("liter_of_oil", value);
                        }}
                      />
                    </Grid>
                  </Grid>
                  {/* <pre>{JSON.stringify({ values, errors }, null, 4)}</pre> */}
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} size="small" color="default">
                    Close
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    color="primary"
                    variant="contained"
                    autoFocus
                    size="small"
                    endIcon={
                      isSubmitting ? (
                        <CheckCircleIcon size="0.9rem" />
                      ) : undefined
                    }
                  >
                    {isSubmitting ? "Submitting" : "Submit"}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>
      </div>
    </Fragment>
  );
});
export default VehiclesUpdate;
