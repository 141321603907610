import { Fragment } from 'react';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Formik, Form, FastField } from 'formik';
import CancelIcon from '@material-ui/icons/Cancel';
import { TextField } from "formik-material-ui";
import { object, string } from 'yup';
import WarningIcon from '@material-ui/icons/Warning';

const useStyles = makeStyles((theme) => ({
  warningTitle: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.error.main, // Use error color
  },
  warningIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.error.main,
  },
  message: {
    textAlign: 'center', // Center the text
    margin: theme.spacing(2, 0), // Add some margin for spacing
    fontWeight: 500, // Slightly bold the message
    color: theme.palette.text.primary,
  },
}));

const ReopenDialog = React.memo((props) => {
  const classes = useStyles();

  const { data, open, handleReopenAppointmentClose, handleReopenSubmit } = props;
  const handleClose = () => {
    handleReopenAppointmentClose();
  };

  const handleSubmit = (values) => {
    handleReopenSubmit({...values, id: data.id});
  };

  const validationSchema = object().shape({
    reopen_reason: string().required('Reopen reason is required'),
  });

  return (
    <Fragment>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xs"
          classes={{ paper: classes.paper }}
        >
          <Formik
            initialValues={{ reopen_reason: '' }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, isSubmitting, setFieldValue }) => (
              <Form autoComplete="off">
                <DialogTitle disableTypography className={classes.warningTitle}>
                  <WarningIcon className={classes.warningIcon} />
                  <Typography variant="h6">Warning</Typography>
                </DialogTitle>

                <DialogContent dividers>
                  <Typography className={classes.message}>
                    You can reopen this appointment <strong>once</strong> to edit the details. Please ensure everything is correct.
                  </Typography>
                  <Box mb={1}>
                    <FastField
                      fullWidth
                      label="Reopen Reason"
                      component={TextField}
                      name="reopen_reason"
                      variant="outlined"
                      size="small"
                    />
                  </Box>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={handleClose}
                    color="secondary"
                    startIcon={<CancelIcon />}
                    size="small"
                    variant="contained"
                    disabled={isSubmitting}
                  >
                    Close
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    color="primary"
                    variant="contained"
                    autoFocus
                    size="small"
                    endIcon={
                      isSubmitting ? (
                        <CheckCircleIcon size="0.9rem" />
                      ) : undefined
                    }
                  >
                    {isSubmitting ? 'Submitting' : 'Submit'}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>
      </div>
    </Fragment>
  );
});
export default ReopenDialog;
