import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { MenuItem, TextField } from '@material-ui/core';

const MenuItemStyle = withStyles({
  root: {
    justifyContent: 'center',
  },
})(MenuItem);

const CustomRescheduleReasonComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const reschedule_reason = [
    { value: 'Mechanic was late', label: 'Mechanic was late' },
    {
      value: 'No parts available/wrong parts',
      label: 'No parts available/wrong parts',
    },
    { value: 'Emergency', label: 'Emergency' },
    { value: 'Typhoon/Rains', label: 'Typhoon/Rains' },
    { value: 'No available mechanics', label: 'No available mechanics' },
  ];
  return (
    <TextField
      fullWidth
      select
      error={errors.reschedule_reason && touched.reschedule_reason}
      helperText={errors && errors.reschedule_reason}
      {...field}
      {...props}
    >
      {reschedule_reason.map((option) => (
        <MenuItemStyle key={option.value} value={option.value}>
          {option.value}
        </MenuItemStyle>
      ))}
    </TextField>
  );
};
export default CustomRescheduleReasonComponent;
