import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

const ProtectedRoute = ({component : Component, ...rest}) => {
  const token = localStorage.getItem('token');
  const isAdmin = localStorage.getItem('isAdmin');

  return(
      <Route {...rest} render={(props) => {

        if (isAdmin && token) {
          return <Component token={token}/>
        }else{
          return <Redirect to={{pathname : '/', state: {from: props.location} }} />
        }

      }}/>
  )
}

export default ProtectedRoute;
