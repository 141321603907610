import React, { useEffect } from 'react';
import { TextField } from '@material-ui/core';

const CustomBookingRefComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, values, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  useEffect(() => {
    const date = new Date();
    const year = date.getFullYear();
    const numbers = Math.floor(Math.random() * 90000) + 10000;
    const name = values.last_name || values.first_name;

    let reference_no = '';
    if (name.trim()) {
      reference_no = `MG-${name.charAt(0)}-${year}-${numbers}`;
    } else {
      reference_no = '';
    }
    setFieldValue('reference_no', reference_no);
  }, [values.first_name + ' ' + values.last_name]);

  return (
    <React.Fragment>
      <TextField
        disabled
        error={errors.reference_no && touched.reference_no}
        helperText={
          errors.reference_no && touched.reference_no ? errors.reference_no : ''
        }
        {...field}
        {...props}
      />
    </React.Fragment>
  );
};
export default CustomBookingRefComponent;
