import React, {useState, useEffect} from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { MenuItem, TextField } from "@material-ui/core";
import config from '../../utils/config'
import axios from "axios";

const MenuItemStyle = withStyles({
  root: {
    justifyContent: "center !important",
  },
})(MenuItem);

const useStyles = makeStyles((theme) => ({
  center: {
    textAlign: "center !important",
  },
}));

const HubLocation = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const classes = useStyles();

  const token = localStorage.getItem('token');

  const [hubs, setHubs] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const { data: {data} } = await axios.get(
        `${config.api}/garages`,
        options
      );
      setHubs(data);
    }
    fetchData()
  }, []);

  return (
    <TextField
      select
      error={errors.hub_location && touched.hub_location}
      helperText={errors.hub_location && touched.hub_location && errors.hub_location}
      {...field}
      {...props}
      classes={{
        root: classes.center,
      }}
    >
      {hubs.map((option) => (
        <MenuItemStyle key={option.id} value={option.id}>
          {option.name}
        </MenuItemStyle>
      ))}
    </TextField>
  );
};
export default HubLocation;
