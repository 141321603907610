import { Fragment, useState, useEffect } from "react";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import Grid from "@material-ui/core/Grid";
import { green } from "@material-ui/core/colors";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";
import moment from "moment";

import { attendanceFilterActions } from "../../store/attendance-filter-slice";

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: "#FAE700",
  },
  titleText: {
    color: "rgb(63,81,181) !important",
    fontWeight: "bold !important",
  },
  button: {
    backgroundColor: green[500],
    color: "#fff",
    "&:hover": {
      backgroundColor: green[600],
    },
  },
  paper: { maxWidth: "20%" },
  grid: {
    position: "relative",
  },
  dateRangePicker: {
    marginBottom: theme.spacing(2),
    "& .react-daterange-picker__wrapper": {
      display: "flex",
      backgroundColor: theme.palette.background.paper,
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shadows[2],
      height: "40px",
      padding: "5px 10px",
    },
    "& .react-daterange-picker__calendar": {
      zIndex: "9999",
    },
  },
}));

const Filters = (props) => {
  const { handleOpen, isAddAttendance, isAddLeave } = props;
  const classes = useStyles();

  const dispatch = useDispatch();

  const date_from = useSelector((state) => state.attendanceFilter.date_from);
  const date_to = useSelector((state) => state.attendanceFilter.date_to);

  const handleChangeDateFilter = (values) => {
    dispatch(
      attendanceFilterActions.changeFilterDateRange({
        date_from: values[0],
        date_to: values[1],
      })
    );
  };

  return (
    <Fragment>
      <div>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <DateRangePicker
              onChange={handleChangeDateFilter}
              value={[date_from, date_to]}
              className={classes.dateRangePicker}
              format="y-MM-dd"
              inputPlaceholder="Select date range"
              name="date"
            />
          </Grid>
          {isAddAttendance && handleOpen && (
            <Grid
              item
              xs={9}
              container
              direction="column"
              alignItems="flex-end"
            >
              <Button
                onClick={handleOpen}
                className={classes.button}
                variant="contained"
                size="medium"
                startIcon={<AddIcon />}
              >
                Add Attendance
              </Button>
            </Grid>
          )}
          {isAddLeave && (
            <Grid
              item
              xs={9}
              container
              direction="column"
              alignItems="flex-end"
            >
              <Button
                onClick={handleOpen}
                className={classes.button}
                variant="contained"
                size="medium"
                startIcon={<AddIcon />}
              >
                Add Leave
              </Button>
            </Grid>
          )}
        </Grid>
      </div>
    </Fragment>
  );
};

export default Filters;
