import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useFormikContext, useField } from "formik";

const useStyles = makeStyles(() => ({
  autocomplete: {
    "& input": {
      borderBottom: "0 !important",
    },
  },
}));

const AutocompleteMultipleSelectWrapper = ({
  label,
  name,
  options,
  disabled = false,
  ...props
}) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);
  const classes = useStyles();

  const handleChange = (evt, value) => {
    setFieldValue(name, value);
  };

  return (
    <Autocomplete
      size="small"
      name={name}
      multiple
      options={options}
      // getOptionLabel={(option) => option.label}
      getOptionLabel={(option) =>
        options.find((item) => item.value === option.value)?.label
      }
      getOptionSelected={(option, value) => option.value === value.value}
      value={field.value}
      onChange={handleChange}
      className={classes.autocomplete}
      filterSelectedOptions
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          variant="outlined"
          name={name}
          {...props}
          {...params}
          label={label}
          placeholder={label}
          error={meta.touched && Boolean(meta.error)}
          helperText={meta.touched && meta.error}
        />
      )}
    />
  );
};

export default AutocompleteMultipleSelectWrapper;
