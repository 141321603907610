import React, { Fragment, useState } from "react";
import "../styles/login.css";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import {
  Collapse as MUIcollapse,
  IconButton,
  Box,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from "@material-ui/core";
import { Alert as MUIalerts } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import * as Yup from "yup";
import Swal from "sweetalert2";
import LoginForm from "../components/login/LoginForm";
import { withRouter } from "react-router-dom";

import config from "../utils/config";

const Login = (props) => {
  const useStyles = makeStyles({
    root: {
      maxWidth: 500,
    },
    signIn: {
      paddingTop: "1em",
      paddingBottom: "0px",
      fontWeight: "bold",
    },
  });

  const useStyles2 = makeStyles((theme) => ({
    root: {
      "& > *": {
        margin: theme.spacing(1),
      },
      textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
    },
  }));

  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const classes = useStyles();
  const classes2 = useStyles2();

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Enter a valid email.")
      .required("Required Email"),
    password: Yup.string().required("Required password"),
  });

  const onSubmit = (values, props) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 1500,
      timerProgressBar: true,
    });

    console.log("config: ", config);

    setIsLoading(true);

    const adminRoles = ["admin_user", "mechanic_user", "super_admin"];

    axios
      .post(config.api + "/login", values)
      .then((response) => {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("isAdmin", true);
        axios
          .get(`${config.api}/user/profile`, {
            headers: {
              Authorization: `Bearer ${response.data.token}`,
            },
          })
          .then((res) => {
            if (adminRoles.includes(res.data.userRole)) {
              localStorage.removeItem("userRole");
              localStorage.setItem("userRole", res.data.userRole);
              Toast.fire({
                icon: "success",
                title: "Signed in successfully",
              }).then(() => {
                setOpen(false);
                setIsLoading(false);
                props.resetForm();
                return (window.location = "/bookings");
              });
            } else {
              setOpen(true);
              setIsLoading(false);
              setMessage("Mechanigo account does not exist");
            }
          })
          .catch((err) => {
            console.error("error: ", err);
          });
      })
      .catch((error) => {
        localStorage.clear();
        setOpen(true);
        setIsLoading(false);
        setMessage("Email or password is incorrect. Please try again.");
      });
  };

  return (
    <Fragment>
      <MUIcollapse
        in={open}
        style={{
          maxWidth: "30%",
          width: "30%",
          position: "absolute",
          right: "2%",
          top: "5%",
        }}
      >
        <MUIalerts
          severity="error"
          variant="filled"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {message}
        </MUIalerts>
      </MUIcollapse>

      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: "100vh" }}
      >
        <Grid item xs={12}>
          <Card className={classes.root}>
            <CardMedia className="brandLogo">
              <Typography
                variant="h4"
                align="center"
                className={classes.signIn}
              >
                Sign In
              </Typography>
              <Box px={3} pb={2}>
                <img
                  src={process.env.PUBLIC_URL + "/images/brandLogo.png"}
                  style={{ height: "100%", width: "100%" }}
                  alt="brandLogo.png"
                />
              </Box>
            </CardMedia>
            <Box px={2}>
              <CardContent>
                <LoginForm
                  classes={classes}
                  classes2={classes2}
                  isLoading={isLoading}
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                />
              </CardContent>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default withRouter(Login);
