import { makeStyles } from '@material-ui/core/styles';
import { green, orange, red } from '@material-ui/core/colors';
import {
  Box,
  Typography,
  Grid,
  FormControlLabel,
  RadioGroup,
  Radio,
  Paper,
  TextField,
} from '@material-ui/core';
// External
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
const useStyles = makeStyles(() => ({
  error: {
    color: red[500],
  },
  icon: {
    position: 'relative',
    width: '2.5rem',
    height: '2.5rem',
    backgroundColor: 'currentColor',
    borderRadius: '50%',
  },
  checkmark: {
    position: 'absolute',
    top: '8px',
    left: '16px',
    width: '8px',
    height: '20px',
    border: 'solid #fff',
    borderWidth: '0 3px 3px 0',
    transform: 'rotate(45deg)',
  },
}));

const List = ({ title, type, data, onChange }) => {
  const classes = useStyles();
  const services = data?.filter((item) => item?.key === type);

  const handleChange = (id, key, type, name, value) => {
    console.log(value);
    onChange(id, key, type, name, value);
  };

  return (
    <div>
      {services?.map((service) => (
        <Paper variant="outlined" style={{ marginBottom: 20 }}>
          <Grid container>
            <Grid item md={1}>
              <Typography
                variant="body2"
                style={{ paddingLeft: 10, paddingTop: 15 }}
              >
                <strong>{service?.type}</strong>
              </Typography>
            </Grid>
            <Grid item md={3}>
              <RadioGroup row style={{ justifyContent: 'start' }}>
                <Grid item xs={3} style={{ textAlign: 'center' }}>
                  <FormControlLabel
                    checked={service?.selected === 'Good'}
                    control={
                      <Radio
                        icon={<span className={classes.icon} />}
                        checkedIcon={
                          <span className={classes.icon}>
                            <span className={classes.checkmark} />
                          </span>
                        }
                        style={{ color: green[500] }}
                        onChange={() =>
                          handleChange(
                            service?.id,
                            service?.key,
                            service?.type,
                            'selected',
                            'Good'
                          )
                        }
                      />
                    }
                    style={{ margin: '0' }}
                  />
                  <p>Good</p>
                </Grid>

                <Grid item xs={3} style={{ textAlign: 'center' }}>
                  <FormControlLabel
                    checked={
                      service?.selected === 'Replace next PMS' ||
                      service?.selected === 'Replece next PMS'
                    }
                    control={
                      <Radio
                        icon={<span className={classes.icon} />}
                        checkedIcon={
                          <span className={classes.icon}>
                            <span className={classes.checkmark} />
                          </span>
                        }
                        style={{ color: orange[500] }}
                        onChange={() =>
                          handleChange(
                            service?.id,
                            service?.key,
                            service?.type,
                            'selected',
                            'Replace next PMS'
                          )
                        }
                      />
                    }
                    style={{ margin: '0' }}
                  />
                  <p>Replace next PMS</p>
                </Grid>

                <Grid item xs={3} style={{ textAlign: 'center' }}>
                  <FormControlLabel
                    checked={service?.selected === 'Replace now'}
                    control={
                      <Radio
                        icon={<span className={classes.icon} />}
                        checkedIcon={
                          <span className={classes.icon}>
                            <span className={classes.checkmark} />
                          </span>
                        }
                        style={{ color: red[500] }}
                        onChange={() =>
                          handleChange(
                            service?.id,
                            service?.key,
                            service?.type,
                            'selected',
                            'Replace now'
                          )
                        }
                      />
                    }
                    style={{ margin: '0' }}
                  />
                  <p>Replace now</p>
                </Grid>

                <Grid item xs={3} style={{ textAlign: 'center' }}>
                  <FormControlLabel
                    checked={service?.selected === 'N/A'}
                    control={
                      <Radio
                        icon={<span className={classes.icon} />}
                        checkedIcon={
                          <span className={classes.icon}>
                            <span className={classes.checkmark} />
                          </span>
                        }
                        style={{ color: '#78909c' }}
                        onChange={() =>
                          handleChange(
                            service?.id,
                            service?.key,
                            service?.type,
                            'selected',
                            'N/A'
                          )
                        }
                      />
                    }
                    style={{ margin: '0' }}
                  />
                  <p>N/A</p>
                </Grid>
              </RadioGroup>
            </Grid>
            <Grid item md={2}>
              <p style={{ paddingBottom: 0 }}>Notes:</p>
              <TextField
                variant="outlined"
                fullWidth
                value={service?.note}
                style={{ marginBottom: 10 }}
                onChange={(e) =>
                  handleChange(
                    service?.id,
                    service?.key,
                    service?.type,
                    'note',
                    e.target.value
                  )
                }
              />
            </Grid>
            <Grid item md={2} style={{ paddingLeft: 10 }}>
              <p style={{ paddingBottom: 0 }}>Service:</p>
              <TextField
                variant="outlined"
                fullWidth
                value={service?.service}
                style={{ marginBottom: 10 }}
                onChange={(e) =>
                  handleChange(
                    service?.id,
                    service?.key,
                    service?.type,
                    'note',
                    e.target.value
                  )
                }
              />
            </Grid>
            <Grid item md={2} style={{ paddingLeft: 10 }}>
              <p style={{ paddingBottom: 0 }}>Part:</p>
              <TextField
                variant="outlined"
                fullWidth
                value={service?.part}
                style={{ marginBottom: 10 }}
                onChange={(e) =>
                  handleChange(
                    service?.id,
                    service?.key,
                    service?.type,
                    'note',
                    e.target.value
                  )
                }
              />
            </Grid>
            <Grid item md={2} style={{ paddingLeft: 10 }}>
              <p>Photo:</p>
              {service?.photo && (
                <PhotoProvider>
                  <PhotoView src={service?.photo}>
                    <img
                      src={service?.photo}
                      alt=""
                      style={{ width: '80px', height: '80px' }}
                    />
                  </PhotoView>
                </PhotoProvider>
              )}
            </Grid>
          </Grid>
        </Paper>
      ))}
    </div>
  );
};

export default List;
