import React, { Fragment, useCallback, useState } from 'react';
import axios from 'axios';
import MaterialTable from 'material-table';
import { makeStyles } from '@material-ui/core/styles';
import { tableIcons } from '../booking/TableIcons';
import { green, yellow } from '@material-ui/core/colors';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import useModal from '../../hooks/use-modal';
import AlertDialog from '../booking/AlertDialog';
import MainSubTable from './mainSubTable/MainSubTable';
import MainDelete from './mainDelete/MainDelete';
import MainUpdate from './mainUpdate/MainUpdate';

// Api config
import config from '../../utils/config';

const useStyles = makeStyles((theme) => ({
  view: {
    backgroundColor: yellow['A200'],
    color: '#000',
    '&:hover': {
      color: '#fff',
    },
  },
  update: {
    color: yellow['A200'],
    maxHeight: '2px !important',
    '&:hover': {
      background: 'none !important',
    },
  },
  delete: {
    maxHeight: '2px !important',
    '&:hover': {
      background: 'none !important',
    },
  },
  add: {
    color: green[500],
    '&:hover': {
      backgroundColor: 'rgba(232, 245, 233,1.0)',
    },
  },
  sizeSmall: {
    height: '18px',
    fontSize: '12px',
  },
}));

const MainServicesTable = React.memo((props) => {
  const classes = useStyles();

  const { tableRef, token } = props;

  // ==============MODAL STATES==============
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState('');
  const [typeAlert, setTypeAlert] = useState('success');

  const {
    modalState: modalStateDelete,
    modalData: modalDataDelete,
    openHandler: modalOpenDelete,
    closeHandler: modalCloseDelete,
  } = useModal();

  const {
    modalState: modalStateUpdate,
    modalData: modalDataUpdate,
    openHandler: modalOpenUpdate,
    closeHandler: modalCloseUpdate,
  } = useModal();

  // =================ALERT - (MODAL)=================
  const handleCloseAlert = useCallback(() => {
    setAlert(false);
    setMessage('');
    setTypeAlert('success');
  }, []);

  // =================Update - (MODAL)=================
  const handleUpdate = useCallback(
    async (values, actions) => {
      const options = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        const resp = await axios.patch(
          `${config.api}/services/${values.id}`,
          values,
          options
        );
        console.log(resp);
        if (resp.status === 200) {
          tableRef.current && tableRef.current.onQueryChange();
          modalCloseUpdate();
          setAlert(true);
          setMessage('Successfully Updated');
          setTypeAlert('success');
        }
      } catch (error) {
        console.log(error.response);
        actions.setSubmitting(false);
        tableRef.current && tableRef.current.onQueryChange();
        setAlert(true);
        setTypeAlert('error');
        setMessage(error.response.data.data);
      }
    },
    [token, tableRef, modalCloseUpdate]
  );

  // =================Delete - (MODAL)=================
  const handleDeletePackage = useCallback(
    async (id) => {
      const options = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        const resp = await axios.delete(
          `${config.api}/services/${id}`,
          options
        );
        tableRef.current && tableRef.current.onQueryChange();
        if (resp.status === 200) {
          modalCloseDelete();
          setAlert(true);
          setMessage('Successfully Deleted');
          setTypeAlert('success');
        }
      } catch (err) {
        tableRef.current && tableRef.current.onQueryChange();
        modalCloseDelete();
        setAlert(true);
        setTypeAlert('error');
        setMessage('Something went wrong please try again.');
      }
    },
    [modalCloseDelete, tableRef, token]
  );

  return (
    <Fragment>
      <AlertDialog
        open={alert}
        typeAlert={typeAlert}
        message={message}
        handleCloseAlert={handleCloseAlert}
      />

      {/* =================Update - (MODAL)================= */}
      <MainUpdate
        open={modalStateUpdate}
        data={modalDataUpdate}
        onClose={modalCloseUpdate}
        onUpdate={handleUpdate}
      />

      {/* =================Delete - (MODAL)================= */}
      <MainDelete
        open={modalStateDelete}
        data={modalDataDelete}
        onClose={modalCloseDelete}
        onDelete={handleDeletePackage}
      />

      <MaterialTable
        icons={tableIcons}
        title={`Main Services Table`}
        tableRef={tableRef}
        columns={[
          {
            title: 'Service Category',
            field: 'category.name',
            cellStyle: {
              width: 100,
              maxWidth: 100,
            },
            headerStyle: {
              width: 100,
              maxWidth: 100,
            },
          },
          {
            title: 'Service Item',
            field: 'name',
            cellStyle: {
              width: 2000,
              maxWidth: 2000,
            },
            headerStyle: {
              width: 2000,
              maxWidth: 2000,
            },
          },
          {
            title: 'Status',
            render: (client) => {
              let status = client.is_active === 1 ? 'Active' : 'Not Active';
              return (
                <Chip
                  classes={{ sizeSmall: classes.sizeSmall }}
                  size="small"
                  label={status}
                  color={`${client.is_active ? 'primary' : 'secondary'}`}
                />
              );
            },
            cellStyle: {
              width: 100,
              maxWidth: 100,
            },
            headerStyle: {
              width: 100,
              maxWidth: 100,
            },
          },
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            let url = config.api + "/services/all?type=''";
            url += `&page=${query.page + 1}`;
            if (query.pageSize) {
              url += `&per_page=${query.pageSize}`;
            }
            if (query.search) {
              url += `&search=${query.search}`;
            }
            const options = {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
              },
            };

            fetch(url, options)
              .then((response) => response.json())
              .then((result) => {
                resolve({
                  data: result.data.data,
                  page: result.data.current_page - 1 || 0,
                  totalCount: result.data.total,
                });
              });
          })
        }
        actions={[
          // {
          //   icon: "update",
          //   tooltip: "Update",
          //   onClick: (event, rowData) => {
          //     modalOpenUpdate(rowData);
          //   },
          // },
          {
            icon: 'delete',
            tooltip: 'Delete',
            onClick: (event, rowData) => {
              modalOpenDelete(rowData);
            },
          },
        ]}
        components={{
          Action: (props) => {
            // =====================Update=====================
            if (props.action.icon === 'update') {
              return (
                <Tooltip title="Update">
                  <IconButton
                    aria-label="update"
                    className={classes.update}
                    size="small"
                    onClick={(event) => props.action.onClick(event, props.data)}
                  >
                    <EditOutlinedIcon />
                  </IconButton>
                </Tooltip>
              );
            }
            // =====================Delete=====================
            if (props.action.icon === 'delete') {
              return (
                <Tooltip title="Delete">
                  <IconButton
                    aria-label="delete"
                    color="secondary"
                    size="small"
                    className={classes.delete}
                    onClick={(event) => props.action.onClick(event, props.data)}
                  >
                    <CancelIcon />
                  </IconButton>
                </Tooltip>
              );
            }
          },
        }}
        options={{
          headerStyle: {
            fontWeight: 'bold',
          },
          rowStyle: {
            fontSize: '.75rem',
            padding: '0px !important',
          },
          actionsCellStyle: {
            justifyContent: 'center',
            padding: '24px',
            marginBottom: '-1px',
          },
          pageSize: 30,
          pageSizeOptions: [],
          actionsColumnIndex: -1,
          tableLayout: 'auto',
        }}
        detailPanel={(rowData) => (
          <MainSubTable
            tableRef={tableRef}
            classes={classes}
            token={token}
            data={rowData}
          />
        )}
      />
    </Fragment>
  );
});

export default MainServicesTable;
