import React, { Fragment, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { makeStyles } from "@material-ui/core/styles";
import {
  IconButton,
  Typography,
  Slide,
  Toolbar,
  AppBar,
  Dialog,
  Button,
  Box,
} from "@material-ui/core";
import MuiDialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import PrintIcon from "@material-ui/icons/Print";
import PrintLayout from "./PrintLayout";
import "./print.css";
import { green } from "@material-ui/core/colors";
import SubJOTable from "../SubJOTable";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  paper: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(4),
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  button: {
    color: theme.palette.grey[900],
  },
  appBar: {
    position: "relative",
    backgroundColor: "#FAE700",
    color: "#000",
  },
  icon: {
    display: "flex",
    alignItems: "center",
  },
  subJOsButton: {
    marginLeft: "1rem",
    backgroundColor: green[500],
    color: "#fff",
    "&:hover": {
      backgroundColor: green[600],
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
      timeout={{ appear: 100, enter: 500, exit: 500 }}
    />
  );
});

const PrintDialog = (props) => {
  const classes = useStyles();

  const { data, open, handleClosePrint, token } = props;
  const [subJOVisible, setSubJOVisible] = useState(false);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleVisibleSubJOs = () => {
    setSubJOVisible(!subJOVisible);
  };

  return (
    <Fragment>
      <SubJOTable
        data={data}
        open={subJOVisible}
        handleCloseSubJO={handleVisibleSubJOs}
      />
      <div>
        <Dialog
          fullScreen
          open={open}
          onClose={handleClosePrint}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar style={{ justifyContent: "space-between" }}>
              <Box display={"flex"} alignItems={"center"}>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClosePrint}
                  aria-label="close"
                >
                  <CloseIcon className={classes.button} />
                </IconButton>
                <Typography variant="h5" className={classes.icon}>
                  Print Preview
                  <PrintIcon />
                </Typography>
                {data?.appointment_post_dispatch?.length > 0 && (
                  <Button
                    variant="contained"
                    className={classes.subJOsButton}
                    onClick={handleVisibleSubJOs}
                  >
                    View Sub JOs
                  </Button>
                )}
              </Box>
              <Button onClick={handlePrint}>PRINT</Button>
            </Toolbar>
          </AppBar>
          <MuiDialogContent dividers>
            <PrintLayout token={token} data={data} ref={componentRef} />
          </MuiDialogContent>
        </Dialog>
      </div>
    </Fragment>
  );
};

export default PrintDialog;
