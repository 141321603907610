import React, { Fragment, useCallback, useState } from "react";
import axios from "axios";
import MaterialTable from "material-table";
import { makeStyles } from "@material-ui/core/styles";
import { tableIcons } from "../booking/TableIcons";
import { green, yellow } from "@material-ui/core/colors";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@material-ui/icons/Cancel";
import Tooltip from "@material-ui/core/Tooltip";
import useModal from "../../hooks/use-modal";
import AlertDialog from "../booking/AlertDialog";
import UpdatePackageCategory from "./components/UpdatePackageCategory";

// Api config
import config from "../../utils/config";

const useStyles = makeStyles((theme) => ({
  update: {
    color: yellow["A700"],
    "&:hover": {
      background: "none !important",
    },
  },
  delete: {
    "&:hover": {
      background: "none !important",
    },
  },
  add: {
    color: green[500],
    "&:hover": {
      backgroundColor: "rgba(232, 245, 233,1.0)",
    },
  },
  sizeSmall: {
    height: "18px",
    fontSize: "12px",
  },
}));

const PackageCategoriesTable = React.memo((props) => {
  const classes = useStyles();

  const { tableRef, token } = props;

  // ==============MODAL STATES==============
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [typeAlert, setTypeAlert] = useState("success");

  const {
    modalState: modalStateDelete,
    modalData: modalDataDelete,
    openHandler: modalOpenDelete,
    closeHandler: modalCloseDelete,
  } = useModal();

  const {
    modalState: modalStateUpdate,
    modalData: modalDataUpdate,
    openHandler: modalOpenUpdate,
    closeHandler: modalCloseUpdate,
  } = useModal();

  const {
    modalState: modalStateView,
    modalData: modalDataView,
    openHandler: modalOpenView,
    closeHandler: modalCloseView,
  } = useModal();

  // =================ALERT - (MODAL)=================
  const handleCloseAlert = useCallback(() => {
    setAlert(false);
    setMessage("");
    setTypeAlert("success");
  }, []);

  // =================Update - (MODAL)=================
  const handleUpdate = useCallback(
    async (values, actions) => {
      console.log("values: ", values);
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        const resp = await axios.put(
          `${config.api}/package-category/${values.id}`,
          values,
          options
        );
        if (resp.status === 200) {
          tableRef.current && tableRef.current.onQueryChange();
          modalCloseUpdate();
          setAlert(true);
          setMessage("Successfully Updated");
          setTypeAlert("success");
        }
      } catch (error) {
        console.log(error.response);
        actions.setSubmitting(false);
        tableRef.current && tableRef.current.onQueryChange();
        setAlert(true);
        setTypeAlert("error");
        setMessage(error.response.data.data);
      }
    },
    [token, tableRef, modalCloseUpdate]
  );

  return (
    <Fragment>
      <AlertDialog
        open={alert}
        typeAlert={typeAlert}
        message={message}
        handleCloseAlert={handleCloseAlert}
      />
      <UpdatePackageCategory
        open={modalStateUpdate}
        data={modalDataUpdate}
        onClose={modalCloseUpdate}
        handleSubmitForm={handleUpdate}
      />

      <MaterialTable
        icons={tableIcons}
        title={`Packages Categories`}
        tableRef={tableRef}
        columns={[
          {
            title: "Package Category",
            field: "name",
          },
          {
            title: "Show in Frontend",
            field: "is_public",
            render: (data) => {
              return data.is_public ? "Yes" : "No";
            },
          },
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            let url = config.api + "/package-category";
            url += `?page=${query.page + 1}`;
            if (query.pageSize) {
              url += `&per_page=${query.pageSize}`;
            }
            if (query.search) {
              url += `&search=${query.search}`;
            }
            const options = {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            };

            fetch(url, options)
              .then((response) => response.json())
              .then((result) => {
                resolve({
                  data: result.data.data,
                  page: result.data.current_page - 1 || 0,
                  totalCount: result.data.total,
                });
              });
          })
        }
        actions={[
          // {
          //   icon: "view",
          //   tooltip: "View",
          //   onClick: (event, rowData) => {
          //     modalOpenView(rowData);
          //   },
          // },
          {
            icon: "update",
            tooltip: "Update",
            onClick: (event, rowData) => {
              modalOpenUpdate(rowData);
            },
          },
          // {
          //   icon: "delete",
          //   tooltip: "Delete",
          //   onClick: (event, rowData) => {
          //     modalOpenDelete(rowData);
          //   },
          // },
        ]}
        components={{
          Action: (props) => {
            // =====================View=====================
            if (props.action.icon === "view") {
              return (
                <Tooltip title="View">
                  <IconButton
                    aria-label="view"
                    size="small"
                    color="default"
                    onClick={(event) => props.action.onClick(event, props.data)}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>
              );
            }
            // =====================Update=====================
            if (props.action.icon === "update") {
              return (
                <Tooltip title="Update">
                  <IconButton
                    aria-label="update"
                    className={classes.update}
                    size="small"
                    onClick={(event) => props.action.onClick(event, props.data)}
                  >
                    <EditOutlinedIcon />
                  </IconButton>
                </Tooltip>
              );
            }
            // =====================Delete=====================
            if (props.action.icon === "delete") {
              return (
                <Tooltip title="Delete">
                  <IconButton
                    aria-label="delete"
                    color="secondary"
                    size="small"
                    className={classes.delete}
                    onClick={(event) => props.action.onClick(event, props.data)}
                  >
                    <CancelIcon />
                  </IconButton>
                </Tooltip>
              );
            }
          },
        }}
        options={{
          headerStyle: {
            fontWeight: "bold",
          },
          rowStyle: {
            fontSize: ".75rem",
            padding: "0px !important",
          },
          actionsCellStyle: {
            justifyContent: "center",
            padding: "24px",
            marginBottom: "-1px",
          },
          pageSize: 30,
          pageSizeOptions: [],
          actionsColumnIndex: -1,
          tableLayout: "auto",
          sorting: false,
        }}
      />
    </Fragment>
  );
});

export default PackageCategoriesTable;
