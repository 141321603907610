import React, { Fragment, useCallback, useState } from 'react';
import axios from 'axios';
import MaterialTable from 'material-table';
import { makeStyles } from '@material-ui/core/styles';
import { tableIcons } from '../booking/TableIcons';
import { green, yellow } from '@material-ui/core/colors';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import Tooltip from '@material-ui/core/Tooltip';
import useModal from '../../hooks/use-modal';
import AlertDialog from '../booking/AlertDialog';
import Chip from '@material-ui/core/Chip';
import ServiceCategoryUpdate from './ServiceCategoryUpdate';

// Api config
import config from '../../utils/config';

const useStyles = makeStyles((theme) => ({
  view: {
    backgroundColor: yellow['A200'],
    color: '#000',
    '&:hover': {
      color: '#fff',
    },
  },
  update: {
    color: yellow['A200'],
    maxHeight: '2px !important',
    '&:hover': {
      background: 'none !important',
    },
  },
  delete: {
    maxHeight: '2px !important',
    '&:hover': {
      background: 'none !important',
    },
  },
  add: {
    color: green[500],
    '&:hover': {
      backgroundColor: 'rgba(232, 245, 233,1.0)',
    },
  },
  sizeSmall: {
    height: '18px',
    fontSize: '12px',
  },
}));

const ServicesCategoriesTable = React.memo((props) => {
  const classes = useStyles();

  const { tableRef, token } = props;

  // ==============MODAL STATES==============
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState('');
  const [typeAlert, setTypeAlert] = useState('success');

  const {
    modalState: modalStateUpdate,
    modalData: modalDataUpdate,
    openHandler: modalOpenUpdate,
    closeHandler: modalCloseUpdate,
  } = useModal();


  // =================Update - (MODAL)=================
  const handleUpdateService = useCallback(
    async (values, actions) => {
      const options = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        const resp = await axios.put(
          `${config.api}/services/categories/${values.id}`,
          values,
          options
        );
        console.log(resp);
        if (resp.status === 200) {
          tableRef.current && tableRef.current.onQueryChange();
          modalCloseUpdate();
          setAlert(true);
          setMessage('Successfully Updated');
          setTypeAlert('success');
        }
      } catch (error) {
        actions.setSubmitting(false);
        tableRef.current && tableRef.current.onQueryChange();
        setAlert(true);
        setTypeAlert('error');
        setMessage(error.response.data.data);
      }
    },
    [token, tableRef, modalCloseUpdate]
  );

  // =================ALERT - (MODAL)=================
  const handleCloseAlert = useCallback(() => {
    setAlert(false);
    setMessage('');
    setTypeAlert('success');
  }, []);

  return (
    <Fragment>
      <AlertDialog
        open={alert}
        typeAlert={typeAlert}
        message={message}
        handleCloseAlert={handleCloseAlert}
      />

      {/* =================UPDATE - (MODAL)================= */}
      <ServiceCategoryUpdate
        open={modalStateUpdate}
        data={modalDataUpdate}
        onClose={modalCloseUpdate}
        onUpdate={handleUpdateService}
      />

      <MaterialTable
        icons={tableIcons}
        title={`Services Table`}
        tableRef={tableRef}
        columns={[
          { title: 'Id', field: 'id' },
          { title: 'Service Name', field: 'name' },
          {
            title: 'Status',
            render: (service) => {
              let status = service.is_active === 1 ? 'Active' : 'Inactive';
              return (
                <Chip
                  classes={{ sizeSmall: classes.sizeSmall }}
                  size="small"
                  label={status}
                  color={`${service.is_active ? 'primary' : 'secondary'}`}
                />
              );
            },
            cellStyle: {
              width: 100,
              maxWidth: 100,
            },
            headerStyle: {
              width: 100,
              maxWidth: 100,
            },
          },
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            let url = config.api + '/services/categories';
            // url += `?page=${query.page + 1}`;
            // if (query.pageSize) {
            //   url += `&per_page=${query.pageSize}`;
            // }
            // if (query.search) {
            //   url += `&search=${query.search}`;
            // }
            const options = {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
              },
            };

            fetch(url, options)
              .then((response) => response.json())
              .then((result) => {
                resolve({
                  data: result.data,
                });
              });
          })
        }
        actions={[
          {
            icon: 'update',
            tooltip: 'Update',
            onClick: (event, rowData) => {
              modalOpenUpdate(rowData);
            },
          },
        ]}
        components={{
          Action: (props) => {
            // =====================Update=====================
            if (props.action.icon === 'update') {
              return (
                <Tooltip title="Update">
                  <IconButton
                    aria-label="update"
                    className={classes.update}
                    size="small"
                    onClick={(event) => props.action.onClick(event, props.data)}
                  >
                    <EditOutlinedIcon />
                  </IconButton>
                </Tooltip>
              );
            }
            // =====================Delete=====================
            if (props.action.icon === 'delete') {
              return (
                <Tooltip title="Delete">
                  <IconButton
                    aria-label="delete"
                    color="secondary"
                    size="small"
                    className={classes.delete}
                    onClick={(event) => props.action.onClick(event, props.data)}
                  >
                    <CancelIcon />
                  </IconButton>
                </Tooltip>
              );
            }
          },
        }}
        options={{
          headerStyle: {
            fontWeight: 'bold',
          },
          rowStyle: {
            fontSize: '.75rem',
            padding: '0px !important',
          },
          actionsCellStyle: {
            justifyContent: 'center',
            padding: '24px',
            marginBottom: '-1px',
          },
          pageSize: 30,
          pageSizeOptions: [],
          actionsColumnIndex: -1,
          tableLayout: 'auto',
          sorting: false,
        }}
      />
    </Fragment>
  );
});

export default ServicesCategoriesTable;
