import { Fragment } from 'react';
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { green } from '@material-ui/core/colors';
import { Formik, Form, FastField } from 'formik';
import { object, string } from 'yup';
import { TextField } from 'formik-material-ui';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: '100%',
  },
  root: {
    padding: '12px',
  },
  title: {
    backgroundColor: '#FAE700',
  },
  titleText: {
    color: 'rgb(63,81,181) !important',
    fontWeight: 'bold !important',
  },
  button: {
    backgroundColor: green[500],
    color: '#fff',
    '&:hover': {
      backgroundColor: green[600],
    },
  },
  paper: { maxWidth: '350px' },
}));

const AddAdsSource = React.memo((props) => {
  const classes = useStyles();
  const [initialValues, setInitialValues] = useState({
    ads_source_name: '',
    is_active: '',
  });

  const { open, data, onUpdate, onClose } = props;

  useEffect(() => {
    setInitialValues({
      ads_source_name: data.ads_source_name,
      is_active: data.is_active,
    });
  }, [data]);

  const handleClose = () => {
    onClose();
    setInitialValues({
      ads_source_name: '',
      is_active: '',
    });
  };

  const handleSubmit = (values) => {
    onUpdate({ ...values, is_active: initialValues.is_active, id: data.id });
  };

  const validationSchema = object().shape({
    ads_source_name: string().required('Ads source is required'),
  });

  const handleChange = (event) => {
    if (event.target.value === 1) {
      setInitialValues({
        ...initialValues,
        is_active: 1,
      });
    } else {
      setInitialValues({
        ...initialValues,
        is_active: 0,
      });
    }
  };

  return (
    <Fragment>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          classes={{ paper: classes.paper }}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form autoComplete="off">
                <DialogTitle
                  id="alert-dialog-title"
                  className={`${classes.title}`}
                >
                  <span className={classes.titleText}>
                    {'Update Ads Source FORM'}
                  </span>
                </DialogTitle>
                <DialogContent dividers>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <FastField
                        label="Ads source"
                        component={TextField}
                        name="ads_source_name"
                        type="text"
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <Select
                          id="is_active"
                          name="is_active"
                          value={initialValues.is_active}
                          onChange={handleChange}
                          classes={{
                            root: classes.root,
                          }}
                        >
                          <MenuItem value={1}>Active</MenuItem>
                          <MenuItem value={0}>Inactive</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} size="small" color="default">
                    Close
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    color="primary"
                    variant="contained"
                    autoFocus
                    size="small"
                    endIcon={isSubmitting && <CheckCircleIcon size="0.9rem" />}
                  >
                    {isSubmitting ? 'Submitting' : 'Submit'}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>
      </div>
    </Fragment>
  );
});
export default AddAdsSource;
