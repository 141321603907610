import React, { Fragment, useState } from 'react';
// mui
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Tabs, Tab, Box } from '@material-ui/core';

import Attendances from './Attendances';
import TabPanel from './TabPanel';
import Leaves from './Leaves';
import Overtimes from './Overtimes';

const useStyles = makeStyles((theme) => ({
    appBar: {
      //backgroundColor: '#fae700', 
      //color: '#000',
      marginBottom: '20px'
    },
}));

const MechanicsAttendance = (props) => {
    const classes = useStyles();
    
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Fragment>
            <Box>
                <AppBar position="static"  className={classes.appBar}>
                    <Tabs value={value} onChange={handleChange}>
                        <Tab label="Attendances" />
                        <Tab label="Overtimes" />
                        <Tab label="Leaves" />
                    </Tabs>
                </AppBar>
                <TabPanel value={value} index={0}>
                    <Attendances {...props}/>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Overtimes {...props} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <Leaves {...props} />
                </TabPanel>
            </Box>
        </Fragment>
    )

}

export default React.memo(MechanicsAttendance)