import React, {
  Fragment,
  useCallback,
  useState,
  useRef,
  useEffect,
} from 'react';
import axios from 'axios';
import MaterialTable from 'material-table';
import { makeStyles } from '@material-ui/core/styles';
import { tableIcons } from '../../booking/TableIcons';
import { green, yellow } from '@material-ui/core/colors';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import Tooltip from '@material-ui/core/Tooltip';
import useModal from '../../../hooks/use-modal';
import AlertDialog from '../../booking/AlertDialog';
import DeleteVariant from './deleteVariant/DeleteVariant';
import AddVariant from './addVariant/AddVariant';
import UpdateVariant from './updateVariant/UpdateVariant';

// Api config
import config from '../../../utils/config';

const useStyles = makeStyles((theme) => ({
  view: {
    backgroundColor: yellow['A200'],
    color: '#000',
    '&:hover': {
      color: '#fff',
    },
  },
  update: {
    color: yellow['A200'],
    maxHeight: '2px !important',
    '&:hover': {
      background: 'none !important',
    },
  },
  delete: {
    maxHeight: '2px !important',
    '&:hover': {
      background: 'none !important',
    },
  },
  add: {
    color: green[500],
    '&:hover': {
      backgroundColor: 'rgba(232, 245, 233,1.0)',
    },
  },
  sizeSmall: {
    height: '18px',
    fontSize: '12px',
  },
}));

const MainSubTable = React.memo((props) => {
  const classes = useStyles();

  const { tableRef, token, data } = props;

  const service_id = data && data.id;
  const packages = data && data.packages;

  const [variants, setVariants] = useState([])

  useEffect(() => {
    fetchServiceVariants(props?.data?.id);
  }, [props]);

  const fetchServiceVariants = async (service_id) => {
    try {
      const options = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };
      const resp = await axios.get(
        `${config.api}/services/variants/${service_id}`,
        options
      );
      if(resp?.data?.status === 'success') {
        setVariants(resp?.data?.data)
      }
    } catch (error) {
      setVariants([])
    }
  };

  // ==============MODAL STATES==============
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState('');
  const [typeAlert, setTypeAlert] = useState('success');

  const [openAdd, setOpenAdd] = useState(false);

  const {
    modalState: modalStateDelete,
    modalData: modalDataDelete,
    openHandler: modalOpenDelete,
    closeHandler: modalCloseDelete,
  } = useModal();

  const {
    modalState: modalStateUpdate,
    modalData: modalDataUpdate,
    openHandler: modalOpenUpdate,
    closeHandler: modalCloseUpdate,
  } = useModal();

  // =================ALERT - (MODAL)=================
  const handleCloseAlert = useCallback(() => {
    setAlert(false);
    setMessage('');
    setTypeAlert('success');
  }, []);

  //   ==============Add Variant (Modal)==============
  const handleOpenAdd = useCallback(() => {
    setOpenAdd(true);
  }, []);

  const handleCloseAdd = useCallback(() => {
    setOpenAdd(false);
  }, []);

  const handleSubmitAdd = useCallback(
    async (values, actions) => {
      const options = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        const resp = await axios.post(
          `${config.api}/services/variants/${service_id}`,
          values,
          options
        );
        if (resp.data?.status === 'success') {
          tableRef.current && tableRef.current.onQueryChange();
          setOpenAdd(false);
          setAlert(true);
          setMessage('Successfully Added');
          setTypeAlert('success');
        }
      } catch (error) {
        actions.setSubmitting(false);
        tableRef.current && tableRef.current.onQueryChange();
        setAlert(true);
        setTypeAlert('error');
        setMessage(error.response.data.data);
      }
    },
    [token, tableRef]
  );

  // =================Update - (MODAL)=================
  const handleUpdateVariant = useCallback(
    async (values, actions) => {
      const options = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        const resp = await axios.put(
          `${config.api}/services/variants/${values.id}`,
          values,
          options
        );
        if (resp?.data?.status === 'success') {
          modalCloseUpdate();
          setAlert(true);
          setMessage('Successfully Updated');
          setTypeAlert('success');
          tableRef.current && tableRef.current.onQueryChange();
        }
      } catch (error) {
        actions.setSubmitting(false);
        setAlert(true);
        setTypeAlert('error');
        setMessage(error.response.data.data);
        tableRef.current && tableRef.current.onQueryChange();
      }
    },
    [tableRef, token, modalCloseUpdate]
  );

  // =================Delete - (MODAL)=================
  const handleDeleteVariant = useCallback(
    async (id) => {
      const options = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        const resp = await axios.delete(
          `${config.api}/services/variants/${id}`,
          options
        );
        if (resp.status === 200) {
          modalCloseDelete();
          setAlert(true);
          setMessage('Successfully Deleted');
          setTypeAlert('success');
          tableRef.current && tableRef.current.onQueryChange();
        }
      } catch (error) {
        tableRef.current && tableRef.current.onQueryChange();
        setAlert(true);
        setTypeAlert('error');
        setMessage(error.response.data.data);
      }
    },
    [tableRef, token, modalCloseDelete]
  );

  return (
    <Fragment>
      {/* =================ALERT - (MODAL)================= */}
      <AlertDialog
        open={alert}
        typeAlert={typeAlert}
        message={message}
        handleCloseAlert={handleCloseAlert}
      />

      {/* =================ADD VARIANT - (MODAL)================= */}
      <AddVariant
        open={openAdd}
        onOpen={handleOpenAdd}
        onClose={handleCloseAdd}
        handleSubmitAdd={handleSubmitAdd}
        token={token}
        service_id={service_id}
      />

      {/* =================DELETE VARIANT- (MODAL)================= */}
      <UpdateVariant
        open={modalStateUpdate}
        data={modalDataUpdate}
        onClose={modalCloseUpdate}
        onUpdate={handleUpdateVariant}
      />

      {/* =================DELETE VARIANT - (MODAL)================= */}
      <DeleteVariant
        open={modalStateDelete}
        data={modalDataDelete}
        onClose={modalCloseDelete}
        onDelete={handleDeleteVariant}
      />

      <MaterialTable
        icons={tableIcons}
        title={`Service Variants`}
        columns={[
          { title: 'Fuel Type', field: 'fuel_type' },
          { title: 'Liter of Oil', field: 'liter_of_oil' },
          { title: 'Price', field: 'price' },
        ]}
        data={variants}
        actions={[
          {
            icon: 'update',
            tooltip: 'Update',
            onClick: (event, rowData) => {
              modalOpenUpdate(rowData);
            },
          },
          {
            icon: 'delete',
            tooltip: 'Delete',
            onClick: (event, rowData) => {
              modalOpenDelete(rowData);
            },
          },
        ]}
        components={{
          Action: (props) => {
            // =====================Update=====================
            if (props.action.icon === 'update') {
              return (
                <Tooltip title="Update">
                  <IconButton
                    aria-label="update"
                    className={classes.update}
                    size="small"
                    onClick={(event) => props.action.onClick(event, props.data)}
                  >
                    <EditOutlinedIcon />
                  </IconButton>
                </Tooltip>
              );
            }
            // =====================Delete=====================
            if (props.action.icon === 'delete') {
              return (
                <Tooltip title="Delete">
                  <IconButton
                    aria-label="delete"
                    color="secondary"
                    size="small"
                    className={classes.delete}
                    onClick={(event) => props.action.onClick(event, props.data)}
                  >
                    <CancelIcon />
                  </IconButton>
                </Tooltip>
              );
            }
          },
        }}
        options={{
          headerStyle: {
            fontWeight: 'bold',
          },
          rowStyle: {
            fontSize: '.75rem',
            padding: '0px !important',
          },
          actionsCellStyle: {
            justifyContent: 'center',
            padding: '24px',
            marginBottom: '-1px',
          },
          pageSize: 20,
          pageSizeOptions: [],
          actionsColumnIndex: -1,
        }}
      />
    </Fragment>
  );
});

export default MainSubTable;
