import React, { Fragment } from "react";
import moment from "moment";
import "moment-timezone";
import { makeStyles } from "@material-ui/core/styles";
import {
  IconButton,
  Typography,
  Slide,
  Toolbar,
  AppBar,
  Dialog,
  Box,
  Paper,
  Grid,
} from "@material-ui/core";
import MuiDialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  paper: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(4),
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  button: {
    color: theme.palette.grey[900],
  },
  icon: {
    position: "absolute",
    top: "18px",
    marginLeft: "3px",
  },
  titleText: {
    fontWeight: "bold",
  },
  text: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    fontSize: "14px !important",
  },
  boxItem: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  listItem: {
    listStyleType: "none",
  },
  liItem: {
    marginBottom: "3px",
  },
  label: {
    fontWeight: "bold",
    fontSize: "14px !important",
    minWidth: "170px",
  },
  data: {
    fontSize: "14px !important",
    fontWeight: "normal",
  },
  liItem2: {
    marginBottom: "3px",
    lineHeight: "1.2",
  },
  label2: {
    fontWeight: "800",
    fontSize: "16px !important",
    minWidth: "25%",
    textAlign: "right",
    marginRight: "160px",
  },
  data2: {
    fontSize: "16px !important",
    fontWeight: "normal",
    minWidth: "45%",
    textAlign: "left",
  },
  appBar: {
    position: "relative",
    backgroundColor: "#FAE700",
    color: "#000",
  },
  paper2: {
    padding: theme.spacing(4),
    paddingRight: theme.spacing(10),
    paddingLeft: theme.spacing(10),
  },
  table: {
    width: 650,
    minWidth: 650,
  },
  textDesign: {
    textTransform: "capitalize",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
      timeout={{ appear: 100, enter: 500, exit: 500 }}
    />
  );
});

const RequestView = React.memo((props) => {
  const classes = useStyles();

  const { data, open, onClose } = props;

  return (
    <Fragment>
      <div>
        <Dialog
          fullScreen
          open={open}
          onClose={onClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <Typography variant="h5" className={classes.title}>
                View Request Quotation
                <CalendarTodayIcon className={classes.icon} />
              </Typography>
              <IconButton
                edge="start"
                color="inherit"
                onClick={onClose}
                aria-label="close"
              >
                <CloseIcon className={classes.button} />
              </IconButton>
            </Toolbar>
          </AppBar>
          <MuiDialogContent dividers>
            <div className={classes.root}>
              <Paper className={classes.paper}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Box mt={2} className={classes.text}>
                      <Typography
                        paragraph={true}
                        display={"inline"}
                        style={{ fontWeight: "bold" }}
                      >
                        Status :
                      </Typography>
                      <Typography
                        paragraph={true}
                        display={"inline"}
                        className={`${classes.span} ${classes.textDesign}`}
                      >
                        {" "}
                        {data.status ? data.status : "New"}
                      </Typography>
                    </Box>
                    <Box className={classes.text}>
                      <Typography
                        paragraph={true}
                        display={"inline"}
                        style={{ fontWeight: "bold" }}
                      >
                        Quotation Date :
                      </Typography>
                      <Typography
                        paragraph={true}
                        display={"inline"}
                        className={`${classes.span} ${classes.textDesign}`}
                      >
                        {` ${moment(data.created_at).format("LLL")}`}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={5}>
                    <Box className={classes.boxItem}>
                      <ul className={classes.listItem}>
                        <li className={classes.liItem}>
                          <div style={{ display: "flex" }}>
                            <div className={classes.label}>Name : </div>
                            <div
                              className={classes.data}
                              style={{ textTransform: "capitalize" }}
                            >
                              {data.name}
                            </div>
                          </div>
                        </li>
                        <li className={classes.liItem}>
                          <div style={{ display: "flex" }}>
                            <div className={classes.label}>Email : </div>
                            <div className={classes.data}>{data.email}</div>
                          </div>
                        </li>
                        <li className={classes.liItem}>
                          <div style={{ display: "flex" }}>
                            <div className={classes.label}>
                              Contact Number :
                            </div>
                            <div className={classes.data}>
                              {data.phone_number}
                            </div>
                          </div>
                        </li>
                        <li className={classes.liItem}>
                          <div style={{ display: "flex" }}>
                            <div className={classes.label}>Address :</div>
                            <div
                              className={classes.data}
                              style={{ textTransform: "capitalize" }}
                            >
                              {data.address}
                            </div>
                          </div>
                        </li>
                        <li className={classes.liItem}>
                          <div style={{ display: "flex" }}>
                            <div className={classes.label}>Services :</div>
                            <div
                              className={classes.data}
                              style={{ textTransform: "capitalize" }}
                            >
                              {data.services}
                            </div>
                          </div>
                        </li>
                        <li className={classes.liItem}>
                          <div style={{ display: "flex" }}>
                            <div className={classes.label}>
                              Comment/Special wishes :{" "}
                            </div>
                            <div
                              className={classes.data}
                              style={{
                                width: "12rem !important",
                                wordBreak: "break-word !important",
                                textTransform: "capitalize",
                              }}
                            >
                              {data.comment}
                            </div>
                          </div>
                        </li>
                        <li className={classes.liItem}>
                          <div style={{ display: "flex" }}>
                            <div className={classes.label}>Remarks : </div>
                            <div
                              className={classes.data}
                              style={{
                                width: "12rem !important",
                                wordBreak: "break-word !important",
                                textTransform: "capitalize",
                              }}
                            >
                              {data.remarks}
                            </div>
                          </div>
                        </li>
                      </ul>
                    </Box>
                  </Grid>
                  <Grid item xs={5}>
                    <Box mt={1} className={classes.boxItem}>
                      <ul className={classes.listItem}>
                        <li className={classes.liItem}>
                          <div style={{ display: "flex" }}>
                            <div className={classes.label}>Make / Model :</div>
                            <div
                              className={classes.data}
                              style={{ textTransform: "capitalize" }}
                            >
                              {data.make} {data.model}
                            </div>
                          </div>
                        </li>
                        <li className={classes.liItem}>
                          <div style={{ display: "flex" }}>
                            <div className={classes.label}>Fuel Type : </div>
                            <div className={classes.data}>{data.fuel_type}</div>
                          </div>
                        </li>
                        <li className={classes.liItem}>
                          <div style={{ display: "flex" }}>
                            <div className={classes.label}>Vehicle Year : </div>
                            <div
                              className={classes.data}
                              style={{ textTransform: "capitalize" }}
                            >
                              {data.year}
                            </div>
                          </div>
                        </li>
                        <li className={classes.liItem}>
                          <div style={{ display: "flex" }}>
                            <div className={classes.label}>Transmission : </div>
                            <div
                              className={classes.data}
                              style={{ textTransform: "capitalize" }}
                            >
                              {data.transmission}
                            </div>
                          </div>
                        </li>
                        <li className={classes.liItem}>
                          <div style={{ display: "flex" }}>
                            <div className={classes.label}>Engine Size : </div>
                            <div
                              className={classes.data}
                              style={{ textTransform: "capitalize" }}
                            >
                              {data.engine_size}
                            </div>
                          </div>
                        </li>
                      </ul>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            </div>
          </MuiDialogContent>
        </Dialog>
      </div>
    </Fragment>
  );
});

export default RequestView;
