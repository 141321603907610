import { Fragment, useState } from 'react';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { green } from '@material-ui/core/colors';
import { Formik, Form, FastField } from 'formik';
import { object, string } from 'yup';
import { TextField } from 'formik-material-ui';

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: '#FAE700',
  },
  titleText: {
    color: 'rgb(63,81,181) !important',
    fontWeight: 'bold !important',
  },
  button: {
    backgroundColor: green[500],
    color: '#fff',
    '&:hover': {
      backgroundColor: green[600],
    },
  },
  paper: { maxWidth: '800px' },
}));

const RemarksDialog = React.memo((props) => {
  const classes = useStyles();

  const { open, onClose, data, onUpdate } = props;

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = (values) => {
    onUpdate(values);
  };

  const initialValues = {
    id: data && data.id ? data.id : null,
    mechanic_appointment_remarks: data && data.mechanic_appointment_remarks ? data.mechanic_appointment_remarks : '',
  };

  const validationSchema = object().shape({
    mechanic_appointment_remarks: string().required('Remarks is required'),
  });


  return (
    <Fragment>
      <div>
        <Dialog
          fullWidth
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          classes={{ paper: classes.paper }}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, isSubmitting, setFieldValue }) => (
              <Form autoComplete="off">
                <DialogTitle
                  id="alert-dialog-title"
                  className={`${classes.title}`}
                >
                  <span className={classes.titleText}>
                    {'ADD YOUR REMARKS (if any)'}
                  </span>
                </DialogTitle>
                <DialogContent dividers>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <FastField
                        label="Remarks"
                        component={TextField}
                        name="mechanic_appointment_remarks"
                        type="text"
                        variant="outlined"
                        size="small"
                        fullWidth
                        inputProps={{
                          style: {
                            textAlign: '',
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} size="small" color="default">
                    Close
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    color="primary"
                    variant="contained"
                    autoFocus
                    size="small"
                    endIcon={
                      isSubmitting ? (
                        <CheckCircleIcon size="0.9rem" />
                      ) : undefined
                    }
                  >
                    {isSubmitting ? 'Updating' : 'Update'}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>
      </div>
    </Fragment>
  );
});
export default RemarksDialog;
