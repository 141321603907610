import React from 'react';
import { StyleSheet, View, Text, Image } from '@react-pdf/renderer';
import data from './data.json';

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  column: {
    width: '32.6%',
    marginLeft: 4,
    marginBottom: 4,
  },
  boxHeader: {
    color: '#fff',
    backgroundColor: '#000b8c',
    padding: 6,
  },
  boxBody: {
    borderWidth: 1,
    borderColor: '#000b8c',
    borderTop: 0,
    padding: 6,
  },
  bold: {
    fontFamily: 'Helvetica-Bold',
  },
  image: {
    width: 171,
    height: 228,
  },
});

const ReferencePhotos = ({ content }) => {
  let files = content?.inspection;

  if (content?.extended_information) {
    if (content?.extended_information?.plate_number_photo_url) {
      files.push({
        type: 'Plate number',
        photo: content?.extended_information?.plate_number_photo_url,
      });
    }
    if (content?.extended_information?.mileage_photo_url) {
      files.push({
        type: 'Mileage',
        photo: content?.extended_information?.mileage_photo_url,
      });
    }
    if (content?.extended_information?.vin_number_photo_url) {
      files.push({
        type: 'Vin number',
        photo: content?.extended_information?.vin_number_photo_url,
      });
    }
    if (content?.extended_information?.tire_size_photo_url) {
      files.push({
        type: 'Tire size',
        photo: content?.extended_information?.tire_size_photo_url,
      });
    }
  }

  const photo = (name, photo) => {
    if (photo) {
      return (
        <View style={styles.column} key={name}>
          <View style={[styles.boxHeader, styles.bold]}>
            <Text>{name}</Text>
          </View>
          <View style={styles.boxBody}>
            <Image style={styles.image} src={photo} />
          </View>
        </View>
      );
    }
    return;
  };

  return (
    <>
      <View
        style={[
          styles.row,
          styles.boxHeader,
          styles.bold,
          {
            fontSize: 10,
            marginVertical: 10,
            justifyContent: 'center',
          },
        ]}
      >
        <Text>Reference Photos</Text>
      </View>
      <View style={[styles.row, { marginBottom: 8, marginLeft: -4 }]}>
        {files?.map((item) => photo(item.type, item.photo))}
      </View>
    </>
  );
};

export default ReferencePhotos;
