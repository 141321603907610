import { Fragment } from "react";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import AddIcon from "@material-ui/icons/Add";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { green } from "@material-ui/core/colors";
import { Formik, Form, FastField } from "formik";
import { object, string, number } from "yup";
import { TextField } from "formik-material-ui";
import SelectServiceCategory from "../common/SelectServiceCategory";
import SelectIsActive from "../common/SelectIsActive"
import SelectInternalService from "../common/SelectInternalService";
import SelectServiceActions from "../common/SelectServiceActions";
import SelectServiceParts from "../common/SelectServiceParts";
import {RichTextField} from 'mui-quill';

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: "#FAE700",
  },
  titleText: {
    color: "rgb(63,81,181) !important",
    fontWeight: "bold !important",
  },
  button: {
    backgroundColor: green[500],
    color: "#fff",
    "&:hover": {
      backgroundColor: green[600],
    },
  },
  paper: { maxWidth: "350px" },
}));

const ServicesUpdate = React.memo((props) => {
  const classes = useStyles();

  const { open, data, onClose } = props;

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = (values) => {
    props.onUpdate(values);
  };

  const initialValues = {
    id: data && data.id,
    service_category_id: data && data.service_category_id,
    name: data && data.part_name,
    service_action_id: data && data.service_action_id,
    description: data && data.description,
    warranty: data && data.warranty,
    duration: data && data.duration,
    is_active: data && data.is_active,
    type: data && data.type
  };

  const validationSchema = object().shape({
    name: string().required("Part is required"),
    service_category_id: string().required("Service category is required"),
    service_action_id: string().required("Service Action is required"),
  });

  return (
    <Fragment>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          disableBackdropClick
          classes={{ paper: classes.paper }}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue, isSubmitting }) => (
              <Form autoComplete="off">
                <DialogTitle
                  id="alert-dialog-title"
                  className={`${classes.title}`}
                >
                  <span className={classes.titleText}>
                    {"UPDATE SERVICE FORM"}
                  </span>
                </DialogTitle>
                <DialogContent dividers>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <FastField
                        name="service_category_id"
                        label="Service Category"
                        variant="outlined"
                        size="small"
                        component={SelectServiceCategory}
                        fullWidth
                        onChange={async (e) => {
                          const { value } = e.target;
                          setFieldValue("service_category_id", value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FastField
                        label="Part"
                        component={SelectServiceParts}
                        name="name"
                        type="text"
                        variant="outlined"
                        size="small"
                        fullWidth
                        inputProps={{
                          style: {
                            textAlign: "center",
                            textTransform: "capitalize",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FastField
                        label="Service Action"
                        component={SelectServiceActions}
                        name="service_action_id"
                        type="text"
                        variant="outlined"
                        size="small"
                        fullWidth
                        onChange={async (e) => {
                          const { value } = e.target;
                          setFieldValue("service_action_id", value);
                        }}
                        inputProps={{
                          style: {
                            textAlign: "center",
                            textTransform: "capitalize",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FastField
                        label="Duration"
                        component={TextField}
                        name="duration"
                        type="text"
                        variant="outlined"
                        size="small"
                        fullWidth
                        inputProps={{
                          style: {
                            textAlign: "center",
                            textTransform: "capitalize",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <RichTextField
                        value={values.description}
                        onChange={(value) => setFieldValue("description", value)}
                        variant="outlined"
                        options={{
                          toolbar: true
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FastField
                        label="Warranty"
                        component={TextField}
                        name="warranty"
                        type="text"
                        variant="outlined"
                        size="small"
                        fullWidth
                        inputProps={{
                          style: {
                            textAlign: "center",
                            textTransform: "capitalize",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FastField
                        label="Offer to Frontend Website"
                        component={SelectInternalService}
                        name="type"
                        type="text"
                        variant="outlined"
                        size="small"
                        fullWidth
                        inputProps={{
                          style: {
                            textAlign: "center",
                            textTransform: "capitalize",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FastField
                        label="Status"
                        component={SelectIsActive}
                        name="is_active"
                        type="text"
                        variant="outlined"
                        size="small"
                        fullWidth
                        inputProps={{
                          style: {
                            textAlign: "center",
                            textTransform: "capitalize",
                          },
                        }}
                      />
                    </Grid>
                    
                  </Grid>
                  {/* <pre>{JSON.stringify({ values, errors }, null, 4)}</pre> */}
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} size="small" color="default">
                    Close
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    color="primary"
                    variant="contained"
                    autoFocus
                    size="small"
                    endIcon={
                      isSubmitting ? (
                        <CheckCircleIcon size="0.9rem" />
                      ) : undefined
                    }
                  >
                    {isSubmitting ? "Submitting" : "Submit"}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>
      </div>
    </Fragment>
  );
});
export default ServicesUpdate;
