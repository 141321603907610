import React, { Fragment } from "react";
import "moment-timezone";
import { TableCell, TableRow } from "@material-ui/core";

const sanitizeText = (text) => {
  if (text !== "") {
    return text.replace(/_/g, " ");
  }
  return text;
};

const numberConverter = (number) => {
  let value = Number(parseFloat(number)).toLocaleString("en", {
    minimumFractionDigits: 2,
  });
  return value;
};

const ViewDialogPaymentTable = (props) => {
  const { dataTable } = props;

  return (
    <Fragment>
      {dataTable.map((row) => (
        <TableRow key={row.id}>
          <TableCell align="center">{sanitizeText(row.payment_mode)}</TableCell>
          <TableCell align="center">{row.payment_ref}</TableCell>
          <TableCell align="center">{row.payment_date}</TableCell>
          <TableCell align="center">
            {numberConverter(row.amount_paid)}
          </TableCell>
        </TableRow>
      ))}
    </Fragment>
  );
};

export default ViewDialogPaymentTable;
