import { Fragment, useRef } from "react";
import { useLocation } from 'react-router-dom'
// component
import MechanicIncentivesDashboardTable from "../components/incentives/MechanicIncentivesDashboardTable";

const MechanicIncentiveDashboard = (props) => {
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const { token } = props;
    const tableRef = useRef();
    const month = searchParams.get("month");
    const year = searchParams.get("year");
  
    return (
      <Fragment>
        <MechanicIncentivesDashboardTable token={token} tableRef={tableRef} month={month} year={year}/>
      </Fragment>
    )
}

export default MechanicIncentiveDashboard;