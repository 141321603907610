import { Fragment } from "react";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { green } from "@material-ui/core/colors";
import { Formik, Form } from "formik";
import { object, string, number } from "yup";
import TextFieldWrapper from "../formUI/TextFieldWrapper";
import DatePickerWrapper from "../formUI/DatePickerWrapper";
import SelectWrapper from "../formUI/SelectWrapper";
import AutocompleteMultipleSelectWrapper from "../formUI/AutocompleteMultipleSelectWrapper";

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: "#FAE700",
  },
  titleText: {
    color: "rgb(63,81,181) !important",
    fontWeight: "bold !important",
  },
  button: {
    backgroundColor: green[500],
    color: "#fff",
    "&:hover": {
      backgroundColor: green[600],
    },
  },
  add: {
    backgroundColor: green[500],
    color: "#fff",
    "&:hover": {
      backgroundColor: green[600],
    },
  },
}));

const VouchersUpdate = React.memo((props) => {
  const classes = useStyles();

  const { packages, data, open, onClose, onUpdate } = props;

  let couponServiceRestriction = data.coupon_service_restriction
    ? data.coupon_service_restriction.map((item) => {
        return {
          value: item.service_category_id + "-" + item.service_id,
          id: item.id,
        };
      })
    : [];

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = (values, actions) => {
    let formValues = values;
    formValues = { ...formValues, is_active: 1 };
    if (values.minimum_price_amount < 1) {
      formValues = {
        ...formValues,
        is_active: 0,
      };
    }
    if (values.discount_type === "percentage") {
      formValues = {
        ...formValues,
        discount_amount: values.discount_amount / 100,
      };
    }
    onUpdate(formValues, actions);
  };

  const initialValues = {
    id: data.id,
    name: data.name,
    description: data.description,
    minimum_price_amount: Number(data.minimum_price_amount),
    slots: data.slots,
    discount_type: data.discount_type,
    discount_amount:
      data.discount_type === "percentage"
        ? Number(data.discount_amount * 100)
        : Number(data.discount_amount),
    start_date: data.start_date,
    end_date: data.end_date,
    packages: couponServiceRestriction,
  };

  const validationSchema = object().shape({
    name: string().required("Voucher Code Name is required."),
    discount_type: string().required("Promo Type is required."),
    discount_amount: number().required("Value is required."),
    start_date: string().required("Start Date is required."),
    end_date: string().required("End Date is required."),
  });

  const discountOptions = [
    {
      value: "fixed_amount",
      label: "Fixed Amount Discount",
    },
    {
      value: "percentage",
      label: "Percentage Discount",
    },
  ];

  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
        fullWidth
        maxWidth="md"
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue, isSubmitting }) => (
            <Form autoComplete="off">
              <DialogTitle
                id="alert-dialog-title"
                className={`${classes.title}`}
              >
                <span className={classes.titleText}>UPDATE VOUCHER FORM</span>
              </DialogTitle>
              <DialogContent dividers>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <TextFieldWrapper label="Voucher Code Name" name="name" />
                  </Grid>
                  <Grid item xs={6}>
                    <TextFieldWrapper label="Description" name="description" />
                  </Grid>
                  <Grid item xs={6}>
                    <TextFieldWrapper
                      label="Minimum Price Amount"
                      name="minimum_price_amount"
                      onChange={(evt) => {
                        const { value } = evt.target;
                        const inputValue = value.replace(/[^0-9]/g, "");
                        setFieldValue(
                          `minimum_price_amount`,
                          Number(inputValue)
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextFieldWrapper
                      label="Slots"
                      name="slots"
                      onChange={(evt) => {
                        const { value } = evt.target;
                        const inputValue = value.replace(/[^0-9]/g, "");
                        setFieldValue(`slots`, Number(inputValue));
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SelectWrapper
                      label="Promo Type"
                      name="discount_type"
                      options={discountOptions}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextFieldWrapper
                      label="Value"
                      name="discount_amount"
                      onChange={(evt) => {
                        const { value } = evt.target;
                        const inputValue = value.replace(/[^0-9]/g, "");
                        setFieldValue(`discount_amount`, Number(inputValue));
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DatePickerWrapper label="Start Date" name="start_date" />
                  </Grid>
                  <Grid item xs={6}>
                    <DatePickerWrapper label="End Date" name="end_date" />
                  </Grid>
                  <Grid item xs={6}>
                    <AutocompleteMultipleSelectWrapper
                      label="Packages"
                      name="packages"
                      options={packages}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} size="small" color="default">
                  Close
                </Button>
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  color="primary"
                  variant="contained"
                  autoFocus
                  size="small"
                  endIcon={
                    isSubmitting ? <CheckCircleIcon size="0.9rem" /> : undefined
                  }
                >
                  {isSubmitting ? "Submitting" : "Submit"}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </Fragment>
  );
});
export default VouchersUpdate;
