import { Fragment } from "react";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import customClasses from "./DatePicker.module.css";
import "react-datepicker/dist/react-datepicker.css";
import {
  Grid,
  Box,
  InputLabel,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Dialog,
  FormHelperText,
} from "@material-ui/core";
import DatePicker from "react-datepicker";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { green } from "@material-ui/core/colors";
import { Formik, Form } from "formik";
import { object, date, ref } from "yup";

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: "#FAE700",
  },
  titleText: {
    color: "rgb(63,81,181) !important",
    fontWeight: "bold !important",
  },
  button: {
    backgroundColor: green[500],
    color: "#fff",
    "&:hover": {
      backgroundColor: green[600],
    },
  },
  paper: {
    width: "350px",
    maxWidth: "350px",
  },
  add: {
    backgroundColor: green[500],
    color: "#fff",
    "&:hover": {
      backgroundColor: green[600],
    },
  },
  inputLabel: {
    margin: theme.spacing("auto", "auto", 1, "auto"),
  },
  myContainer: {
    width: "100% !important",
  },
}));

const ScheduleUpdate = React.memo((props) => {
  const classes = useStyles();

  const { open, data, onClose, onUpdate } = props;

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = (values, actions) => {
    onUpdate(values, actions);
  };

  const initialValues = {
    id: data.id,
    schedule_from: new Date(),
    schedule_to: new Date(),
  };

  const validationSchema = object().shape({
    schedule_from: date()
      .typeError("Schedule from time is required.")
      .required("Schedule from time is required."),
    schedule_to: date()
      .typeError("Schedule from time is required.")
      .min(ref("schedule_from"), "End date shall not be earlier than Start date.")
      .required("Schedule to time is required."),
  });

  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
        classes={{ paper: classes.paper }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, errors, setFieldValue, setSubmitting, isSubmitting }) => (
            <Form autoComplete="off">
              <DialogTitle
                id="alert-dialog-title"
                className={`${classes.title}`}
              >
                <span className={classes.titleText}>
                  {"UPDATE SCHEDULE FORM"}
                </span>
              </DialogTitle>
              <DialogContent dividers>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <InputLabel
                      htmlFor="my-input"
                      className={classes.inputLabel}
                    >
                      Date From
                    </InputLabel>
                    <DatePicker
                      minDate={new Date()}
                      selected={values.schedule_from}
                      onChange={(date) => {
                        setFieldValue(`schedule_from`, date);
                      }}
                      showMonthDropdown
                      selectsStart
                      startDate={values.schedule_from}
                      endDate={values.schedule_to}
                      popperProps={{
                        strategy: "fixed",
                      }}
                      wrapperClassName={`${classes["myContainer"]}`}
                      popperClassName={`${customClasses["react-datepicker-popper"]}`}
                      className={`${customClasses["react-datepicker"]} ${customClasses["react-datepicker__current-month"]} ${customClasses["react-datepicker__header"]} ${customClasses["react-datepicker__navigation"]} ${customClasses["react-datepicker__day-name"]} ${customClasses["react-datepicker__day"]}`}
                    />
                    {errors.schedule_from && (
                      <FormHelperText
                        id="component-error-text"
                        style={{ color: "#f44336" }}
                      >
                        {errors.schedule_from}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel
                      htmlFor="my-input"
                      className={classes.inputLabel}
                    >
                      Date To
                    </InputLabel>
                    <DatePicker
                      selected={values.schedule_to}
                      onChange={(date) => {
                        setFieldValue(`schedule_to`, date);
                      }}
                      showMonthDropdown
                      selectsEnd
                      startDate={values.schedule_from}
                      endDate={values.schedule_to}
                      minDate={values.schedule_from}
                      popperProps={{
                        strategy: "fixed",
                      }}
                      wrapperClassName={`${classes["myContainer"]}`}
                      popperClassName={`${customClasses["react-datepicker-popper"]}`}
                      className={`${customClasses["react-datepicker"]} ${customClasses["react-datepicker__current-month"]} ${customClasses["react-datepicker__header"]} ${customClasses["react-datepicker__navigation"]} ${customClasses["react-datepicker__day-name"]} ${customClasses["react-datepicker__day"]}`}
                    />
                    {errors.schedule_to && (
                      <FormHelperText
                        id="component-error-text"
                        style={{ color: "#f44336" }}
                      >
                        {errors.schedule_to}
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>
                {/* <pre>{JSON.stringify({ values, errors }, null, 4)}</pre> */}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} size="small" color="default">
                  Close
                </Button>
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  color="primary"
                  variant="contained"
                  autoFocus
                  size="small"
                  endIcon={
                    isSubmitting ? <CheckCircleIcon size="0.9rem" /> : undefined
                  }
                >
                  {isSubmitting ? "Submitting" : "Submit"}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </Fragment>
  );
});
export default ScheduleUpdate;
