import React, {
  useEffect,
  useState,
  Fragment,
  useRef,
  useCallback,
} from "react";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import axios from "axios";
import moment from "moment";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  IconButton,
  Typography,
  Slide,
  Checkbox,
  FormControlLabel,
  Chip,
  Select,
  FormControl,
  Input,
  MenuItem,
  Toolbar,
  AppBar,
  Divider,
  Dialog,
  Button,
  Box,
  Paper,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  TextField as TextFieldMUI,
  InputLabel,
  FormHelperText,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Tooltip from "@material-ui/core/Tooltip";
import CloseIcon from "@material-ui/icons/Close";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ListAltSharpIcon from "@material-ui/icons/ListAltSharp";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DatePicker, TimePicker } from "formik-material-ui-pickers";
import { TextField } from "formik-material-ui";
import { green } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";
import { Formik, Form, Field, FastField, FieldArray } from "formik";
import { numberWithCommas } from "../../../utils/accounting";
import { PaymentType } from "../../../utils/constant";
import { object, date, number, array, string } from "yup";
import CustomProvinceComponent from "../addBooking/CustomProvinceComponent";
import CustomMunicipalityComponent from "../addBooking/CustomMunicipalityComponent";
import CustomBarangayComponent from "../addBooking/CustomBarangayComponent";
import CustomMakeComponent from "../addBooking/CustomMakeComponent";
import CustomModelComponent from "../addBooking/CustomModelComponent";
import CustomYearComponent from "../addBooking/CustomYearComponent";
import CustomTransmissionComponent from "../addBooking/CustomTransmissionComponent";
import CustomFuelComponent from "../addBooking/CustomFuelComponent";
import CustomPaymentMethodComponent from "../addBooking/CustomPaymentMethodComponent";
import CustomServiceFeeComponent from "../addBooking/CustomServiceFeeComponent";
import CustomServiceFee2Component from "../addBooking/CustomServiceFee2Component";
import CustomTotalComponentUpdate from "../addBooking/CustomTotalComponentUpdate";
import CustomOtherServicesTotalPrice from "../addBooking/CustomOtherServicesTotalPrice";
import CustomDiscountComponent from "../addBooking/CustomDiscountComponent";
import CustomServiceLocationComponent from "../addBooking/CustomServiceLocationComponent";
import ParkingSpaceComponent from "../addBooking/ParkingSpaceComponent";
import CustomRescheduleReasonComponent from "../addBooking/CustomRescheduleReasonComponent";
import CustomPartsAutocompleteComponent from "../addBooking/CustomPartsAutocompleteComponent";
import CustomPartsTotalPrice from "../addBooking/CustomPartsTotalPrice";
import CustomTiresAutocompleteComponent from "../addBooking/CustomTiresAutocompleteComponent";
import SelectPropertyType from "../../common/SelectPropertyType";
import AlertDialog from "../../alertDialog/AlertDialog";
import CustomTransactionTypeComponent from "../addBooking/CustomTransactionTypeComponent";
import SelectPaymentType from "../../common/SelectPaymentType";
import InputField from "../../common/InputField";
import SelectOdometer from "../../common/SelectOdometer";

// Api config
import config from "../../../utils/config";
import CustomTiresTotalPrice from "../addBooking/CustomTiresTotalPrice";
import SelectCustomerType from "../../common/SelectCustomerType";

const muiTheme = createMuiTheme({
  textAlign: "center !important",
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, adsSource, theme) {
  return {
    fontWeight:
      adsSource.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  inputMultiple: {
    minWidth: "250px",
    height: "45px",
    border: "1.5px solid #ddd",
    borderRadius: "5px",
  },
  paper: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(4),
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  button: {
    color: theme.palette.grey[900],
  },
  house: {
    marginTop: "8px",
  },
  icon: {
    position: "absolute",
    top: "18px",
    marginLeft: "3px",
  },
  titleText2: {
    fontWeight: "bold",
    marginBottom: "20px",
  },
  listItem: {
    listStyleType: "none",
    margin: "0px !important",
  },
  liItem: {
    marginBottom: "3px",
  },
  label: {
    fontWeight: "bold",
    fontSize: "14px !important",
    minWidth: "160px",
    lineHeight: "2.5",
    letterSpacing: "1.2px",
  },
  data: {
    fontSize: "14px !important",
    fontWeight: "normal",
  },
  label2: {
    fontWeight: "bold",
    fontSize: "14px !important",
    minWidth: "160px",
    lineHeight: "5.0",
    letterSpacing: "1.2px",
  },
  appBar: {
    position: "relative",
    backgroundColor: "#FAE700",
    color: "#000",
  },
  gridCustom: {
    textAlign: "center !important",
  },
  gridCustom2: {
    maxWidth: "7.0% !important",
  },
  gridCustom3: {
    maxWidth: "52.5% !important",
  },
  addressDisabled: {
    minWidth: "499px !important",
  },
  remarks: {
    minWidth: "715px !important",
  },
  asterisk: {
    color: "red !important",
    fontSize: "1.3rem",
    lineHeight: "0 !important",
  },
  asterisk2: {
    position: "relative",
    bottom: "5px",
    left: "2px",
    color: "red !important",
    fontSize: "0.8rem !important",
  },
  disabledRoot: {
    "& .MuiOutlinedInput-input": {
      color: "#000",
    },
  },
  largeIcon: {
    width: "30px !important",
    height: "30px !important",
  },
  add: {
    backgroundColor: green[500],
    color: "#fff",
    "&:hover": {
      backgroundColor: green[600],
    },
  },
  inputCustomField: {
    width: "500px !important",
  },
}));

const useStyles2 = makeStyles((theme) => ({
  input: {
    textAlign: "center !important",
  },
  root: {
    overflowX: "unset",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
      timeout={{ appear: 100, enter: 500, exit: 500 }}
    />
  );
});

const emptyPackage = {
  id: "",
  service_name: "",
  quantity: 1,
  price: 0.0,
  total: 0.0,
  type: "",
};
const emptyServices = {
  id: "",
  service_name: "",
  remarks: "",
  quantity: 1,
  price: 0.0,
  totalPrice: 0,
  type: "manual",
};

const emptyParts = {
  part_id: "",
  part_number: "",
  name: "",
  remarks: "",
  brand_id: null,
  category_id: null,
  quantity: 1,
  price: 0.0,
  totalPrice: 0,
};

export const emptyTires = {
  sku: "",
  quantity: 1,
  price: 0.0,
  totalPrice: 0,
};

const numberConverter = (number) => {
  let value = Number(parseFloat(number)).toLocaleString("en", {
    minimumFractionDigits: 2,
  });
  return value;
};

const UpdateDialog = (props) => {
  const { open, isLoading, handleUpdateClose, data, token } = props;
  const [adsSource, setAdsSource] = useState([]);
  const [adsSourceData, setAdsSourceData] = useState([]);
  const [is_dispatched, setIsDispatched] = useState(false);
  const [billToCompany, setBillToCompany] = React.useState(false);

  const [initialValues, setInitialValues] = useState({
    id: "",
    ads_source: [],
    address: "",
    name: "",
    lastname: "",
    email: "",
    contact_number: "",
    province: "",
    municipality: "",
    barangay: "",
    zip_code: "",
    landmarks: "",
    customer_type: "",
    service_fee: 0.0,
    coverAddress: "cover",
    distance_in_km: "",
    discounts: 0,
    subTotal: "",
    total: "",
    post_dispatch_discount: 0,
    post_dispatch_sub_total: "",
    post_dispatch_total: "",
    make: "",
    model: "",
    year: "",
    parking_space: "",
    reschedule_reason: "",
    plate_number: "",
    transmission: "",
    mileage: "",
    fuel_type: "",
    liter_of_oil: "",
    engine_size: "",
    servicesList: [],
    services: [],
    tires: [],
    package_add_ons: [],
    manualServicesList: [],
    manualServices: [],
    ap_date: "",
    ap_time: "",
    remarks: "",
    models: [],
    payment_method: "",
    provinces: [],
    municipalities: [],
    barangays: [],
    payment_reference_no: "",
    payment_amount: 0.0,
    payment_type: "",
    billing_company_name: "",
    billing_company_name: "",
    billing_company_tin: "",
  });

  // const { values: setFieldValue } = useFormikContext();

  // ==============MODAL STATES==============
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    typeAlert: "success",
  });

  const formRef = useRef();
  const classes = useStyles();
  const classes2 = useStyles2();
  const theme = useTheme();
  const userRole = localStorage.getItem("userRole");

  // Alert Dialog
  const handleCloseAlert = useCallback(
    (event, reason) => {
      if (reason === "clickaway") {
        return;
      }

      setAlert({
        ...alert,
        open: false,
        message: "",
      });
    },
    [alert]
  );

  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${props.token}`,
    },
  };

  const fetchAdsSources = async () => {
    const {
      data: { data: adsSources },
    } = await axios.get(`${config.api}/ads-sources?limit=10000`, options);
    setAdsSourceData(adsSources);
  };

  const fetchDataModel = (brand) => {
    return new Promise((resolve, reject) => {
      let request;
      request = axios.get(
        config.api + "/vehicles/search?make=" + brand,
        options
      );
      resolve(request);
    });
  };

  const fetchDataLiter = (brandModel, fuelType) => {
    return new Promise((resolve, reject) => {
      let request;
      request = axios.get(
        `${config.api}/vehicles/search?model=${brandModel}&fuel_type=${fuelType}`,
        options
      );
      resolve(request);
    });
  };

  const fetchDataServices = async (
    make,
    model,
    liter_of_oil = 0,
    fuel_type,
    odometer = ""
  ) => {
    let url = `${config.api}/services?make=${make}&model=${model}&liter_of_oil=${liter_of_oil}&fuel_type=${fuel_type}&is_public=all`;
    if (odometer) {
      url = `${url}&odometer_id=${odometer}`;
    }
    return axios.get(`${url}`, options);
  };

  const handleSubmitCustom = (values) => {
    const adsSourceJoined = adsSource.join(",");
    setAdsSource([]);
    let mileage = "";
    if (values.mileage) {
      const stringWithoutCommas = values.mileage.replace(/,/g, "");
      // Convert the string to an integer
      mileage = parseInt(stringWithoutCommas, 10);
    }
    props.handleUpdate({
      ...values,
      ads_source: adsSourceJoined,
      mileage,
      billToCompany,
    });
    return true;
  };

  const validationSchema = object().shape({
    landmarks: string().nullable(),
    make: string().required("Make is required."),
    model: string().required("Model is required."),
    year: string().nullable(),
    plate_number: string().nullable(),
    transmission: string().nullable(),
    mileage: string().typeError("Mileage must be valid.").nullable(),
    fuel_type: string().required("Fuel type is required."),
    liter_of_oil: string().required("Liter of oil is required."),
    engine_size: number().typeError("Engine size must be valid.").nullable(),
    odometer: string().required("Odometer is required."),
    province: string().required("Province is required."),
    municipality: string().required("Municipality/City is required."),
    barangay: string().required("Barangay is required."),
    services: array(
      object({
        id: number().nullable(true),
        service_name: string().required("Please fill up this field."),
        quantity: number()
          .required("Quantity is required.")
          .min(1, "Please input at least 1 item."),
        price: string().nullable(true),
        total: string().nullable(true),
        type: string().nullable(true),
      })
    ).nullable(),
    manualServices: array(
      object({
        id: number().nullable(true),
        service_name: string().required("Please fill up this field."),
        remarks: string().nullable(true),
        quantity: number()
          .required("Quantity is required.")
          .min(1, "Please input at least 1 item."),
        price: number()
          .nullable(true)
          .transform((value, originalValue) => {
            // Handle the case when the value is "-"
            const parsedValue = Number(originalValue);
            return isNaN(parsedValue) ? undefined : parsedValue;
          })
          .when("$price", (price, schema) => {
            return price
              ? schema.positive("Price must be a positive number")
              : schema;
          }),
        totalPrice: number().required(""),
        type: string().required(),
      })
    ).nullable(),
    ap_date: date().required("Appointment date is required"),
    ap_time: date().required("Appointment time is required"),
    remarks: string().nullable(),
    ads_source: array()
      .of(string())
      .min(1, "Select at least 1 of ads sources")
      .required("Ads source is required"),
  });

  useEffect(() => {
    // this is the ser
    const packages =
      data?.appointment_services
        ?.filter(({ type }) => type === "manual")
        ?.map(
          ({
            service_id,
            service_name,
            remarks,
            quantity,
            price,
            total,
            is_added_post_dispatch,
          }) => ({
            id: service_id,
            service_name,
            remarks,
            quantity,
            price,
            totalPrice: total,
            type: "manual",
            is_added_post_dispatch,
          })
        ) || [];

    // this is the services
    const services =
      data?.appointment_services
        ?.filter(({ type }) => !type)
        ?.map(
          ({
            service_id,
            package_id,
            service_name,
            quantity,
            price,
            is_added_post_dispatch,
          }) => ({
            id: service_id ?? package_id,
            service_name,
            quantity,
            price,
            totalPrice: price,
            type: "",
            is_added_post_dispatch,
          })
        ) || [];

    const findIfDispatched = data?.appointment_history?.find(
      (ah) => ah.appointment_status === "Dispatched"
    );

    setIsDispatched(!!findIfDispatched);

    if (Object.keys(data).length > 0) {
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      let manualListInitial = [];
      let servicesList = [];
      let models = [];

      async function fetchData() {
        const request3 = await axios.get(
          `${config.api}/vehicles/search?make=${data.make}`,
          options
        );
        let items3 = request3.data.data;
        items3.forEach((item3) => {
          let data1 = {
            name: item3.name,
          };
          models.push(data1);
        });

        // ========Services========
        const request = await axios.get(
          `${config.api}/services/all?type=manual&limit=10000`,
          options
        );
        let items = request.data.data.data;

        items.forEach((item) => {
          let data = {
            id: item.id,
            service_name: item.name,
            quantity: 0,
            price: 0,
            totalPrice: 0,
            type: "manual",
          };
          manualListInitial.push(data);
        });
        const request2 = await axios.get(
          `${config.api}/services?make=${data.make}&model=${data.model}&liter_of_oil=${data.liter_of_oil}&fuel_type=${data.fuel_type}&is_public=all`,
          options
        );
        let items2 = Array.isArray(request2.data.data)
          ? request2.data.data
          : [];

        items2.forEach((item2) => {
          if (item2.packages && Array.isArray(item2.packages)) {
            item2.packages.forEach((service) => {
              if (service && service.type !== "manual" && service?.variants) {
                servicesList.push({
                  id: service.id,
                  service_name: service.name,
                  quantity: 0,
                  price: service.variants.price,
                  total: service.variants.price,
                  type: "",
                });
              }
            });
          }
        });
      }
      fetchData();

      const latestPayment = data?.payment || [];

      setBillToCompany(
        Boolean(
          data.billing_company_name ||
            data.billing_company_address ||
            data.billing_company_tin
        )
      );

      setInitialValues({
        id: data.id,
        ads_source: data.ads_source ? data.ads_source.split(",") : [],
        name: data.name || "",
        lastname: data.lastname || "",
        email: data.email || "",
        contact_number: data.phone || "",
        address: data.address || "",
        province: data.province,
        service_location: data.service_location || "",
        municipality: data.municipality,
        barangay: data.barangay,
        zip_code: data.zip_code,
        landmarks: data.landmarks || "",
        customer_type: data.customer_type || "",
        service_fee: data.service_fee || 0.0,
        coverAddress: "cover",
        distance_in_km: data.distance_in_km || "",
        subTotal: data.sub_total || "",
        total: data.total || "",
        make: data.make,
        model: data.model,
        year: data.year || "",
        plate_number: data.plate_number || "",
        transmission: data.transmission || "",
        mileage: data.mileage || "",
        fuel_type: data.fuel_type || "",
        liter_of_oil: data.liter_of_oil || "",
        engine_size: data.engine_size || "",
        servicesList: servicesList,
        services: services,
        parts: data.service_parts || [],
        tires: data.service_tires || [],
        package_add_ons: data.appointment_package_add_ons,
        manualServicesList: manualListInitial,
        manualServices: packages,
        discounts: data.discount,
        ap_date: data.appointment_date,
        ap_time: data.appointment_date,
        remarks: data.remarks,
        parking_space: data.parking_space,
        property_type: data.property_type,
        reschedule_reason: data.reschedule_reason,
        odometer: data.odometer_id,
        models,
        payment_method: data.payment_method,
        transaction_type: data?.transaction_type,
        payment_reference_no:
          data?.payment?.[latestPayment.length - 1]?.payment_ref || "",
        payment_amount:
          data?.payment?.[latestPayment.length - 1]?.amount_paid || 0.0,
        payment_type:
          data?.payment?.[latestPayment.length - 1]?.payment_type || "",
        billing_company_name: data.billing_company_name,
        billing_company_address: data.billing_company_address,
        billing_company_tin: data.billing_company_tin,
      });
    }
    if (data.ads_source) {
      setAdsSource(data.ads_source.split(","));
    }
    fetchAdsSources();
  }, [data, open, handleUpdateClose, token]);

  const ServiceItems = ({ services }) => {
    if (services.length === 0) return <></>;
    return (
      <Fragment>
        {services
          .filter((p) => !p.is_added_post_dispatch)
          .map((p) => (
            <Grid container item key={p.id} style={{ marginLeft: "1.5rem" }}>
              <Grid item xs={6}>
                {p?.service_name || p?.sku} ({p.quantity}x)
              </Grid>
              <Grid
                item
                xs={6}
                style={{ textAlign: "right", borderBottom: "1px solid #000" }}
              >
                ₱{numberWithCommas(p?.price ?? p?.variants?.price)}
              </Grid>
            </Grid>
          ))}
      </Fragment>
    );
  };

  const renderPaymentType = (values) => {
    const paymentTypeValue = PaymentType[values.payment_type] || null;
    return paymentTypeValue ? (
      <Grid container item xs={12}>
        <Grid item xs={6}>
          <Typography
            style={{
              fontWeight: "bold",
              color: "red",
            }}
          >
            {paymentTypeValue}:
          </Typography>
        </Grid>
        <Grid
          item
          xs={6}
          style={{
            textAlign: "right",
            borderBottom: "1px solid #000",
          }}
        >
          {numberWithCommas(values.payment_amount)}
        </Grid>
      </Grid>
    ) : null;
  };

  const fetchNearestHub = async (province, municipality, setFieldValue) => {
    const { data: nearestHub } = await axios.get(`
      ${config.api}/nearest-hub?province=${province}&municipality=${municipality}
    `);
    if (nearestHub) setFieldValue("service_location", nearestHub);
  };

  return (
    <Fragment>
      <div>
        <AlertDialog
          open={alert.open}
          typeAlert={alert.typeAlert}
          message={alert.message}
          handleCloseAlert={handleCloseAlert}
        />
        <Dialog
          fullScreen
          open={open}
          onClose={() => {
            handleUpdateClose();
            setAdsSource([]);
          }}
          TransitionComponent={Transition}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            innerRef={formRef}
            onSubmit={handleSubmitCustom}
          >
            {({
              values,
              errors,
              touched,
              isSubmitting,
              setFieldValue,
              handleChange,
              setTouched,
            }) => (
              <Form autoComplete="off">
                <AppBar className={classes.appBar}>
                  <Toolbar>
                    <IconButton
                      edge="start"
                      color="inherit"
                      onClick={handleUpdateClose}
                      aria-label="close"
                    >
                      <CloseIcon className={classes.button} />
                    </IconButton>
                    <Typography variant="h5" className={classes.title}>
                      Update Booking
                      <ListAltSharpIcon className={classes.icon} />
                    </Typography>
                    <Button
                      disabled={isLoading}
                      type="submit"
                      startIcon={
                        isLoading ? (
                          <CircularProgress size="0.9rem" />
                        ) : undefined
                      }
                    >
                      {isLoading ? "Saving" : "Save"}
                    </Button>
                  </Toolbar>
                </AppBar>
                <MuiDialogContent dividers>
                  <div className={classes.root}>
                    <Paper className={classes.paper}>
                      <Grid container spacing={3}>
                        <Grid item xs={3}>
                          <TextFieldMUI
                            label="Booking No."
                            fullWidth
                            defaultValue={data ? data.reference_no : ""}
                            variant="outlined"
                            size="small"
                            disabled
                            inputProps={{
                              readOnly: "readonly",
                              style: {
                                textAlign: "center",
                                textTransform: "uppercase",
                              },
                            }}
                            InputProps={{
                              classes: {
                                root: classes.disabledRoot,
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextFieldMUI
                            fullWidth
                            size="small"
                            label="Booking Date"
                            defaultValue={
                              data ? moment(data.created_at).format("LLL") : ""
                            }
                            variant="outlined"
                            disabled
                            inputProps={{
                              readOnly: "readonly",
                              style: {
                                textAlign: "center",
                              },
                            }}
                            InputProps={{
                              classes: {
                                root: classes.disabledRoot,
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <TextFieldMUI
                            fullWidth
                            label="BS Number"
                            size="small"
                            defaultValue={
                              data && data.bs_number ? data.bs_number : "N/A"
                            }
                            variant="outlined"
                            disabled
                            inputProps={{
                              readOnly: "readonly",
                              style: {
                                textAlign: "center",
                              },
                            }}
                            InputProps={{
                              classes: {
                                root: classes.disabledRoot,
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <FastField
                            label="Transaction Type"
                            component={CustomTransactionTypeComponent}
                            name="transaction_type"
                            type="text"
                            variant="outlined"
                            size="small"
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <FastField
                            fullWidth
                            label="Service Location"
                            component={CustomServiceLocationComponent}
                            name="service_location"
                            type="text"
                            variant="outlined"
                            size="small"
                          />
                        </Grid>
                      </Grid>
                      <Box my={2}>
                        <Divider />
                      </Box>
                      <Typography variant="h6" className={classes.titleText2}>
                        CLIENT INFO
                      </Typography>
                      <Grid container spacing={3}>
                        <Grid item xs={3} className={classes.gridCustom}>
                          <FastField
                            label="First Name"
                            name="name"
                            fullWidth
                            type="text"
                            variant="outlined"
                            component={TextField}
                            size="small"
                            disabled={userRole !== "super_admin"}
                            inputProps={{
                              style: {
                                textAlign: "center",
                                textTransform: "capitalize",
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={3} className={classes.gridCustom}>
                          <FastField
                            fullWidth
                            label="Last Name"
                            name="lastname"
                            type="text"
                            variant="outlined"
                            component={TextField}
                            size="small"
                            disabled={userRole !== "super_admin"}
                            inputProps={{
                              style: {
                                textAlign: "center",
                                textTransform: "capitalize",
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={3} className={classes.gridCustom}>
                          <FastField
                            fullWidth
                            label="Email"
                            name="email"
                            variant="outlined"
                            component={TextField}
                            size="small"
                            disabled={userRole !== "super_admin"}
                            InputProps={{
                              classes: {
                                root: classes.disabledRoot,
                              },
                            }}
                            inputProps={{ style: { textAlign: "center" } }}
                          />
                        </Grid>
                        <Grid item xs={3} className={classes.gridCustom}>
                          <FastField
                            fullWidth
                            label="Contact Number"
                            name="contact_number"
                            variant="outlined"
                            component={TextField}
                            size="small"
                            disabled={userRole !== "super_admin"}
                            inputProps={{ style: { textAlign: "center" } }}
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={3}>
                        {/* {data?.province &&
                        data?.municipality &&
                        data?.barangay ? ( */}
                        <>
                          <Grid item xs={3} className={classes.house}>
                            <FastField
                              fullWidth
                              name="address"
                              component={TextField}
                              label="House #, Street"
                              size="small"
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item xs={3} className={classes.house}>
                            <FastField
                              fullWidth
                              label="Province"
                              component={CustomProvinceComponent}
                              name="province"
                              type="text"
                              variant="outlined"
                              size="small"
                              onChange={async (e) => {
                                setFieldValue("province", e.target.value);
                                setFieldValue("municipality", "");
                                setFieldValue("barangay", "");
                                setInitialValues({
                                  ...initialValues,
                                  province: e.target.value,
                                  municipality: "",
                                  barangay: "",
                                });
                              }}
                            />
                          </Grid>
                          <Grid item xs={3} className={classes.house}>
                            <Field
                              fullWidth
                              label="Municipality/City"
                              component={CustomMunicipalityComponent}
                              name="municipality"
                              type="text"
                              variant="outlined"
                              size="small"
                              onChange={async (e) => {
                                setFieldValue("municipality", e.target.value);
                                setFieldValue("barangay", "");

                                fetchNearestHub(
                                  values.province,
                                  e.target.value,
                                  setFieldValue
                                );
                              }}
                              // inputProps={{ style: { textAlign: 'center' } }}
                            />
                          </Grid>
                          <Grid item xs={3} className={classes.house}>
                            <Field
                              fullWidth
                              label="Barangay/Zip Code"
                              component={CustomBarangayComponent}
                              name="barangay"
                              type="text"
                              variant="outlined"
                              size="small"
                              onChange={async (e) => {
                                setFieldValue("barangay", e.target.value);
                                const {
                                  data: { fee_amount },
                                } = await axios.get(
                                  `${config.api}/home-service-fee/${values.province}/${values.municipality}/${e.target.value}`
                                );
                                const { data: address } = await axios.get(
                                  `${config.api}/addressByName?province=${values.province}&municipality=${values.municipality}&barangay=${e.target.value}`
                                );
                                // setFieldValue("service_fee", fee_amount);
                                setFieldValue(
                                  "zip_code",
                                  address?.municipality?.[0]?.barangay?.[0]
                                    ?.zip_code?.[0]?.zip_code
                                );
                              }}
                            />
                          </Grid>
                        </>
                        {/* ) : ( */}
                        {!data?.landmarks && (
                          <Grid item xs={12} className={classes.house}>
                            <FastField
                              fullWidth
                              name="address"
                              component={TextField}
                              label="Address"
                              size="small"
                              variant="outlined"
                              classes={{
                                root: classes.inputCustomField,
                              }}
                            />
                          </Grid>
                        )}
                        {/* )} */}
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={8} className={classes.house}>
                          <FastField
                            fullWidth
                            name="landmarks"
                            component={TextField}
                            label="Exact Address"
                            size="small"
                            variant="outlined"
                            // classes={{
                            //   root: classes.inputCustomField,
                            // }}
                          />
                        </Grid>
                        <Grid item xs={4} className={classes.house}>
                          <FastField
                            fullWidth
                            name="customer_type"
                            component={SelectCustomerType}
                            label="Customer Type"
                            size="small"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={billToCompany}
                                onChange={() =>
                                  setBillToCompany(!billToCompany)
                                }
                                name="bill_to_company"
                                color="primary"
                              />
                            }
                            label="Bill to Company"
                          />
                        </Grid>
                        {billToCompany && (
                          <>
                            <Grid item xs={4}>
                              <FastField
                                fullWidth
                                name="billing_company_name"
                                component={TextField}
                                label="Company name"
                                size="small"
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <FastField
                                fullWidth
                                name="billing_company_address"
                                component={TextField}
                                label="Company address"
                                size="small"
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <FastField
                                fullWidth
                                name="billing_company_tin"
                                component={TextField}
                                label="Company TIN"
                                size="small"
                                variant="outlined"
                              />
                            </Grid>
                          </>
                        )}
                      </Grid>
                      <Box my={2}>
                        <Divider />
                      </Box>
                      <Typography variant="h6" className={classes.titleText2}>
                        VEHICLE INFO
                      </Typography>
                      <Grid container direction="row" spacing={3}>
                        <Grid item xs={3} className={classes.gridCustom}>
                          <FastField
                            name="make"
                            label="Make"
                            fullWidth
                            variant="outlined"
                            size="small"
                            component={CustomMakeComponent}
                            onChange={async (e) => {
                              const { value } = e.target;
                              setFieldValue("make", value);
                              setFieldValue("model", "");
                              setFieldValue("liter_of_oil", "");
                              setFieldValue("servicesList", []);
                              setFieldValue("services", []);
                              const _models = await fetchDataModel(value);
                              setFieldValue("models", _models.data.data);
                            }}
                          />
                        </Grid>
                        <Grid item xs={3} className={classes.gridCustom}>
                          <Field
                            name="model"
                            label="Model"
                            fullWidth
                            variant="outlined"
                            size="small"
                            component={CustomModelComponent}
                            onChange={async (e) => {
                              const { value } = e.target;
                              setFieldValue("model", value);
                              setFieldValue("liter_of_oil", "");
                              setFieldValue("servicesList", []);
                              setFieldValue("services", []);

                              let brandModel = `${values.make} ${value}`;

                              const _literVal = await fetchDataLiter(
                                brandModel,
                                values.fuel_type
                              );
                              let liter_of_oil = _literVal.data.data;
                              let optionsItems = [];

                              if (liter_of_oil) {
                                setFieldValue(
                                  "liter_of_oil",
                                  liter_of_oil.liter_of_oil
                                );

                                const _services = await fetchDataServices(
                                  values.make,
                                  values.model,
                                  liter_of_oil.liter_of_oil,
                                  value,
                                  values.odometer
                                );
                                let items = _services.data.data;
                                if (Array.isArray(items) && items.length > 0) {
                                  items.forEach((item) => {
                                    if (
                                      item.packages &&
                                      Array.isArray(item.packages)
                                    ) {
                                      item.packages.forEach((service) => {
                                        if (service?.variants) {
                                          optionsItems.push(service);
                                        }
                                      });
                                    }
                                  });
                                }
                              } else {
                                setFieldValue("liter_of_oil", "");
                              }
                              let optionsItemsFinal = optionsItems
                                .filter((service) => service.type !== "manual")
                                .map((item) => {
                                  return {
                                    id: item.id,
                                    service_name: item.name,
                                    quantity: 0,
                                    price: item.variants.price,
                                    total: item.variants.price,
                                    type: "",
                                  };
                                });
                              setFieldValue("servicesList", optionsItemsFinal);
                            }}
                          />
                        </Grid>
                        <Grid item xs={3} className={classes.gridCustom}>
                          <FastField
                            name="year"
                            label="Year"
                            variant="outlined"
                            size="small"
                            component={CustomYearComponent}
                          />
                        </Grid>
                        <Grid item xs={3} className={classes.gridCustom}>
                          <FastField
                            fullWidth
                            name="plate_number"
                            label="Plate Number"
                            variant="outlined"
                            size="small"
                            component={TextField}
                            inputProps={{
                              style: {
                                textAlign: "center",
                                textTransform: "uppercase",
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={3} className={classes.gridCustom}>
                          <FastField
                            name="transmission"
                            label="Transmission"
                            variant="outlined"
                            size="small"
                            component={CustomTransmissionComponent}
                            inputProps={{
                              style: {
                                textAlign: "center",
                                textTransform: "capitalize",
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={3} className={classes.gridCustom}>
                          <FastField
                            fullWidth
                            name="mileage"
                            label="Mileage"
                            variant="outlined"
                            size="small"
                            component={TextField}
                            onChange={(evt) => {
                              const { value } = evt.target;
                              const inputValue = value.replace(/[^0-9]/g, "");
                              const formattedValue =
                                Number(inputValue).toLocaleString();
                              setFieldValue(`mileage`, formattedValue);
                            }}
                            inputProps={{
                              style: {
                                textAlign: "center",
                                textTransform: "capitalize",
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={3} className={classes.gridCustom}>
                          <Field
                            name="fuel_type"
                            label="Fuel Type"
                            variant="outlined"
                            size="small"
                            component={CustomFuelComponent}
                            fullWidth
                            onChange={async (e) => {
                              const { value } = e.target;
                              setFieldValue("fuel_type", value);
                              setFieldValue("liter_of_oil", "");
                              setFieldValue("servicesList", []);
                              setFieldValue("services", []);

                              let brandModel = `${values.make} ${values.model}`;
                              const _literVal = await fetchDataLiter(
                                brandModel,
                                values.make,
                                values.model,
                                value
                              );
                              let liter_of_oil = _literVal.data.data;
                              let optionsItems = [];

                              if (liter_of_oil) {
                                setFieldValue(
                                  "liter_of_oil",
                                  liter_of_oil.liter_of_oil
                                );
                                const _services = await fetchDataServices(
                                  values.make,
                                  values.model,
                                  liter_of_oil.liter_of_oil,
                                  value
                                );
                                let items = _services.data.data;
                                if (Array.isArray(items) && items.length > 0) {
                                  items.forEach((item) => {
                                    if (
                                      item.packages &&
                                      Array.isArray(item.packages)
                                    ) {
                                      item.packages.forEach((service) => {
                                        if (service?.variants) {
                                          optionsItems.push(service);
                                        }
                                      });
                                    }
                                  });
                                }
                              } else {
                                setFieldValue("liter_of_oil", "");
                              }

                              let optionsItemsFinal = optionsItems
                                .filter((service) => service.type !== "manual")
                                .map((item) => {
                                  return {
                                    id: item.id,
                                    service_name: item.name,
                                    quantity: 0,
                                    price: item.variants.price,
                                    total: item.variants.price,
                                    type: "",
                                  };
                                });
                              setFieldValue("servicesList", optionsItemsFinal);
                            }}
                          />
                        </Grid>
                        <Grid item xs={3} className={classes.gridCustom}>
                          <FastField
                            fullWidth
                            name="liter_of_oil"
                            label="Liter of Oil"
                            variant="outlined"
                            size="small"
                            component={TextField}
                            inputProps={{
                              readOnly: "readOnly",
                              style: { textAlign: "center" },
                            }}
                          />
                        </Grid>
                        <Grid item xs={3} className={classes.gridCustom}>
                          <FastField
                            fullWidth
                            name="engine_size"
                            label="Engine Size"
                            variant="outlined"
                            size="small"
                            onChange={(evt) => {
                              const { value } = evt.target;
                              const inputValue = value.replace(/[^0-9.]/g, "");
                              let splitValue = inputValue
                                .split(".")
                                .slice(0, 4);
                              let finalValue = "";

                              if (splitValue.length === 1) {
                                const joined = splitValue.join("");
                                const lengthOfString = joined.length;
                                finalValue = joined;
                                if (joined > 20) {
                                  finalValue = `${joined}.0`;
                                }
                                if (lengthOfString === 3) {
                                  const firstTwoCharacters = joined.substring(
                                    0,
                                    2
                                  );
                                  const thirdCharacter = joined.charAt(2);
                                  finalValue = `${firstTwoCharacters}.${thirdCharacter}`;
                                }
                                if (lengthOfString === 2) {
                                  const firstCharacter = joined.substring(0, 1);
                                  const secondCharacter = joined.charAt(1);
                                  finalValue = `${firstCharacter}.${secondCharacter}`;
                                }
                              } else if (splitValue.length === 2) {
                                const joined = splitValue.join("");
                                const lengthOfString = joined.length;
                                finalValue = joined;
                                if (lengthOfString === 3) {
                                  const firstTwoCharacters = joined.substring(
                                    0,
                                    2
                                  );
                                  const thirdCharacter = joined.charAt(2);
                                  if (firstTwoCharacters > 20) {
                                    const firstCharacter = joined.substring(
                                      0,
                                      1
                                    );
                                    const secondCharacter = joined.charAt(1);
                                    finalValue = `${firstCharacter}.${secondCharacter}`;
                                  } else {
                                    finalValue = `${firstTwoCharacters}.${thirdCharacter}`;
                                  }
                                } else if (lengthOfString === 4) {
                                  const firstTwoCharacters = joined.substring(
                                    0,
                                    2
                                  );
                                  const thirdAndFourthCharacters =
                                    joined.charAt(2);
                                  finalValue = `${firstTwoCharacters}.${thirdAndFourthCharacters}`;
                                }
                              }
                              setFieldValue("engine_size", finalValue);
                            }}
                            component={TextField}
                            inputProps={{
                              style: {
                                textAlign: "center",
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={3} className={classes.gridCustom}>
                          <FastField
                            fullWidth
                            name="property_type"
                            label="Property Type"
                            variant="outlined"
                            size="small"
                            component={SelectPropertyType}
                            inputProps={{
                              style: {
                                textAlign: "center",
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={3} className={classes.gridCustom}>
                          <FastField
                            fullWidth
                            name="parking_space"
                            label="Parking Space"
                            variant="outlined"
                            size="small"
                            component={ParkingSpaceComponent}
                            inputProps={{
                              style: {
                                textAlign: "center",
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={3} className={classes.gridCustom}>
                          <Field
                            fullWidth
                            name="odometer"
                            label="Odometer"
                            variant="outlined"
                            size="small"
                            disabled={!values.make || !values.model}
                            component={SelectOdometer}
                            onChange={async (evt) => {
                              const { value } = evt.target;
                              setFieldValue("odometer", value);
                              //setFieldValue("servicesList", []);
                              //setFieldValue("services", []);
                              let optionsItems = [];
                              const _services = await fetchDataServices(
                                values.make,
                                values.model,
                                values.liter_of_oil,
                                values.fuel_type,
                                value
                              );

                              let items = _services.data.data;
                              if (Array.isArray(items) && items.length > 0) {
                                items.forEach((item) => {
                                  if (
                                    item.packages &&
                                    Array.isArray(item.packages)
                                  ) {
                                    item.packages.forEach((service) => {
                                      if (service?.variants) {
                                        optionsItems.push(service);
                                      }
                                    });
                                  }
                                });
                              }

                              setFieldValue("servicesList", optionsItems);
                            }}
                            inputProps={{
                              style: {
                                textAlign: "center",
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Box my={4}>
                        <Divider />
                      </Box>
                      <FieldArray name="services">
                        {({ push, remove }) => (
                          <React.Fragment>
                            <Box display="flex">
                              <Box p={1} width="100%">
                                <Typography
                                  variant="h6"
                                  className={classes.titleText2}
                                >
                                  PACKAGES
                                </Typography>
                              </Box>
                              <Box p={1} flexShrink={0}>
                                <Button
                                  variant="contained"
                                  className={classes.add}
                                  startIcon={<AddCircleIcon />}
                                  onClick={() => {
                                    push(emptyPackage);
                                    setTouched({
                                      make: true,
                                      model: true,
                                      odometer: true,
                                    });
                                  }}
                                >
                                  Add Package
                                </Button>
                              </Box>
                            </Box>
                            <Grid
                              container
                              direction="row"
                              justify="space-evenly"
                              alignItems="center"
                              spacing={3}
                            >
                              <Grid item xs={10} className={classes.gridCustom}>
                                <TableContainer component={Paper}>
                                  <Table size="small" aria-label="simple table">
                                    <TableHead>
                                      <TableRow>
                                        <TableCell
                                          style={{
                                            fontWeight: "bold",
                                            width: "5%",
                                          }}
                                          align="center"
                                        >
                                          ACTIONS
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            fontWeight: "bold",
                                            width: "75%",
                                          }}
                                          align="center"
                                        >
                                          SERVICE ITEMS
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            fontWeight: "bold",
                                            width: "10%",
                                          }}
                                        >
                                          QUANTITY
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            fontWeight: "bold",
                                            width: "10%",
                                          }}
                                        >
                                          TOTAL PRICE
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {values.services.map((value, index) => (
                                        <TableRow key={index}>
                                          <TableCell
                                            align="center"
                                            scope="row"
                                            style={{ width: "5%" }}
                                          >
                                            <Tooltip title="Remove">
                                              <IconButton
                                                color="secondary"
                                                aria-label="remove"
                                                onClick={() => {
                                                  remove(index);
                                                  setFieldValue(
                                                    "package_add_ons",
                                                    []
                                                  );
                                                }}
                                              >
                                                <HighlightOffIcon
                                                  className={classes.largeIcon}
                                                />
                                              </IconButton>
                                            </Tooltip>
                                          </TableCell>
                                          <TableCell
                                            scope="row"
                                            style={{ width: "75%" }}
                                          >
                                            <Autocomplete
                                              getOptionLabel={(option) =>
                                                option.service_name
                                              }
                                              disableClearable
                                              size="small"
                                              classes={classes2}
                                              value={values.services[index]}
                                              options={[
                                                ...values.services,
                                                ...values.servicesList,
                                              ].filter(
                                                (v, i, a) =>
                                                  a.findIndex(
                                                    (t) => t.id === v.id
                                                  ) === i
                                              )}
                                              filterSelectedOptions
                                              onChange={(event, newValue) => {
                                                if (newValue) {
                                                  var serviceCollection =
                                                    values.services;
                                                  var filtered =
                                                    serviceCollection.filter(
                                                      (c) =>
                                                        c.id === newValue.id
                                                    );

                                                  if (filtered.length === 0) {
                                                    setFieldValue(
                                                      `services.${index}`,
                                                      {
                                                        ...newValue,
                                                        quantity: 1,
                                                        is_added_post_dispatch:
                                                          is_dispatched,
                                                      }
                                                    );
                                                  } else {
                                                    setAlert({
                                                      ...alert,
                                                      open: true,
                                                      message:
                                                        "The service already exists.",
                                                      typeAlert: "error",
                                                    });
                                                  }
                                                }
                                              }}
                                              renderInput={(params) => (
                                                <TextFieldMUI
                                                  error={
                                                    errors.services &&
                                                    typeof errors.services[
                                                      index
                                                    ] != "undefined"
                                                      ? true
                                                      : false
                                                  }
                                                  helperText={
                                                    errors.services &&
                                                    typeof errors.services[
                                                      index
                                                    ] != "undefined"
                                                      ? errors.services[index]
                                                          .service_name
                                                      : ""
                                                  }
                                                  variant="standard"
                                                  name={`services.${index}.name`}
                                                  fullWidth
                                                  {...params}
                                                />
                                              )}
                                            />
                                          </TableCell>
                                          <TableCell
                                            scope="row"
                                            style={{ width: "10%" }}
                                          >
                                            <Field
                                              name={`services.${index}.quantity`}
                                              fullWidth
                                              variant="standard"
                                              size="small"
                                              component={TextField}
                                              inputProps={{
                                                style: {
                                                  textAlign: "center",
                                                },
                                              }}
                                            />
                                          </TableCell>
                                          <TableCell
                                            scope="row"
                                            style={{ width: "10%" }}
                                          >
                                            ₱
                                            {values.services[index]
                                              ? numberConverter(
                                                  values.services[index].price
                                                )
                                              : "0.00"}
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                      {values.services.length <= 0 &&
                                      errors.services === undefined ? (
                                        <TableRow>
                                          <TableCell
                                            scope="row"
                                            colSpan={3}
                                            align="center"
                                            style={{
                                              color: "#ccc",
                                              fontStyle: "italic",
                                            }}
                                          >
                                            No package selected.
                                          </TableCell>
                                        </TableRow>
                                      ) : null}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Grid>
                            </Grid>
                          </React.Fragment>
                        )}
                      </FieldArray>
                      <Box my={4}>
                        <Divider />
                      </Box>
                      {values.package_add_ons.length > 0 && (
                        <>
                          <FieldArray name="package_add_ons">
                            {({ remove }) => (
                              <React.Fragment>
                                <Box display="flex">
                                  <Box p={1} width="100%">
                                    <Typography
                                      variant="h6"
                                      className={classes.titleText2}
                                    >
                                      PACKAGE ADD ONS
                                    </Typography>
                                  </Box>
                                </Box>
                                <Grid
                                  container
                                  direction="row"
                                  justify="space-evenly"
                                  alignItems="center"
                                  spacing={3}
                                >
                                  <Grid
                                    item
                                    xs={10}
                                    className={classes.gridCustom}
                                  >
                                    <TableContainer component={Paper}>
                                      <Table
                                        size="small"
                                        aria-label="simple table"
                                      >
                                        <TableHead>
                                          <TableRow>
                                            <TableCell
                                              style={{
                                                fontWeight: "bold",
                                                width: "5%",
                                              }}
                                              align="center"
                                            >
                                              ACTIONS
                                            </TableCell>
                                            <TableCell
                                              style={{
                                                fontWeight: "bold",
                                                width: "75%",
                                              }}
                                              align="center"
                                            >
                                              SERVICE ITEMS
                                            </TableCell>
                                            <TableCell
                                              style={{
                                                fontWeight: "bold",
                                                width: "10%",
                                              }}
                                            >
                                              QUANTITY
                                            </TableCell>
                                            <TableCell
                                              style={{
                                                fontWeight: "bold",
                                                width: "10%",
                                              }}
                                            >
                                              TOTAL PRICE
                                            </TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {values.package_add_ons.map(
                                            (value, index) => (
                                              <TableRow key={index}>
                                                <TableCell
                                                  align="center"
                                                  scope="row"
                                                  style={{ width: "5%" }}
                                                >
                                                  <Tooltip title="Remove">
                                                    <IconButton
                                                      color="secondary"
                                                      aria-label="remove"
                                                      onClick={() => {
                                                        remove(index);
                                                        const recalculatedDiscount =
                                                          parseFloat(
                                                            values.discounts
                                                          ) -
                                                          parseFloat(
                                                            values
                                                              .package_add_ons[
                                                              index
                                                            ].saved_amount
                                                          );
                                                        setFieldValue(
                                                          "discounts",
                                                          recalculatedDiscount ||
                                                            0
                                                        );
                                                      }}
                                                    >
                                                      <HighlightOffIcon
                                                        className={
                                                          classes.largeIcon
                                                        }
                                                      />
                                                    </IconButton>
                                                  </Tooltip>
                                                </TableCell>
                                                <TableCell
                                                  scope="row"
                                                  style={{
                                                    width: "75%",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  {
                                                    values.package_add_ons[
                                                      index
                                                    ].service_name
                                                  }
                                                </TableCell>
                                                <TableCell
                                                  scope="row"
                                                  style={{ width: "10%" }}
                                                >
                                                  1
                                                </TableCell>
                                                <TableCell
                                                  scope="row"
                                                  style={{ width: "10%" }}
                                                >
                                                  ₱0.00
                                                </TableCell>
                                              </TableRow>
                                            )
                                          )}
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                  </Grid>
                                </Grid>
                              </React.Fragment>
                            )}
                          </FieldArray>
                          <Box my={4}>
                            <Divider />
                          </Box>
                        </>
                      )}
                      <FieldArray name="manualServices">
                        {({ push, remove }) => (
                          <React.Fragment>
                            <Box display="flex">
                              <Box p={1} width="100%">
                                <Typography
                                  variant="h6"
                                  className={classes.titleText2}
                                >
                                  SERVICES
                                </Typography>
                              </Box>
                              <Box p={1} flexShrink={0}>
                                <Button
                                  variant="contained"
                                  className={classes.add}
                                  startIcon={<AddCircleIcon />}
                                  onClick={() => {
                                    push(emptyServices);
                                  }}
                                >
                                  Add Service
                                </Button>
                              </Box>
                            </Box>
                            <Grid
                              container
                              direction="row"
                              justify="space-evenly"
                              alignItems="center"
                              spacing={3}
                            >
                              <Grid item xs={10} className={classes.gridCustom}>
                                <TableContainer component={Paper}>
                                  <Table size="small" aria-label="simple table">
                                    <TableHead>
                                      <TableRow>
                                        <TableCell
                                          style={{
                                            fontWeight: "bold",
                                            width: "5%",
                                          }}
                                          align="center"
                                        >
                                          ACTIONS
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            fontWeight: "bold",
                                            width: "45%",
                                          }}
                                          align="center"
                                        >
                                          SERVICE ITEMS
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            fontWeight: "bold",
                                            width: "20%",
                                          }}
                                          align="center"
                                        >
                                          REMARKS
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            fontWeight: "bold",
                                            width: "20%",
                                          }}
                                          align="center"
                                        >
                                          QUANTITY
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            fontWeight: "bold",
                                            width: "20%",
                                          }}
                                          align="center"
                                        >
                                          PRICE
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            fontWeight: "bold",
                                            width: "10%",
                                          }}
                                        >
                                          TOTAL PRICE
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {values.manualServices.map(
                                        (value, index) => (
                                          <TableRow key={index}>
                                            <TableCell
                                              align="center"
                                              scope="row"
                                              style={{ width: "5%" }}
                                            >
                                              <Tooltip title="Remove">
                                                <IconButton
                                                  color="secondary"
                                                  aria-label="remove"
                                                  onClick={() => {
                                                    remove(index);
                                                  }}
                                                >
                                                  <HighlightOffIcon
                                                    className={
                                                      classes.largeIcon
                                                    }
                                                  />
                                                </IconButton>
                                              </Tooltip>
                                            </TableCell>
                                            <TableCell
                                              scope="row"
                                              style={{ width: "45%" }}
                                            >
                                              <Autocomplete
                                                getOptionLabel={(option) =>
                                                  option.service_name
                                                }
                                                disableClearable
                                                size="small"
                                                classes={classes2}
                                                value={
                                                  values.manualServices[index]
                                                }
                                                options={[
                                                  ...values.manualServices,
                                                  ...values.manualServicesList,
                                                ].filter(
                                                  (v, i, a) =>
                                                    a.findIndex(
                                                      (t) =>
                                                        t.service_name ===
                                                        v.service_name
                                                    ) === i
                                                )}
                                                filterSelectedOptions
                                                onChange={(event, newValue) => {
                                                  if (newValue) {
                                                    var serviceCollection =
                                                      values.manualServices;
                                                    var filtered =
                                                      serviceCollection.filter(
                                                        (c) =>
                                                          c.name ===
                                                          newValue.service_name
                                                      );

                                                    if (filtered.length === 0) {
                                                      setFieldValue(
                                                        `manualServices.${index}`,
                                                        {
                                                          ...newValue,
                                                          quantity: 1,
                                                          is_added_post_dispatch:
                                                            is_dispatched,
                                                        }
                                                      );
                                                    } else {
                                                      setAlert({
                                                        ...alert,
                                                        open: true,
                                                        message:
                                                          "The service already exists.",
                                                        typeAlert: "error",
                                                      });
                                                    }
                                                  }
                                                }}
                                                renderInput={(params) => (
                                                  <TextFieldMUI
                                                    error={
                                                      errors.manualServices &&
                                                      typeof errors
                                                        .manualServices[
                                                        index
                                                      ] != "undefined"
                                                        ? true
                                                        : false
                                                    }
                                                    helperText={
                                                      errors.manualServices &&
                                                      typeof errors
                                                        .manualServices[
                                                        index
                                                      ] != "undefined"
                                                        ? errors.manualServices[
                                                            index
                                                          ].service_name
                                                        : ""
                                                    }
                                                    variant="standard"
                                                    name={`manualServices.${index}.service_name`}
                                                    fullWidth
                                                    {...params}
                                                  />
                                                )}
                                              />
                                            </TableCell>
                                            <TableCell
                                              scope="row"
                                              style={{ width: "20%" }}
                                            >
                                              <Field
                                                name={`manualServices.${index}.remarks`}
                                                fullWidth
                                                variant="standard"
                                                size="small"
                                                component={TextField}
                                                inputProps={{
                                                  style: {
                                                    textAlign: "center",
                                                  },
                                                }}
                                              />
                                            </TableCell>
                                            <TableCell
                                              scope="row"
                                              style={{ width: "20%" }}
                                            >
                                              <Field
                                                name={`manualServices.${index}.quantity`}
                                                fullWidth
                                                variant="standard"
                                                size="small"
                                                component={TextField}
                                                inputProps={{
                                                  style: {
                                                    textAlign: "center",
                                                  },
                                                }}
                                              />
                                            </TableCell>
                                            <TableCell
                                              scope="row"
                                              style={{ width: "20%" }}
                                            >
                                              <Field
                                                name={`manualServices.${index}.price`}
                                                fullWidth
                                                variant="standard"
                                                size="small"
                                                component={TextField}
                                                inputProps={{
                                                  style: {
                                                    textAlign: "center",
                                                  },
                                                }}
                                              />
                                            </TableCell>
                                            <TableCell
                                              scope="row"
                                              style={{ width: "10%" }}
                                            >
                                              <Field
                                                name={`manualServices.${index}.totalPrice`}
                                                component={
                                                  CustomOtherServicesTotalPrice
                                                }
                                                index={index}
                                              />
                                            </TableCell>
                                          </TableRow>
                                        )
                                      )}
                                      {values.manualServices.length <= 0 ? (
                                        <TableRow>
                                          <TableCell
                                            scope="row"
                                            colSpan={5}
                                            align="center"
                                            style={{
                                              color: "#ccc",
                                              fontStyle: "italic",
                                            }}
                                          >
                                            No services selected.
                                          </TableCell>
                                        </TableRow>
                                      ) : null}
                                      {typeof errors.manualServices ===
                                      "string" ? (
                                        <TableRow>
                                          <TableCell
                                            scope="row"
                                            colSpan={5}
                                            align="center"
                                            style={{
                                              color: "#ccc",
                                              fontStyle: "italic",
                                            }}
                                          >
                                            No services selected.
                                          </TableCell>
                                        </TableRow>
                                      ) : null}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Grid>
                            </Grid>
                          </React.Fragment>
                        )}
                      </FieldArray>
                      <Box my={4}>
                        <Divider />
                      </Box>
                      <FieldArray name="parts">
                        {({ push, remove }) => (
                          <React.Fragment>
                            <Box display="flex">
                              <Box p={1} width="100%">
                                <Typography
                                  variant="h6"
                                  className={classes.titleText2}
                                >
                                  PARTS
                                </Typography>
                              </Box>
                              <Box p={1} flexShrink={0}>
                                <Button
                                  variant="contained"
                                  className={classes.add}
                                  startIcon={<AddCircleIcon />}
                                  onClick={() => {
                                    push(emptyParts);
                                  }}
                                >
                                  Add Parts
                                </Button>
                              </Box>
                            </Box>
                            <Grid
                              container
                              direction="row"
                              justify="space-evenly"
                              alignItems="center"
                              spacing={3}
                            >
                              <Grid item xs={10} className={classes.gridCustom}>
                                <TableContainer
                                  component={Paper}
                                  className={classes2.root}
                                >
                                  <Table size="small" aria-label="simple table">
                                    <TableHead>
                                      <TableRow>
                                        <TableCell
                                          style={{
                                            fontWeight: "bold",
                                            width: "5%",
                                          }}
                                          align="center"
                                        >
                                          ACTIONS
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            fontWeight: "bold",
                                            width: "45%",
                                          }}
                                          align="center"
                                        >
                                          PART ITEMS
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            fontWeight: "bold",
                                            width: "20%",
                                          }}
                                          align="center"
                                        >
                                          REMARKS
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            fontWeight: "bold",
                                            width: "20%",
                                          }}
                                          align="center"
                                        >
                                          QUANTITY
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            fontWeight: "bold",
                                            width: "20%",
                                          }}
                                          align="center"
                                        >
                                          PRICE
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            fontWeight: "bold",
                                            width: "10%",
                                          }}
                                        >
                                          TOTAL PRICE
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {values.parts.map((value, index) => (
                                        <TableRow key={index}>
                                          <TableCell
                                            align="center"
                                            scope="row"
                                            style={{ width: "5%" }}
                                          >
                                            <Tooltip title="Remove">
                                              <IconButton
                                                color="secondary"
                                                aria-label="remove"
                                                onClick={() => {
                                                  remove(index);
                                                }}
                                              >
                                                <HighlightOffIcon
                                                  className={classes.largeIcon}
                                                />
                                              </IconButton>
                                            </Tooltip>
                                          </TableCell>
                                          <TableCell
                                            scope="row"
                                            style={{ width: "45%" }}
                                          >
                                            <CustomPartsAutocompleteComponent
                                              key={index}
                                              index={index}
                                              values={values}
                                              setFieldValue={setFieldValue}
                                              errors={errors}
                                              classes2={classes2}
                                              setAlert={setAlert}
                                              alert={alert}
                                            />
                                          </TableCell>
                                          <TableCell
                                            scope="row"
                                            style={{ width: "20%" }}
                                          >
                                            <Field
                                              name={`parts.${index}.remarks`}
                                              fullWidth
                                              variant="standard"
                                              size="small"
                                              component={TextField}
                                              inputProps={{
                                                style: {
                                                  textAlign: "center",
                                                },
                                              }}
                                            />
                                          </TableCell>
                                          <TableCell
                                            scope="row"
                                            style={{ width: "20%" }}
                                          >
                                            <Field
                                              name={`parts.${index}.quantity`}
                                              fullWidth
                                              variant="standard"
                                              size="small"
                                              component={TextField}
                                              inputProps={{
                                                style: {
                                                  textAlign: "center",
                                                },
                                              }}
                                            />
                                          </TableCell>
                                          <TableCell
                                            scope="row"
                                            style={{ width: "20%" }}
                                          >
                                            <Field
                                              name={`parts.${index}.price`}
                                              fullWidth
                                              variant="standard"
                                              size="small"
                                              component={TextField}
                                              inputProps={{
                                                style: {
                                                  textAlign: "center",
                                                },
                                              }}
                                            />
                                          </TableCell>
                                          <TableCell
                                            scope="row"
                                            style={{ width: "10%" }}
                                          >
                                            <Field
                                              name={`parts.${index}.totalPrice`}
                                              component={CustomPartsTotalPrice}
                                              index={index}
                                            />
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                      {values.parts.length <= 0 ? (
                                        <TableRow>
                                          <TableCell
                                            scope="row"
                                            colSpan={5}
                                            align="center"
                                            style={{
                                              color: "#ccc",
                                              fontStyle: "italic",
                                            }}
                                          >
                                            No parts selected.
                                          </TableCell>
                                        </TableRow>
                                      ) : null}
                                      {typeof errors.parts === "string" ? (
                                        <TableRow>
                                          <TableCell
                                            scope="row"
                                            colSpan={5}
                                            align="center"
                                            style={{
                                              color: "#ccc",
                                              fontStyle: "italic",
                                            }}
                                          >
                                            No parts selected.
                                          </TableCell>
                                        </TableRow>
                                      ) : null}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Grid>
                            </Grid>
                          </React.Fragment>
                        )}
                      </FieldArray>
                      <Box my={4}>
                        <Divider />
                      </Box>
                      <FieldArray name="tires">
                        {({ push, remove }) => (
                          <React.Fragment>
                            <Box display="flex">
                              <Box p={1} width="100%">
                                <Typography
                                  variant="h6"
                                  className={classes.titleText2}
                                >
                                  TIRES
                                </Typography>
                              </Box>
                              <Box p={1} flexShrink={0}>
                                <Button
                                  variant="contained"
                                  className={classes.add}
                                  startIcon={<AddCircleIcon />}
                                  onClick={() => {
                                    push(emptyTires);
                                  }}
                                >
                                  Add Tires
                                </Button>
                              </Box>
                            </Box>
                            <Grid
                              container
                              direction="row"
                              justify="space-evenly"
                              alignItems="center"
                              spacing={3}
                            >
                              <Grid item xs={10} className={classes.gridCustom}>
                                <TableContainer
                                  component={Paper}
                                  className={classes2.root}
                                >
                                  <Table size="small" aria-label="simple table">
                                    <TableHead>
                                      <TableRow>
                                        <TableCell
                                          style={{
                                            fontWeight: "bold",
                                            width: "5%",
                                          }}
                                          align="center"
                                        >
                                          ACTIONS
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            fontWeight: "bold",
                                            width: "45%",
                                          }}
                                          align="center"
                                        >
                                          TIRE ITEMS
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            fontWeight: "bold",
                                            width: "20%",
                                          }}
                                          align="center"
                                        >
                                          QUANTITY
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            fontWeight: "bold",
                                            width: "20%",
                                          }}
                                          align="center"
                                        >
                                          PRICE
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            fontWeight: "bold",
                                            width: "10%",
                                          }}
                                        >
                                          TOTAL PRICE
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {values.tires.map((value, index) => (
                                        <TableRow key={index}>
                                          <TableCell
                                            align="center"
                                            scope="row"
                                            style={{ width: "5%" }}
                                          >
                                            <Tooltip title="Remove">
                                              <IconButton
                                                color="secondary"
                                                aria-label="remove"
                                                onClick={() => {
                                                  remove(index);
                                                }}
                                              >
                                                <HighlightOffIcon
                                                  className={classes.largeIcon}
                                                />
                                              </IconButton>
                                            </Tooltip>
                                          </TableCell>
                                          <TableCell
                                            scope="row"
                                            style={{ width: "45%" }}
                                          >
                                            <CustomTiresAutocompleteComponent
                                              key={index}
                                              index={index}
                                              values={values}
                                              setFieldValue={setFieldValue}
                                              errors={errors}
                                              classes2={classes2}
                                              setAlert={setAlert}
                                              alert={alert}
                                            />
                                          </TableCell>
                                          <TableCell
                                            scope="row"
                                            style={{ width: "20%" }}
                                          >
                                            <Field
                                              name={`tires.${index}.quantity`}
                                              fullWidth
                                              variant="standard"
                                              size="small"
                                              component={TextField}
                                              inputProps={{
                                                style: {
                                                  textAlign: "center",
                                                },
                                              }}
                                            />
                                          </TableCell>
                                          <TableCell
                                            scope="row"
                                            style={{ width: "20%" }}
                                          >
                                            <Field
                                              name={`tires.${index}.price`}
                                              fullWidth
                                              variant="standard"
                                              size="small"
                                              component={TextField}
                                              inputProps={{
                                                style: {
                                                  textAlign: "center",
                                                },
                                              }}
                                            />
                                          </TableCell>
                                          <TableCell
                                            scope="row"
                                            style={{ width: "10%" }}
                                          >
                                            <Field
                                              name={`tires.${index}.totalPrice`}
                                              component={CustomTiresTotalPrice}
                                              index={index}
                                            />
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                      {values.tires.length <= 0 ? (
                                        <TableRow>
                                          <TableCell
                                            scope="row"
                                            colSpan={5}
                                            align="center"
                                            style={{
                                              color: "#ccc",
                                              fontStyle: "italic",
                                            }}
                                          >
                                            No tires selected.
                                          </TableCell>
                                        </TableRow>
                                      ) : null}
                                      {typeof errors.tires === "string" ? (
                                        <TableRow>
                                          <TableCell
                                            scope="row"
                                            colSpan={5}
                                            align="center"
                                            style={{
                                              color: "#ccc",
                                              fontStyle: "italic",
                                            }}
                                          >
                                            No tires selected.
                                          </TableCell>
                                        </TableRow>
                                      ) : null}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Grid>
                            </Grid>
                          </React.Fragment>
                        )}
                      </FieldArray>
                      <Box mt={3}>
                        <Grid
                          container
                          direction="row"
                          justify="space-evenly"
                          alignItems="center"
                          spacing={3}
                        >
                          <Grid item xs={10} className={classes.gridCustom}>
                            <TableContainer component={Paper}>
                              <Table size="small" aria-label="simple table">
                                <TableHead>
                                  <TableRow></TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow>
                                    <TableCell
                                      scope="row"
                                      colSpan={4}
                                      style={{
                                        fontWeight: "bold",
                                        width: "90%",
                                      }}
                                      align="right"
                                    >
                                      Convenience Fee :
                                    </TableCell>
                                    <TableCell
                                      align="left"
                                      style={{ width: "10%" }}
                                    >
                                      <Field
                                        name="service_fee"
                                        component={CustomServiceFeeComponent}
                                        token={props.token}
                                      />
                                      <Field
                                        name="service_fee"
                                        component={CustomServiceFee2Component}
                                      />
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      scope="row"
                                      colSpan={4}
                                      style={{
                                        fontWeight: "bold",
                                        width: "90%",
                                      }}
                                      align="right"
                                    >
                                      Discount :
                                    </TableCell>
                                    <TableCell
                                      align="left"
                                      style={{ width: "10%" }}
                                    >
                                      {/* ₱{numberConverter(values.discount)} */}
                                      <FastField
                                        name="discounts"
                                        component={CustomDiscountComponent}
                                      />
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      scope="row"
                                      colSpan={4}
                                      style={{
                                        fontWeight: "bold",
                                        width: "90%",
                                      }}
                                      align="right"
                                    >
                                      Total Amount :
                                    </TableCell>
                                    <TableCell
                                      align="right"
                                      style={{ width: "10%" }}
                                    >
                                      ₱
                                      <Field
                                        name="total"
                                        component={CustomTotalComponentUpdate}
                                      />
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box my={4}>
                        <Divider />
                      </Box>
                      <Typography variant="h6" className={classes.titleText2}>
                        APPOINTMENT DETAILS
                      </Typography>
                      <Grid container spacing={3}>
                        <Grid item xs={3}>
                          <MuiPickersUtilsProvider
                            theme={muiTheme}
                            utils={DateFnsUtils}
                          >
                            <Grid container>
                              <FormControl fullWidth>
                                <InputLabel
                                  style={{
                                    position: "absolute",
                                    top: "-40px",
                                    fontSize: "0.9rem",
                                  }}
                                >
                                  Appointment Date
                                </InputLabel>
                                <FastField
                                  component={DatePicker}
                                  format="yyyy-MM-dd"
                                  size="small"
                                  inputVariant="outlined"
                                  name="ap_date"
                                  variant="inline"
                                  autoOk
                                  inputProps={{
                                    style: {
                                      textAlign: "center",
                                    },
                                  }}
                                  InputProps={{
                                    classes: {
                                      root:
                                        data && data?.status?.id === 3
                                          ? classes.disabledRoot
                                          : "",
                                    },
                                  }}
                                />
                              </FormControl>
                            </Grid>
                          </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={3}>
                          <MuiPickersUtilsProvider
                            theme={muiTheme}
                            utils={DateFnsUtils}
                          >
                            <Grid container>
                              <FormControl fullWidth>
                                <InputLabel
                                  style={{
                                    position: "absolute",
                                    top: "-40px",
                                    fontSize: "0.9rem",
                                  }}
                                >
                                  Appointment Time
                                </InputLabel>
                                <FastField
                                  component={TimePicker}
                                  size="small"
                                  inputVariant="outlined"
                                  name="ap_time"
                                  variant="dialog"
                                  autoOk
                                  inputProps={{
                                    style: {
                                      textAlign: "center",
                                    },
                                  }}
                                  InputProps={{
                                    classes: {
                                      root:
                                        data && data?.status?.id === 3
                                          ? classes.disabledRoot
                                          : "",
                                    },
                                  }}
                                />
                              </FormControl>
                            </Grid>
                          </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={4} style={{ position: "relative" }}>
                          <InputLabel
                            id="ads-source-label"
                            style={{
                              position: "absolute",
                              top: "-8px",
                              fontSize: "0.8rem",
                            }}
                          >
                            Ads source
                          </InputLabel>
                          <Select
                            id="ads-source"
                            multiple
                            error={errors.ads_source && touched.ads_source}
                            helperText={
                              errors.ads_source &&
                              touched.ads_source &&
                              errors.ads_source
                            }
                            name="ads_source"
                            value={adsSource}
                            onChange={(event) => {
                              const {
                                target: { value },
                              } = event;
                              // On autofill we get a stringified value.
                              const aSources =
                                typeof value === "string"
                                  ? value.split(",")
                                  : value;

                              setFieldValue("ads_source", aSources);
                              setAdsSource(aSources);
                            }}
                            input={
                              <Input
                                id="select-multiple-ads-source"
                                classes={{
                                  root: classes.inputMultiple, // class name, e.g. `classes-nesting-root-x`
                                }}
                              />
                            }
                            renderValue={(selected) => (
                              <div className={classes.chips}>
                                {selected.map((value) => (
                                  <Chip
                                    key={value}
                                    label={value}
                                    className={classes.chip}
                                  />
                                ))}
                              </div>
                            )}
                            MenuProps={MenuProps}
                          >
                            {adsSourceData &&
                              adsSourceData.map((src) => (
                                <MenuItem
                                  key={src.id}
                                  value={src.ads_source_name}
                                  style={getStyles(
                                    src.ads_source_name,
                                    adsSource,
                                    theme
                                  )}
                                >
                                  {src.ads_source_name}
                                </MenuItem>
                              ))}
                          </Select>
                          {errors.ads_source && touched.ads_source ? (
                            <div
                              id="component-error-text"
                              style={{ color: "#f44336" }}
                            >
                              {"Ads source is required."}
                            </div>
                          ) : null}
                        </Grid>
                        <Grid item xs={3}>
                          <FastField
                            name="reschedule_reason"
                            label="Reschedule Reason"
                            component={CustomRescheduleReasonComponent}
                            size="small"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={8}>
                          <FastField
                            component={TextField}
                            multiline
                            size="small"
                            name="remarks"
                            variant="outlined"
                            fullWidth
                            rows={2}
                            rowsMax={3}
                          />
                        </Grid>
                      </Grid>
                      <Box my={4}>
                        <Divider />
                      </Box>
                      <Grid container>
                        <Grid item xs={4}>
                          {" "}
                          <Typography
                            variant="h6"
                            className={classes.titleText2}
                          >
                            PAYMENT DETAILS
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          {" "}
                          <Typography
                            variant="h6"
                            className={classes.titleText2}
                          >
                            PAYMENT SUMMARY
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          {" "}
                          <Typography
                            variant="h6"
                            className={classes.titleText2}
                          >
                            PAYMENT SUMMARY - Additional Service
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item container xs={4} spacing={1}>
                          <Grid item xs={12}>
                            <Field
                              name="payment_method"
                              label="Mode of Payment"
                              variant="outlined"
                              size="small"
                              component={CustomPaymentMethodComponent}
                            />
                            {errors.payment_method && touched.payment_method ? (
                              <FormHelperText
                                id="component-error-text"
                                style={{ color: "#f44336" }}
                              >
                                {"Mode of Payment is required."}
                              </FormHelperText>
                            ) : null}
                          </Grid>
                          <Grid item xs={12}>
                            <Field
                              name="payment_type"
                              label="Payment type"
                              variant="outlined"
                              size="small"
                              component={SelectPaymentType}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Field
                              name="payment_amount"
                              label="Amount"
                              variant="outlined"
                              size="small"
                              component={InputField}
                              disabled={!values.payment_type}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FastField
                              fullWidth
                              label="Payment Reference No."
                              component={TextField}
                              name="payment_reference_no"
                              type="text"
                              variant="outlined"
                              size="small"
                              inputProps={{
                                style: {
                                  textTransform: "capitalize",
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Grid container item xs={4} spacing={1}>
                          <Grid container item xs={12} spacing={1}>
                            <Grid container item xs={12}>
                              <Typography style={{ fontWeight: "bold" }}>
                                Service items
                              </Typography>
                              <ServiceItems services={values.services} />
                              <ServiceItems services={values.manualServices} />
                              <ServiceItems services={values.parts} />
                              <ServiceItems services={values.tires} />
                            </Grid>

                            <Grid container item xs={12}>
                              <Grid item xs={6}>
                                <Typography
                                  style={{ fontWeight: "bold", color: "green" }}
                                >
                                  Subtotal:
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                style={{
                                  textAlign: "right",
                                  borderBottom: "1px solid #000",
                                }}
                              >
                                ₱{numberWithCommas(values.subTotal)}
                              </Grid>
                            </Grid>
                            <Grid container item xs={12}>
                              <Grid item xs={6}>
                                <Typography style={{ fontWeight: "bold" }}>
                                  Convenience Fee:
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                style={{
                                  textAlign: "right",
                                  borderBottom: "1px solid #000",
                                }}
                              >
                                <Field
                                  name="service_fee"
                                  component={CustomServiceFee2Component}
                                />
                              </Grid>
                            </Grid>
                            {values.payment_type && renderPaymentType(values)}
                            <Grid container item xs={12}>
                              <Grid item xs={6}>
                                <Typography
                                  style={{ fontWeight: "bold", color: "red" }}
                                >
                                  Discount:
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                style={{
                                  textAlign: "right",
                                  borderBottom: "1px solid #000",
                                }}
                              >
                                <FastField
                                  name="discounts"
                                  component={CustomDiscountComponent}
                                />
                                {/* {numberWithCommas(values.discounts)} */}
                              </Grid>
                            </Grid>
                            <Grid container item xs={12}>
                              <Grid item xs={6}>
                                <Typography
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "18px",
                                    color: "green",
                                  }}
                                >
                                  Total Balance:
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                style={{
                                  textAlign: "right",
                                }}
                              >
                                ₱
                                <Field
                                  name="total"
                                  component={CustomTotalComponentUpdate}
                                  token={props.token}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid></Grid>
                        </Grid>
                        <Grid container item xs={4} spacing={1}>
                          <Grid item xs={6}>
                            <Typography>Service Subtotal:</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography
                              style={{
                                textAlign: "right",
                                borderBottom: "solid",
                              }}
                            >
                              ₱
                              {numberWithCommas(
                                values.post_dispatch_sub_total || 0
                              )}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography>Added Discount:</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography
                              style={{
                                textAlign: "right",
                                borderBottom: "solid",
                              }}
                            >
                              ₱
                              {numberWithCommas(
                                values.post_dispatch_discount || 0
                              )}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography>Total Balance:</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography
                              style={{
                                textAlign: "right",
                                borderBottom: "solid",
                              }}
                            >
                              ₱
                              {numberWithCommas(
                                values.post_dispatch_total || 0
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Paper>
                  </div>
                </MuiDialogContent>
              </Form>
            )}
          </Formik>
        </Dialog>
      </div>
    </Fragment>
  );
};

export default UpdateDialog;
