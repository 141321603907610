import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { MenuItem, TextField } from "@material-ui/core";

const MenuItemStyle = withStyles({
  root: {
    justifyContent: "center !important",
  },
})(MenuItem);

const useStyles = makeStyles(() => ({
  center: {
    textAlign: "center !important",
    width: "100%",
    marginTop: "1rem",
  },
}));

const SelectTransactionType = ({ field, form, ...props }) => {
  const classes = useStyles();

  const handleTransactionChange = (value) => {
    props.handleTransactionChange(value);
  };

  const types = ["Home Service", "Hub Service"];

  return (
    <TextField
      select
      SelectProps={{ multiple: true }}
      value={props.transactionType}
      label="Transaction Type"
      {...props}
      onChange={(event) => handleTransactionChange(event.target.value)}
      classes={{
        root: classes.center,
      }}
    >
      {types.map((option) => (
        <MenuItemStyle key={option} value={option}>
          {option}
        </MenuItemStyle>
      ))}
    </TextField>
  );
};
export default SelectTransactionType;
