import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { MenuItem, TextField } from "@material-ui/core";
import axios from 'axios';
import config from "../../utils/config";

const MenuItemStyle = withStyles({
  root: {
    justifyContent: "center !important",
  },
})(MenuItem);

const useStyles = makeStyles((theme) => ({
  center: {
    textAlign: "center !important",
  },
}));

const SelectServiceCategory = ({
  field,
  form: { touched, errors },
  ...props
}) => {
  const classes = useStyles();
  const token = localStorage.getItem('token');
  const [serviceCategories, setServiceCategories] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const { data: data } = await axios.get(
        `${config.api}/services/categories`,
        options
      );
      let filtered = data?.data?.filter(x => x.id !== 1)
      filtered.sort((a, b) => a.name.localeCompare(b.name));
      setServiceCategories(filtered);
    }
    fetchData();
  }, []);

  return (
    <TextField
      select
      error={errors.service_category_id && touched.service_category_id}
      helperText={errors.service_category_id && touched.service_category_id && errors.service_category_id}
      {...field}
      {...props}
      classes={{
        root: classes.center,
      }}
    >
      {serviceCategories.map((option) => (
        <MenuItemStyle key={option.id} value={option.id}>
          {option.name}
        </MenuItemStyle>
      ))}
    </TextField>
  );
};
export default SelectServiceCategory;
