import { Fragment, useCallback, useState, useRef } from "react";
import axios from "axios";
import AlertDialog from "../components/booking/AlertDialog";
import PackageTable from "../components/packages/PackageTable";
import AddPackage from "../components/packages/AddPackage";

// Api config
import config from "../utils/config";

const Mechanic = (props) => {
  const { token } = props;

  const tableRef = useRef();

  // ==============MODAL STATES==============
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [typeAlert, setTypeAlert] = useState("success");
  const [isLoading, setIsLoading] = useState(false);

  // ==============ADD Manual Services STATES==============
  const [openAdd, setOpenAdd] = useState(false);

  const handleOpenAdd = useCallback(() => {
    setOpenAdd(true);
  }, []);

  const handleCloseAdd = useCallback(() => {
    setOpenAdd(false);
  }, []);

  const handleSubmitAddPackage = useCallback(
    (values, actions) => {
      setIsLoading(true);
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      axios
        .post(`${config.api}/packages`, values, options)
        .then((response) => {
          setOpenAdd(false);
          setTimeout(() => {
            setAlert(true);
            setMessage("Successfully Added");
            setTypeAlert("success");
          }, 1500);

          tableRef.current && tableRef.current.onQueryChange();
        })
        .catch((error) => {
          actions.setSubmitting(false);
          setAlert(true);
          setTypeAlert("error");
          setMessage("Something went wrong please try again.");
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [token]
  );

  // =================ALERT - (MODAL)=================
  const handleCloseAlert = useCallback(() => {
    setAlert(false);
    setMessage("");
    setTypeAlert("success");
  }, []);

  return (
    <Fragment>
      <AlertDialog
        open={alert}
        typeAlert={typeAlert}
        message={message}
        handleCloseAlert={handleCloseAlert}
      />
      <AddPackage
        open={openAdd}
        onOpen={handleOpenAdd}
        onClose={handleCloseAdd}
        handleSubmitAddPackage={handleSubmitAddPackage}
        token={token}
        isLoading={isLoading}
      />
      <PackageTable tableRef={tableRef} token={token} />
    </Fragment>
  );
};

export default Mechanic;
