import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Collapse as MUIcollapse,
  IconButton,
  Snackbar,
} from "@material-ui/core";
import { Alert as MUIalerts } from "@material-ui/lab";
import MuiAlert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  alertCustom: {
    zIndex: "9999999999 !important",
    maxWidth: "30%",
    width: "30%",
    position: "fixed",
    right: "2%",
    top: "90%",
  },
}));

const AlertDialog = React.memo((props) => {
  const classes = useStyles();
  const { open, message, typeAlert, handleCloseAlert } = props;

  return (
    <Fragment>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={open}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert onClose={handleCloseAlert} severity={typeAlert}>
          {message}
        </Alert>
      </Snackbar>
    </Fragment>
  );
});

export default AlertDialog;
