import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { bookFilterActions } from "../../../store/book-filter-slice";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import EventNoteIcon from "@material-ui/icons/EventNote";

const AntTabs = withStyles({
  root: {
    border: "none",
  },
  indicator: {
    backgroundColor: "#FAE700 !important",
    borderBottom: "5px solid #FAE700",
    width: "20% !important",
    maxWidth: "100% !important",
    "& > span": {
      maxWidth: 40,
      width: "100%",
    },
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    border: "1px solid #e8e8e8",
    textTransform: "none",
    minWidth: "20% !important",
    maxWidth: "30%",
    fontWeight: theme.typography.fontWeightRegular,
    backgroundColor: theme.palette.background.paper,
    marginRight: theme.spacing(3),
    opacity: 1,
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  selected: {},
}))((props) => <Tab {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(3),
  },
  demo1: {
    backgroundColor: theme.palette.background.paper,
  },
  appointmentsToday: {
    backgroundColor: "#B4F58F",
  },
  appointmentsUpcoming: {
    backgroundColor: "#F7ED71",
  },
  appointmentsAll: {
    backgroundColor: "#717EF7",
  },
}));

const FilterBookingType = (props) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const filterBookingType = useSelector(
    (state) => state.bookFilter.filterBookingType
  );

  const handleChange = (event, data) => {
    dispatch(
      bookFilterActions.changeFilterBookingType({ filterBookingType: data })
    );
  };
  return (
    <div className={classes.root}>
      <div>
        <AntTabs
          value={filterBookingType}
          onChange={handleChange}
          aria-label="ant example"
        >
          <AntTab
            className={classes.appointmentsToday}
            label="Appointments Today"
            icon={<EventNoteIcon />}
            aria-label="calendar"
            value="0"
          />
          <AntTab
            className={classes.appointmentsUpcoming}
            label="Upcoming Appointments"
            icon={<EventNoteIcon />}
            aria-label="calendar"
            value="1"
          />
          <AntTab
            className={classes.appointmentsAll}
            label="All Booking"
            icon={<EventNoteIcon />}
            aria-label="calendar"
            value="2"
          />
        </AntTabs>
      </div>
    </div>
  );
};

export default FilterBookingType;
