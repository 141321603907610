import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import { MenuItem, TextField } from '@material-ui/core';

// Api config
import config from '../../../utils/config';

const MenuItemStyle = withStyles({
  root: {
    justifyContent: 'center',
  },
})(MenuItem);

const CustomProvinceComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, values }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const [provinces, setProvinces] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const { data: provinces } = await axios.get(`${config.api}/provinces`);
      setProvinces(provinces);
    }
    fetchData();
  }, [props.token]);

  return (
    <TextField
      fullWidth
      select
      error={errors.province && touched.province}
      helperText={errors.province && touched.province ? errors.province : ''}
      {...field}
      {...props}
    >
      {provinces.map((option) => (
        <MenuItemStyle key={option.province_id} value={option.province_name}>
          {option.province_name}
        </MenuItemStyle>
      ))}
    </TextField>
  );
};
export default CustomProvinceComponent;
