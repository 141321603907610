import React, { Fragment, useCallback, useState } from "react";
import axios from "axios";
import MaterialTable from "material-table";
import { makeStyles } from "@material-ui/core/styles";
import { tableIcons } from "../booking/TableIcons";
import { green, yellow } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@material-ui/icons/Cancel";
import Tooltip from "@material-ui/core/Tooltip";
import useModal from "../../hooks/use-modal";
import AlertDialog from "../booking/AlertDialog";
import ServiceDelete from "./ServiceDelete";
import OtherSubTable from "./otherSubTable/OtherSubTable";

// Api config
import config from "../../utils/config";

const useStyles = makeStyles((theme) => ({
  view: {
    backgroundColor: yellow["A200"],
    color: "#000",
    "&:hover": {
      color: "#fff",
    },
  },
  update: {
    color: yellow["A200"],
    maxHeight: "2px !important",
    "&:hover": {
      background: "none !important",
    },
  },
  delete: {
    maxHeight: "2px !important",
    "&:hover": {
      background: "none !important",
    },
  },
  add: {
    color: green[500],
    "&:hover": {
      backgroundColor: "rgba(232, 245, 233,1.0)",
    },
  },
  sizeSmall: {
    height: "18px",
    fontSize: "12px",
  },
}));

const ServicesTable = React.memo((props) => {
  const classes = useStyles();

  const { tableRef, token } = props;

  // ==============MODAL STATES==============
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [typeAlert, setTypeAlert] = useState("success");

  const {
    modalState: modalStateDelete,
    modalData: modalDataDelete,
    openHandler: modalOpenDelete,
    closeHandler: modalCloseDelete,
  } = useModal();

  //  =================DELETE - (MODAL)=================
  const handleDeleteService = useCallback(
    async (id) => {
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        const resp = await axios.delete(
          `${config.api}/services/${id}`,
          options
        );
        tableRef.current && tableRef.current.onQueryChange();
        modalCloseDelete();
        setAlert(true);
        setMessage("Successfully Deleted");
        setTypeAlert("success");
      } catch (err) {
        // Handle Error Here
        console.error(err);
        tableRef.current && tableRef.current.onQueryChange();
        modalCloseDelete();
        setAlert(true);
        setTypeAlert("error");
        setMessage("Something went wrong please try again.");
      }
    },
    [modalCloseDelete, token, tableRef]
  );

  // =================ALERT - (MODAL)=================
  const handleCloseAlert = useCallback(() => {
    setAlert(false);
    setMessage("");
    setTypeAlert("success");
  }, []);

  return (
    <Fragment>
      <AlertDialog
        open={alert}
        typeAlert={typeAlert}
        message={message}
        handleCloseAlert={handleCloseAlert}
      />

      {/* =================DELETE - (MODAL)================= */}
      <ServiceDelete
        open={modalStateDelete}
        data={modalDataDelete}
        onClose={modalCloseDelete}
        onDelete={handleDeleteService}
      />

      <MaterialTable
        icons={tableIcons}
        title={`Other Services Table`}
        tableRef={tableRef}
        columns={[
          { title: "Service Category", field: "category.name" },
          { title: "Service Item", field: "name" },
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            let url = config.api + "/services/all?type=manual";
            url += `&page=${query.page + 1}`;
            if (query.pageSize) {
              url += `&per_page=${query.pageSize}`;
            }
            if (query.search) {
              url += `&search=${query.search}`;
            }
            const options = {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            };

            fetch(url, options)
              .then((response) => response.json())
              .then((result) => {
                resolve({
                  data: result.data.data,
                  page: result.data.current_page - 1 || 0,
                  totalCount: result.data.total,
                });
              });
          })
        }
        actions={[
          {
            icon: "delete",
            tooltip: "Delete",
            onClick: (event, rowData) => {
              modalOpenDelete(rowData);
            },
          },
        ]}
        components={{
          Action: (props) => {
            // =====================Delete=====================
            if (props.action.icon === "delete") {
              return (
                <Tooltip title="Delete">
                  <IconButton
                    aria-label="delete"
                    color="secondary"
                    size="small"
                    className={classes.delete}
                    onClick={(event) => props.action.onClick(event, props.data)}
                  >
                    <CancelIcon />
                  </IconButton>
                </Tooltip>
              );
            }
          },
        }}
        options={{
          headerStyle: {
            fontWeight: "bold",
          },
          rowStyle: {
            fontSize: ".75rem",
            padding: "0px !important",
          },
          actionsCellStyle: {
            justifyContent: "center",
            padding: "24px",
            marginBottom: "-1px",
          },
          pageSize: 30,
          pageSizeOptions: [],
          actionsColumnIndex: -1,
          tableLayout: "auto",
        }}
        detailPanel={(rowData) => (
          <OtherSubTable tableRef={tableRef} token={token} data={rowData} />
        )}
      />
    </Fragment>
  );
});

export default ServicesTable;
