import React, { Fragment } from "react";
import moment from "moment";
import "moment-timezone";
import { makeStyles } from "@material-ui/core/styles";
import {
  IconButton,
  Typography,
  Slide,
  Toolbar,
  AppBar,
  Divider,
  Dialog,
  Box,
  Paper,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import MuiDialogContent from "@material-ui/core/DialogContent";
import ViewDialogPaymentTable from "./ViewDialogPaymentTable";
import CloseIcon from "@material-ui/icons/Close";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import { PaymentType } from "../../../utils/constant";
import { capitalizeAllWords } from "../../../utils/helper";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  paper: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(4),
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  button: {
    color: theme.palette.grey[900],
  },
  icon: {
    position: "absolute",
    top: "18px",
    marginLeft: "3px",
  },
  titleText: {
    fontWeight: "bold",
  },
  text: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    fontSize: "14px !important",
  },
  boxItem: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  listItem: {
    listStyleType: "none",
  },
  liItem: {
    marginBottom: "3px",
  },
  label: {
    fontWeight: "bold",
    fontSize: "14px !important",
    minWidth: "170px",
  },
  data: {
    fontSize: "14px !important",
    fontWeight: "normal",
  },
  liItem2: {
    marginBottom: "3px",
    lineHeight: "1.2",
  },
  label2: {
    fontWeight: "800",
    fontSize: "16px !important",
    minWidth: "25%",
    textAlign: "right",
    marginRight: "160px",
  },
  data2: {
    fontSize: "16px !important",
    fontWeight: "normal",
    minWidth: "45%",
    textAlign: "left",
  },
  appBar: {
    position: "relative",
    backgroundColor: "#FAE700",
    color: "#000",
  },
  paper2: {
    padding: theme.spacing(4),
    paddingRight: theme.spacing(10),
    paddingLeft: theme.spacing(10),
  },
  table: {
    width: 650,
    minWidth: 650,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
      timeout={{ appear: 100, enter: 500, exit: 500 }}
    />
  );
});

const numberConverter = (number) => {
  let value = Number(parseFloat(number)).toLocaleString("en", {
    minimumFractionDigits: 2,
  });
  return value;
};

const freeEngineScanningVouchers = ["MGO-NIKKO", "MGO-CLAIRE", "MGO-ANJO"];

const formatVoucher = (voucher) => {
  return (
    voucher.length > 0 && (
      <span style={{ marginLeft: "5px" }}>
        {voucher
          .map((c) => {
            if (freeEngineScanningVouchers.includes(c.coupon)) {
              return `${c.coupon} (FREE ENGINE SCANNING)`;
            } else if (c.label) {
              if (c.coupon === c.label) {
                return c.coupon;
              } else {
                return `${c.coupon} (${c.label})`;
              }
            }
            return c.coupon;
          })
          .join(", ")}
      </span>
    )
  );
};

const displayAddress = (data) => {
  if (data.barangay && data.municipality && data.province) {
    return `${data.address}, ${data.barangay}, ${data.municipality}, ${
      data.province || ""
    } ${data.zip_code || ""}`;
  }
  return data.address;
};

const ViewDialog = (props) => {
  const classes = useStyles();

  const { data, open, handleViewClose } = props;

  const ListItem = ({ label, value }) => (
    <li className={classes.liItem}>
      <div style={{ display: "flex" }}>
        <div className={classes.label}>{label} </div>
        <div className={classes.data}>{value}</div>
      </div>
    </li>
  );

  const TableCellItem = ({ label, textAlign = "" }) => (
    <TableCell
      style={{
        fontWeight: "bold",
        borderBottom: "2px solid rgb(82 81 81)",
        textAlign,
      }}
    >
      {label}
    </TableCell>
  );
  return (
    <Fragment>
      <div>
        <Dialog
          fullScreen
          open={open}
          onClose={handleViewClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <Typography variant="h5" className={classes.title}>
                View Booking
                <CalendarTodayIcon className={classes.icon} />
              </Typography>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleViewClose}
                aria-label="close"
              >
                <CloseIcon className={classes.button} />
              </IconButton>
            </Toolbar>
          </AppBar>
          <MuiDialogContent dividers>
            {Object.keys(data).length > 0 ? (
              <div className={classes.root}>
                <Paper className={classes.paper}>
                  <Typography variant="h6" className={classes.titleText}>
                    BOOKING DETAILS
                  </Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Box mt={2} className={classes.text}>
                        <Typography
                          paragraph={true}
                          display={"inline"}
                          style={{ fontWeight: "bold" }}
                        >
                          Order Status :
                        </Typography>
                        <Typography
                          paragraph={true}
                          display={"inline"}
                          className={classes.span}
                        >
                          {" "}
                          {data.status.name}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={5}>
                      <Box className={classes.boxItem}>
                        <ul className={classes.listItem}>
                          <ListItem
                            label="Booking No.:"
                            value={data.reference_no}
                          />
                          <ListItem
                            label="Customer ID:"
                            value={data.customer_id}
                          />
                          <ListItem
                            label="Booking Date:"
                            value={moment(data.created_at).format("LLL")}
                          />
                          <ListItem
                            label="BS Number:"
                            value={data.bs_number ? data.bs_number : "N/A"}
                          />
                          <ListItem
                            label="Appointment Date:"
                            value={moment(data.appointment_date).format("LLL")}
                          />
                          {data.date_confirmed && (
                            <ListItem
                              label="Date Confirmed:"
                              value={moment(data.date_confirmed).format("LLL")}
                            />
                          )}
                          {data.date_dispatched && (
                            <ListItem
                              label="Date Dispatched:"
                              value={moment(data.date_dispatched).format("LLL")}
                            />
                          )}
                          {data.date_completed && (
                            <ListItem
                              label="Date Completed:"
                              value={moment(data.date_completed).format("LLL")}
                            />
                          )}
                          <ListItem
                            label="Mode of Payment:"
                            value={data.payment_method.replace(/_/g, " ")}
                          />
                          <ListItem
                            label="Plate Number:"
                            value={
                              data.plate_number ? data.plate_number : "N/A"
                            }
                          />
                          <ListItem label="Remarks:" value={data.remarks} />
                          <ListItem
                            label="Parking Space:"
                            value={data.parking_space}
                          />
                          <ListItem
                            label="Property Type:"
                            value={data.property_type}
                          />
                          <ListItem
                            label="Ads Source/s:"
                            value={data.ads_source}
                          />
                          {data.status.id === 6 && data.date_cancelled && (
                            <>
                              <ListItem
                                label="Date Cancelled:"
                                value={moment(data.date_cancelled).format(
                                  "LLL"
                                )}
                              />
                              {data.reason_for_cancellation && (
                                <ListItem
                                  label="Reason for Cancellation:"
                                  value={data.reason_for_cancellation.replace(
                                    /_/g,
                                    " "
                                  )}
                                />
                              )}
                            </>
                          )}
                        </ul>
                      </Box>
                    </Grid>
                    <Grid item xs={5}>
                      <Box mt={1} className={classes.boxItem}>
                        <ul className={classes.listItem}>
                          <ListItem
                            label="Customer Name:"
                            value={capitalizeAllWords(
                              `${data.name} ${data.lastname}`
                            )}
                          />
                          <ListItem label="Contact No:" value={data.phone} />
                          <ListItem
                            label="Transaction Type:"
                            value={data.transaction_type}
                          />
                          <ListItem
                            label="Service Location:"
                            value={data.service_location}
                          />
                          {/* <ListItem
                            label="Address:"
                            value={capitalizeAllWords(displayAddress(data))}
                          /> */}
                          <ListItem
                            label="Customer Type:"
                            value={capitalizeAllWords(data.customer_type)}
                          />
                          <ListItem
                            label="Exact Address:"
                            value={
                              data.landmarks
                                ? capitalizeAllWords(data.landmarks)
                                : "N/A"
                            }
                          />
                          {/* <ListItem
                            label="Vehicle:"
                            value={capitalizeAllWords(`${data.make} ${
                              data.model
                            } ${data.year}${" "}
                                ${data.engine_size} ${data.fuel_type}`)}
                          /> */}
                          <ListItem
                            label="Liter of oil:"
                            value={data.liter_of_oil}
                          />
                          <ListItem
                            label="Engine size:"
                            value={data.engine_size}
                          />
                          {(data.billing_company_name ||
                            data.billing_company_address ||
                            data.billing_company_tin) && (
                            <>
                              {data.billing_company_name && (
                                <ListItem
                                  label="Billing company name:"
                                  value={data.billing_company_name}
                                />
                              )}
                              {data.billing_company_address && (
                                <ListItem
                                  label="Billing company address:"
                                  value={data.billing_company_address}
                                />
                              )}
                              {data.billing_company_tin && (
                                <ListItem
                                  label="Billing company tin:"
                                  value={data.billing_company_tin}
                                />
                              )}
                            </>
                          )}
                        </ul>
                      </Box>
                    </Grid>
                  </Grid>
                  <Box my={2}>
                    <Divider />
                  </Box>
                  <Typography variant="h6" className={classes.titleText}>
                    ORDER SUMMARY
                  </Typography>
                  <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                  >
                    <Grid item xs={1}></Grid>
                    <Grid item xs={10}>
                      <Paper className={classes.paper2}>
                        <Typography
                          variant="h6"
                          component="div"
                          style={{ fontWeight: "bold" }}
                        >
                          ORDER DETAILS
                        </Typography>
                        <TableContainer>
                          <Table
                            size="small"
                            className={classes.table}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCellItem label="Services item" />
                                <TableCellItem label="Remarks" />
                                <TableCellItem
                                  label="Price"
                                  textAlign="right"
                                />
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {data.appointment_services.map((row) => (
                                <TableRow key={row.id}>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    style={{ width: "50%" }}
                                  >
                                    {row.service_name}
                                  </TableCell>
                                  <TableCell style={{ width: "25%" }}>
                                    {row?.remarks || ""}
                                  </TableCell>
                                  <TableCell
                                    style={{ width: "25%", textAlign: "right" }}
                                  >
                                    ₱
                                    {row.total
                                      ? numberConverter(row.total)
                                      : numberConverter(row.price)}
                                  </TableCell>
                                </TableRow>
                              ))}
                              {data.appointment_package_add_ons.map((row) => (
                                <TableRow key={row.id}>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    style={{ width: "50%" }}
                                  >
                                    {row.service_name}
                                  </TableCell>
                                  <TableCell style={{ width: "25%" }}>
                                    {row?.remarks || ""}
                                  </TableCell>
                                  <TableCell
                                    style={{ width: "25%", textAlign: "right" }}
                                  >
                                    ₱0
                                  </TableCell>
                                </TableRow>
                              ))}
                              {data?.service_parts?.length > 0 && (
                                <>
                                  <TableRow>
                                    <TableCell
                                      style={{
                                        fontWeight: "bold",
                                        borderTop: "2px solid rgb(82 81 81)",
                                      }}
                                      component="th"
                                      scope="row"
                                    >
                                      Parts
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        borderTop: "2px solid rgb(82 81 81)",
                                      }}
                                    ></TableCell>
                                    <TableCell
                                      style={{
                                        borderTop: "2px solid rgb(82 81 81)",
                                      }}
                                    ></TableCell>
                                  </TableRow>
                                  {data.service_parts.map((row) => (
                                    <TableRow key={row.id}>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        style={{ width: "50%" }}
                                      >
                                        {row.name}
                                      </TableCell>
                                      <TableCell style={{ width: "25%" }}>
                                        {row.remarks}
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          width: "25%",
                                          textAlign: "right",
                                        }}
                                      >
                                        ₱
                                        {row.total
                                          ? numberConverter(row.total)
                                          : numberConverter(row.price)}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </>
                              )}
                              {data?.service_tires?.length > 0 && (
                                <>
                                  <TableRow>
                                    <TableCell
                                      style={{
                                        fontWeight: "bold",
                                        borderTop: "2px solid rgb(82 81 81)",
                                      }}
                                      component="th"
                                      scope="row"
                                    >
                                      Tires
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        borderTop: "2px solid rgb(82 81 81)",
                                      }}
                                    ></TableCell>
                                    <TableCell
                                      style={{
                                        borderTop: "2px solid rgb(82 81 81)",
                                      }}
                                    ></TableCell>
                                  </TableRow>
                                  {data.service_tires.map((row) => (
                                    <TableRow key={row.id}>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        style={{ width: "50%" }}
                                      >
                                        {row.sku}
                                      </TableCell>
                                      <TableCell
                                        style={{ width: "25%" }}
                                      ></TableCell>
                                      <TableCell
                                        style={{
                                          width: "25%",
                                          textAlign: "right",
                                        }}
                                      >
                                        ₱
                                        {row.total
                                          ? numberConverter(row.total)
                                          : numberConverter(row.price)}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </>
                              )}
                              <TableRow>
                                <TableCell
                                  style={{
                                    fontWeight: "bold",
                                    borderTop: "2px solid rgb(82 81 81)",
                                  }}
                                  component="th"
                                  scope="row"
                                >
                                  Sub Total
                                </TableCell>
                                <TableCell
                                  style={{
                                    borderTop: "2px solid rgb(82 81 81)",
                                  }}
                                ></TableCell>
                                <TableCell
                                  style={{
                                    borderTop: "2px solid rgb(82 81 81)",
                                    textAlign: "right",
                                  }}
                                >
                                  ₱{numberConverter(data.sub_total)}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  style={{ fontWeight: "bold" }}
                                  component="th"
                                  scope="row"
                                >
                                  Convenience Fee
                                </TableCell>
                                <TableCell></TableCell>
                                <TableCell style={{ textAlign: "right" }}>
                                  ₱{data.service_fee}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  style={{ fontWeight: "bold" }}
                                  component="th"
                                  scope="row"
                                >
                                  Discount
                                  {formatVoucher(data.coupons)}
                                </TableCell>
                                <TableCell></TableCell>
                                <TableCell style={{ textAlign: "right" }}>
                                  ₱{numberConverter(data?.discount || 0)}
                                </TableCell>
                              </TableRow>
                              {data?.payment?.length > 0 &&
                                (() => {
                                  const lastPayment =
                                    data.payment[data.payment.length - 1];
                                  const paymentTypeKey =
                                    lastPayment?.payment_type;
                                  const paymentTypeValue =
                                    PaymentType[paymentTypeKey] || null;

                                  return paymentTypeValue ? (
                                    <TableRow>
                                      <TableCell
                                        style={{ fontWeight: "bold" }}
                                        component="th"
                                        scope="row"
                                      >
                                        {paymentTypeValue}
                                      </TableCell>
                                      <TableCell></TableCell>
                                      <TableCell style={{ textAlign: "right" }}>
                                        ₱
                                        {numberConverter(
                                          lastPayment.amount_paid
                                        )}
                                      </TableCell>
                                    </TableRow>
                                  ) : null;
                                })()}
                              <TableRow>
                                <TableCell
                                  style={{
                                    fontWeight: "bold",
                                    borderTop: "2px solid rgb(82 81 81)",
                                  }}
                                  component="th"
                                  scope="row"
                                >
                                  TOTAL BALANCE
                                </TableCell>
                                <TableCell
                                  style={{
                                    borderTop: "2px solid rgb(82 81 81)",
                                  }}
                                ></TableCell>
                                <TableCell
                                  style={{
                                    borderTop: "2px solid rgb(82 81 81)",
                                    textAlign: "right",
                                  }}
                                >
                                  ₱{numberConverter(data?.total)}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Paper>
                    </Grid>
                    <Grid item xs={1}></Grid>
                  </Grid>
                  <Box mb={2} mt={3}>
                    <Divider />
                  </Box>
                  <Typography variant="h6" className={classes.titleText}>
                    PAYMENT DETAILS
                  </Typography>
                  <Grid container direction="row">
                    <Grid item xs={12}>
                      <Box mt={3}>
                        <TableContainer component={Paper}>
                          <Table size="small" aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  align="center"
                                  style={{ fontWeight: "bold" }}
                                >
                                  MODE OF PAYMENT
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{ fontWeight: "bold" }}
                                >
                                  PAYMENT REF
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{ fontWeight: "bold" }}
                                >
                                  PAYMENT DATE
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{ fontWeight: "bold" }}
                                >
                                  AMOUNT PAID
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {data.payment.length > 0 ? (
                                <ViewDialogPaymentTable
                                  dataTable={data.payment}
                                />
                              ) : (
                                <TableRow>
                                  <TableCell
                                    colSpan="4"
                                    style={{
                                      fontWeight: "bold",
                                      textAlign: "center",
                                    }}
                                  >
                                    No records found.
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </Grid>
                  </Grid>
                </Paper>
              </div>
            ) : (
              <div></div>
            )}
          </MuiDialogContent>
        </Dialog>
      </div>
    </Fragment>
  );
};

export default ViewDialog;
