import React, { Fragment, useCallback, useState, useRef } from "react";
import moment from "moment";
import axios from "axios";
import MaterialTable from "material-table";
import { makeStyles } from "@material-ui/core/styles";
import { tableIcons } from "../../booking/TableIcons";
import { green, yellow } from "@material-ui/core/colors";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AlertDialog from "../../alertDialog/AlertDialog";
import ScheduleSubUpdate from "./scheduleSubUpdate/ScheduleSubUpdate";
import useModal from "../../../hooks/use-modal";

// Api config
import config from "../../../utils/config";

const useStyles = makeStyles((theme) => ({
  view: {
    backgroundColor: yellow["A200"],
    color: "#000",
    "&:hover": {
      color: "#fff",
    },
  },
  update: {
    color: yellow["A200"],
    maxHeight: "2px !important",
    "&:hover": {
      background: "none !important",
    },
  },
  delete: {
    maxHeight: "2px !important",
    "&:hover": {
      background: "none !important",
    },
  },
  add: {
    color: green[500],
    "&:hover": {
      backgroundColor: "rgba(232, 245, 233,1.0)",
    },
  },
  sizeSmall: {
    height: "18px",
    fontSize: "12px",
  },
}));

const ScheduleSubTable = React.memo((props) => {
  const classes = useStyles();

  const { data, token } = props;
  const tableRef = useRef();

  const id = data && data.id;

  // ==============MODAL STATES==============
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    typeAlert: "success",
  });

  const {
    modalState: modalStateUpdate,
    modalData: modalDataUpdate,
    openHandler: modalOpenUpdate,
    closeHandler: modalCloseUpdate,
  } = useModal();

  const handleUpdateScheduleTime = useCallback(
    async (data, actions) => {
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        const resp = await axios.put(
          `${config.api}/schedule-times/${data.id}`,
          data,
          options
        );
        tableRef.current && tableRef.current.onQueryChange();
        if (resp.status === 201) {
          modalCloseUpdate();
          setAlert({
            ...alert,
            open: true,
            message: "Successfully Updated",
            typeAlert: "success",
          });
        }
      } catch (err) {
        actions.setSubmitting(false);
        modalCloseUpdate();
        tableRef.current && tableRef.current.onQueryChange();
        setAlert({
          ...alert,
          open: true,
          message: "Something went wrong please try again.",
          typeAlert: "error",
        });
      }
    },
    [alert, modalCloseUpdate, token]
  );

  // Alert Dialog
  const handleCloseAlert = useCallback(
    (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setAlert({
        ...alert,
        open: false,
        message: "",
      });
    },
    [alert]
  );

  return (
    <Fragment>
      {/* Alert Dialog */}
      <AlertDialog
        open={alert.open}
        typeAlert={alert.typeAlert}
        message={alert.message}
        handleCloseAlert={handleCloseAlert}
      />

      {/* =================Update Schedule - (MODAL)================= */}
      <ScheduleSubUpdate
        open={modalStateUpdate}
        data={modalDataUpdate}
        onClose={modalCloseUpdate}
        onUpdate={handleUpdateScheduleTime}
      />

      <MaterialTable
        tableRef={tableRef}
        icons={tableIcons}
        title={`Schedule Times`}
        columns={[
          {
            title: "Schedule Time",
            render: (client) => {
              let time = moment(client.time, ["h:mm A"]).format("h:mm A");
              return time;
            },
          },
          {
            title: "Max slots",
            render: (client) => {
              let max_slots = `${client.max_slots}`;
              return max_slots;
            },
          },
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            let url = config.api + "/schedule-times?";
            url += `page=${query.page + 1}`;
            if (id) {
              url += `&id=${id}`;
            }
            if (query.pageSize) {
              url += `&per_page=${query.pageSize}`;
            }
            if (query.search) {
              url += `&search=${query.search}`;
            }
            const options = {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            };
            fetch(url, options)
              .then((response) => response.json())
              .then((result) => {
                resolve({
                  data: result.data.data,
                  page: result.data.current_page - 1 || 0,
                  totalCount: result.data.total,
                });
              });
          })
        }
        // actions={[
        //   {
        //     icon: "update",
        //     tooltip: "Update",
        //     onClick: (event, rowData) => {
        //       modalOpenUpdate(rowData);
        //     },
        //   },
        // ]}
        components={{
          Action: (props) => {
            // =====================Update=====================
            if (props.action.icon === "update") {
              return (
                <Tooltip title="Update">
                  <IconButton
                    aria-label="update"
                    className={classes.update}
                    size="small"
                    onClick={(event) => props.action.onClick(event, props.data)}
                  >
                    <EditOutlinedIcon />
                  </IconButton>
                </Tooltip>
              );
            }
          },
        }}
        options={{
          headerStyle: {
            fontWeight: "bold",
          },
          rowStyle: {
            fontSize: ".75rem",
            padding: "0px !important",
          },
          actionsCellStyle: {
            justifyContent: "center",
            padding: "24px",
            marginBottom: "-1px",
          },
          pageSize: 9,
          pageSizeOptions: [],
          actionsColumnIndex: -1,
          search: false,
        }}
      />
    </Fragment>
  );
});

export default ScheduleSubTable;
