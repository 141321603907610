import React from "react";
import "./App.css";
import "fontsource-roboto";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ProtectedRoute from "./routes/ProtectedRoute";
import Sidebar from "./partials/Sidebar";
import Login from "./pages/Login";
import Booking from "./pages/Booking";
import Mechanic from "./pages/Mechanic";
import AdsSources from "./pages/AdsSources";
import OtherServices from "./pages/OtherServices";
import MainServices from "./pages/MainServices";
import Packages from "./pages/Packages";
import Services from "./pages/Services";
import ServicesCategories from "./pages/ServicesCategories";
import RequestQuotation from "./pages/RequestQuotation";
import Users from "./pages/Users";
import Vehicles from "./pages/Vehicles";
import Schedules from "./pages/Schedules";
import Garage from "./pages/Garage";
import AssignMechanic from "./pages/AssignMechanic";
import Vouchers from "./pages/Vouchers";
import Quotation from "./pages/Quotation";
import PackageCategories from "./pages/PackageCategories";
import MechanicIncentives from "./pages/MechanicIncentives";
import MechanicAttendance from "./pages/MechanicAttendance";
import MechanicIncentiveDashboard from "./pages/MechanicIncentiveDashboard";
import MechanicBookings from "./pages/MechanicBookings";

function App() {
  const Logout = (props) => {
    localStorage.clear();
    window.location = "/";
  };

 
  document.body.style.backgroundColor = "#F6F8FA";

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route component={Logout} path="/logout" />
        <Sidebar>
          <ProtectedRoute path="/bookings" component={Booking} />
          <ProtectedRoute path="/mechanics" component={Mechanic} />
          <ProtectedRoute path="/assign-mechanics" component={AssignMechanic} />
          <ProtectedRoute path="/ads-sources" component={AdsSources} />
          <ProtectedRoute
            path="/services/other-services"
            component={OtherServices}
          />
          <ProtectedRoute
            path="/services/main-services"
            component={MainServices}
          />
          <ProtectedRoute path="/services/packages" component={Packages} />
          <ProtectedRoute
            path="/services/package-categories"
            component={PackageCategories}
          />
          <ProtectedRoute path="/services/services" component={Services} />
          <ProtectedRoute
            path="/services/categories"
            component={ServicesCategories}
          />
          <ProtectedRoute
            path="/quotations/overview"
            component={RequestQuotation}
          />
          <ProtectedRoute path="/quotations/quotations" component={Quotation} />
          <ProtectedRoute path="/users" component={Users} />
          <ProtectedRoute path="/garage" component={Garage} />
          <ProtectedRoute path="/vehicles" component={Vehicles} />
          <ProtectedRoute path="/schedules" component={Schedules} />
          <ProtectedRoute path="/vouchers" component={Vouchers} />
          <ProtectedRoute path="/mechanics-incentives" component={MechanicIncentives} />
          <ProtectedRoute path="/mechanics-attendance" component={MechanicAttendance}/>
          <ProtectedRoute path="/mechanics-incentives-dashboard" component={MechanicIncentiveDashboard}/>
          <ProtectedRoute path="/mechanics-bookings/:id" component={MechanicBookings}/>
        </Sidebar>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
