import { Fragment, useState } from "react";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import AddIcon from "@material-ui/icons/Add";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { green } from "@material-ui/core/colors";
import { Formik, Form, FastField, Field, FieldArray } from "formik";
import { TextField } from "formik-material-ui";
import CustomSelectServiceCategory from "./customSelectServiceCategory/CustomSelectServiceCategory";
import CustomSelectVehicleType from "./customSelectVehicleType/CustomSelectVehicleType";
import CustomSelectFuelType from "./customSelectFuelType/CustomSelectFuelType";
import CustomSelectLiterOfOil from "./customSelectLiterOfOil/CustomSelectLiterOfOil";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { object, number, array, string } from "yup";
import AlertDialog from "../booking/AlertDialog";

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: "#FAE700",
  },
  titleText: {
    color: "rgb(63,81,181) !important",
    fontWeight: "bold !important",
  },
  button: {
    backgroundColor: green[500],
    color: "#fff",
    "&:hover": {
      backgroundColor: green[600],
    },
  },
  paper: {
    width: "750px",
    maxWidth: "750px",
  },
  add: {
    backgroundColor: green[500],
    color: "#fff",
    "&:hover": {
      backgroundColor: green[600],
    },
  },
}));

const MainServicesAdd = React.memo((props) => {
  const classes = useStyles();

  const { open, onOpen, onClose, handleSubmitMainServices } = props;

  // ==============MODAL STATES==============
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [typeAlert, setTypeAlert] = useState("success");

  const handleCloseAlert = () => {
    setAlert(false);
    setMessage("");
    setTypeAlert("success");
  };

  const handleOpen = () => {
    onOpen();
  };

  const handleClose = () => {
    onClose();
  };

  const handleCheckDuplicate = (index, name, value, lists) => {
    let vehicle_type =
      name === "vehicle_type" ? value : lists[index].vehicle_type;
    let fuel_type = name === "fuel_type" ? value : lists[index].fuel_type;
    let liter_of_oil =
      name === "liter_of_oil" ? value : lists[index].liter_of_oil;

      if(vehicle_type !== '' && fuel_type !== '' && liter_of_oil !== '') {
        var filtered = lists.filter(
          (c) =>
            c.vehicle_type === vehicle_type &&
            c.fuel_type === fuel_type &&
            c.liter_of_oil === liter_of_oil
        );
        if (filtered.length > 0) {
          setAlert(true);
          setTypeAlert("error");
          setMessage("The variant already exists.");
          return true;
        }
      }
    return false;
  };

  const handleSubmit = (values, actions) => {
    // console.log(actions);
    handleSubmitMainServices(values, actions);
  };

  const initialValues = {
    name: "",
    service_category_id: "",
    variants: [
      {
        vehicle_type: "",
        fuel_type: "",
        variant: "",
        liter_of_oil: "",
        price: "0",
      },
    ],
  };

  const validationSchema = object().shape({
    name: string().required("Manual services name is required"),
    service_category_id: string().required("Service category is required"),
    variants: array(
      object({
        fuel_type: string().required("Please fill up this field."),
        vehicle_type: string().required("Please fill up this field."),
        variant: string().nullable(true),
        liter_of_oil: number()
          .typeError("Liter of oil must be a number")
          .required("Please fill up this field.")
          .min(1, "Please input valid liter of oil."),
        price: number()
          .typeError("Price must be a number")
          .required("Please fill up this field.")
          .min(0),
      })
    ).required(),
  });

  return (
    <Fragment>
      <div>
        <AlertDialog
          open={alert}
          typeAlert={typeAlert}
          message={message}
          handleCloseAlert={handleCloseAlert}
        />
        <Box mb={2} display="flex" flexDirection="row-reverse">
          <Button
            onClick={handleOpen}
            className={classes.button}
            variant="contained"
            size="medium"
            startIcon={<AddIcon />}
          >
            Add Service
          </Button>
        </Box>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          disableBackdropClick
          classes={{ paper: classes.paper }}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              setFieldValue,
              setSubmitting,
              isSubmitting,
              handleChange,
              handleBlur,
            }) => (
              <Form autoComplete="off">
                <DialogTitle
                  id="alert-dialog-title"
                  className={`${classes.title}`}
                >
                  <span className={classes.titleText}>
                    {"ADD MAIN SERVICE FORM"}
                  </span>
                </DialogTitle>
                <DialogContent dividers>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <FastField
                        name="service_category_id"
                        label="Service Category"
                        variant="outlined"
                        size="small"
                        component={CustomSelectServiceCategory}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FastField
                        label="Service Item"
                        component={TextField}
                        name="name"
                        type="text"
                        variant="outlined"
                        size="small"
                        fullWidth
                        inputProps={{
                          style: {
                            textAlign: "center",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FieldArray name="variants">
                        {({ push, remove }) => (
                          <React.Fragment>
                            <Box
                              mb={1}
                              display="flex"
                              flexDirection="row-reverse"
                            >
                              <Box flexShrink={0}>
                                <Button
                                  variant="contained"
                                  className={classes.add}
                                  startIcon={<AddCircleIcon />}
                                  onClick={() => {
                                    push({
                                      vehicle_type: "",
                                      fuel_type: "",
                                      variant: "",
                                      liter_of_oil: "",
                                      price: "0",
                                    });
                                  }}
                                  disabled={isSubmitting}
                                >
                                  Add
                                </Button>
                              </Box>
                            </Box>
                            <TableContainer component={Paper}>
                              <Table size="small" aria-label="simple table">
                                <TableHead>
                                  <TableRow>
                                    <TableCell
                                      style={{
                                        fontWeight: "bold",
                                        width: "23%",
                                      }}
                                      align="center"
                                    >
                                      Vehicle Type
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        fontWeight: "bold",
                                        width: "23%",
                                      }}
                                      align="center"
                                    >
                                      Fuel Type
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        fontWeight: "bold",
                                        width: "23%",
                                      }}
                                      align="center"
                                    >
                                      Liter of Oil
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        fontWeight: "bold",
                                        width: "23%",
                                      }}
                                      align="center"
                                    >
                                      Price
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        fontWeight: "bold",
                                        width: "8%",
                                      }}
                                      align="center"
                                    >
                                      Action
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {values.variants.map((value, index) => (
                                    <TableRow key={index}>
                                      <TableCell
                                        align="center"
                                        scope="row"
                                        style={{ width: "23%" }}
                                      >
                                        <Field
                                          name={`variants.${index}.vehicle_type`}
                                          variant="standard"
                                          index={index}
                                          size="small"
                                          fullWidth
                                          component={CustomSelectVehicleType}
                                          onChange={async (e) => {
                                            const { value } = e.target;

                                            let result =
                                              await handleCheckDuplicate(
                                                index,
                                                "vehicle_type",
                                                value,
                                                values.variants
                                              );
                                            if (!result) {
                                              setFieldValue(
                                                `variants.${index}.vehicle_type`,
                                                value
                                              );
                                            }
                                          }}
                                        />
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        scope="row"
                                        style={{ width: "23%" }}
                                      >
                                        <Field
                                          name={`variants.${index}.fuel_type`}
                                          variant="standard"
                                          index={index}
                                          size="small"
                                          fullWidth
                                          component={CustomSelectFuelType}
                                          onChange={async (e) => {
                                            const { value } = e.target;
                                            let result =
                                              await handleCheckDuplicate(
                                                index,
                                                "fuel_type",
                                                value,
                                                values.variants
                                              );
                                            if (!result) {
                                              setFieldValue(
                                                `variants.${index}.fuel_type`,
                                                value
                                              );
                                            }
                                          }}
                                        />
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        scope="row"
                                        style={{ width: "23%" }}
                                      >
                                        <Field
                                          name={`variants.${index}.liter_of_oil`}
                                          variant="standard"
                                          index={index}
                                          size="small"
                                          fullWidth
                                          component={CustomSelectLiterOfOil}
                                          onChange={async (e) => {
                                            const { value } = e.target;
                                            let result =
                                              await handleCheckDuplicate(
                                                index,
                                                "liter_of_oil",
                                                value,
                                                values.variants
                                              );
                                            if (!result) {
                                              setFieldValue(
                                                `variants.${index}.liter_of_oil`,
                                                value
                                              );
                                            }
                                          }}
                                        />
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        scope="row"
                                        style={{ width: "23%" }}
                                      >
                                        <Field
                                          component={TextField}
                                          name={`variants.${index}.price`}
                                          type="text"
                                          variant="standard"
                                          size="small"
                                          fullWidth
                                          inputProps={{
                                            style: {
                                              textAlign: "center",
                                              textTransform: "capitalize",
                                            },
                                          }}
                                        />
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        scope="row"
                                        style={{ width: "8%" }}
                                      >
                                        <Tooltip title="Remove">
                                          <IconButton
                                            color="secondary"
                                            aria-label="remove"
                                            onClick={() => {
                                              remove(index);
                                            }}
                                            disabled={isSubmitting}
                                          >
                                            <HighlightOffIcon
                                              className={classes.largeIcon}
                                            />
                                          </IconButton>
                                        </Tooltip>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </React.Fragment>
                        )}
                      </FieldArray>
                    </Grid>
                  </Grid>
                  {/* <pre>{JSON.stringify({ values, errors }, null, 4)}</pre> */}
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} size="small" color="default">
                    Close
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    color="primary"
                    variant="contained"
                    autoFocus
                    size="small"
                    endIcon={
                      isSubmitting ? (
                        <CheckCircleIcon size="0.9rem" />
                      ) : undefined
                    }
                  >
                    {isSubmitting ? "Submitting" : "Submit"}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>
      </div>
    </Fragment>
  );
});
export default MainServicesAdd;
