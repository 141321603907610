import { Fragment, useState } from "react";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

import ListAltSharpIcon from "@material-ui/icons/ListAltSharp";
import { green } from "@material-ui/core/colors";
import { Formik, Form, FastField, Field, FieldArray } from "formik";
import { TextField } from "formik-material-ui";
import IsActive from "../../common/SelectIsActive";
import { object, number, array, string } from "yup";
import AlertDialog from "../../booking/AlertDialog";
import SelectInternalService from "../../common/SelectInternalService";
import CustomSelectBrand from "./customSelectBrand/CustomSelectBrand";
import CustomSelectLiterOfOil from "./customSelectLiterOfOil/CustomSelectLiterOfOil";
import CustomSelectFuelType from "./customSelectFuelType/CustomSelectFuelType";
import PackageCategorySelect from "../../common/PackageCategorySelect";
import SelectOdometer from "../../common/SelectOdometer";
import InclusionsTable from "../addPackage/inclusions/InclusionsTable";
import AddOnsTable from "../addOns/AddOnsTable";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    backgroundColor: "#FAE700",
    color: "#000",
  },
  button: {
    color: theme.palette.grey[900],
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  icon: {
    position: "absolute",
    top: "18px",
    marginLeft: "3px",
  },
  titleText: {
    color: "rgb(63,81,181) !important",
    fontWeight: "bold !important",
  },
  add: {
    backgroundColor: green[500],
    color: "#fff",
    "&:hover": {
      backgroundColor: green[600],
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
      timeout={{ appear: 100, enter: 500, exit: 500 }}
    />
  );
});

const PackageUpdate = React.memo((props) => {
  const scrollableListRef = React.useRef();
  const classes = useStyles();

  const { open, onClose, data, isLoading, onUpdate } = props;

  // ==============MODAL STATES==============
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [typeAlert, setTypeAlert] = useState("success");

  if (Array.isArray(data) && data.length === 0) {
    return null;
  }

  const handleCloseAlert = () => {
    setAlert(false);
    setMessage("");
    setTypeAlert("success");
  };

  const handleClose = () => {
    onClose();
  };

  const handleCheckDuplicate = (index, name, value, lists) => {
    let vehicle_type =
      name === "vehicle_type" ? value : lists[index].vehicle_type;
    let fuel_type = name === "fuel_type" ? value : lists[index].fuel_type;
    let liter_of_oil =
      name === "liter_of_oil" ? value : lists[index].liter_of_oil;
    let brand = name === "brand" ? value : lists[index].brand;
    if (vehicle_type !== "" && fuel_type !== "" && liter_of_oil !== "") {
      var filtered = lists.filter(
        (c) =>
          c.vehicle_type === vehicle_type &&
          c.fuel_type === fuel_type &&
          c.liter_of_oil === liter_of_oil &&
          c.brand === brand
      );
      if (filtered.length > 0) {
        setAlert(true);
        setTypeAlert("error");
        setMessage("The variant already exists.");
        return true;
      }
    }
    return false;
  };

  const handleSubmit = (values, actions) => {
    onUpdate(
      { ...values, package_category_id: values.package_category.id },
      actions
    );
  };

  const initialValues = {
    package_category: data && data.package_category,
    id: data && data.id,
    name: data && data.name,
    service_category_id: data && data?.service_category?.id,
    variations: data && data.variations,
    is_active: data && data.is_active,
    is_public: data && data.is_public == 1 ? 0 : "manual",
    odometer: data && data.odometer_id,
    hours_taken: data && data.hours_taken,
    guidelines: data && data.guidelines,
    description: data && data.description,
    inclusions: data && data.inclusions,
    addOns: data && data.add_ons,
  };

  const validationSchema = object().shape({
    package_category_id: object({
      id: number().nullable(true),
      is_active: string().nullable(true),
      is_public: string().nullable(true),
    }).required("Package category is required"),
    name: string().required("Package name is required"),
    service_category_id: string().required("Service category is required"),
    variations: array(
      object({
        fuel_type: string().nullable(true),
        liter_of_oil: number().nullable(true),
        price: number().test(
          "nonZero",
          "Price must NOT be a zero.",
          (value) => value !== 0
        ),
        brand: string().nullable(true),
      })
    ).required(),
    is_public: string().required("This is required"),
    is_active: number().required("Is active is required"),
  });

  return (
    <Fragment>
      <AlertDialog
        open={alert}
        typeAlert={typeAlert}
        message={message}
        handleCloseAlert={handleCloseAlert}
      />

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        ref={scrollableListRef}
        TransitionComponent={Transition}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            setFieldValue,
            setSubmitting,
            isSubmitting,
            handleChange,
            handleBlur,
          }) => (
            <Form autoComplete="off">
              <AppBar className={classes.appBar}>
                <Toolbar>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                  >
                    <CloseIcon className={classes.button} />
                  </IconButton>
                  <Typography variant="h5" className={classes.title}>
                    UPDATE PACKAGE FORM
                    <ListAltSharpIcon className={classes.icon} />
                  </Typography>
                  <Button
                    disabled={isLoading}
                    type="submit"
                    startIcon={
                      isLoading ? <CircularProgress size="0.9rem" /> : undefined
                    }
                  >
                    {isLoading ? "Saving" : "Save"}
                  </Button>
                </Toolbar>
              </AppBar>
              <DialogContent dividers>
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <FastField
                      label="Category Name"
                      component={PackageCategorySelect}
                      name="package_category"
                      type="text"
                      variant="outlined"
                      size="small"
                      fullWidth
                      inputProps={{
                        style: {
                          textAlign: "center",
                          textTransform: "capitalize",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FastField
                      label="Package Name"
                      component={TextField}
                      name="name"
                      type="text"
                      variant="outlined"
                      size="small"
                      fullWidth
                      inputProps={{
                        style: {
                          textAlign: "center",
                          textTransform: "capitalize",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FastField
                      label="Odometer"
                      component={SelectOdometer}
                      name="odometer"
                      type="text"
                      variant="outlined"
                      size="small"
                      fullWidth
                      inputProps={{
                        style: {
                          textAlign: "center",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FastField
                      label="Hours Taken"
                      component={TextField}
                      name="hours_taken"
                      type="number"
                      variant="outlined"
                      size="small"
                      fullWidth
                      inputProps={{
                        style: {
                          textAlign: "center",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FastField
                      name="is_active"
                      label="Status"
                      variant="outlined"
                      size="small"
                      component={IsActive}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FastField
                      name="is_public"
                      label="Show in Front End"
                      variant="outlined"
                      size="small"
                      component={SelectInternalService}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FastField
                      label="Description"
                      component={TextField}
                      name="description"
                      type="text"
                      variant="outlined"
                      size="small"
                      fullWidth
                      multiline
                      rows={3}
                      rowsMax={3}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FastField
                      name="guidelines"
                      label="Guidelines"
                      variant="outlined"
                      size="small"
                      component={TextField}
                      fullWidth
                      multiline
                      rows={3}
                      rowsMax={3}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InclusionsTable
                      inclusions={values.inclusions}
                      setFieldValue={setFieldValue}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <AddOnsTable
                      addOns={values.addOns}
                      setFieldValue={setFieldValue}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FieldArray name="variations">
                      {({ push, remove }) => (
                        <React.Fragment>
                          <Box
                            mb={1}
                            display="flex"
                            flexDirection="row-reverse"
                          >
                            <Box flexShrink={0}>
                              <Button
                                variant="contained"
                                className={classes.add}
                                startIcon={<AddCircleIcon />}
                                onClick={() => {
                                  push({
                                    fuel_type: "",
                                    liter_of_oil: "",
                                    price: 0,
                                    // brand: "jac",
                                  });
                                }}
                                disabled={isSubmitting}
                              >
                                Add
                              </Button>
                            </Box>
                          </Box>
                          <TableContainer component={Paper}>
                            <Table size="small" aria-label="simple table">
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    style={{
                                      fontWeight: "bold",
                                      width: "19%",
                                    }}
                                    align="center"
                                  >
                                    Fuel Type
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      fontWeight: "bold",
                                      width: "19%",
                                    }}
                                    align="center"
                                  >
                                    Liter of Oil
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      fontWeight: "bold",
                                      width: "19%",
                                    }}
                                    align="center"
                                  >
                                    Price
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      fontWeight: "bold",
                                      width: "19%",
                                    }}
                                    align="center"
                                  >
                                    Brand
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      fontWeight: "bold",
                                      width: "5%",
                                    }}
                                    align="center"
                                  >
                                    Action
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {values.variations.map((value, index) => (
                                  <TableRow key={index}>
                                    <TableCell
                                      align="center"
                                      scope="row"
                                      style={{ width: "19%" }}
                                    >
                                      <Field
                                        name={`variations.${index}.fuel_type`}
                                        variant="standard"
                                        index={index}
                                        size="small"
                                        fullWidth
                                        component={CustomSelectFuelType}
                                        onChange={async (e) => {
                                          const { value } = e.target;
                                          let result =
                                            await handleCheckDuplicate(
                                              index,
                                              "fuel_type",
                                              value,
                                              values.variations
                                            );
                                          if (!result) {
                                            setFieldValue(
                                              `variations.${index}.fuel_type`,
                                              value
                                            );
                                          }
                                        }}
                                      />
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      scope="row"
                                      style={{ width: "19%" }}
                                    >
                                      <Field
                                        name={`variations.${index}.liter_of_oil`}
                                        variant="standard"
                                        index={index}
                                        size="small"
                                        fullWidth
                                        component={CustomSelectLiterOfOil}
                                        onChange={async (e) => {
                                          const { value } = e.target;
                                          let result =
                                            await handleCheckDuplicate(
                                              index,
                                              "liter_of_oil",
                                              value,
                                              values.variations
                                            );
                                          if (!result) {
                                            setFieldValue(
                                              `variations.${index}.liter_of_oil`,
                                              value
                                            );
                                          }
                                        }}
                                      />
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      scope="row"
                                      style={{ width: "19%" }}
                                    >
                                      <Field
                                        component={TextField}
                                        name={`variations.${index}.price`}
                                        type="text"
                                        variant="standard"
                                        size="small"
                                        fullWidth
                                        inputProps={{
                                          style: {
                                            textAlign: "center",
                                            textTransform: "capitalize",
                                          },
                                        }}
                                      />
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      scope="row"
                                      style={{ width: "19%" }}
                                    >
                                      <Field
                                        name={`variations.${index}.brand`}
                                        variant="standard"
                                        index={index}
                                        size="small"
                                        fullWidth
                                        component={CustomSelectBrand}
                                        onChange={async (e) => {
                                          const { value } = e.target;

                                          let result =
                                            await handleCheckDuplicate(
                                              index,
                                              "brand",
                                              value,
                                              values.variations
                                            );
                                          if (!result) {
                                            setFieldValue(
                                              `variations.${index}.brand`,
                                              value
                                            );
                                          }
                                        }}
                                      />
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      scope="row"
                                      style={{ width: "5%" }}
                                    >
                                      <Tooltip title="Remove">
                                        <IconButton
                                          color="secondary"
                                          aria-label="remove"
                                          onClick={() => {
                                            remove(index);
                                          }}
                                          disabled={isSubmitting}
                                        >
                                          <HighlightOffIcon
                                            className={classes.largeIcon}
                                          />
                                        </IconButton>
                                      </Tooltip>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </React.Fragment>
                      )}
                    </FieldArray>
                  </Grid>
                </Grid>
              </DialogContent>
            </Form>
          )}
        </Formik>
      </Dialog>
    </Fragment>
  );
});
export default PackageUpdate;
