import React from 'react';
import { StyleSheet, PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import PDFDocument from './PdfDocument';
// External
import { isMobile, isBrowser } from 'react-device-detect';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';

const styles = StyleSheet.create({
  viewer: {
    width: '100%',
    height: '100%',
  },
  page: {
    padding: 16,
    fontSize: 9,
    textTransform: 'uppercase',
  },
  row: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginRight: -8,
    marginLeft: 4,
  },
  column: {
    flexBasis: 1,
    flexGrow: 1,
    marginRight: 8,
    marginLeft: -4,
  },
});

const useStyles = makeStyles((theme) => ({
  button: {
    padding: '5px 0px !important',
    maxWidth: '100%',
    backgroundColor: green[500],
    color: '#fff',
    '&:hover': {
      backgroundColor: green[600],
    },
    marginTop: '20px',
  },
}));

const InspectionDataPdf = ({ content }) => {
  const classes = useStyles();

  if (isBrowser) {
    return (
      <>
        <PDFViewer style={styles.viewer}>
          <PDFDocument content={content} />
        </PDFViewer>
      </>
    );
  }

  if (isMobile) {
    return (
      <>
        <PDFDownloadLink
          document={<PDFDocument content={content} />}
          fileName="inspection-data.pdf"
        >
          {({ blob, url, loading, error }) =>
            loading ? (
              'Loading...'
            ) : (
              <Button
                className={classes.button}
                fullWidth
                variant="contained"
                size="small"
              >
                Download PDF
              </Button>
            )
          }
        </PDFDownloadLink>
      </>
    );
  }
};

export default InspectionDataPdf;
