import React from "react";
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  FormControl,
  FormHelperText,
} from "@material-ui/core";

const CustomSellerType = ({ field, form, label, row, ...rest }) => {
  const { name, value } = field;
  const { setFieldValue, errors, touched } = form;
  const errorText = errors[name] && touched[name] ? errors[name] : "";

  const handleChange = (event) => {
    setFieldValue(name, event.target.value);
  };

  const options = [
    {
      label: "Car Dealership",
      value: "1",
    },
    {
      label: "Bank",
      value: "2",
    },
    {
      label: "Individual Seller",
      value: "3",
    },
  ];

  return (
    <FormControl component="fieldset" error={!!errorText}>
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup
        {...field}
        {...rest}
        value={value}
        onChange={handleChange}
        row={row}
      >
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio />}
            label={option.label}
          />
        ))}
      </RadioGroup>
      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  );
};

export default CustomSellerType;
