import React from 'react';
import { StyleSheet, View, Text } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
  },
  column: {
    flexBasis: 1,
    flexGrow: 1,
    marginLeft: 4,
  },
  blue: {
    fontFamily: 'Helvetica-Bold',
    color: '#000b8c',
  },
  label: {
    width: '84',
  },
  content: {
    flexGrow: 1,
    alignItems: 'flex-end',
  },
  line: {
    borderBottomWidth: 1,
    borderColor: '#000b8c',
    paddingBottom: 2,
    marginBottom: 4,
  },
});

const CustomerVehicleDetails = ({ content }) => {
  return (
    <>
      <View style={[styles.row, { marginLeft: -4, marginBottom: 8 }]}>
        <View style={{ marginLeft: 4, width: 130 }}>
          <View style={[styles.blue, { fontSize: 12 }]}>
            <Text>
              {content?.extended_information?.plate_number ||
                content?.plate_number}
            </Text>
            <Text>{content?.make + ' ' + content?.year}</Text>
          </View>
          <Text style={styles.blue}>
            {content?.fuel_type + ' ' + content?.transmission}
          </Text>
        </View>
        <View style={styles.column}>
          <View style={[styles.row, styles.line]}>
            <View style={[styles.blue, styles.label]}>
              <Text>VIN</Text>
            </View>
            <View style={styles.content}>
              <Text>{content?.extended_information?.vin_number}</Text>
            </View>
          </View>
          <View style={[styles.row, styles.line]}>
            <View style={[styles.blue, styles.label]}>
              <Text>Mileage</Text>
            </View>
            <View style={styles.content}>
              <Text>
                {content?.extended_information?.mileage ||
                  content?.mileage + ' KM'}
              </Text>
            </View>
          </View>
          <View style={[styles.row, styles.line, { marginBottom: 0 }]}>
            <View style={[styles.blue, styles.label]}>
              <Text>Assigned Tech</Text>
            </View>
            <View style={styles.content}>
              <Text>
                {content?.appointment_mechanics[0]?.mechanic?.firstname +
                  ' ' +
                  content?.appointment_mechanics[0]?.mechanic?.lastname}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.column}>
          <View style={[styles.row, styles.line]}>
            <View style={[styles.blue, styles.label]}>
              <Text>Customer</Text>
            </View>
            <View style={styles.content}>
              <Text>{content.name + ' ' + content.lastname}</Text>
            </View>
          </View>
          <View style={[styles.row, styles.line]}>
            <View style={[styles.blue, styles.label]}>
              <Text>Next PMS</Text>
            </View>
            <View style={styles.content}>
              <Text>
                {content?.mileage &&
                  parseFloat(content?.mileage?.replace(/,/g, '')) +
                    10000 +
                    ' KM Or '}{' '}
                6 Months
              </Text>
            </View>
          </View>
          <View style={[styles.row, styles.line, { marginBottom: 0 }]}>
            <View style={[styles.blue, styles.label]}>
              <Text>Availed Service</Text>
            </View>
            <View style={styles.content}>
              {content?.appointment_services?.map((service) => (
                <Text key={service?.service_name}>{service?.service_name}</Text>
              ))}
            </View>
          </View>
        </View>
      </View>
    </>
  );
};

export default CustomerVehicleDetails;
