import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { MenuItem, TextField } from "@material-ui/core";

const MenuItemStyle = withStyles({
  root: {
    justifyContent: "center !important",
  },
})(MenuItem);

const useStyles = makeStyles((theme) => ({
  center: {
    textAlign: "center !important",
  },
}));

const categories = [
  { value: 1, label: "Yes" },
  { value: 0, label: "No" },
];

const CustomSelectLaborOnly = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const classes = useStyles();

  return (
    <TextField
      select
      error={errors.labor_only && touched.labor_only}
      helperText={errors.labor_only && touched.labor_only && errors.labor_only}
      {...field}
      {...props}
      classes={{
        root: classes.center,
      }}
    >
      {categories.map((option) => (
        <MenuItemStyle key={option.value} value={option.value}>
          {option.label}
        </MenuItemStyle>
      ))}
    </TextField>
  );
};
export default CustomSelectLaborOnly;
