import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  ButtonGroup,
  Grow,
  ClickAwayListener,
  Popper,
  Paper,
  MenuList,
  MenuItem,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { green } from "@material-ui/core/colors";
import axios from "axios";
import config from "../../../utils/config";

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: green[500],
    color: "#fff",
    "&:hover": {
      backgroundColor: green[600],
    },
  },
}));

const AddBooking = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [packageCategories, setPackageCategories] = React.useState([]);
  const token = localStorage.getItem("token");

  React.useEffect(() => {
    async function fetchData() {
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const {
        data: { data },
      } = await axios.get(`${config.api}/package-category`, options);
      console.log("");
      setPackageCategories(data.data);
    }
    fetchData();
  }, []);

  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ButtonGroup
        variant="contained"
        color="primary"
        ref={anchorRef}
        aria-label="split button"
      >
        <Button
          onClick={handleToggle}
          className={classes.button}
          variant="contained"
          size="small"
          startIcon={<AddIcon />}
        >
          Add Booking
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: 1300 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
              maxWidth: "130px",
              borderRadius: 0,
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {packageCategories.map((category) => (
                    <MenuItem
                      key={category.id}
                      onClick={() => props.handleOpenAddBooking(category)}
                      style={{
                        fontSize: "14px",
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                      }}
                    >
                      {category.name}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default AddBooking;
