import React, { useState, useEffect } from "react";
import { Map as LeafMap, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
// Import marker icon images
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';
// Fix for marker icons not displaying correctly
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: markerIcon2x,
    iconUrl: markerIcon,
    shadowUrl: markerShadow,
});

const Map = ({ data }) => {

    const [locations, setLocations] = useState([{
        latitude: parseFloat('14.563865'),
        longitude: parseFloat('121.00914'),
        title: 'Default Location - Rapide San Antonio'
    }]);

    useEffect(() => {
        const items = []
        if (data && data.latitude_time_in && data.longitude_time_in && data.latitude_time_in !== "undefined" && data.longitude_time_in !== "undefined") {
            items.push({
                latitude: parseFloat(data.latitude_time_in),
                longitude: parseFloat(data.longitude_time_in),
                title: data?.time_in_address || `${data.latitude_time_in}-${data.longitude_time_in}`,
            });
        }
        if (data && data.latitude_time_out && data.longitude_time_out && data.latitude_time_out !== "undefined" && data.longitude_time_out !== "undefined") {
            items.push({
                latitude: parseFloat(data.latitude_time_out),
                longitude: parseFloat(data.longitude_time_out),
                title: data?.time_out_address || `${data.latitude_time_out}-${data.longitude_time_out}`,
            });
        }
        if (items?.length > 0) {
            setLocations(items)
        }
    }, [data])

    return (
        <div>
            <LeafMap center={[locations?.[0]?.latitude, locations?.[0]?.longitude]} zoom={9} style={{ height: '400px', width: '50%' }}>
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                {locations?.map((location, index) => (
                    <Marker key={index} position={[location.latitude, location.longitude]}>
                        <Popup>{location.title}</Popup>
                    </Marker>
                ))}
            </LeafMap>
        </div>
    )
}

export default Map