import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import { MenuItem, TextField } from '@material-ui/core';

// Api config
import config from '../../../utils/config';

const MenuItemStyle = withStyles({
  root: {
    justifyContent: 'center',
  },
})(MenuItem);

const CustomBarangayComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, values }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const [barangays, setBarangays] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const { data: barangays } = await axios.get(
        `${config.api}/barangay/${values.municipality}`
      );
      setBarangays(barangays);
    }
    if (values.municipality) fetchData();
  }, [props.token, values.municipality]);

  return (
    <TextField
      select
      fullWidth
      error={errors.barangay && touched.barangay}
      helperText={errors.barangay && touched.barangay ? errors.barangay : ''}
      {...field}
      {...props}
    >
      {barangays.map((option) => (
        <MenuItemStyle key={option.barangay_id} value={option.barangay_name}>
          {option.barangay_name} - {option?.zip_code?.[0]?.zip_code}
        </MenuItemStyle>
      ))}
    </TextField>
  );
};
export default CustomBarangayComponent;
