import { Fragment, useCallback, useState, useRef } from "react";
import axios from "axios";
import AlertDialog from "../components/alertDialog/AlertDialog";
import ScheduleAdd from "../components/scheduleAdd/ScheduleAdd";
import ScheduleTable from "../components/scheduleTable/ScheduleTable";
import { Box } from "@material-ui/core";
import SelectServiceLocation from "../components/common/SelectServiceLocation";

// Api config
import config from "../utils/config";

const Users = (props) => {
  const { token } = props;
  const [hubId, setHubId] = useState(1);

  const tableRef = useRef();

  // ==============MODAL STATES==============
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    typeAlert: "success",
  });

  //   ==============ADD Schedule STATES==============
  const [openAdd, setOpenAdd] = useState(false);

  const handleOpenAdd = useCallback(() => {
    setOpenAdd(true);
  }, []);

  const handleCloseAdd = useCallback(() => {
    setOpenAdd(false);
  }, []);

  const handleSubmitSchedule = useCallback(
    (values, actions) => {
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      axios
        .post(`${config.api}/schedules`, values, options)
        .then((response) => {
          setOpenAdd(false);
          setAlert({
            ...alert,
            open: true,
            message: "Successfully Confirmed",
            typeAlert: "success",
          });
          actions.setSubmitting(false);
          tableRef.current && tableRef.current.onQueryChange();
        })
        .catch((error) => {
          actions.setSubmitting(false);
          setAlert({
            ...alert,
            open: true,
            message: "Something went wrong please try again.",
            typeAlert: "error",
          });
        });
    },
    [token, alert]
  );

  // Alert Dialog
  const handleCloseAlert = useCallback(
    (event, reason) => {
      if (reason === "clickaway") {
        return;
      }

      setAlert({
        ...alert,
        open: false,
        message: "",
      });
    },
    [alert]
  );

  const handleChange = (value) => {
    setHubId(value);
    tableRef.current.onQueryChange();
  };

  return (
    <Fragment>
      {/* Alert Dialog */}
      <AlertDialog
        open={alert.open}
        typeAlert={alert.typeAlert}
        message={alert.message}
        handleCloseAlert={handleCloseAlert}
      />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems={"center"}
        style={{
          background: "#fff",
          borderRadius: "5px",
          margin: "1rem 0",
          padding: "1rem",
          boxShadow:
            "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
        }}
      >
        <Box style={{ width: "20%" }}>
          <SelectServiceLocation
            handleLocationChange={handleChange}
            serviceLocation={hubId}
            isId={true}
          />
        </Box>
        <ScheduleAdd
          open={openAdd}
          onOpen={handleOpenAdd}
          onClose={handleCloseAdd}
          handleSubmitSchedule={handleSubmitSchedule}
        />
      </Box>
      <ScheduleTable tableRef={tableRef} token={token} hubId={hubId} />
    </Fragment>
  );
};

export default Users;
