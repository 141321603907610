import { useState, useRef } from "react";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { TextField } from "@material-ui/core";
import { useFormikContext, useField } from "formik";

const DatePickerWrapper = ({ name, ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);
  const [open, setOpen] = useState(false);
  const anchorElRef = useRef();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        variant="inline"
        inputVariant="outlined"
        size="small"
        fullWidth
        autoOk
        disableToolbar
        format="MM/dd/yyyy"
        {...field}
        {...props}
        error={meta.touched && Boolean(meta.error)}
        helperText={meta.touched && meta.error}
        open={open}
        onChange={(evt) => {
          setFieldValue(name, evt);
          setOpen(false);
        }}
        InputProps={{
          inputProps: {
            style: { textAlign: "center" },
          },
        }}
        FormHelperTextProps={{
          style: {
            textAlign: "center",
          },
        }}
        PopoverProps={{
          disableRestoreFocus: true,
          onClose: () => {
            setOpen(false);
          },
          anchorEl: () => anchorElRef.current,
        }}
        KeyboardButtonProps={{ disabled: true, style: { display: "none" } }}
        TextFieldComponent={(params) => (
          <TextField
            {...params}
            onFocusCapture={() => {
              setOpen(true);
            }}
            ref={anchorElRef}
          />
        )}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DatePickerWrapper;
