import React, { Fragment, useState, useCallback } from 'react';
import MaterialTable from 'material-table';
import axios from "axios";
// mui
import { makeStyles } from '@material-ui/core/styles';
import { green, yellow } from '@material-ui/core/colors';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import moment from 'moment';
// api config
import config from '../../utils/config';

import AlertDialog from "../alertDialog/AlertDialog";

const useStyles = makeStyles((theme) => ({
    view: {
        color: yellow[500],
        "&:hover": {
            color: "#fff",
        },
    },
    update: {
        "&:hover": {
            backgroundColor: "rgba(255, 253, 231,1.0)",
        },
    },
    dashboard: {
        color: green[500],
        '&:hover': {
            backgroundColor: 'rgba(232, 245, 233,1.0)',
        },
    },
    sizeSmall: {
        height: '18px',
        fontSize: '12px',
    },
    root: {
        textAlign: "center !important",
    },
}));

const Overtimes = (props) => {
    const { tableRef, token } = props;
    const classes = useStyles();

    const [loading, setLoading] = useState(false);

    // ==============MODAL STATES==============
    const [alert, setAlert] = useState({
        open: false,
        message: "",
        typeAlert: "success",
    });

    // Alert Dialog
    const handleCloseAlert = useCallback(
        (event, reason) => {
            if (reason === "clickaway") {
                return;
            }

            setAlert({
                ...alert,
                open: false,
                message: "",
            });
        },
        [alert]
    );

    const handleChange = useCallback((data, event) => {
        try {
            setLoading(true);
            const payload = data;
            payload.status = event.target.value;
            axios.put(`${config.api}/mechanics/${data?.mechanic_id}/overtimes/${data?.id}`, payload, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            setLoading(false);
            setAlert({
                ...alert,
                open: true,
                message: "Successfully added",
                typeAlert: "success",
            });
            tableRef.current && tableRef.current.onQueryChange();
        } catch (error) {
            setLoading(false);
            setAlert({
                ...alert,
                open: true,
                message: "Something went wrong please try again.",
                typeAlert: "error",
            });
        }
    }, [tableRef, token])
    

    return (
        <Fragment>
            {/* Alert Dialog */}
            <AlertDialog
                open={alert.open}
                typeAlert={alert.typeAlert}
                message={alert.message}
                handleCloseAlert={handleCloseAlert}
            />
            <MaterialTable
                title={`Mechanics Overtimes`}
                tableRef={tableRef}
                columns={[
                    {
                        title: 'Date',
                        render: (row) => {
                            return (
                                moment(row?.datetime_from).format('ddd, MMM Do, hh:mm a')
                            );
                        },
                    },
                    {
                        title: 'Mechanic',
                        render: (row) => {
                            return (
                                <p>{`${row.mechanic.firstname}  ${row.mechanic.lastname}`}</p>
                            );
                        },
                    },
                    {
                        title: 'Time From',
                        render: (row) => {
                            return (
                                moment(row?.datetime_from).format('hh:mm a')
                            );
                        },
                    },
                    {
                        title: 'Time To',
                        render: (row) => {
                            return (
                                moment(row?.datetime_to).format('hh:mm a')
                            );
                        },
                    },
                    {
                        title: 'Reason',
                        field: 'reason',
                    },
                    {
                        title: 'Status',
                        render: (row) => {
                            return (
                                <FormControl fullWidth>
                                    <Select
                                        id="simple-select"
                                        onChange={(event) => handleChange(row, event)}
                                        value={row.status}
                                        classes={{
                                            root: classes.root,
                                        }}
                                    >
                                        <MenuItem value="Pending">
                                            Pending
                                        </MenuItem>
                                        <MenuItem value="Approved">
                                            Approved
                                        </MenuItem>
                                        <MenuItem value="Rejected">
                                            Rejected
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            )
                        }
                    }
                ]}

                data={(query) =>
                    new Promise((resolve, reject) => {
                        let url = config.api + '/mechanics/overtimes?';
                        url += `page=${query.page + 1}`;
                        if (query.pageSize) {
                            url += `&per_page=${query.pageSize}`;
                        }
                        if (query.search) {
                            url += `&search=${query.search}`;
                        }
                        const options = {
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${token}`,
                            },
                        };
                        fetch(url, options)
                            .then((response) => response.json())
                            .then((result) => {
                                resolve({
                                    data: result?.data?.data,
                                    page: result?.data?.current_page - 1 || 0,
                                    totalCount: result?.data?.total,
                                });
                            })
                    })
                }
                
                options={{
                    headerStyle: {
                        fontWeight: 'bold',
                    },
                    rowStyle: {
                        fontSize: '.75rem',
                        padding: '0px !important',
                    },
                    actionsCellStyle: {
                        justifyContent: 'center',
                        padding: '24px',
                        marginBottom: '-1px',
                    },
                    pageSize: 30,
                    pageSizeOptions: [],
                    actionsColumnIndex: -1,
                    tableLayout: 'auto',
                    sorting: false,
                }}
            />
        </Fragment>
    )
}

export default Overtimes;