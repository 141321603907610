import { Fragment } from "react";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { green } from "@material-ui/core/colors";
import { Formik, Form, FastField } from "formik";
import { object, string } from "yup";
import { TextField } from "formik-material-ui";

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: "#FAE700",
  },
  titleText: {
    color: "rgb(63,81,181) !important",
    fontWeight: "bold !important",
  },
  button: {
    backgroundColor: green[500],
    color: "#fff",
    "&:hover": {
      backgroundColor: green[600],
    },
  },
  paper: { width: "550px", maxWidth: "550px" },
}));

const RequestUpdateRemarks = React.memo((props) => {
  const classes = useStyles();

  const { open, data, onClose, onRemarks } = props;

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = (values) => {
    onRemarks(values);
  };

  const initialValues = {
    id: data.id,
    name: data.name,
    email: data.email,
    phone_number: data.phone_number,
    address: data.address,
    make: data.make,
    model: data.model,
    year: data.year,
    engine_size: data.engine_size,
    transmission: data.transmission,
    fuel_type: data.fuel_type,
    plate_number: data.plate_number,
    comment: data.comment,
    services: data.services,
    created_at: data.created_at,
    updated_at: data.updated_at,
    status: data.status,
    remarks: data.remarks ? data.remarks : "",
  };

  const validationSchema = object().shape({
    remarks: string().nullable(),
  });

  return (
    <Fragment>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          disableBackdropClick
          classes={{ paper: classes.paper }}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, isSubmitting }) => (
              <Form autoComplete="off">
                <DialogTitle
                  id="alert-dialog-title"
                  className={`${classes.title}`}
                >
                  <span className={classes.titleText}>UPDATE REMARKS</span>
                </DialogTitle>
                <DialogContent dividers>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <FastField
                        label="Remarks"
                        component={TextField}
                        name="remarks"
                        type="text"
                        variant="outlined"
                        size="small"
                        fullWidth
                        multiline
                        rows={4}
                        maxrows={4}
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} size="small" color="default">
                    Close
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    color="primary"
                    variant="contained"
                    autoFocus
                    size="small"
                    endIcon={
                      isSubmitting ? (
                        <CheckCircleIcon size="0.9rem" />
                      ) : undefined
                    }
                  >
                    {isSubmitting ? "Submitting" : "Submit"}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>
      </div>
    </Fragment>
  );
});
export default RequestUpdateRemarks;
