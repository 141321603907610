import React, {
  useEffect,
  useState,
  Fragment,
  useRef,
  useCallback,
} from "react";
import "date-fns";
import axios from "axios";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  IconButton,
  Typography,
  Slide,
  Toolbar,
  AppBar,
  Divider,
  Dialog,
  Button,
  Box,
  Paper,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  TextField as TextFieldMUI,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Tooltip from "@material-ui/core/Tooltip";
import CloseIcon from "@material-ui/icons/Close";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ListAltSharpIcon from "@material-ui/icons/ListAltSharp";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { TextField } from "formik-material-ui";
import { green } from "@material-ui/core/colors";
import { Formik, Form, Field, FastField, FieldArray } from "formik";
import { object, number, array, string } from "yup";
import CustomProvinceComponent from "../../booking/addBooking/CustomProvinceComponent";
import CustomMunicipalityComponent from "../../booking/addBooking/CustomMunicipalityComponent";
import CustomBarangayComponent from "../../booking/addBooking/CustomBarangayComponent";
import CustomMakeComponent from "../../booking/addBooking/CustomMakeComponent";
import CustomModelComponent from "../../booking/addBooking/CustomModelComponent";
import CustomYearComponent from "../../booking/addBooking/CustomYearComponent";
import CustomTransmissionComponent from "../../booking/addBooking/CustomTransmissionComponent";
import CustomFuelComponent from "../../booking/addBooking/CustomFuelComponent";
import CustomServiceFeeComponent from "../../booking/addBooking/CustomServiceFeeComponent";
import CustomServiceFee2Component from "../../booking/addBooking/CustomServiceFee2Component";
import CustomTotalComponentUpdate from "../../booking/addBooking/CustomTotalComponentUpdate";
import CustomOtherServicesTotalPrice from "../../booking/addBooking/CustomOtherServicesTotalPrice";
import CustomDiscountComponent from "../../booking/addBooking/CustomDiscountComponent";
import CustomPartsAutocompleteComponent from "../../booking/addBooking/CustomPartsAutocompleteComponent";
import CustomPartsTotalPrice from "../../booking/addBooking/CustomPartsTotalPrice";
import CustomTiresAutocompleteComponent from "../../booking/addBooking/CustomTiresAutocompleteComponent";
import AlertDialog from "../../alertDialog/AlertDialog";

// Api config
import config from "../../../utils/config";
import CustomTiresTotalPrice from "../../booking/addBooking/CustomTiresTotalPrice";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  inputMultiple: {
    minWidth: "250px",
    height: "45px",
    border: "1.5px solid #ddd",
    borderRadius: "5px",
  },
  paper: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(4),
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  button: {
    color: theme.palette.grey[900],
  },
  house: {
    marginTop: "8px",
  },
  icon: {
    position: "absolute",
    top: "18px",
    marginLeft: "3px",
  },
  titleText2: {
    fontWeight: "bold",
    marginBottom: "20px",
  },
  listItem: {
    listStyleType: "none",
    margin: "0px !important",
  },
  liItem: {
    marginBottom: "3px",
  },
  label: {
    fontWeight: "bold",
    fontSize: "14px !important",
    minWidth: "160px",
    lineHeight: "2.5",
    letterSpacing: "1.2px",
  },
  data: {
    fontSize: "14px !important",
    fontWeight: "normal",
  },
  label2: {
    fontWeight: "bold",
    fontSize: "14px !important",
    minWidth: "160px",
    lineHeight: "5.0",
    letterSpacing: "1.2px",
  },
  appBar: {
    position: "relative",
    backgroundColor: "#FAE700",
    color: "#000",
  },
  gridCustom: {
    textAlign: "center !important",
  },
  gridCustom2: {
    maxWidth: "7.0% !important",
  },
  gridCustom3: {
    maxWidth: "52.5% !important",
  },
  addressDisabled: {
    minWidth: "499px !important",
  },
  remarks: {
    minWidth: "715px !important",
  },
  asterisk: {
    color: "red !important",
    fontSize: "1.3rem",
    lineHeight: "0 !important",
  },
  asterisk2: {
    position: "relative",
    bottom: "5px",
    left: "2px",
    color: "red !important",
    fontSize: "0.8rem !important",
  },
  disabledRoot: {
    "& .MuiOutlinedInput-input": {
      color: "#000",
    },
  },
  largeIcon: {
    width: "30px !important",
    height: "30px !important",
  },
  add: {
    backgroundColor: green[500],
    color: "#fff",
    "&:hover": {
      backgroundColor: green[600],
    },
  },
  inputCustomField: {
    width: "500px !important",
  },
}));

const useStyles2 = makeStyles((theme) => ({
  input: {
    textAlign: "center !important",
  },
  root: {
    overflowX: "unset",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
      timeout={{ appear: 100, enter: 500, exit: 500 }}
    />
  );
});

const emptyPackage = {
  id: "",
  name: "",
  quantity: 1,
  price: 0.0,
  total: 0.0,
  type: "",
};
const emptyServices = {
  id: "",
  name: "",
  remarks: "",
  quantity: 1,
  price: 0.0,
  totalPrice: 0,
  type: "manual",
};

const emptyParts = {
  id: "",
  part_number: "",
  name: "",
  remarks: "",
  brand_id: null,
  category_id: null,
  quantity: 1,
  price: 0.0,
  totalPrice: 0,
};

export const emptyTires = {
  sku: "",
  quantity: 1,
  price: 0.0,
  totalPrice: 0,
};

const numberConverter = (number) => {
  let value = Number(parseFloat(number)).toLocaleString("en", {
    minimumFractionDigits: 2,
  });
  return value;
};

const AddQuotationForm = (props) => {
  const { open, isLoading, onClose, data, handleSubmitQuotation } = props;

  const token = localStorage.getItem("token");

  const [initialValues, setInitialValues] = useState({
    id: "",
    address: "",
    name: "",
    lastname: "",
    email: "",
    contact_number: "",
    province: "",
    municipality: "",
    barangay: "",
    zip_code: "",
    landmarks: "",
    customer_type: "",
    service_fee: 0.0,
    coverAddress: "cover",
    distance_in_km: "",
    subTotal: "",
    total: "",
    make: "",
    model: "",
    year: "",
    parking_space: "",
    plate_number: "",
    transmission: "",
    mileage: "",
    fuel_type: "",
    liter_of_oil: "",
    engine_size: "",
    servicesList: [],
    services: [],
    tires: [],
    manualServicesList: [],
    manualServices: [],
    discounts: 0,
    ap_date: "",
    ap_time: "",
    remarks: "",
    models: [],
    payment_method: "",
    provinces: [],
    municipalities: [],
    barangays: [],
  });

  // const { values: setFieldValue } = useFormikContext();

  // ==============MODAL STATES==============
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    typeAlert: "success",
  });

  const formRef = useRef();
  const classes = useStyles();
  const classes2 = useStyles2();

  // Alert Dialog
  const handleCloseAlert = useCallback(
    (event, reason) => {
      if (reason === "clickaway") {
        return;
      }

      setAlert({
        ...alert,
        open: false,
        message: "",
      });
    },
    [alert]
  );

  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const fetchDataModel = (brand) => {
    return new Promise((resolve, reject) => {
      let request;
      request = axios.get(
        config.api + "/vehicles/search?make=" + brand,
        options
      );
      resolve(request);
    });
  };

  const fetchDataLiter = (brandModel, fuelType) => {
    return new Promise((resolve, reject) => {
      let request;
      request = axios.get(
        `${config.api}/vehicles/search?model=${brandModel}&fuel_type=${fuelType}`,
        options
      );
      resolve(request);
    });
  };

  const fetchDataServices = (make, model, liter_of_oil = 0, fuel_type) => {
    return new Promise((resolve, reject) => {
      let request;
      request = axios.get(
        `${config.api}/services?make=${make}&model=${model}&liter_of_oil=${liter_of_oil}&fuel_type=${fuel_type}&is_public=all`,
        options
      );
      resolve(request);
    });
  };

  const handleSubmitCustom = (values) => {
    let mileage = "";
    if (values.mileage) {
      const stringWithoutCommas = values.mileage.replace(/,/g, "");
      // Convert the string to an integer
      mileage = parseInt(stringWithoutCommas, 10);
    }
    handleSubmitQuotation({ ...values, mileage });
  };

  const validationSchema = object().shape({
    name: string().required("First name is required"),
    lastname: string().required("Last name is required"),
    email: string().nullable(),
    landmarks: string().nullable(),
    make: string().required("Make is required."),
    model: string().required("Model is required."),
    year: string().nullable(),
    plate_number: string().nullable(),
    transmission: string().nullable(),
    mileage: string().typeError("Mileage must be valid.").nullable(),
    fuel_type: string().required("Fuel type is required."),
    liter_of_oil: string().required("Liter of oil is required."),
    engine_size: number().typeError("Engine size must be valid.").nullable(),
    province: string().nullable(),
    municipality: string().nullable(),
    barangay: string().nullable(),
    services: array(
      object({
        id: number().nullable(true),
        name: string().required("Please fill up this field."),
        quantity: number()
          .required("Quantity is required.")
          .min(1, "Please input at least 1 item."),
        price: string().nullable(true),
        total: string().nullable(true),
        type: string().nullable(true),
      })
    ).nullable(),
    manualServices: array(
      object({
        id: number().nullable(true),
        name: string().required("Please fill up this field."),
        remarks: string().nullable(true),
        quantity: number()
          .required("Quantity is required.")
          .min(1, "Please input at least 1 item."),
        price: number()
          .nullable(true)
          .transform((value, originalValue) => {
            // Handle the case when the value is "-"
            const parsedValue = Number(originalValue);
            return isNaN(parsedValue) ? undefined : parsedValue;
          })
          .when("$price", (price, schema) => {
            return price
              ? schema.positive("Price must be a positive number")
              : schema;
          }),
        totalPrice: number().required(""),
        type: string().required(),
      })
    ).nullable(),
    remarks: string().nullable(),
  });

  useEffect(() => {
    // this is the packages
    const servicesManual =
      data?.appointment_services
        ?.filter(({ type }) => type === "manual")
        ?.map(
          ({ service_id, service_name, remarks, quantity, price, total }) => ({
            id: service_id,
            name: service_name,
            remarks,
            quantity,
            price,
            totalPrice: total,
            type: "manual",
          })
        ) || [];

    // this is the services
    const servicesNotManual =
      data?.appointment_services
        ?.filter(({ type }) => !type)
        ?.map(({ service_id, package_id, service_name, quantity, price }) => ({
          id: service_id ?? package_id,
          name: service_name,
          quantity,
          price,
          totalPrice: price,
          type: "",
        })) || [];

    if (Object.keys(data).length > 0) {
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      let manualListInitial = [];
      let servicesList = [];
      let models = [];

      async function fetchData() {
        const request3 = await axios.get(
          `${config.api}/vehicles/search?make=${data.make}`,
          options
        );
        let items3 = request3.data.data;
        items3.forEach((item3) => {
          let data1 = {
            name: item3.name,
          };
          models.push(data1);
        });

        // ========Services========
        const request = await axios.get(
          `${config.api}/services/all?type=manual&limit=10000`,
          options
        );
        let items = request.data.data.data;

        items.forEach((item) => {
          let data = {
            id: item.id,
            name: item.name,
            quantity: 0,
            price: 0,
            totalPrice: 0,
            type: "manual",
          };
          manualListInitial.push(data);
        });
        const request2 = await axios.get(
          `${config.api}/services?make=${data.make}&model=${data.model}&liter_of_oil=${data.liter_of_oil}&fuel_type=${data.fuel_type}&is_public=all`,
          options
        );
        let items2 = Array.isArray(request2.data.data)
          ? request2.data.data
          : [];

        items2.forEach((item2) => {
          if (item2.packages && Array.isArray(item2.packages)) {
            item2.packages.forEach((service) => {
              if (service && service.type !== "manual" && service?.variants) {
                servicesList.push({
                  id: service.id,
                  name: service.name,
                  quantity: 0,
                  price: service.variants.price,
                  total: service.variants.price,
                  type: "",
                });
              }
            });
          }
        });
      }
      fetchData();

      setInitialValues({
        id: data.id,
        name: data.name || "",
        lastname: data.lastname || "",
        email: data.email || "",
        contact_number: data.phone_number || "",
        address: data.address || "",
        province: data.province,
        municipality: data.municipality,
        barangay: data.barangay,
        zip_code: data.zip_code,
        landmarks: data.landmarks || "",
        service_fee: data.service_fee || 0.0,
        coverAddress: "cover",
        subTotal: data.sub_total || "",
        total: data.total || "",
        make: data.make,
        model: data.model,
        year: data.year || "",
        plate_number: data.plate_number || "",
        transmission: data.transmission || "",
        mileage: data.mileage || "",
        fuel_type: data.fuel_type || "",
        liter_of_oil: data.liter_of_oil || "",
        engine_size: data.engine_size || "",
        servicesList: servicesList,
        services: servicesNotManual,
        parts: data.service_parts || [],
        tires: data.service_tires || [],
        manualServicesList: manualListInitial,
        manualServices: servicesManual,
        discounts: data.discount,
        remarks: data.remarks,
        models,
      });
    }
  }, [data, open, onClose, token]);

  return (
    <Fragment>
      <div>
        <AlertDialog
          open={alert.open}
          typeAlert={alert.typeAlert}
          message={alert.message}
          handleCloseAlert={handleCloseAlert}
        />
        <Dialog
          fullScreen
          open={open}
          onClose={() => {
            onClose();
          }}
          TransitionComponent={Transition}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            innerRef={formRef}
            onSubmit={handleSubmitCustom}
          >
            {({ values, errors, setFieldValue }) => (
              <Form autoComplete="off">
                <AppBar className={classes.appBar}>
                  <Toolbar>
                    <IconButton
                      edge="start"
                      color="inherit"
                      onClick={onClose}
                      aria-label="close"
                    >
                      <CloseIcon className={classes.button} />
                    </IconButton>
                    <Typography variant="h5" className={classes.title}>
                      Create Quotation
                      <ListAltSharpIcon className={classes.icon} />
                    </Typography>
                    <Button
                      disabled={isLoading}
                      type="submit"
                      startIcon={
                        isLoading ? (
                          <CircularProgress size="0.9rem" />
                        ) : undefined
                      }
                    >
                      {isLoading ? "Saving" : "Save"}
                    </Button>
                  </Toolbar>
                </AppBar>
                <MuiDialogContent dividers>
                  <div className={classes.root}>
                    <Paper className={classes.paper}>
                      <Typography variant="h6" className={classes.titleText2}>
                        CLIENT INFO
                      </Typography>
                      <Grid container spacing={3}>
                        <Grid item xs={3} className={classes.gridCustom}>
                          <FastField
                            label="First Name"
                            name="name"
                            fullWidth
                            type="text"
                            variant="outlined"
                            component={TextField}
                            size="small"
                            inputProps={{
                              style: {
                                textAlign: "center",
                                textTransform: "capitalize",
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={3} className={classes.gridCustom}>
                          <FastField
                            fullWidth
                            label="Last Name"
                            name="lastname"
                            type="text"
                            variant="outlined"
                            component={TextField}
                            size="small"
                            inputProps={{
                              style: {
                                textAlign: "center",
                                textTransform: "capitalize",
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={3} className={classes.gridCustom}>
                          <FastField
                            fullWidth
                            label="Email"
                            name="email"
                            variant="outlined"
                            component={TextField}
                            size="small"
                            InputProps={{
                              classes: {
                                root: classes.disabledRoot,
                              },
                            }}
                            inputProps={{ style: { textAlign: "center" } }}
                          />
                        </Grid>
                        <Grid item xs={3} className={classes.gridCustom}>
                          <FastField
                            fullWidth
                            label="Contact Number"
                            name="contact_number"
                            variant="outlined"
                            component={TextField}
                            size="small"
                            inputProps={{ style: { textAlign: "center" } }}
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={3}>
                        <>
                          <Grid item xs={3} className={classes.house}>
                            <FastField
                              fullWidth
                              name="address"
                              component={TextField}
                              label="House #, Street"
                              size="small"
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item xs={3} className={classes.house}>
                            <FastField
                              fullWidth
                              label="Province"
                              component={CustomProvinceComponent}
                              name="province"
                              type="text"
                              variant="outlined"
                              size="small"
                              onChange={async (e) => {
                                setFieldValue("province", e.target.value);
                                setFieldValue("municipality", "");
                                setFieldValue("barangay", "");
                                setInitialValues({
                                  ...initialValues,
                                  province: e.target.value,
                                  municipality: "",
                                  barangay: "",
                                });
                              }}
                            />
                          </Grid>
                          <Grid item xs={3} className={classes.house}>
                            <Field
                              fullWidth
                              label="Municipality/City"
                              component={CustomMunicipalityComponent}
                              name="municipality"
                              type="text"
                              variant="outlined"
                              size="small"
                              onChange={async (e) => {
                                setFieldValue("municipality", e.target.value);
                                setFieldValue("barangay", "");
                              }}
                              // inputProps={{ style: { textAlign: 'center' } }}
                            />
                          </Grid>
                          <Grid item xs={3} className={classes.house}>
                            <Field
                              fullWidth
                              label="Barangay/Zip Code"
                              component={CustomBarangayComponent}
                              name="barangay"
                              type="text"
                              variant="outlined"
                              size="small"
                              onChange={async (e) => {
                                setFieldValue("barangay", e.target.value);
                                const {
                                  data: { fee_amount },
                                } = await axios.get(
                                  `${config.api}/home-service-fee/${values.province}/${values.municipality}/${e.target.value}`
                                );
                                const { data: address } = await axios.get(
                                  `${config.api}/addressByName?province=${values.province}&municipality=${values.municipality}&barangay=${e.target.value}`
                                );
                                // setFieldValue("service_fee", fee_amount);
                                setFieldValue(
                                  "zip_code",
                                  address?.municipality?.[0]?.barangay?.[0]
                                    ?.zip_code?.[0]?.zip_code
                                );
                              }}
                            />
                          </Grid>
                        </>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={8} className={classes.house}>
                          <FastField
                            fullWidth
                            name="landmarks"
                            component={TextField}
                            label="Exact Address"
                            size="small"
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                      <Box my={2}>
                        <Divider />
                      </Box>
                      <Typography variant="h6" className={classes.titleText2}>
                        VEHICLE INFO
                      </Typography>
                      <Grid container direction="row" spacing={3}>
                        <Grid item xs={3} className={classes.gridCustom}>
                          <FastField
                            name="make"
                            label="Make"
                            fullWidth
                            variant="outlined"
                            size="small"
                            component={CustomMakeComponent}
                            onChange={async (e) => {
                              const { value } = e.target;
                              setFieldValue("make", value);
                              setFieldValue("model", "");
                              setFieldValue("liter_of_oil", "");
                              setFieldValue("servicesList", []);
                              setFieldValue("services", []);
                              const _models = await fetchDataModel(value);
                              setFieldValue("models", _models.data.data);
                            }}
                          />
                        </Grid>
                        <Grid item xs={3} className={classes.gridCustom}>
                          <Field
                            name="model"
                            label="Model"
                            fullWidth
                            variant="outlined"
                            size="small"
                            component={CustomModelComponent}
                            onChange={async (e) => {
                              const { value } = e.target;
                              setFieldValue("model", value);
                              setFieldValue("liter_of_oil", "");
                              setFieldValue("servicesList", []);
                              setFieldValue("services", []);

                              let brandModel = `${values.make} ${value}`;

                              const _literVal = await fetchDataLiter(
                                brandModel,
                                values.fuel_type
                              );
                              let liter_of_oil = _literVal.data.data;
                              let optionsItems = [];

                              if (liter_of_oil) {
                                setFieldValue(
                                  "liter_of_oil",
                                  liter_of_oil.liter_of_oil
                                );

                                const _services = await fetchDataServices(
                                  values.make,
                                  value,
                                  liter_of_oil.liter_of_oil,
                                  values.fuel_type
                                );
                                let items = _services.data.data;
                                if (Array.isArray(items) && items.length > 0) {
                                  items.forEach((item) => {
                                    if (
                                      item.packages &&
                                      Array.isArray(item.packages)
                                    ) {
                                      item.packages.forEach((service) => {
                                        if (service?.variants) {
                                          optionsItems.push(service);
                                        }
                                      });
                                    }
                                  });
                                }
                              } else {
                                setFieldValue("liter_of_oil", "");
                              }

                              let optionsItemsFinal = optionsItems
                                .filter((service) => service.type !== "manual")
                                .map((item) => {
                                  return {
                                    id: item.id,
                                    name: item.name,
                                    quantity: 0,
                                    price: item.variants.price,
                                    total: item.variants.price,
                                    type: "",
                                  };
                                });
                              setFieldValue("servicesList", optionsItemsFinal);
                            }}
                          />
                        </Grid>
                        <Grid item xs={3} className={classes.gridCustom}>
                          <FastField
                            name="year"
                            label="Year"
                            variant="outlined"
                            size="small"
                            component={CustomYearComponent}
                          />
                        </Grid>
                        <Grid item xs={3} className={classes.gridCustom}>
                          <FastField
                            fullWidth
                            name="plate_number"
                            label="Plate Number"
                            variant="outlined"
                            size="small"
                            component={TextField}
                            inputProps={{
                              style: {
                                textAlign: "center",
                                textTransform: "uppercase",
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={3} className={classes.gridCustom}>
                          <FastField
                            name="transmission"
                            label="Transmission"
                            variant="outlined"
                            size="small"
                            component={CustomTransmissionComponent}
                            inputProps={{
                              style: {
                                textAlign: "center",
                                textTransform: "capitalize",
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={3} className={classes.gridCustom}>
                          <FastField
                            fullWidth
                            name="mileage"
                            label="Mileage"
                            variant="outlined"
                            size="small"
                            component={TextField}
                            onChange={(evt) => {
                              const { value } = evt.target;
                              const inputValue = value.replace(/[^0-9]/g, "");
                              const formattedValue =
                                Number(inputValue).toLocaleString();
                              setFieldValue(`mileage`, formattedValue);
                            }}
                            inputProps={{
                              style: {
                                textAlign: "center",
                                textTransform: "capitalize",
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={3} className={classes.gridCustom}>
                          <Field
                            name="fuel_type"
                            label="Fuel Type"
                            variant="outlined"
                            size="small"
                            component={CustomFuelComponent}
                            fullWidth
                            onChange={async (e) => {
                              const { value } = e.target;
                              setFieldValue("fuel_type", value);
                              setFieldValue("liter_of_oil", "");
                              setFieldValue("servicesList", []);
                              setFieldValue("services", []);

                              let brandModel = `${values.make} ${values.model}`;
                              const _literVal = await fetchDataLiter(
                                brandModel,
                                values.make,
                                values.model,
                                value
                              );
                              let liter_of_oil = _literVal.data.data;
                              let optionsItems = [];

                              if (liter_of_oil) {
                                setFieldValue(
                                  "liter_of_oil",
                                  liter_of_oil.liter_of_oil
                                );
                                const _services = await fetchDataServices(
                                  values.make,
                                  values.model,
                                  liter_of_oil.liter_of_oil,
                                  value
                                );
                                let items = _services.data.data;
                                if (Array.isArray(items) && items.length > 0) {
                                  items.forEach((item) => {
                                    if (
                                      item.packages &&
                                      Array.isArray(item.packages)
                                    ) {
                                      item.packages.forEach((service) => {
                                        if (service?.variants) {
                                          optionsItems.push(service);
                                        }
                                      });
                                    }
                                  });
                                }
                              } else {
                                setFieldValue("liter_of_oil", "");
                              }

                              let optionsItemsFinal = optionsItems
                                .filter((service) => service.type !== "manual")
                                .map((item) => {
                                  return {
                                    id: item.id,
                                    name: item.name,
                                    quantity: 0,
                                    price: item.variants.price,
                                    total: item.variants.price,
                                    type: "",
                                  };
                                });
                              setFieldValue("servicesList", optionsItemsFinal);
                            }}
                          />
                        </Grid>
                        <Grid item xs={3} className={classes.gridCustom}>
                          <FastField
                            fullWidth
                            name="liter_of_oil"
                            label="Liter of Oil"
                            variant="outlined"
                            size="small"
                            component={TextField}
                            inputProps={{
                              readOnly: "readOnly",
                              style: { textAlign: "center" },
                            }}
                          />
                        </Grid>
                        <Grid item xs={3} className={classes.gridCustom}>
                          <FastField
                            fullWidth
                            name="engine_size"
                            label="Engine Size"
                            variant="outlined"
                            size="small"
                            onChange={(evt) => {
                              const { value } = evt.target;
                              const inputValue = value.replace(/[^0-9.]/g, "");
                              let splitValue = inputValue
                                .split(".")
                                .slice(0, 4);
                              let finalValue = "";

                              if (splitValue.length === 1) {
                                const joined = splitValue.join("");
                                const lengthOfString = joined.length;
                                finalValue = joined;
                                if (joined > 20) {
                                  finalValue = `${joined}.0`;
                                }
                                if (lengthOfString === 3) {
                                  const firstTwoCharacters = joined.substring(
                                    0,
                                    2
                                  );
                                  const thirdCharacter = joined.charAt(2);
                                  finalValue = `${firstTwoCharacters}.${thirdCharacter}`;
                                }
                                if (lengthOfString === 2) {
                                  const firstCharacter = joined.substring(0, 1);
                                  const secondCharacter = joined.charAt(1);
                                  finalValue = `${firstCharacter}.${secondCharacter}`;
                                }
                              } else if (splitValue.length === 2) {
                                const joined = splitValue.join("");
                                const lengthOfString = joined.length;
                                finalValue = joined;
                                if (lengthOfString === 3) {
                                  const firstTwoCharacters = joined.substring(
                                    0,
                                    2
                                  );
                                  const thirdCharacter = joined.charAt(2);
                                  if (firstTwoCharacters > 20) {
                                    const firstCharacter = joined.substring(
                                      0,
                                      1
                                    );
                                    const secondCharacter = joined.charAt(1);
                                    finalValue = `${firstCharacter}.${secondCharacter}`;
                                  } else {
                                    finalValue = `${firstTwoCharacters}.${thirdCharacter}`;
                                  }
                                } else if (lengthOfString === 4) {
                                  const firstTwoCharacters = joined.substring(
                                    0,
                                    2
                                  );
                                  const thirdAndFourthCharacters =
                                    joined.charAt(2);
                                  finalValue = `${firstTwoCharacters}.${thirdAndFourthCharacters}`;
                                }
                              }
                              setFieldValue("engine_size", finalValue);
                            }}
                            component={TextField}
                            inputProps={{
                              style: {
                                textAlign: "center",
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Box my={4}>
                        <Divider />
                      </Box>
                      <FieldArray name="services">
                        {({ push, remove }) => (
                          <React.Fragment>
                            <Box display="flex">
                              <Box p={1} width="100%">
                                <Typography
                                  variant="h6"
                                  className={classes.titleText2}
                                >
                                  PACKAGES
                                </Typography>
                              </Box>
                              <Box p={1} flexShrink={0}>
                                <Button
                                  variant="contained"
                                  className={classes.add}
                                  startIcon={<AddCircleIcon />}
                                  onClick={() => {
                                    push(emptyPackage);
                                  }}
                                >
                                  Add Package
                                </Button>
                              </Box>
                            </Box>
                            <Grid
                              container
                              direction="row"
                              justify="space-evenly"
                              alignItems="center"
                              spacing={3}
                            >
                              <Grid item xs={10} className={classes.gridCustom}>
                                <TableContainer component={Paper}>
                                  <Table size="small" aria-label="simple table">
                                    <TableHead>
                                      <TableRow>
                                        <TableCell
                                          style={{
                                            fontWeight: "bold",
                                            width: "5%",
                                          }}
                                          align="center"
                                        >
                                          ACTIONS
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            fontWeight: "bold",
                                            width: "75%",
                                          }}
                                          align="center"
                                        >
                                          SERVICE ITEMS
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            fontWeight: "bold",
                                            width: "10%",
                                          }}
                                        >
                                          QUANTITY
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            fontWeight: "bold",
                                            width: "10%",
                                          }}
                                        >
                                          TOTAL PRICE
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {values.services.map((value, index) => (
                                        <TableRow key={index}>
                                          <TableCell
                                            align="center"
                                            scope="row"
                                            style={{ width: "5%" }}
                                          >
                                            <Tooltip title="Remove">
                                              <IconButton
                                                color="secondary"
                                                aria-label="remove"
                                                onClick={() => {
                                                  remove(index);
                                                }}
                                              >
                                                <HighlightOffIcon
                                                  className={classes.largeIcon}
                                                />
                                              </IconButton>
                                            </Tooltip>
                                          </TableCell>
                                          <TableCell
                                            scope="row"
                                            style={{ width: "75%" }}
                                          >
                                            <Autocomplete
                                              getOptionLabel={(option) =>
                                                option.name
                                              }
                                              disableClearable
                                              size="small"
                                              classes={classes2}
                                              value={values.services[index]}
                                              options={[
                                                ...values.services,
                                                ...values.servicesList,
                                              ].filter(
                                                (v, i, a) =>
                                                  a.findIndex(
                                                    (t) => t.id === v.id
                                                  ) === i
                                              )}
                                              filterSelectedOptions
                                              onChange={(event, newValue) => {
                                                if (newValue) {
                                                  var serviceCollection =
                                                    values.services;
                                                  var filtered =
                                                    serviceCollection.filter(
                                                      (c) =>
                                                        c.id === newValue.id
                                                    );

                                                  if (filtered.length === 0) {
                                                    setFieldValue(
                                                      `services.${index}`,
                                                      {
                                                        ...newValue,
                                                        quantity: 1,
                                                      }
                                                    );
                                                  } else {
                                                    setAlert({
                                                      ...alert,
                                                      open: true,
                                                      message:
                                                        "The service already exists.",
                                                      typeAlert: "error",
                                                    });
                                                  }
                                                }
                                              }}
                                              renderInput={(params) => (
                                                <TextFieldMUI
                                                  error={
                                                    errors.services &&
                                                    typeof errors.services[
                                                      index
                                                    ] != "undefined"
                                                      ? true
                                                      : false
                                                  }
                                                  helperText={
                                                    errors.services &&
                                                    typeof errors.services[
                                                      index
                                                    ] != "undefined"
                                                      ? errors.services[index]
                                                          .name
                                                      : ""
                                                  }
                                                  variant="standard"
                                                  name={`services.${index}.name`}
                                                  fullWidth
                                                  {...params}
                                                />
                                              )}
                                            />
                                          </TableCell>
                                          <TableCell
                                            scope="row"
                                            style={{ width: "10%" }}
                                          >
                                            <Field
                                              name={`services.${index}.quantity`}
                                              fullWidth
                                              variant="standard"
                                              size="small"
                                              component={TextField}
                                              inputProps={{
                                                style: {
                                                  textAlign: "center",
                                                },
                                              }}
                                            />
                                          </TableCell>
                                          <TableCell
                                            scope="row"
                                            style={{ width: "10%" }}
                                          >
                                            ₱
                                            {values.services[index]
                                              ? numberConverter(
                                                  values.services[index].price
                                                )
                                              : "0.00"}
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                      {values.services.length <= 0 &&
                                      errors.services === undefined ? (
                                        <TableRow>
                                          <TableCell
                                            scope="row"
                                            colSpan={3}
                                            align="center"
                                            style={{
                                              color: "#ccc",
                                              fontStyle: "italic",
                                            }}
                                          >
                                            No package selected.
                                          </TableCell>
                                        </TableRow>
                                      ) : null}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Grid>
                            </Grid>
                          </React.Fragment>
                        )}
                      </FieldArray>
                      <Box my={4}>
                        <Divider />
                      </Box>
                      <FieldArray name="manualServices">
                        {({ push, remove }) => (
                          <React.Fragment>
                            <Box display="flex">
                              <Box p={1} width="100%">
                                <Typography
                                  variant="h6"
                                  className={classes.titleText2}
                                >
                                  SERVICES
                                </Typography>
                              </Box>
                              <Box p={1} flexShrink={0}>
                                <Button
                                  variant="contained"
                                  className={classes.add}
                                  startIcon={<AddCircleIcon />}
                                  onClick={() => {
                                    push(emptyServices);
                                  }}
                                >
                                  Add Service
                                </Button>
                              </Box>
                            </Box>
                            <Grid
                              container
                              direction="row"
                              justify="space-evenly"
                              alignItems="center"
                              spacing={3}
                            >
                              <Grid item xs={10} className={classes.gridCustom}>
                                <TableContainer component={Paper}>
                                  <Table size="small" aria-label="simple table">
                                    <TableHead>
                                      <TableRow>
                                        <TableCell
                                          style={{
                                            fontWeight: "bold",
                                            width: "5%",
                                          }}
                                          align="center"
                                        >
                                          ACTIONS
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            fontWeight: "bold",
                                            width: "45%",
                                          }}
                                          align="center"
                                        >
                                          SERVICE ITEMS
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            fontWeight: "bold",
                                            width: "20%",
                                          }}
                                          align="center"
                                        >
                                          REMARKS
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            fontWeight: "bold",
                                            width: "20%",
                                          }}
                                          align="center"
                                        >
                                          QUANTITY
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            fontWeight: "bold",
                                            width: "20%",
                                          }}
                                          align="center"
                                        >
                                          PRICE
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            fontWeight: "bold",
                                            width: "10%",
                                          }}
                                        >
                                          TOTAL PRICE
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {values.manualServices.map(
                                        (value, index) => (
                                          <TableRow key={index}>
                                            <TableCell
                                              align="center"
                                              scope="row"
                                              style={{ width: "5%" }}
                                            >
                                              <Tooltip title="Remove">
                                                <IconButton
                                                  color="secondary"
                                                  aria-label="remove"
                                                  onClick={() => {
                                                    remove(index);
                                                  }}
                                                >
                                                  <HighlightOffIcon
                                                    className={
                                                      classes.largeIcon
                                                    }
                                                  />
                                                </IconButton>
                                              </Tooltip>
                                            </TableCell>
                                            <TableCell
                                              scope="row"
                                              style={{ width: "45%" }}
                                            >
                                              <Autocomplete
                                                getOptionLabel={(option) =>
                                                  option.name
                                                }
                                                disableClearable
                                                size="small"
                                                classes={classes2}
                                                value={
                                                  values.manualServices[index]
                                                }
                                                options={[
                                                  ...values.manualServices,
                                                  ...values.manualServicesList,
                                                ].filter(
                                                  (v, i, a) =>
                                                    a.findIndex(
                                                      (t) => t.name === v.name
                                                    ) === i
                                                )}
                                                filterSelectedOptions
                                                onChange={(event, newValue) => {
                                                  if (newValue) {
                                                    var serviceCollection =
                                                      values.manualServices;
                                                    var filtered =
                                                      serviceCollection.filter(
                                                        (c) =>
                                                          c.name ===
                                                          newValue.name
                                                      );

                                                    if (filtered.length === 0) {
                                                      setFieldValue(
                                                        `manualServices.${index}`,
                                                        {
                                                          ...newValue,
                                                          quantity: 1,
                                                        }
                                                      );
                                                    } else {
                                                      setAlert({
                                                        ...alert,
                                                        open: true,
                                                        message:
                                                          "The service already exists.",
                                                        typeAlert: "error",
                                                      });
                                                    }
                                                  }
                                                }}
                                                renderInput={(params) => (
                                                  <TextFieldMUI
                                                    error={
                                                      errors.manualServices &&
                                                      typeof errors
                                                        .manualServices[
                                                        index
                                                      ] != "undefined"
                                                        ? true
                                                        : false
                                                    }
                                                    helperText={
                                                      errors.manualServices &&
                                                      typeof errors
                                                        .manualServices[
                                                        index
                                                      ] != "undefined"
                                                        ? errors.manualServices[
                                                            index
                                                          ].name
                                                        : ""
                                                    }
                                                    variant="standard"
                                                    name={`manualServices.${index}.name`}
                                                    fullWidth
                                                    {...params}
                                                  />
                                                )}
                                              />
                                            </TableCell>
                                            <TableCell
                                              scope="row"
                                              style={{ width: "20%" }}
                                            >
                                              <Field
                                                name={`manualServices.${index}.remarks`}
                                                fullWidth
                                                variant="standard"
                                                size="small"
                                                component={TextField}
                                                inputProps={{
                                                  style: {
                                                    textAlign: "center",
                                                  },
                                                }}
                                              />
                                            </TableCell>
                                            <TableCell
                                              scope="row"
                                              style={{ width: "20%" }}
                                            >
                                              <Field
                                                name={`manualServices.${index}.quantity`}
                                                fullWidth
                                                variant="standard"
                                                size="small"
                                                component={TextField}
                                                inputProps={{
                                                  style: {
                                                    textAlign: "center",
                                                  },
                                                }}
                                              />
                                            </TableCell>
                                            <TableCell
                                              scope="row"
                                              style={{ width: "20%" }}
                                            >
                                              <Field
                                                name={`manualServices.${index}.price`}
                                                fullWidth
                                                variant="standard"
                                                size="small"
                                                component={TextField}
                                                inputProps={{
                                                  style: {
                                                    textAlign: "center",
                                                  },
                                                }}
                                              />
                                            </TableCell>
                                            <TableCell
                                              scope="row"
                                              style={{ width: "10%" }}
                                            >
                                              <Field
                                                name={`manualServices.${index}.totalPrice`}
                                                component={
                                                  CustomOtherServicesTotalPrice
                                                }
                                                index={index}
                                              />
                                            </TableCell>
                                          </TableRow>
                                        )
                                      )}
                                      {values.manualServices.length <= 0 ? (
                                        <TableRow>
                                          <TableCell
                                            scope="row"
                                            colSpan={5}
                                            align="center"
                                            style={{
                                              color: "#ccc",
                                              fontStyle: "italic",
                                            }}
                                          >
                                            No services selected.
                                          </TableCell>
                                        </TableRow>
                                      ) : null}
                                      {typeof errors.manualServices ===
                                      "string" ? (
                                        <TableRow>
                                          <TableCell
                                            scope="row"
                                            colSpan={5}
                                            align="center"
                                            style={{
                                              color: "#ccc",
                                              fontStyle: "italic",
                                            }}
                                          >
                                            No services selected.
                                          </TableCell>
                                        </TableRow>
                                      ) : null}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Grid>
                            </Grid>
                          </React.Fragment>
                        )}
                      </FieldArray>
                      <Box my={4}>
                        <Divider />
                      </Box>
                      <FieldArray name="parts">
                        {({ push, remove }) => (
                          <React.Fragment>
                            <Box display="flex">
                              <Box p={1} width="100%">
                                <Typography
                                  variant="h6"
                                  className={classes.titleText2}
                                >
                                  PARTS
                                </Typography>
                              </Box>
                              <Box p={1} flexShrink={0}>
                                <Button
                                  variant="contained"
                                  className={classes.add}
                                  startIcon={<AddCircleIcon />}
                                  onClick={() => {
                                    push(emptyParts);
                                  }}
                                >
                                  Add Parts
                                </Button>
                              </Box>
                            </Box>
                            <Grid
                              container
                              direction="row"
                              justify="space-evenly"
                              alignItems="center"
                              spacing={3}
                            >
                              <Grid item xs={10} className={classes.gridCustom}>
                                <TableContainer
                                  component={Paper}
                                  className={classes2.root}
                                >
                                  <Table size="small" aria-label="simple table">
                                    <TableHead>
                                      <TableRow>
                                        <TableCell
                                          style={{
                                            fontWeight: "bold",
                                            width: "5%",
                                          }}
                                          align="center"
                                        >
                                          ACTIONS
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            fontWeight: "bold",
                                            width: "45%",
                                          }}
                                          align="center"
                                        >
                                          PART ITEMS
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            fontWeight: "bold",
                                            width: "20%",
                                          }}
                                          align="center"
                                        >
                                          REMARKS
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            fontWeight: "bold",
                                            width: "20%",
                                          }}
                                          align="center"
                                        >
                                          QUANTITY
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            fontWeight: "bold",
                                            width: "20%",
                                          }}
                                          align="center"
                                        >
                                          PRICE
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            fontWeight: "bold",
                                            width: "10%",
                                          }}
                                        >
                                          TOTAL PRICE
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {values.parts.map((value, index) => (
                                        <TableRow key={index}>
                                          <TableCell
                                            align="center"
                                            scope="row"
                                            style={{ width: "5%" }}
                                          >
                                            <Tooltip title="Remove">
                                              <IconButton
                                                color="secondary"
                                                aria-label="remove"
                                                onClick={() => {
                                                  remove(index);
                                                }}
                                              >
                                                <HighlightOffIcon
                                                  className={classes.largeIcon}
                                                />
                                              </IconButton>
                                            </Tooltip>
                                          </TableCell>
                                          <TableCell
                                            scope="row"
                                            style={{ width: "45%" }}
                                          >
                                            <CustomPartsAutocompleteComponent
                                              key={index}
                                              index={index}
                                              values={values}
                                              setFieldValue={setFieldValue}
                                              errors={errors}
                                              classes2={classes2}
                                              setAlert={setAlert}
                                              alert={alert}
                                            />
                                          </TableCell>
                                          <TableCell
                                            scope="row"
                                            style={{ width: "20%" }}
                                          >
                                            <Field
                                              name={`parts.${index}.remarks`}
                                              fullWidth
                                              variant="standard"
                                              size="small"
                                              component={TextField}
                                              inputProps={{
                                                style: {
                                                  textAlign: "center",
                                                },
                                              }}
                                            />
                                          </TableCell>
                                          <TableCell
                                            scope="row"
                                            style={{ width: "20%" }}
                                          >
                                            <Field
                                              name={`parts.${index}.quantity`}
                                              fullWidth
                                              variant="standard"
                                              size="small"
                                              component={TextField}
                                              inputProps={{
                                                style: {
                                                  textAlign: "center",
                                                },
                                              }}
                                            />
                                          </TableCell>
                                          <TableCell
                                            scope="row"
                                            style={{ width: "20%" }}
                                          >
                                            <Field
                                              name={`parts.${index}.price`}
                                              fullWidth
                                              variant="standard"
                                              size="small"
                                              component={TextField}
                                              inputProps={{
                                                style: {
                                                  textAlign: "center",
                                                },
                                              }}
                                            />
                                          </TableCell>
                                          <TableCell
                                            scope="row"
                                            style={{ width: "10%" }}
                                          >
                                            <Field
                                              name={`parts.${index}.totalPrice`}
                                              component={CustomPartsTotalPrice}
                                              index={index}
                                            />
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                      {values.parts.length <= 0 ? (
                                        <TableRow>
                                          <TableCell
                                            scope="row"
                                            colSpan={5}
                                            align="center"
                                            style={{
                                              color: "#ccc",
                                              fontStyle: "italic",
                                            }}
                                          >
                                            No parts selected.
                                          </TableCell>
                                        </TableRow>
                                      ) : null}
                                      {typeof errors.parts === "string" ? (
                                        <TableRow>
                                          <TableCell
                                            scope="row"
                                            colSpan={5}
                                            align="center"
                                            style={{
                                              color: "#ccc",
                                              fontStyle: "italic",
                                            }}
                                          >
                                            No parts selected.
                                          </TableCell>
                                        </TableRow>
                                      ) : null}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Grid>
                            </Grid>
                          </React.Fragment>
                        )}
                      </FieldArray>
                      <Box my={4}>
                        <Divider />
                      </Box>
                      <FieldArray name="tires">
                        {({ push, remove }) => (
                          <React.Fragment>
                            <Box display="flex">
                              <Box p={1} width="100%">
                                <Typography
                                  variant="h6"
                                  className={classes.titleText2}
                                >
                                  TIRES
                                </Typography>
                              </Box>
                              <Box p={1} flexShrink={0}>
                                <Button
                                  variant="contained"
                                  className={classes.add}
                                  startIcon={<AddCircleIcon />}
                                  onClick={() => {
                                    push(emptyTires);
                                  }}
                                >
                                  Add Tires
                                </Button>
                              </Box>
                            </Box>
                            <Grid
                              container
                              direction="row"
                              justify="space-evenly"
                              alignItems="center"
                              spacing={3}
                            >
                              <Grid item xs={10} className={classes.gridCustom}>
                                <TableContainer
                                  component={Paper}
                                  className={classes2.root}
                                >
                                  <Table size="small" aria-label="simple table">
                                    <TableHead>
                                      <TableRow>
                                        <TableCell
                                          style={{
                                            fontWeight: "bold",
                                            width: "5%",
                                          }}
                                          align="center"
                                        >
                                          ACTIONS
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            fontWeight: "bold",
                                            width: "45%",
                                          }}
                                          align="center"
                                        >
                                          TIRE ITEMS
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            fontWeight: "bold",
                                            width: "20%",
                                          }}
                                          align="center"
                                        >
                                          QUANTITY
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            fontWeight: "bold",
                                            width: "20%",
                                          }}
                                          align="center"
                                        >
                                          PRICE
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            fontWeight: "bold",
                                            width: "10%",
                                          }}
                                        >
                                          TOTAL PRICE
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {values.tires.map((value, index) => (
                                        <TableRow key={index}>
                                          <TableCell
                                            align="center"
                                            scope="row"
                                            style={{ width: "5%" }}
                                          >
                                            <Tooltip title="Remove">
                                              <IconButton
                                                color="secondary"
                                                aria-label="remove"
                                                onClick={() => {
                                                  remove(index);
                                                }}
                                              >
                                                <HighlightOffIcon
                                                  className={classes.largeIcon}
                                                />
                                              </IconButton>
                                            </Tooltip>
                                          </TableCell>
                                          <TableCell
                                            scope="row"
                                            style={{ width: "45%" }}
                                          >
                                            <CustomTiresAutocompleteComponent
                                              key={index}
                                              index={index}
                                              values={values}
                                              setFieldValue={setFieldValue}
                                              errors={errors}
                                              classes2={classes2}
                                              setAlert={setAlert}
                                              alert={alert}
                                            />
                                          </TableCell>
                                          <TableCell
                                            scope="row"
                                            style={{ width: "20%" }}
                                          >
                                            <Field
                                              name={`tires.${index}.quantity`}
                                              fullWidth
                                              variant="standard"
                                              size="small"
                                              component={TextField}
                                              inputProps={{
                                                style: {
                                                  textAlign: "center",
                                                },
                                              }}
                                            />
                                          </TableCell>
                                          <TableCell
                                            scope="row"
                                            style={{ width: "20%" }}
                                          >
                                            <Field
                                              name={`tires.${index}.price`}
                                              fullWidth
                                              variant="standard"
                                              size="small"
                                              component={TextField}
                                              inputProps={{
                                                style: {
                                                  textAlign: "center",
                                                },
                                              }}
                                            />
                                          </TableCell>
                                          <TableCell
                                            scope="row"
                                            style={{ width: "10%" }}
                                          >
                                            <Field
                                              name={`tires.${index}.totalPrice`}
                                              component={CustomTiresTotalPrice}
                                              index={index}
                                            />
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                      {values.tires.length <= 0 ? (
                                        <TableRow>
                                          <TableCell
                                            scope="row"
                                            colSpan={5}
                                            align="center"
                                            style={{
                                              color: "#ccc",
                                              fontStyle: "italic",
                                            }}
                                          >
                                            No tires selected.
                                          </TableCell>
                                        </TableRow>
                                      ) : null}
                                      {typeof errors.tires === "string" ? (
                                        <TableRow>
                                          <TableCell
                                            scope="row"
                                            colSpan={5}
                                            align="center"
                                            style={{
                                              color: "#ccc",
                                              fontStyle: "italic",
                                            }}
                                          >
                                            No tires selected.
                                          </TableCell>
                                        </TableRow>
                                      ) : null}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Grid>
                            </Grid>
                          </React.Fragment>
                        )}
                      </FieldArray>
                      <Box mt={3}>
                        <Grid
                          container
                          direction="row"
                          justify="space-evenly"
                          alignItems="center"
                          spacing={3}
                        >
                          <Grid item xs={10} className={classes.gridCustom}>
                            <TableContainer component={Paper}>
                              <Table size="small" aria-label="simple table">
                                <TableHead>
                                  <TableRow></TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow>
                                    <TableCell
                                      scope="row"
                                      colSpan={4}
                                      style={{
                                        fontWeight: "bold",
                                        width: "90%",
                                      }}
                                      align="right"
                                    >
                                      Convenience Fee :
                                    </TableCell>
                                    <TableCell
                                      align="left"
                                      style={{ width: "10%" }}
                                    >
                                      <Field
                                        name="service_fee"
                                        component={CustomServiceFeeComponent}
                                        token={token}
                                      />
                                      <Field
                                        name="service_fee"
                                        component={CustomServiceFee2Component}
                                      />
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      scope="row"
                                      colSpan={4}
                                      style={{
                                        fontWeight: "bold",
                                        width: "90%",
                                      }}
                                      align="right"
                                    >
                                      Discount :
                                    </TableCell>
                                    <TableCell
                                      align="left"
                                      style={{ width: "10%" }}
                                    >
                                      {/* ₱{numberConverter(values.discount)} */}
                                      <FastField
                                        name="discounts"
                                        component={CustomDiscountComponent}
                                      />
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      scope="row"
                                      colSpan={4}
                                      style={{
                                        fontWeight: "bold",
                                        width: "90%",
                                      }}
                                      align="right"
                                    >
                                      Total Amount :
                                    </TableCell>
                                    <TableCell
                                      align="left"
                                      style={{ width: "10%" }}
                                    >
                                      ₱
                                      <Field
                                        name="total"
                                        component={CustomTotalComponentUpdate}
                                      />
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        </Grid>
                      </Box>
                    </Paper>
                  </div>
                </MuiDialogContent>
              </Form>
            )}
          </Formik>
        </Dialog>
      </div>
    </Fragment>
  );
};

export default AddQuotationForm;
