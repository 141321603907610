import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
// form
import { Formik, Form, FastField, Field, FieldArray } from "formik";
import { TextField } from "formik-material-ui";
import { object, number, array, string } from "yup";
// components
import SelectInternalService from "../../common/SelectInternalService";

const useStyles = makeStyles((theme) => ({
  container: { display: "flex", marginBottom: "1rem", justifyContent: "end" },
  title: {
    backgroundColor: "#FAE700",
  },
  titleText: {
    color: "rgb(63,81,181) !important",
    fontWeight: "bold !important",
  },
  button: {
    backgroundColor: green[500],
    color: "#fff",
    "&:hover": {
      backgroundColor: green[600],
    },
  },
  paper: {
    width: "450px",
    // maxWidth: "750px",
  },
  add: {
    backgroundColor: green[500],
    color: "#fff",
    "&:hover": {
      backgroundColor: green[600],
    },
  },
}));

const PackageCategoryForm = (props) => {
  const {
    open,
    onOpen,
    onClose,
    handleSubmitForm,
    action = "add",
    data = {},
  } = props;

  const classes = useStyles();

  const initialValues = {
    name: data?.name || "",
    is_active: data?.is_active || 1,
    is_public: data && data.is_public == 1 ? 0 : "manual",
  };

  const validationSchema = object().shape({
    name: string().required("Package Category name is required"),
    is_public: string().required("This is required"),
  });

  const handleSubmit = (values, actions) => {
    if (action === "edit") values.id = data.id;
    const data = { ...values, is_public: values.is_public === 0 ? 1 : 0 };
    handleSubmitForm({ ...data }, actions);
  };

  const handleClose = () => {
    onClose();
  };
  return (
    <>
      {action === "add" && (
        <div className={classes.container}>
          <Button
            className={classes.button}
            variant="contained"
            size="medium"
            startIcon={<AddIcon />}
            onClick={() => onOpen()}
          >
            Add
          </Button>
        </div>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.paper }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form autoComplete="off">
              <DialogTitle
                id="alert-dialog-title"
                className={`${classes.title}`}
              >
                <span className={classes.titleText}>
                  {`${
                    action === "add" ? "ADD" : action === "edit" ? "EDIT" : ""
                  } PACKAGE CATEGORY FORM`}
                </span>
              </DialogTitle>
              <DialogContent dividers>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FastField
                      label="Package Category Name"
                      component={TextField}
                      name="name"
                      type="text"
                      variant="outlined"
                      size="small"
                      fullWidth
                      inputProps={{
                        style: {
                          textAlign: "center",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FastField
                      name="is_public"
                      label="Show in Front End"
                      variant="outlined"
                      size="small"
                      component={SelectInternalService}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} size="small" color="default">
                  Close
                </Button>
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  color="primary"
                  variant="contained"
                  autoFocus
                  size="small"
                  endIcon={
                    isSubmitting ? <CheckCircleIcon size="0.9rem" /> : undefined
                  }
                >
                  {isSubmitting ? "Submitting" : "Submit"}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default PackageCategoryForm;
