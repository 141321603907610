import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { MenuItem, TextField } from '@material-ui/core';

const MenuItemStyle = withStyles({
  root: {
    justifyContent: 'center',
  },
})(MenuItem);

const CustomTransmissionComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const transmissions = [
    { value: 'Manual', label: 'Manual' },
    { value: 'Automatic', label: 'Automatic' },
    { value: 'CVT', label: 'CVT' },
  ];
  return (
    <TextField
      select
      fullWidth
      error={errors.transmission && touched.transmission}
      helperText={errors && errors.transmission}
      {...field}
      {...props}
    >
      {transmissions.map((option) => (
        <MenuItemStyle key={option.value} value={option.value}>
          {option.value}
        </MenuItemStyle>
      ))}
    </TextField>
  );
};
export default CustomTransmissionComponent;
