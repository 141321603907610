import React, { useEffect, useState } from 'react';

const numberConverter = (number) => {
  let value = Number(parseFloat(number)).toLocaleString('en', {
                  minimumFractionDigits: 2
               });
  return value;
}


const CustomOtherServicesTotalPrice = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, values, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {

  useEffect(() => {

    if (values.manualServices.length > 0) {
      let totalPrice = 0;

      let quantityRaw = values.manualServices[props.index].quantity;
      let priceRaw = values.manualServices[props.index].price;
      let quantity = quantityRaw > 0 && quantityRaw !== '' ? quantityRaw : 0;
      let price = priceRaw > 0 && priceRaw !== '' ? priceRaw : 0;

      if (price > 0 && quantity > 0) {
        totalPrice = parseInt(quantity) * parseFloat(price);
      }

      setFieldValue(`manualServices.${props.index}.totalPrice`, totalPrice);
    }

  }, [values])

  return (
      <React.Fragment>
        <input {...props} {...field} style={{display : 'none'}} />
        <span>₱{field.value ? numberConverter(+field.value) : '0.00'}</span>
      </React.Fragment>
    )
};
export default CustomOtherServicesTotalPrice;
