import React, { Fragment } from 'react';
import MaterialTable from 'material-table';
// mui
import { Chip } from "@material-ui/core";
import moment from 'moment';
import { numberWithCommas } from "../../utils/accounting";
import { paymentStatusTypes } from "../../utils/constant";
// api config
import config from '../../utils/config';

const MechanicBookings = (props) => {
    const { tableRef, token, mechanicId, month, year } = props;

    let startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
    let endOfMonth   = moment().endOf('month').format('YYYY-MM-DD');

    if (month && year) {
        // Parse the given month and year
        const parsedDate = moment(`${month} ${year}`, 'MMMM YYYY');
        // Format the date to YYYY-MM-DD
        const formattedDate = parsedDate.format('YYYY-MM-DD');
        startOfMonth = moment(formattedDate).startOf('month').format('YYYY-MM-DD');
        endOfMonth = moment(formattedDate).endOf('month').format('YYYY-MM-DD');
    }

    return (
        <Fragment>
            <MaterialTable
                title={`Mechanics Bookings - As of ${month} ${year}`}
                tableRef={tableRef}
                columns={[
                    { title: "Billing Statement No.", field: "bs_number" },
                    {
                        title: "Appointment Date & Time",
                        field: "appointment_date",
                        render: (client) => {
                            return (
                                <div>
                                    <p>
                                        {moment(client.appointment_date).format("MM-DD-YY hh:mm A")}
                                    </p>
                                    {client.reschedule_reason && (
                                        <Chip
                                            size="small"
                                            label="Rescheduled"
                                            style={{
                                                color: "#fff",
                                                padding: "p-2",
                                                backgroundColor: "#8064A2",
                                            }}
                                        />
                                    )}
                                    <p></p>
                                </div>
                            );
                        },
                    },
                    {
                        title: "Customer Name",
                        field: "name",
                        render: (client) => {
                            let name;
                            typeof client.name !== "string"
                                ? (name = "")
                                : (name = client.name + " " + client.lastname);
                            return (
                                <span style={{ textTransform: "capitalize" }}>{name}</span>
                            );
                        },
                    },
                    {
                        title: "Vehicle",
                        field: "make",
                        render: (client) => {
                            return (
                                <span>{`${client.make} ${client.model} ${client.year}`}</span>
                            );
                        },
                    },
                    {
                        title: "Type",
                        field: "customer_type",
                        render: (client) => (
                            <>
                                <span style={{ textTransform: "capitalize" }}>
                                    {client.lead_source}
                                </span>
                                <span style={{ textTransform: "capitalize" }}>
                                    {`${client.transaction_type
                                            ? ` | ${client.transaction_type}`
                                            : ""
                                        }`}
                                </span>
                            </>
                        ),
                    },
                    { title: "Service Location", field: `service_location` },
                    {
                        title: "Mechanic/s",
                        sorting: false,
                        render: (client) => {
                            let mechanics = client.appointment_mechanics.map((item) => {
                                return (
                                    <p style={{ margin: "2px" }} key={item.id}>
                                        {item.mechanic.firstname} {item.mechanic.lastname}
                                    </p>
                                );
                            });
                            return <>{mechanics}</>;
                        },
                    },
                    {
                        title: "Invoice Amount",
                        sorting: false,
                        render: (client) => {
                            const subTotal = parseFloat(client.sub_total);
                            const serviceFee = parseFloat(client.service_fee);
                            const discount = parseFloat(client.discount);
                            let invoiceAmount = subTotal + serviceFee - discount;
                            if (client.appointment_status_id === 6) {
                                // compute total reservation/downpayment paid
                                const totalPaymentsMade =
                                    client?.payment?.length > 0
                                        ? client.payment
                                            .filter((p) => p.payment_type !== null) // Use lowercase `null` for the value check
                                            .reduce(
                                                (total, payment) =>
                                                    total + parseFloat(payment.amount_paid),
                                                0
                                            )
                                        : 0;
                                // Check if the appointment was dispatched
                                const isDispatched = client.appointment_history.find(
                                    (history) => history.appointment_status === "Dispatched"
                                );

                                // Add service fee if dispatched
                                invoiceAmount =
                                    totalPaymentsMade + (isDispatched ? serviceFee : 0);
                            }
                            const paymentInfo =
                                paymentStatusTypes[client.payment_status.name];
                            return (
                                <div style={{ textAlign: "center" }}>
                                    <p style={{ marginBottom: "0" }}>
                                        ₱{numberWithCommas(invoiceAmount)}
                                    </p>
                                    <p style={{ marginTop: "0" }}>
                                        <Chip
                                            size="small"
                                            label={paymentInfo.text}
                                            style={{
                                                color: "#fff",
                                                padding: "p-2",
                                                backgroundColor: paymentInfo.color,
                                            }}
                                        />
                                    </p>
                                </div>
                            );
                        },
                    },
                    {
                        title: "Status",
                        field: `appointment_status_id`,
                        render: (client) => {
                            return (
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <span
                                        style={{
                                            color: "#161B25",
                                            marginRight: "0.5rem",
                                        }}
                                    >
                                        {client.status.name}
                                    </span>
                                </div>
                            );
                        },
                    },
                ]}
                data={(query) =>
                    new Promise((resolve, reject) => {
                        let url = config.api + "/appointments?";
                        url += `page=${query.page + 1}`;
                        url += `&mechanic_id=${mechanicId}`
                        url += `&appointment_date_from=${startOfMonth}`
                        url += `&appointment_date_to=${endOfMonth}`
                        if (query.pageSize) {
                            url += `&per_page=${query.pageSize}`;
                        }

                        const options = {
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${token}`,
                            },
                        };
                        fetch(url, options)
                            .then((response) => response.json())
                            .then((result) => {
                                resolve({
                                    data: result.data.data,
                                    page: result.data.current_page - 1 || 0,
                                    totalCount: result.data.total,
                                });
                            })
                            .catch((err) => reject(err));
                    })
                }

                options={{
                    headerStyle: {
                        fontWeight: 'bold',
                    },
                    rowStyle: {
                        fontSize: '.75rem',
                        padding: '0px !important',
                    },
                    actionsCellStyle: {
                        justifyContent: 'center',
                        padding: '24px',
                        marginBottom: '-1px',
                    },
                    pageSize: 30,
                    pageSizeOptions: [],
                    actionsColumnIndex: -1,
                    tableLayout: 'auto',
                    sorting: false,
                    search: false
                }}
            />
        </Fragment>
    )
}

export default React.memo(MechanicBookings)