import { Fragment, useState } from "react";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import "react-datepicker/dist/react-datepicker.css";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  FormControl,
  FormLabel,
  Grid,
  Typography,
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { green } from "@material-ui/core/colors";
import { Formik, Form, FastField, Field, FieldArray } from "formik";
import { object, number, string } from "yup";
import { TextField } from "formik-material-ui";
import HubLocation from "./HubLocation";
import MechanicRole from "./MechanicRole";
import MechanicType from "./MechanicType";
import SelectDaysOfTheWeek from "../common/SelectDaysOfTheWeek";
import AlertDialog from "../booking/AlertDialog";

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: "#FAE700",
  },
  titleText: {
    color: "rgb(63,81,181) !important",
    fontWeight: "bold !important",
  },
  button: {
    backgroundColor: green[500],
    color: "#fff",
    "&:hover": {
      backgroundColor: green[600],
    },
  },
}));

const MechanicUpdate = React.memo((props) => {
  const classes = useStyles();
  const [fileName, setFileName] = React.useState("");
  // ==============Alert State==============
  const [alert, setAlert] = React.useState({
    open: false,
    message: "",
    typeAlert: "success",
  });

  const { open, onClose, data, onUpdate, onUpload } = props;

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = (values) => {
    const formData = new FormData();

    const merged = {
      ...values,
      serviceExpertise: values.serviceExpertise.join(","),
    };
    onUpdate(merged, values.id);
    if (values.file) {
      formData.append("file", values.file);
      onUpload(formData, values.id);
    }
  };

  // Transform data.schedules to the desired format
  const transformedSchedules = data?.schedules?.map((schedule) => ({
    day: schedule.day,
    startTime: schedule.start_time,
    endTime: schedule.end_time,
  })) || [{ day: "", startTime: "", endTime: "" }];

  const initialValues = data
    ? {
        id: data.id || null,
        firstname: data.firstname || "",
        lastname: data.lastname || "",
        mobile: data.mobile || "",
        username: data.username || "",
        email: data.email || "",
        password: "",
        password_confirmation: "",
        degree_or_certificate: data.degree_or_certificate || "",
        years_of_experience: data.years_of_experience || "",
        role: data.role || "",
        employment_status: data.employment_status || "",
        garage_id: data.garage_id || "",
        is_active: data.is_active || false,
        serviceExpertise: data.service_expertise
          ? data.service_expertise.split(",")
          : [],
        schedules: transformedSchedules,
      }
    : {
        firstname: "",
        lastname: "",
        mobile: "",
        username: "",
        email: "",
        password: "",
        password_confirmation: "",
        degree_or_certificate: "",
        years_of_experience: "",
        role: "",
        employment_status: "",
        garage_id: "",
        is_active: true,
        serviceExpertise: [],
        schedules: [{ day: "", startTime: "", endTime: "" }],
      };

  const validationSchema = object().shape({
    firstname: string().required("First Name is required"),
    lastname: string().required("Last Name is required"),
    mobile: number()
      .typeError("Must be a valid number.")
      .required("Mobile is required"),
  });

  const serviceExpertiseOptions = [
    "PMS",
    "Car Buying",
    "Initial Diagnosis",
    "Electrical",
  ];

  const CheckboxGroup = ({ name, value, onChange, options }) => {
    return (
      <FormGroup style={{ display: "flex", flexDirection: "row" }}>
        {options.map((option) => (
          <FormControlLabel
            key={option}
            control={
              <Checkbox
                name={name}
                value={option}
                checked={value.includes(option)}
                onChange={onChange}
              />
            }
            label={option}
            style={{ marginRight: "20px" }}
          />
        ))}
      </FormGroup>
    );
  };

  const isDayDuplicate = (day, values) => {
    return values.schedules.some((schedule) => schedule.day === day);
  };

  const handleCloseAlert = () => {
    setAlert({
      ...alert,
      open: false,
      message: "",
    });
  };

  return (
    <Fragment>
      <AlertDialog
        open={alert.open}
        typeAlert={alert.typeAlert}
        message={alert.message}
        handleCloseAlert={handleCloseAlert}
      />
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="md"
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, isSubmitting, setFieldValue }) => (
              <Form autoComplete="off">
                <DialogTitle
                  id="alert-dialog-title"
                  className={`${classes.title}`}
                >
                  <span className={classes.titleText}>
                    {"ADD MECHANIC FORM"}
                  </span>
                </DialogTitle>
                <DialogContent dividers>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <FastField
                        label="ID No."
                        component={TextField}
                        name="id"
                        type="text"
                        variant="outlined"
                        size="small"
                        fullWidth
                        disabled
                        inputProps={{
                          style: {
                            textAlign: "center",
                            textTransform: "capitalize",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FastField
                        label="First Name"
                        component={TextField}
                        name="firstname"
                        type="text"
                        variant="outlined"
                        size="small"
                        fullWidth
                        inputProps={{
                          style: {
                            textAlign: "center",
                            textTransform: "capitalize",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FastField
                        label="Last Name"
                        component={TextField}
                        name="lastname"
                        type="text"
                        variant="outlined"
                        size="small"
                        fullWidth
                        inputProps={{
                          style: {
                            textAlign: "center",
                            textTransform: "capitalize",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FastField
                        label="Contact #"
                        component={TextField}
                        name="mobile"
                        type="text"
                        variant="outlined"
                        size="small"
                        fullWidth
                        inputProps={{
                          style: {
                            textAlign: "center",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FastField
                        label="Username"
                        component={TextField}
                        name="username"
                        type="text"
                        variant="outlined"
                        size="small"
                        disabled
                        fullWidth
                        inputProps={{
                          style: {
                            textAlign: "center",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FastField
                        label="Email Address"
                        component={TextField}
                        name="email"
                        type="email"
                        variant="outlined"
                        size="small"
                        fullWidth
                        disabled
                        inputProps={{
                          style: {
                            textAlign: "center",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FastField
                        label="Degree or Certificate"
                        component={TextField}
                        name="degree_or_certificate"
                        type="text"
                        variant="outlined"
                        size="small"
                        fullWidth
                        inputProps={{
                          style: {
                            textAlign: "center",
                            textTransform: "capitalize",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FastField
                        label="Years of Experience"
                        component={TextField}
                        name="years_of_experience"
                        type="text"
                        variant="outlined"
                        size="small"
                        fullWidth
                        inputProps={{
                          style: {
                            textAlign: "center",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <input
                        accept="image/*"
                        className={classes.input}
                        id="contained-button-file"
                        type="file"
                        onChange={(event) => {
                          const file = event.currentTarget.files[0];
                          setFieldValue("file", file);
                          // Set the file name if a file is selected
                          if (file) {
                            setFileName(file.name);
                          } else {
                            setFileName(""); // Reset if no file is selected
                          }
                        }}
                      />
                      <label htmlFor="contained-button-file">
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                        >
                          Upload Photo
                        </Button>
                      </label>
                      {fileName && (
                        <Typography
                          variant="primary"
                          style={{ display: "inline-block" }}
                        >
                          {fileName}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl component="fieldset" margin="normal">
                        <FormLabel component="legend">
                          Service Expertise
                        </FormLabel>
                        <CheckboxGroup
                          name="serviceExpertise"
                          value={values.serviceExpertise}
                          onChange={(e) => {
                            const { value } = e.target;
                            const currentIndex =
                              values.serviceExpertise.indexOf(value);
                            const newChecked = [...values.serviceExpertise];

                            if (currentIndex === -1) {
                              newChecked.push(value);
                            } else {
                              newChecked.splice(currentIndex, 1);
                            }
                            setFieldValue("serviceExpertise", newChecked);
                          }}
                          options={serviceExpertiseOptions}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <FastField
                        label="Role"
                        component={MechanicRole}
                        name="role"
                        type="text"
                        variant="outlined"
                        size="small"
                        fullWidth
                        inputProps={{
                          style: {
                            textAlign: "center",
                            textTransform: "capitalize",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FastField
                        label="Mechanic Type"
                        component={MechanicType}
                        name="employment_status"
                        type="text"
                        variant="outlined"
                        size="small"
                        fullWidth
                        inputProps={{
                          style: {
                            textAlign: "center",
                            textTransform: "capitalize",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FastField
                        label="Hub"
                        component={HubLocation}
                        name="garage_id"
                        type="text"
                        variant="outlined"
                        size="small"
                        fullWidth
                        inputProps={{
                          style: {
                            textAlign: "center",
                            textTransform: "capitalize",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Typography>Schedules</Typography>
                    </Grid>
                    <FieldArray name="schedules">
                      {({ push, remove }) => (
                        <>
                          {values.schedules.map((schedule, index) => (
                            <Grid container spacing={2} key={index}>
                              <Grid item xs={3}>
                                <Field
                                  label="Days"
                                  name={`schedules.${index}.day`}
                                  component={SelectDaysOfTheWeek}
                                  onChange={(e) => {
                                    const selectedDay = e.target.value;
                                    if (!isDayDuplicate(selectedDay, values)) {
                                      const newSchedules = [
                                        ...values.schedules,
                                      ];
                                      newSchedules[index].day = selectedDay;
                                      setFieldValue("schedules", newSchedules);
                                    } else {
                                      setAlert({
                                        ...alert,
                                        open: true,
                                        message:
                                          "This day has already been added",
                                        typeAlert: "error",
                                      });
                                    }
                                  }}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <Field
                                  name={`schedules.${index}.startTime`}
                                  label="Time From"
                                  type="time"
                                  component={TextField}
                                  onChange={(e) => {
                                    const newSchedules = [...values.schedules];
                                    newSchedules[index].startTime =
                                      e.target.value;
                                    setFieldValue("schedules", newSchedules);
                                  }}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <Field
                                  name={`schedules.${index}.endTime`}
                                  label="From To"
                                  type="time"
                                  component={TextField}
                                  onChange={(e) => {
                                    const newSchedules = [...values.schedules];
                                    newSchedules[index].endTime =
                                      e.target.value;
                                    setFieldValue("schedules", newSchedules);
                                  }}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  fullWidth
                                />
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                container
                                justifyContent="center"
                              >
                                <Button
                                  variant="outlined"
                                  color="secondary"
                                  onClick={() => remove(index)} // Remove current schedule row
                                  style={{ marginTop: "16px" }}
                                >
                                  Remove Schedule
                                </Button>
                              </Grid>
                            </Grid>
                          ))}
                          <Grid
                            container
                            // justifyContent="flex-end"
                            style={{ marginTop: "16px" }}
                          >
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() =>
                                push({ day: "", startTime: "", endTime: "" })
                              } // Add new schedule row
                            >
                              Add Schedule
                            </Button>
                          </Grid>
                        </>
                      )}
                    </FieldArray>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="is_active"
                            checked={values.is_active}
                            onChange={(e) =>
                              setFieldValue("is_active", e.target.checked)
                            }
                          />
                        }
                        label="is active"
                        style={{ marginRight: "20px" }}
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} size="small" color="default">
                    Close
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    color="primary"
                    variant="contained"
                    autoFocus
                    size="small"
                    endIcon={
                      isSubmitting ? (
                        <CheckCircleIcon size="0.9rem" />
                      ) : undefined
                    }
                  >
                    {isSubmitting ? "Submitting" : "Submit"}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>
      </div>
    </Fragment>
  );
});
export default MechanicUpdate;
