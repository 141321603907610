import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { MenuItem, TextField } from "@material-ui/core";

const MenuItemStyle = withStyles({
  root: {
    justifyContent: "center !important",
  },
})(MenuItem);

const useStyles = makeStyles((theme) => ({
  center: {
    textAlign: "center !important",
  },
}));

const categories = [
  { value: "1", label: "Maintenance" },
  { value: "2", label: "Preventive Maintenance Service" },
  { value: "3", label: "Brakes" },
  { value: "4", label: "Battery/Electrical" },
  { value: "5", label: "Tires" },
  { value: "6", label: "Aircon Services" },
  { value: "7", label: "Car Disinfection" },
  { value: "8", label: "Car Inspection" },
];

const CustomSelectServiceCategory = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const classes = useStyles();

  return (
    <TextField
      select
      error={errors.service_category_id && touched.service_category_id}
      helperText={errors.service_category_id && touched.service_category_id && errors.service_category_id}
      {...field}
      {...props}
      classes={{
        root: classes.center,
      }}
    >
      {categories.map((option) => (
        <MenuItemStyle key={option.value} value={option.value}>
          {option.label}
        </MenuItemStyle>
      ))}
    </TextField>
  );
};
export default CustomSelectServiceCategory;
