import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { bookFilterActions } from "../../../store/book-filter-slice";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

const AntTabs = withStyles({
  root: {
    border: "1px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: "#FAE700",
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 120,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    opacity: 1,
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:disabled": {
      color: "black",
      fontWeight: "bold",
      opacity: 0.7,
      width: 145,
      maxWidth: 145,
    },
    "&:hover": {
      color: "#3f51b5",
      opacity: 1,
    },
    "&$selected": {
      backgroundColor: "#3f51b5",
      color: "#fff",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "#fff",
    },
  },
  selected: {},
}))((props) => <Tab {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(3),
  },
  demo1: {
    backgroundColor: theme.palette.background.paper,
  },
  demo2: {
    backgroundColor: "#2e1534",
  },
}));

const PaymentStatus = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const paymentStatus = useSelector((state) => state.bookFilter.paymentStatus);

  const handleChange = (event, data) => {
    dispatch(bookFilterActions.changePaymentStatus({ status: data }));
  };

  return (
    <div className={classes.root}>
      <div className={classes.demo1}>
        <AntTabs
          value={paymentStatus}
          onChange={handleChange}
          aria-label="ant example"
        >
          <AntTab label="Payment Status : " disabled />
          <AntTab label="All" value="all" />
          <AntTab label="Unpaid" value="1" />
          <AntTab label="Partial Payment" value="2" />
          <AntTab label="Fully Paid" value="3" />
        </AntTabs>
      </div>
    </div>
  );
};

export default PaymentStatus;
