import React, { useEffect, useCallback } from "react";

const numberConverter = (number) => {
  let value = Number(parseFloat(number)).toLocaleString("en", {
    minimumFractionDigits: 2,
  });
  return value;
};

const CustomTotalComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { values, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const {
    services,
    manualServices,
    parts,
    service_fee,
    discounts,
    tires,
    payment_amount,
  } = values;
  // Helper function to parse numbers with commas and handle empty values
  const parseNumber = useCallback(
    (value) => parseFloat(value.replace(/,/g, "") || 0),
    []
  );

  useEffect(() => {
    // Calculate subtotal for packages
    const subTotalNM = services.reduce((acc, item) => {
      const itemPrice =
        parseFloat(item?.variants?.price || 0) * (item?.quantity || 1);
      return itemPrice + acc;
    }, 0);

    // Calculate total price for services
    const manualServicesTotalPrice = manualServices.reduce(
      (acc, item) => acc + parseFloat(item.totalPrice),
      0
    );

    // Calculate total price for parts
    const partsTotalPrice = parts.reduce(
      (acc, item) => acc + parseFloat(item?.totalPrice || 0),
      0
    );

    // Calculate total price for tires
    const tiresTotalPrice = tires.reduce(
      (acc, item) => acc + parseFloat(item.totalPrice),
      0
    );

    // Parse total discount
    const totalDiscount = parseNumber(discounts);
    const paymentAmount = parseFloat(payment_amount) || 0;

    // Calculate overall subtotal
    const subTotal =
      subTotalNM + manualServicesTotalPrice + partsTotalPrice + tiresTotalPrice;

    // Calculate total with service fee and subtract discounts
    const total =
      parseFloat(service_fee) + subTotal - totalDiscount - paymentAmount;
    // Ensure the final total is not negative
    const totalFinal = Math.max(0, total);

    // Set form field values
    setFieldValue("subTotal", subTotal);
    setFieldValue("total", totalFinal);
  }, [
    values.services,
    values.manualServices,
    values.parts,
    values.service_fee,
    values.discounts,
    values.tires,
    values.payment_amount,
  ]);

  return (
    <React.Fragment>
      <input {...props} {...field} style={{ display: "none" }} />
      <span>{field.value ? numberConverter(+field.value) : "0.00"}</span>
    </React.Fragment>
  );
};
export default CustomTotalComponent;
