import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const bookFilterSlice = createSlice({
  name: "book filter",
  initialState: {
    search: true,
    orderStatus: "all",
    paymentStatus: "all",
    bookingDateFrom: null,
    bookingDateTo: null,
    appointmentMin: moment(new Date()).format("YYYY-MM-DD"),
    appointmentMax: undefined,
    appointmentDateFrom: moment(new Date()).format("YYYY-MM-DD"),
    appointmentDateTo: moment(new Date()).format("YYYY-MM-DD"),
    appointmentTime: null,
    serviceLocation: [],
    transactionType: [],
    filterBookingType: "0",
    totalResultEntries: 0,
    mechanic: null,
    salesAgent: null,
  },
  reducers: {
    changeSearch(state) {
      state.search = true;
    },
    changeClear(state) {
      state.search = true;
      state.orderStatus = "all";
      state.paymentStatus = "all";
      state.bookingDateFrom = null;
      state.bookingDateTo = null;
      state.appointmentMin = moment(new Date()).format("YYYY-MM-DD");
      state.appointmentMax = undefined;
      state.appointmentDateFrom = moment(new Date()).format("YYYY-MM-DD");
      state.appointmentDateTo = moment(new Date()).format("YYYY-MM-DD");
      state.appointmentTime = null;
      state.filterBookingType = "0";
      state.mechanic = null;
      state.salesAgent = null;
      state.totalResultEntries = 0;
    },
    clearFilters(state) {
      state.appointmentDateFrom = null;
      state.appointmentDateTo = null;
      state.serviceLocation = [];
      state.transactionType = [];
    },
    changeOrderStatus(state, action) {
      state.orderStatus = action.payload.status;
      state.search = true;
    },
    changePaymentStatus(state, action) {
      state.paymentStatus = action.payload.status;
      state.search = true;
    },
    changeBookingDateFrom(state, action) {
      state.bookingDateFrom = action.payload.bookingDateFrom;
      state.search = false;
    },
    changeBookingDateTo(state, action) {
      state.bookingDateTo = action.payload.bookingDateTo;
      state.search = false;
    },
    changeAppointmentDateFrom(state, action) {
      state.appointmentDateFrom = action.payload.appointmentDateFrom;
      state.search = false;
    },
    changeAppointmentDateTo(state, action) {
      state.appointmentDateTo = action.payload.appointmentDateTo;
      state.search = false;
    },
    changeAppointmentTime(state, action) {
      state.appointmentTime = action.payload.appointmentTime;
      state.search = false;
    },
    changeServiceLocation(state, action) {
      state.serviceLocation = action.payload.serviceLocation;
      state.search = false;
    },
    changeTransactionType(state, action) {
      state.transactionType = action.payload.transactionType;
      state.search = false;
    },
    changeFilterBookingType(state, action) {
      let date = new Date();
      state.bookingDateFrom = null;
      state.bookingDateTo = null;
      state.appointmentTime = null;
      state.serviceLocation = [];
      state.transactionType = [];

      if (action.payload.filterBookingType === "0") {
        state.appointmentMin = moment(date).format("YYYY-MM-DD");
        state.appointmentMax = moment(date).format("YYYY-MM-DD");
        state.appointmentDateFrom = moment(date).format("YYYY-MM-DD");
        state.appointmentDateTo = moment(date).format("YYYY-MM-DD");
      } else if (action.payload.filterBookingType === "1") {
        let date_tomorrow = moment(date.setDate(date.getDate() + 1)).format(
          "YYYY-MM-DD"
        );
        state.appointmentMin = date_tomorrow;
        state.appointmentMax = undefined;
        state.appointmentDateFrom = date_tomorrow;
        state.appointmentDateTo = date_tomorrow;
      } else {
        state.appointmentMin = undefined;
        state.appointmentMax = undefined;
        state.appointmentDateFrom = null;
        state.appointmentDateTo = null;
        state.serviceLocation = [];
      }

      state.filterBookingType = action.payload.filterBookingType;
      state.search = true;
    },
    changeTotalResultEntries(state, action) {
      state.totalResultEntries = action.payload.totalResultEntries;
      // state.search = false;
    },
    setMechanic(state, action) {
      state.mechanic = action.payload.mechanic;
      state.search = false;
    },
    setSalesAgent(state, action) {
      state.salesAgent = action.payload.salesAgent;
      state.search = false;
    },
  },
});

export const bookFilterActions = bookFilterSlice.actions;
export default bookFilterSlice;
