import { Fragment, useCallback, useState, useRef } from "react";
import axios from "axios";
import AlertDialog from "../components/booking/AlertDialog";
import PackageCategoriesTable from "../components/packageCategories/PackageCategoriesTable";
// import AddPackage from "../components/packages/AddPackage";
import PackageCategoryForm from "../components/packageCategories/components/PackageCategoryForm";

// Api config
import config from "../utils/config";

const PackageCategories = (props) => {
  const { token } = props;

  const tableRef = useRef();

  // ==============MODAL STATES==============
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [typeAlert, setTypeAlert] = useState("success");

  // ==============ADD Manual Services STATES==============
  const [openAdd, setOpenAdd] = useState(false);

  const handleOpenAdd = useCallback(() => {
    setOpenAdd(true);
  }, []);

  const handleCloseAdd = useCallback(() => {
    setOpenAdd(false);
  }, []);

  const handleSubmitAdd = useCallback(
    (values, actions) => {
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      axios
        .post(`${config.api}/package-category`, values, options)
        .then((response) => {
          setOpenAdd(false);
          setTimeout(() => {
            setAlert(true);
            setMessage("Successfully Added");
            setTypeAlert("success");
          }, 1500);

          tableRef.current && tableRef.current.onQueryChange();
        })
        .catch((error) => {
          actions.setSubmitting(false);
          setAlert(true);
          setTypeAlert("error");
          setMessage("Something went wrong please try again.");
        });
    },
    [token]
  );

  // =================ALERT - (MODAL)=================
  const handleCloseAlert = useCallback(() => {
    setAlert(false);
    setMessage("");
    setTypeAlert("success");
  }, []);

  return (
    <Fragment>
      <AlertDialog
        open={alert}
        typeAlert={typeAlert}
        message={message}
        handleCloseAlert={handleCloseAlert}
      />
      <PackageCategoryForm
        open={openAdd}
        onOpen={handleOpenAdd}
        onClose={handleCloseAdd}
        handleSubmitForm={handleSubmitAdd}
      />
      <PackageCategoriesTable tableRef={tableRef} token={token} />
    </Fragment>
  );
};

export default PackageCategories;
