import { useReducer, useCallback } from "react";

const initialInputState = {
  data: [],
  open: false,
};

const inputStateReducer = (state, action) => {
  if (action.type === "OPEN") {
    return { data: action.data, open: true };
  }

  if (action.type === "CLOSE") {
    return { data: [], open: false };
  }

  return initialInputState;
};

const useModal = () => {
  const [modalState, dispatch] = useReducer(
    inputStateReducer,
    initialInputState
  );

  const openHandler = useCallback((data) => {
    dispatch({ type: "OPEN", data: data });
  }, []);

  const closeHandler = useCallback((event) => {
    dispatch({ type: "CLOSE" });
  }, []);

  return {
    modalState: modalState.open,
    modalData: modalState.data,
    openHandler,
    closeHandler,
  };
};
export default useModal;
