import React, { useEffect, useState } from 'react';
import axios from 'axios';
// Api config
import config from '../../../utils/config';

const numberConverter = (number) => {
  let value = Number(parseFloat(number)).toLocaleString('en', {
    minimumFractionDigits: 2,
  });
  return value;
};

const CustomServiceFeeComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, values, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  // useEffect(() => {
  //   const mainAdd = `Rapidé 1 San Antonio Makati - Auto Service, Car Repair, 1166 Chino Roces Avenue, Corner Estrella, Makati, 1203`;

  // const options = {
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: `Bearer ${props.token}`,
  //   },
  // };
  // axios
  //   .get(
  //     `${config.api}/distance/matrix?fromAddress=${mainAdd}&toAddress=${values.address}`,
  //     options
  //   )
  //   .then((response) => {
  //     let datas = response.data.data;
  //     let status = datas.rows[0].elements[0].status;

  //     if (status === "OK") {
  //       let distance = datas.rows[0].elements[0].distance.value * 0.001;
  //       let km = Math.round(distance);
  //       // console.log(km);
  //       if (km >= 20 && km <= 30) {
  //         // setFieldValue("service_fee", "350.00");
  //         setFieldValue("distance_in_km", km);
  //       } else if (km >= 31 && km <= 40) {
  //         // setFieldValue("service_fee", "450.00");
  //         setFieldValue("distance_in_km", km);
  //       } else if (km >= 41 && km <= 50) {
  //         // setFieldValue("service_fee", "750.00");
  //         setFieldValue("distance_in_km", km);
  //       } else {
  //         // setFieldValue("service_fee", "0");
  //         setFieldValue("distance_in_km", km);
  //       }

  //       if (km > 50) {
  //         setFieldValue("distance_in_km", "");
  //         setFieldValue("coverAddress", "");
  //       } else {
  //         setFieldValue("coverAddress", "cover");
  //       }

  //     } else {
  //       setFieldValue("distance_in_km", "");
  //       // setFieldValue("address", "");
  //       setFieldValue("coverAddress", "");
  //     }
  //   })
  //   .catch((error) => {
  //     // console.log(error);
  //     // setFieldValue("distance_in_km", "");
  //     // setFieldValue("service_fee", "0");
  //     // setFieldValue("address", "");
  //     // setFieldValue("coverAddress", "cover");
  //   });
  //   return () => {}
  // }, [values.address, props.token, setFieldValue]);
  // console.log(values.coverAddress);
  return (
    <React.Fragment>
      {/* <input {...props} {...field} style={{ display: "none" }} />
      <span>{field.value ? numberConverter(+field.value) : "0.00"}</span> */}
    </React.Fragment>
  );
};
export default CustomServiceFeeComponent;
