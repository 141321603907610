import React, { useEffect, useState } from "react";
import axios from "axios";
import { withStyles } from "@material-ui/core/styles";
import { MenuItem, TextField } from "@material-ui/core";

// Api config
import config from "../../utils/config";

const MenuItemStyle = withStyles({
  root: {
    justifyContent: "center",
  },
})(MenuItem);

const SelectOdometer = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, values }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const [odometer, setOdometer] = useState([]);

  const token = localStorage.getItem("token");
  useEffect(() => {
    async function fetchData() {
      const {
        data: { data: odometer },
      } = await axios.get(`${config.api}/odometer`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setOdometer(odometer);
    }
    fetchData();
  }, []);

  return (
    <TextField
      fullWidth
      select
      error={errors.odometer && touched.odometer}
      helperText={errors.odometer && touched.odometer ? errors.odometer : ""}
      {...field}
      {...props}
    >
      {odometer.map((option) => (
        <MenuItemStyle key={option.id} value={option.id}>
          {option.name} {"KM"}
        </MenuItemStyle>
      ))}
    </TextField>
  );
};
export default SelectOdometer;
