import { useState, useRef } from "react";
import { Box, Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import QuotationTable from "../components/quotations/QuotationTable";
import AddQuotation from "../components/quotations/components/AddQuotation";

const useStyles = makeStyles((theme) => ({
  button: {
    padding: "5px 0px !important",
    maxWidth: "14.44%",
    backgroundColor: green[500],
    color: "#fff",
    "&:hover": {
      backgroundColor: green[600],
    },
  },
}));

const Quotation = (props) => {
  const { token } = props;
  const [open, setOpen] = useState(false);

  const tableRef = useRef();
  const classes = useStyles();

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <AddQuotation open={open} onClose={onClose} tableRef={tableRef} />
      <Box display={"flex"} justifyContent={"end"} marginBottom={"1rem"}>
        <Button
          className={classes.button}
          fullWidth
          variant="contained"
          size="small"
          startIcon={<AddIcon />}
          color="primary"
          onClick={() => setOpen(true)}
        >
          Add Quotation
        </Button>
      </Box>
      <QuotationTable tableRef={tableRef} token={token} />
    </>
  );
};

export default Quotation;
