import { makeStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import { Typography, Grid, Paper, TextField } from '@material-ui/core';
// External
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';

const useStyles = makeStyles(() => ({
  error: {
    color: red[500],
  },
  icon: {
    position: 'relative',
    width: '2.5rem',
    height: '2.5rem',
    backgroundColor: 'currentColor',
    borderRadius: '50%',
  },
  checkmark: {
    position: 'absolute',
    top: '8px',
    left: '16px',
    width: '8px',
    height: '20px',
    border: 'solid #fff',
    borderWidth: '0 3px 3px 0',
    transform: 'rotate(45deg)',
  },
}));

const BodyMarkings = ({ title, type, data, onChange }) => {
  const classes = useStyles();
  const services = data?.filter((item) => item?.key === type);

  const handleChange = (id, key, type, name, value) => {
    onChange(id, key, type, name, value);
  };

  return (
    <div>
      {services?.map((service) => (
        <Paper variant="outlined" style={{ marginBottom: 20 }}>
          <Grid container>
            <Grid item md={2} xs={3}>
              <Typography
                variant="body2"
                style={{ paddingLeft: 10, paddingTop: 15 }}
              >
                <strong>{service?.type}</strong>
              </Typography>
            </Grid>
            <Grid item md={3} xs={2}>
              <p style={{ paddingBottom: 0 }}>Part:</p>
              <TextField
                variant="outlined"
                fullWidth
                value={service?.part}
                style={{ marginBottom: 10 }}
                onChange={(e) =>
                  handleChange(
                    service?.id,
                    service?.key,
                    service?.type,
                    'note',
                    e.target.value
                  )
                }
              />
            </Grid>
            <Grid item md={3} xs={2} style={{ paddingLeft: 10 }}>
              <p style={{ paddingBottom: 0 }}>Issue:</p>
              <TextField
                variant="outlined"
                fullWidth
                value={service?.issue}
                style={{ marginBottom: 10 }}
                onChange={(e) =>
                  handleChange(
                    service?.id,
                    service?.key,
                    service?.type,
                    'note',
                    e.target.value
                  )
                }
              />
            </Grid>
            <Grid item md={2} xs={1} style={{ paddingLeft: 10 }}>
              <p>Photo:</p>
              {service?.photo && (
                <PhotoProvider>
                  <PhotoView src={service?.photo}>
                    <img
                      src={service?.photo}
                      alt=""
                      style={{ width: '80px', height: '80px' }}
                    />
                  </PhotoView>
                </PhotoProvider>
              )}
            </Grid>
          </Grid>
        </Paper>
      ))}
    </div>
  );
};

export default BodyMarkings;
