import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import { useField } from "formik";

const useStyles = makeStyles(() => ({
  inputRoot: {
    "&$disabled": {
      color: "#000",
    },
  },
  disabled: {},
}));

const TextFieldWrapper = ({ name, ...props }) => {
  const [field, meta] = useField(name);
  const classes = useStyles();

  return (
    <TextField
      variant="outlined"
      size="small"
      fullWidth
      {...field}
      {...props}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      InputProps={{
        classes: {
          root: classes.inputRoot,
          disabled: classes.disabled,
        },
        inputProps: {
          style: { textAlign: "center" },
        },
      }}
      FormHelperTextProps={{
        style: {
          textAlign: "center",
        },
      }}
    />
  );
};

export default TextFieldWrapper;
