import React, { Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { bookFilterActions } from "../../../store/book-filter-slice";
import "date-fns";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Box } from "@material-ui/core";
import customClasses from "./AppointmentDatePicker.module.css";
import { createMuiTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";

const muiTheme = createMuiTheme({
  textAlign: "center !important",
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    border: "1px solid #e8e8e8",
    borderTop: "none",
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  grid: {
    position: "relative",
  },
  text: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    textAlign: "left",
    marginTop: "20px !important",
    fontSize: "0.845rem !important",
    whiteSpace: "normal",
    letterSpacing: "0.02857em",
    fontWeight: "bold !important",
    color: "black !important",
    opacity: 0.7,
  },
  dateCustom: {
    textAlign: "center !important",
  },
  button: {
    marginTop: theme.spacing(1),
  },
}));

const AppointmentDatePicker = (props) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const appointmentMin = useSelector(
    (state) => state.bookFilter.appointmentMin
  );
  const appointmentMax = useSelector(
    (state) => state.bookFilter.appointmentMax
  );
  const appointmentDateFrom = useSelector(
    (state) => state.bookFilter.appointmentDateFrom
  );
  const appointmentDateTo = useSelector(
    (state) => state.bookFilter.appointmentDateTo
  );

  const handleChangeFrom = (stringDate, date) => {
    let value = moment(date).format("YYYY-MM-DD");
    dispatch(
      bookFilterActions.changeAppointmentDateFrom({
        appointmentDateFrom: value,
      })
    );
  };

  const handleChangeTo = (stringDate, date) => {
    let value = moment(date).format("YYYY-MM-DD");
    dispatch(
      bookFilterActions.changeAppointmentDateTo({ appointmentDateTo: value })
    );
  };

  const handleClear = () => {
    dispatch(bookFilterActions.changeClear());
  };

  return (
    <Fragment>
      <div className={classes.root}>
        <Grid
          container
          spacing={1}
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          <Grid container item xs={12} spacing={3}>
            {/*1st*/}
            <Grid item xs={3} className={classes.grid}>
              <Box ml={2}>
                <Typography
                  className={classes.text}
                  paragraph={true}
                  style={{ margin: 0, marginBottom: "7px" }}
                >
                  Appointment Date From :
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box>
                <MuiPickersUtilsProvider theme={muiTheme} utils={DateFnsUtils}>
                  <Grid container>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="yyyy-MM-dd"
                      margin="normal"
                      id={customClasses.appointment_from}
                      autoOk
                      value={appointmentDateFrom}
                      onChange={handleChangeFrom}
                      minDate={appointmentMin}
                      maxDate={appointmentMax}
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Box>
            </Grid>
            {/*2nd*/}
            <Grid item xs={1} className={classes.grid}>
              <Box ml={2}>
                <Typography
                  className={classes.text}
                  paragraph={true}
                  style={{ margin: 0 }}
                >
                  To :
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box>
                <MuiPickersUtilsProvider theme={muiTheme} utils={DateFnsUtils}>
                  <Grid container>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="yyyy-MM-dd"
                      margin="normal"
                      id={customClasses.appointment_to}
                      autoOk
                      value={appointmentDateTo}
                      onChange={handleChangeTo}
                      minDate={appointmentDateFrom}
                      maxDate={appointmentMax}
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                size="small"
                startIcon={<DeleteIcon />}
                className={classes.button}
                onClick={handleClear}
              >
                Clear Filters
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Fragment>
  );
};

export default AppointmentDatePicker;
