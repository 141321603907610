import React, { Fragment, useCallback, useState } from 'react';
import MaterialTable from 'material-table';
import axios from "axios";
import { useHistory } from 'react-router-dom';
// mui
import IconButton from '@material-ui/core/IconButton';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import VisibilityIcon from "@material-ui/icons/Visibility";
import DashboardIcon from "@material-ui/icons/Dashboard";
import { makeStyles } from '@material-ui/core/styles';
import { green, yellow } from '@material-ui/core/colors';
import Tooltip from '@material-ui/core/Tooltip';
// api config
import config from '../../utils/config';

import useModal from "../../hooks/use-modal";

import UpdateIncentive from './UpdateIncentive';
import ViewIncentive from './ViewIncentive';
import AlertDialog from "../alertDialog/AlertDialog";

function groupByMonthAndYear(data) {
  const grouped = {};
  data.forEach(item => {
    const key = `${item.month}-${item.year}`;
    if (!grouped[key]) {
      grouped[key] = {
        month: item.month,
        year: item.year,
        regular_mechanics: item.regular_mechanics,
        trainee_mechanics: item.trainee_mechanics,
        values: []
      };
    }
    grouped[key].values.push({...item});
  });
  return Object.values(grouped);
}

const useStyles = makeStyles((theme) => ({
  view: {
    color: yellow[500],
    "&:hover": {
      color: "#fff",
    },
  },
  update: {
    "&:hover": {
      backgroundColor: "rgba(255, 253, 231,1.0)",
    },
  },
  dashboard: {
    color: green[500],
    '&:hover': {
      backgroundColor: 'rgba(232, 245, 233,1.0)',
    },
  },
  sizeSmall: {
    height: '18px',
    fontSize: '12px',
  },
}));
  
  const MechanicIncentives = (props) => {
    const { tableRef, token } = props;
    const history = useHistory();
    const classes = useStyles();

  const [loading, setLoading] = useState(false);

  // ==============MODAL STATES==============
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    typeAlert: "success",
  });

  // View Dialog
  const {
    modalState: modalStateView,
    modalData: modalDataView,
    openHandler: modalOpenView,
    closeHandler: modalCloseView,
  } = useModal();

  // Update Dialog
  const {
    modalState: modalStateUpdate,
    modalData: modalDataUpdate,
    openHandler: modalOpenUpdate,
    closeHandler: modalCloseUpdate,
  } = useModal();

  // Alert Dialog
  const handleCloseAlert = useCallback(
    (event, reason) => {
      if (reason === "clickaway") {
        return;
      }

      setAlert({
        ...alert,
        open: false,
        message: "",
      });
    },
    [alert]
  );

  const handleUpdateSubmit = useCallback((created, updated, deleted) => {
     setLoading(true);
     const payload = {
      created,
      updated,
      deleted,
     }
     axios
        .post(`${config.api}/mechanics/incentives/update`, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setLoading(false);
          modalCloseUpdate();
          setAlert({
            ...alert,
            open: true,
            message: "Successfully Updated",
            typeAlert: "success",
          });
          tableRef.current && tableRef.current.onQueryChange();
        })
        .catch((error) => {
          setLoading(false);
          modalCloseUpdate();
          setAlert({
            ...alert,
            open: true,
            message: "Something went wrong please try again.",
            typeAlert: "error",
          });
        });
  }, [token])


  return (
        <Fragment>
          {/* Alert Dialog */}
          <AlertDialog
            open={alert.open}
            typeAlert={alert.typeAlert}
            message={alert.message}
            handleCloseAlert={handleCloseAlert}
          />
          {/* =================Update Dialog - (MODAL)================= */}
          <UpdateIncentive
            token={token}
            data={modalDataUpdate}
            loading={loading}
            open={modalStateUpdate}
            handleUpdateSubmit={handleUpdateSubmit}
            handleUpdateClose={modalCloseUpdate}
          />
          {/* =================View Dialog - (MODAL)================= */}
          <ViewIncentive
            data={modalDataView}
            open={modalStateView}
            handleViewClose={modalCloseView}
          />
          <MaterialTable
            title={`Mechanics Incentives`}
            tableRef={tableRef}
            columns={[
                {
                    title: 'Month/Year',
                    render: (row) => {
                      return (
                        <p>{`${row.month} / ${row.year}`}</p>
                      );
                    },
                },
                {
                    title: 'Target Achievement in %',
                    render: (row) => {
                      return (
                        <p>{`${row?.values?.[0].target_in_percentage} %`}</p>
                      );
                    },
                },
                {
                    title: 'No of Bookings',
                    render: (row) => {
                      return (
                        <p>{row?.values?.[0].no_bookings}</p>
                      );
                    },
                },
                {
                    title: 'Nets Sales',
                    render: (row) => {
                      return (
                        <p>{row?.values?.[0].net_sales}</p>
                      );
                    },
                }
            ]}
            data={(query) =>
              new Promise((resolve, reject) => {
                let url = config.api + '/mechanics/incentives?';
                url += `page=${query.page + 1}`;
                if (query.pageSize) {
                  url += `&per_page=${query.pageSize}`;
                }
                if (query.search) {
                  url += `&search=${query.search}`;
                }
                const options = {
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                  },
                };
                fetch(url, options)
                  .then((response) => response.json())
                  .then((result) => {
                    const groupedData = groupByMonthAndYear(result?.data?.data);
                    resolve({
                      data: groupedData,
                      page: result.data.current_page - 1 || 0,
                      totalCount: groupedData?.length,
                    });
                  })
              })
            }
            actions={[
              {
                icon: "view",
                tooltip: "View",
                onClick: (event, rowData) => {
                  modalOpenView(rowData);
                }
              },
              {
                icon: 'update',
                tooltip: 'Update',
                onClick: (event, rowData) => {
                  modalOpenUpdate(rowData);
                }
              },
              {
                icon: 'dashboard',
                tooltip: 'Dashboard',
                onClick: (event, rowData) => {
                  history.push(`/mechanics-incentives-dashboard?month=${rowData?.month}&year=${rowData?.year}`)
                }
              },
            ]}
            components={{
              Action: (props) => {
                // =====================Update=====================
                if (props.action.icon === 'update') {
                  return (
                    <Tooltip title="Update">
                      <IconButton
                        aria-label="update"
                        className={classes.update}
                        size="small"
                        onClick={(event) => props.action.onClick(event, props.data)}
                      >
                        <EditOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  );
                }
                // =====================View=====================
                if (props.action.icon === 'view') {
                  return (
                    <Tooltip title="View">
                      <IconButton
                        aria-label="view"
                        className={classes.view}
                        size="small"
                        onClick={(event) => props.action.onClick(event, props.data)}
                      >
                        <VisibilityIcon />
                      </IconButton>
                    </Tooltip>
                  );
                }
                // =====================Dashboard=====================
                if (props.action.icon === 'dashboard') {
                  return (
                    <Tooltip title="Dashboard">
                      <IconButton
                        aria-label="dashboard"
                        className={classes.dashboard}
                        size="small"
                        onClick={(event) => props.action.onClick(event, props.data)}
                      >
                        <DashboardIcon />
                      </IconButton>
                    </Tooltip>
                  );
                }
              }
            }}
            options={{
              headerStyle: {
                fontWeight: 'bold',
              },
              rowStyle: {
                fontSize: '.75rem',
                padding: '0px !important',
              },
              actionsCellStyle: {
                justifyContent: 'center',
                padding: '24px',
                marginBottom: '-1px',
              },
              pageSize: 30,
              pageSizeOptions: [],
              actionsColumnIndex: -1,
              tableLayout: 'auto',
              sorting: false,
              search: false
            }}
          />
        </Fragment>
    )
  }

  export default React.memo(MechanicIncentives)