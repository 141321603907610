import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  IconButton,
  Typography,
  Slide,
  Toolbar,
  AppBar,
  Dialog,
} from "@material-ui/core";
import MuiDialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import InspectionDataPdf from "./InspectionDataPdf";

const useStyles = makeStyles((theme) => ({
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  button: {
    color: theme.palette.grey[900],
  },
  appBar: {
    position: "relative",
    backgroundColor: "#FAE700",
    color: "#000",
  },
  gridCustom: {
    textAlign: "center !important",
  },
  stepper: {
    paddingLeft: "0",
    paddingRight: "0",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
      timeout={{ appear: 100, enter: 500, exit: 500 }}
    />
  );
});

const InspectionDataPdfDialog = (props) => {
  const { open, handleInspectionDataPdfClose, data } = props;
  const classes = useStyles();

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleInspectionDataPdfClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleInspectionDataPdfClose}
            aria-label="close"
          >
            <CloseIcon className={classes.button} />
          </IconButton>
          <Typography variant="h5" className={classes.title}>
            INSPECTION DATA PDF
          </Typography>
        </Toolbar>
      </AppBar>
      <MuiDialogContent dividers style={{ backgroundColor: "#f4f4f4" }}>
        {data.inspection && <InspectionDataPdf content={data} />}
      </MuiDialogContent>
    </Dialog>
  );
};

export default InspectionDataPdfDialog;
