import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import {
  AppBar,
  Backdrop,
  Card,
  CardHeader,
  CardMedia,
  Dialog,
  IconButton,
  Modal,
  Fade,
  Slide,
  Toolbar,
  Typography,
} from '@material-ui/core';
// utils
import { formatDate } from '../../../utils/helper';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    backgroundColor: '#FAE700',
    color: '#000',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  card: {
    maxWidth: 345,
    margin: theme.spacing(2),
    flex: '1 0 300px',
  },
  media: {
    position: 'relative',
    height: 0,
    paddingTop: '56.25%', // 16:9
    '&:hover': {
      '& $overlay': {
        opacity: 1,
      },
    },
    cursor: 'pointer',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0,
    transition: 'opacity 0.3s',
  },
  overlayText: {
    color: '#fff',
    fontSize: 16,
    fontWeight: 'bold',
  },
  notFound: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    fontSize: '2rem',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalImage: {
    width: 'auto',
    maxHeight: '90vh',
    maxWidth: '90vw',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog({ data, open, handleViewClose }) {
  const classes = useStyles();
  const [visible, setVisible] = useState(false);

  const [selectedImage, setSelectedImage] = useState('');

  const handleOpen = (image) => {
    setSelectedImage(image);
    setVisible(true);
  };

  const handleClose = () => {
    setVisible(false);
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleViewClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleViewClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {data.make} {data.model} {data.year} {data.fuel_type}
            </Typography>
          </Toolbar>
        </AppBar>
        <div className={classes.container}>
          {data?.vehicle_history?.length ? (
            data.vehicle_history.map((card, index) => (
              <Card key={index} className={classes.card}>
                <CardHeader
                  title={formatDate(card.created_at)}
                  subheader="Click image to enlarge"
                />
                <CardMedia
                  className={classes.media}
                  image={card.url}
                  title="Enlarged Image"
                  onClick={() => handleOpen(card.url)}
                >
                  <div className={classes.overlay}>
                    <span className={classes.overlayText}>
                      Click to enlarge
                    </span>
                  </div>
                </CardMedia>
              </Card>
            ))
          ) : (
            <div className={classes.notFound}>
              <span>No History Found</span>
            </div>
          )}

          <Modal
            className={classes.modal}
            open={visible}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={visible}>
              <img
                className={classes.modalImage}
                src={selectedImage}
                alt="Enlarged"
              />
            </Fade>
          </Modal>
        </div>
      </Dialog>
    </div>
  );
}
