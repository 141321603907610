import React from "react";
import { StyleSheet, View, Text } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  boxHeader: {
    color: "#fff",
    backgroundColor: "#000b8c",
    padding: 6,
  },
  boxBody: {
    borderWidth: 1,
    borderColor: "#000b8c",
    borderTop: 0,
    padding: 6,
  },
  bold: {
    fontFamily: "Helvetica-Bold",
  },
});

const Recommendations = ({ content, itemServices }) => {
  const data = content ? content.split(",") : null;

  return (
    <>
      <View break style={[styles.boxHeader, styles.bold]}>
        <Text>Recommendations</Text>
      </View>
      <View style={styles.boxBody}>
        {data &&
          data.map((item) => (
            <Text
              style={[styles.bold, { color: "#fe0000", minHeight: 9 }]}
              key={item}
            >
              {item}
            </Text>
          ))}
        {data &&
          itemServices.map((item) => (
            <Text style={[styles.bold, { color: "#fe0000" }]} key={item.id}>
              {item.service}
            </Text>
          ))}
      </View>
    </>
  );
};

export default Recommendations;
