import React, { Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { bookFilterActions } from "../../store/book-filter-slice";
import "date-fns";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { Grid, Typography, Box } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";

// styles
import { makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import SelectServiceLocation from "../common/SelectServiceLocation";
import SelectTransactionType from "../common/SelectTransactionType";

const muiTheme = createMuiTheme({
  textAlign: "center !important",
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    border: "1px solid #e8e8e8",
    borderTop: "none",
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    marginLeft: "1rem",
    marginTop: "0.5rem",
  },
  daterangepicker: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  to: {
    marginTop: "8px",
  },
  submitButton: {
    "& > *": {
      margin: theme.spacing(1),
    },
    display: "flex",
    alignItems: "center",
    marginTop: "1rem",
  },
  grid: {
    position: "relative",
  },
  text: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    textAlign: "left",
    marginTop: "20px !important",
    fontSize: "0.845rem !important",
    whiteSpace: "normal",
    letterSpacing: "0.02857em",
    fontWeight: "bold !important",
    color: "black !important",
    opacity: 0.7,
  },
  dateCustom: {
    textAlign: "center !important",
  },
  button: {
    marginTop: theme.spacing(1),
  },
}));

const Filters = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const appointmentMin = useSelector(
    (state) => state.bookFilter.appointmentMin
  );
  const appointmentMax = useSelector(
    (state) => state.bookFilter.appointmentMax
  );
  const appointmentDateFrom = useSelector(
    (state) => state.bookFilter.appointmentDateFrom
  );
  const appointmentDateTo = useSelector(
    (state) => state.bookFilter.appointmentDateTo
  );
  const serviceLocation = useSelector(
    (state) => state.bookFilter.serviceLocation
  );
  const transactionType = useSelector(
    (state) => state.bookFilter.transactionType
  );

  const handleChangeFrom = (stringDate, date) => {
    let value = moment(date).format("YYYY-MM-DD");
    dispatch(
      bookFilterActions.changeAppointmentDateFrom({
        appointmentDateFrom: value,
      })
    );
  };

  const handleChangeTo = (stringDate, date) => {
    let value = moment(date).format("YYYY-MM-DD");
    dispatch(
      bookFilterActions.changeAppointmentDateTo({ appointmentDateTo: value })
    );
  };

  const handleSubmitFilters = () => {
    dispatch(bookFilterActions.changeSearch());
  };

  const handleLocationChange = (location) => {
    dispatch(
      bookFilterActions.changeServiceLocation({
        serviceLocation: location,
      })
    );
  };

  const handleTransactionChange = (type) => {
    dispatch(
      bookFilterActions.changeTransactionType({
        transactionType: type,
      })
    );
  };

  const handleClear = () => {
    dispatch(bookFilterActions.clearFilters());
  };

  return (
    <Fragment>
      <div className={classes.root}>
        <Grid container spacing={1} direction="row" alignItems="center">
          <Typography
            color="textPrimary"
            variant="h6"
            className={classes.title}
          >
            Filters
          </Typography>
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={5}>
              <div className={classes.daterangepicker}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="yyyy-MM-dd"
                    margin="normal"
                    autoOk
                    id="date-picker-inline-from"
                    label="Appointment Date From"
                    value={appointmentDateFrom}
                    minDate={appointmentMin}
                    maxDate={appointmentMax}
                    onChange={handleChangeFrom}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                  <div className={classes.to}>to</div>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="yyyy-MM-dd"
                    margin="normal"
                    autoOk
                    id="date-picker-inline-to"
                    label="Appointment Date To"
                    value={appointmentDateTo}
                    minDate={appointmentMin}
                    maxDate={appointmentMax}
                    onChange={handleChangeTo}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </Grid>
            <Grid item xs={2}>
              <SelectTransactionType
                handleTransactionChange={handleTransactionChange}
                transactionType={transactionType}
              />
            </Grid>
            <Grid item xs={2}>
              <SelectServiceLocation
                handleLocationChange={handleLocationChange}
                serviceLocation={serviceLocation}
                isMultiple={true}
              />
            </Grid>
            <div className={classes.submitButton}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmitFilters}
              >
                FILTER
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClear}
              >
                Clear
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </Fragment>
  );
};

export default Filters;
