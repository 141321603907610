import React from "react";
import { Button, CircularProgress } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import WarningIcon from "@material-ui/icons/Warning";

export default function WarningMessage(props) {
  const { handleCloseWarning, isOpen, message } = props;

  return (
    <div>
      <Dialog
        open={isOpen}
        maxWidth="xs"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="simple-dialog-title">
          Warning! <WarningIcon color="secondary" />
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText
            id="alert-dialog-description"
            style={{ fontWeight: "bold" }}
          >
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseWarning}
            size="small"
            variant="contained"
            color="secondary"
          >
            CLOSE
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
