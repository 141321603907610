import React from "react";
import { Button, CircularProgress } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

export default function DialogConfirmation(props) {
  const {
    handleClickOpenDialog,
    handleCloseDialogConfirm,
    openDialogConfirm,
    isLoading,
  } = props;

  return (
    <div>
      <Dialog
        open={openDialogConfirm}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent dividers>
          <DialogContentText
            id="alert-dialog-description"
            style={{ fontWeight: "bold" }}
          >
            Make sure all entries are correct, Do you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialogConfirm}
            size="small"
            variant="contained"
            color="secondary"
            disabled={isLoading}
          >
            CANCEL
          </Button>
          <Button
            onClick={handleClickOpenDialog}
            size="small"
            variant="contained"
            color="primary"
            autoFocus
            disabled={isLoading}
          >
            CONFIRM{" "}
            {isLoading && (
              <CircularProgress size={20} style={{ marginLeft: "3px" }} />
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
