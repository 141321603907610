import { Fragment, useCallback, useState, useRef } from 'react';
import axios from 'axios';
import AlertDialog from '../components/booking/AlertDialog';
import UsersTable from '../components/users/UsersTable';
import UsersAdd from '../components/usersAdd/UsersAdd';

// Api config
import config from '../utils/config';

const Users = (props) => {
  const { token } = props;

  const tableRef = useRef();

  // ==============MODAL STATES==============
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState('');
  const [typeAlert, setTypeAlert] = useState('success');

  //   ==============ADD Users STATES==============
  const [openAdd, setOpenAdd] = useState(false);

  const handleOpenAdd = useCallback(() => {
    setOpenAdd(true);
  }, []);

  const handleCloseAdd = useCallback(() => {
    setOpenAdd(false);
  }, []);

  const handleSubmitUser = useCallback(
    (values, actions) => {
      const options = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };
      axios
        .post(`${config.api}/register`, values, options)
        .then((response) => {
          setOpenAdd(false);
          setTimeout(() => {
            setAlert(true);
            setMessage('Successfully Added');
            setTypeAlert('success');
          }, 1500);

          tableRef.current && tableRef.current.onQueryChange();
        })
        .catch((error) => {
          console.log(error);
          setOpenAdd(false);
          setAlert(true);
          setTypeAlert('error');
          setMessage('Something went wrong please try again.');
        });
    },
    [token]
  );

  // =================ALERT - (MODAL)=================
  const handleCloseAlert = useCallback(() => {
    setAlert(false);
    setMessage('');
    setTypeAlert('success');
  }, []);

  return (
    <Fragment>
      <AlertDialog
        open={alert}
        typeAlert={typeAlert}
        message={message}
        handleCloseAlert={handleCloseAlert}
      />
      <UsersAdd
        open={openAdd}
        onOpen={handleOpenAdd}
        onClose={handleCloseAdd}
        handleSubmitUser={handleSubmitUser}
        token={props.token}
      />
      <UsersTable tableRef={tableRef} token={token} />
    </Fragment>
  );
};

export default Users;
