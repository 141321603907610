import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { MenuItem, TextField } from "@material-ui/core";

const MenuItemStyle = withStyles({
  root: {
    justifyContent: "center !important",
  },
})(MenuItem);

const useStyles = makeStyles((theme) => ({
  center: {
    textAlign: "center !important",
  },
}));

const liter_of_oils = [
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "11", label: "11" },
];
const CustomSelectLiterOfOil = ({
  field, // { name, value, onChange, onBlur }
  index,
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const classes = useStyles();

  return (
    <TextField
      select
      error={errors.variations && errors.variations[index]?.liter_of_oil && touched.variations && touched.variations[index]?.liter_of_oil ? true : false }
      helperText={errors.variations && errors.variations[index]?.liter_of_oil && touched.variations && touched.variations[index]?.liter_of_oil && errors.variations[index]?.liter_of_oil}
      {...field}
      {...props}
      classes={{
        root: classes.center,
      }}
    >
      <MenuItemStyle value="">
        <em>None</em>
      </MenuItemStyle>
      {liter_of_oils.map((option) => (
        <MenuItemStyle key={option.value} value={option.value}>
          {option.label}
        </MenuItemStyle>
      ))}
    </TextField>
  );
};
export default CustomSelectLiterOfOil;
