import React from "react";
import { TextField } from "@material-ui/core";

const InputField = ({
  field, // { name, value, onChange, onBlur }
  ...props
}) => {
  return (
    <TextField
      {...field}
      {...props}
      fullWidth
      inputProps={{ type: "number" }}
    ></TextField>
  );
};
export default InputField;
