import React, { useState, useEffect } from "react";
import axios from "axios";
import classes from "../../styles/Sidebar.module.css";
import { NavLink, useLocation } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import PersonIcon from "@material-ui/icons/Person";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import SettingsIcon from "@material-ui/icons/Settings";
import BuildIcon from "@material-ui/icons/Build";
import TrackChangesIcon from "@material-ui/icons/TrackChanges";
import SettingsApplicationsIcon from "@material-ui/icons/SettingsApplications";
import ListIcon from "@material-ui/icons/List";
import ScheduleIcon from "@material-ui/icons/Schedule";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import { makeStyles } from "@material-ui/core/styles";
import { appSliceAtions } from "../../store/app.slice";
import { useSelector, useDispatch } from "react-redux";

// Api config
import config from "../../utils/config";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
    backgroundColor: "#f0f0f0",
  },
}));

const itemsList2 = [
  {
    text: "Quotations",
    icon: <ListIcon />,
    path: "/quotations",
    id: "quotations",
    type: "group",
    lists: [
      {
        text: "Quotations Overview",
        icon: <ListIcon />,
        path: "/quotations/overview",
        id: "quotations",
        type: "",
        lists: [],
      },
      {
        text: "Quotations",
        icon: <ListIcon />,
        path: "/quotations/quotations",
        id: "quotations",
        type: "",
        lists: [],
      },
    ],
  },

  {
    text: "Mechanics Management",
    icon: <BuildIcon />,
    path: "/mechanics",
    id: "mechanics",
    type: "group",
    lists: [
      {
        text: "Assign Mechanics",
        icon: <BuildIcon />,
        path: "/assign-mechanics",
        id: "assign-mechanics",
        type: "",
        lists: [],
      },
      {
        text: "Mechanics",
        icon: <BuildIcon />,
        path: "/mechanics",
        id: "mechanics",
        type: "",
        lists: [],
      },
      {
        text: "Mechanics Attendance",
        icon: <BuildIcon />,
        path: "/mechanics-attendance",
        id: "mechanics-attendance",
        type: "",
        lists: [],
      },
      {
        text: "Mechanics Incentives",
        icon: <BuildIcon />,
        path: "/mechanics-incentives",
        id: "mechanics",
        type: "",
        lists: [],
      },
    ],
  },
  {
    text: "Ads Sources",
    icon: <TrackChangesIcon />,
    path: "/ads-sources",
    id: "ads-sources",
    type: "",
    lists: [],
  },
  {
    text: "Services",
    icon: <SettingsIcon />,
    path: "/services",
    id: "services",
    type: "group",
    lists: [
      {
        text: "Package Category",
        icon: <ListIcon />,
        path: "/services/package-categories",
        id: "package-category",
        type: "",
        lists: [],
      },
      {
        text: "Packages",
        icon: <ListIcon />,
        path: "/services/packages",
        id: "packages",
        type: "",
        lists: [],
      },
      {
        text: "Services",
        icon: <ListIcon />,
        path: "/services/services",
        id: "servicesServices",
        type: "",
        lists: [],
      },
      {
        text: "Services Categories",
        icon: <ListIcon />,
        path: "/services/categories",
        id: "servicesCategories",
        type: "",
        lists: [],
      },
    ],
  },
  {
    text: "Vehicles",
    icon: <LocalShippingIcon />,
    path: "/vehicles",
    id: "vehicles",
    type: "",
    lists: [],
  },
  {
    text: "Customers",
    icon: <PersonIcon />,
    path: "/customers",
    id: "customers",
    type: "",
    lists: [],
  },
  {
    text: "Users",
    icon: <PeopleAltIcon />,
    path: "/users",
    id: "users",
    type: "",
    lists: [],
  },
  {
    text: "Garage",
    icon: <DriveEtaIcon />,
    path: "/garage",
    id: "garage",
    type: "",
    lists: [],
  },
  {
    text: "Schedules",
    icon: <ScheduleIcon />,
    path: "/schedules",
    id: "schedules",
    type: "",
    lists: [],
  },
  {
    text: "Vouchers",
    icon: <LocalOfferIcon />,
    path: "/vouchers",
    id: "vouchers",
    type: "",
    lists: [],
  },
];

const DropdownList = React.memo((props) => {
  const { pathname } = useLocation();

  const classes2 = useStyles();
  const [open, setOpen] = useState(true);
  const [open2, setOpen2] = useState(true);
  const [user, setUser] = useState(false);
  const [subMenuOpen, setSubMenuOpen] = React.useState(null);

  const appStore = useSelector((state) => state.appStore);
  const dispatch = useDispatch();

  const userRole = localStorage.getItem("userRole");

  useEffect(() => {
    const token = localStorage.getItem("token");
    const fetchUser = async () => {
      const {
        data: { data },
      } = await axios.get(`${config.api}/user/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUser(data);
    };
    fetchUser().catch(console.error);
  }, []);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleClick2 = () => {
    setOpen2(!open2);
  };

  const handleSubMenuClick = (text) => {
    if (subMenuOpen === text) {
      setSubMenuOpen(null); // Close submenu if already open
    } else {
      setSubMenuOpen(text); // Open submenu for the clicked main menu item
    }
  };

  return (
    <List>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>
          <SupervisorAccountIcon />
        </ListItemIcon>
        <ListItemText primary="Admin" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List>
          {itemsList2.map((item) => {
            if (item.id === "ads-sources" && userRole !== "super_admin") {
              return null;
            }
            return (
              <div key={item.id}>
                <ListItem
                  button
                  onClick={() => {
                    dispatch(
                      appSliceAtions.changeHeaderName({
                        header_name: item.text,
                      })
                    );
                    if (item.type === "group") {
                      handleSubMenuClick(item.text);
                    }
                  }}
                  {...(item.type !== "group" && {
                    component: NavLink,
                    to: item.path,
                  })}
                  activeClassName={
                    item.type === "group" ? "" : classes.activeNav
                  }
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                  {item.type === "group" &&
                    (subMenuOpen === item.text ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    ))}
                </ListItem>
                {item.type === "group" && (
                  <Collapse
                    in={subMenuOpen === item.text}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      {item.lists.map((subItem) => (
                        <ListItem
                          button
                          key={subItem.id}
                          component={NavLink}
                          to={subItem.path}
                          activeClassName={classes.activeSubNav}
                          className={classes2.nested}
                          onClick={() => {
                            dispatch(
                              appSliceAtions.changeHeaderName({
                                header_name: subItem.text,
                              })
                            );
                          }}
                        >
                          <ListItemIcon>{subItem.icon}</ListItemIcon>
                          <ListItemText primary={subItem.text} />
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                )}
              </div>
            );
          })}
        </List>
      </Collapse>
    </List>
  );
});

export default DropdownList;
