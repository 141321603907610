import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { MenuItem, TextField } from "@material-ui/core";

const MenuItemStyle = withStyles({
  root: {
    justifyContent: "center !important",
  },
})(MenuItem);

const useStyles = makeStyles((theme) => ({
  center: {
    textAlign: "center !important",
  },
}));

const brands = [
  { value: "jac", label: "Jac" },
];
const CustomSelectBrand = ({
  field, // { name, value, onChange, onBlur }
  index,
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const classes = useStyles();

  return (
    <TextField
      select
      error={errors.variants && errors.variants[index]?.brand && touched.variants && touched.variants[index]?.brand ? true : false }
      helperText={errors.variants && errors.variants[index]?.brand && touched.variants && touched.variants[index]?.brand && errors.variants[index]?.brand}
      {...field}
      {...props}
      classes={{
        root: classes.center,
      }}
    >
      <MenuItemStyle value={''}>
        None    
      </MenuItemStyle>
      {brands.map((option) => (
        <MenuItemStyle key={option.value} value={option.value}>
          {option.label}
        </MenuItemStyle>
      ))}
    </TextField>
  );
};
export default CustomSelectBrand;
