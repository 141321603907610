import React from "react";
import { StyleSheet, View, Text } from "@react-pdf/renderer";
import data from "./data.json";

const styles = StyleSheet.create({
  column: {
    flexDirection: "column",
    flexWrap: "wrap",
    marginLeft: -4,
    maxHeight: 730,
  },
  box: {
    width: "49.3%",
    height: "auto",
    marginLeft: 4,
  },
  boxHeader: {
    color: "#fff",
    backgroundColor: "#000b8c",
    padding: 6,
  },
  boxBody: {
    borderWidth: 1,
    borderColor: "#000b8c",
    borderTop: 0,
    padding: 6,
  },
  row: {
    flexDirection: "row",
  },
  checkBox: {
    width: 13,
    height: 13,
    borderRadius: 50,
    marginRight: 6,
  },
  italic: {
    fontFamily: "Helvetica-Oblique",
  },
  bold: {
    fontFamily: "Helvetica-Bold",
  },
});

const Checklist = ({ content, tireSize }) => {
  const checkBox = (name) => {
    let condition = content.find((item) => item.type === name);
    let color = "currenColor";

    switch (condition?.selected) {
      case "Good":
        color = "#04fc05";
        break;
      case "Replace next PMS":
        color = "#ffa200";
        break;
      case "Replace now":
        color = "#fe0000";
        break;
      default:
        color = "#cdcdcd";
        break;
    }

    return <View style={[styles.checkBox, { backgroundColor: color }]}></View>;
  };

  const notes = (name) => {
    let condition = content.find((item) => item.type === name);

    if (condition?.note) {
      return (
        <View style={styles.row}>
          <Text style={styles.italic}>{condition.note}</Text>
        </View>
      );
    }
    return;
  };

  return (
    <>
      <View
        style={[
          styles.boxHeader,
          styles.bold,
          styles.row,
          {
            fontSize: 10,
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 4,
          },
        ]}
      >
        <View>
          <Text>Inspection Result</Text>
        </View>
        <View
          style={[
            styles.row,
            {
              justifyContent: "space-between",
              alignItems: "center",
            },
          ]}
        >
          <View style={styles.row}>
            <View style={[styles.row, { alignItems: "center" }]}>
              <View
                style={[styles.checkBox, { backgroundColor: "#04fc05" }]}
              ></View>
              <Text>Good</Text>
            </View>
            <View style={[styles.row, { alignItems: "center", marginLeft: 8 }]}>
              <View
                style={[styles.checkBox, { backgroundColor: "#ffa200" }]}
              ></View>
              <Text>Replace next PMS</Text>
            </View>
            <View style={[styles.row, { alignItems: "center", marginLeft: 8 }]}>
              <View
                style={[styles.checkBox, { backgroundColor: "#fe0000" }]}
              ></View>
              <Text>Replace now</Text>
            </View>
          </View>
        </View>
      </View>
      <View style={styles.column}>
        {data.map((list) => (
          <View style={styles.box} key={list.title}>
            <View style={[styles.boxHeader, styles.bold]}>
              <Text>{list.title}</Text>
            </View>
            <View style={styles.boxBody}>
              {list.items.map((item) => (
                <View style={styles.row} key={item.name}>
                  <View style={{ width: "55%" }}>
                    <View
                      style={[
                        styles.row,
                        { alignItems: "center", marginBottom: 0.5 },
                      ]}
                    >
                      {checkBox(item.name)}
                      <Text>{item.label}</Text>
                    </View>
                  </View>
                  <View style={{ width: "45%", alignItems: "flex-end" }}>
                    {item.name === "tiresize" && tireSize && (
                      <View style={styles.row}>
                        <Text style={styles.italic}>{tireSize}</Text>
                      </View>
                    )}
                    {notes(item.name)}
                  </View>
                </View>
              ))}
            </View>
          </View>
        ))}
      </View>
    </>
  );
};

export default Checklist;
