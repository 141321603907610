/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import axios from "axios";
import config from "../../utils/config";

export default function PackageCategorySelect({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, values, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) {
  const [categories, setCategories] = useState([]);

  const token = localStorage.getItem("token");

  useEffect(() => {
    async function fetchData() {
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const {
        data: { data },
      } = await axios.get(`${config.api}/package-category`, options);
      setCategories(data.data);
    }
    fetchData();
  }, []);

  return (
    <Autocomplete
      id="pacakge-category-select"
      size="small"
      options={categories}
      getOptionLabel={(option) => option.name}
      onChange={(event, value) => {
        setFieldValue("package_category", value);
      }}
      value={values.package_category}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Package Category"
          variant="outlined"
          error={errors.package_category && touched.package_category}
          helperText={
            errors.package_category &&
            touched.package_category &&
            errors.package_category
          }
        />
      )}
    />
  );
}
