import { Fragment, useCallback, useState, useRef, useEffect } from "react";
import axios from "axios";
import AlertDialog from "../components/booking/AlertDialog";
import ServicesCategoriesTable from "../components/serviceCategories/ServicesCategoriesTable";
import ServiceCategoryAdd from "../components/serviceCategories/ServiceCategoryAdd";

// Api config
import config from "../utils/config";

const ServicesCategories = (props) => {
  const { token } = props;

  const tableRef = useRef();
  const _isMounted = useRef(true);

  useEffect(() => {
    return () => {
      _isMounted.current = false;
    };
  }, []);
  // ==============MODAL STATES==============
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [typeAlert, setTypeAlert] = useState("success");

  // ==============ADD Manual Services STATES==============
  const [openAdd, setOpenAdd] = useState(false);

  const handleOpenAdd = useCallback(() => {
    setOpenAdd(true);
  }, []);

  const handleCloseAdd = useCallback(() => {
    setOpenAdd(false);
  }, []);

  const handleSubmit = useCallback(
    (values) => {
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      axios
        .post(`${config.api}/services/categories`, values, options)
        .then((response) => {
          if (_isMounted.current) {
            setOpenAdd(false);
            setTimeout(() => {
              setAlert(true);
              setMessage("Successfully Added");
              setTypeAlert("success");
            }, 1500);
            tableRef.current && tableRef.current.onQueryChange();
          }
        })
        .catch((error) => {
          if (_isMounted.current) {
            setOpenAdd(false);
            setAlert(true);
            setTypeAlert("error");
            setMessage("Something went wrong please try again.");
          }
        });
    },
    [token]
  );

  // =================ALERT - (MODAL)=================
  const handleCloseAlert = useCallback(() => {
    setAlert(false);
    setMessage("");
    setTypeAlert("success");
  }, []);

  return (
    <Fragment>
      <AlertDialog
        open={alert}
        typeAlert={typeAlert}
        message={message}
        handleCloseAlert={handleCloseAlert}
      />
      <ServiceCategoryAdd
        open={openAdd}
        onOpen={handleOpenAdd}
        onClose={handleCloseAdd}
        handleSubmit={handleSubmit}
      />
      <ServicesCategoriesTable tableRef={tableRef} token={token} />
    </Fragment>
  );
};

export default ServicesCategories;
