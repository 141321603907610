import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import { MenuItem, TextField } from '@material-ui/core';

// Api config
import config from '../../../utils/config';

const MenuItemStyle = withStyles({
  root: {
    justifyContent: 'center',
  },
})(MenuItem);

const CustomMakeComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const [brands, setBrands] = useState([]);

  useEffect(() => {
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${props.token}`,
      },
    };

    async function fetchData() {
      const request = await axios.get(config.api + '/vehicles/make', options);
      setBrands(request.data.data);
    }
    fetchData();
  }, [props.token]);

  return (
    <TextField
      select
      fullWidth
      error={errors.make && touched.make}
      helperText={errors.make && touched.make ? errors.make : ''}
      {...field}
      {...props}
      value={brands.length > 0 ? field.value : ''}
    >
      {brands.map((option) => (
        <MenuItemStyle key={option.name} value={option.name}>
          {option.name}
        </MenuItemStyle>
      ))}
    </TextField>
  );
};
export default CustomMakeComponent;
