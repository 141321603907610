import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { MenuItem, TextField } from "@material-ui/core";
import axios from "axios";
import config from "../../utils/config";
import { useSelector, useDispatch } from "react-redux";
import { bookFilterActions } from "../../store/book-filter-slice";

const MenuItemStyle = withStyles({
  root: {
    justifyContent: "center !important",
  },
})(MenuItem);

const useStyles = makeStyles((theme) => ({
  center: {
    textAlign: "center !important",
    width: "100%",
    marginTop: "1rem",
  },
}));

const SelectSalesAgent = ({ ...props }) => {
  const classes = useStyles();
  const token = localStorage.getItem("token");

  const [salesAgent, setSalesAgent] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const {
        data: { data },
      } = await axios.get(`${config.api}/users/sales-agent`, options);
      setSalesAgent(data);
    }
    fetchData();
  }, []);

  const dispatch = useDispatch();
  const salesAgentBookFilter = useSelector(
    (state) => state.bookFilter.salesAgent
  );

  const handleChange = (event) => {
    dispatch(
      bookFilterActions.setSalesAgent({
        salesAgent: event.target.value,
      })
    );
  };

  return (
    <TextField
      select
      // label="Sales Agent"
      value={salesAgentBookFilter}
      {...props}
      classes={{
        root: classes.center,
      }}
      onChange={handleChange}
    >
      {salesAgent.map((option) => (
        <MenuItemStyle key={option.id} value={option.id}>
          {option.name} {option.lastname}
        </MenuItemStyle>
      ))}
    </TextField>
  );
};
export default SelectSalesAgent;
