import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { MenuItem, TextField } from "@material-ui/core";

const MenuItemStyle = withStyles({
  root: {
    justifyContent: "center !important",
  },
})(MenuItem);

const useStyles = makeStyles((theme) => ({
  center: {
    textAlign: "center !important",
  },
}));

const categories = [
  { value: "Gas", label: "Gas" },
  { value: "Diesel", label: "Diesel" },
];

const CustomSelectFuelType = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const classes = useStyles();

  return (
    <TextField
      select
      error={errors.fuel_type && touched.fuel_type}
      helperText={errors.fuel_type && touched.fuel_type && errors.fuel_type}
      {...field}
      {...props}
      classes={{
        root: classes.center,
      }}
    >
      <MenuItemStyle value="None">
        <em>None</em>
      </MenuItemStyle>
      {categories.map((option) => (
        <MenuItemStyle key={option.value} value={option.value}>
          {option.label}
        </MenuItemStyle>
      ))}
    </TextField>
  );
};
export default CustomSelectFuelType;
