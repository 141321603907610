import { Fragment } from "react";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { green } from "@material-ui/core/colors";
import { Formik, Form, FastField } from "formik";
import { TextField } from "formik-material-ui";
import { object, string, number } from "yup";
import SelectServices from "../../common/SelectServices";

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: "#FAE700",
  },
  titleText: {
    color: "rgb(63,81,181) !important",
    fontWeight: "bold !important",
  },
  button: {
    backgroundColor: green[500],
    color: "#fff",
    "&:hover": {
      backgroundColor: green[600],
    },
  },
  paper: {
    width: "350px",
    maxWidth: "350px",
  },
  add: {
    backgroundColor: green[500],
    color: "#fff",
    "&:hover": {
      backgroundColor: green[600],
    },
  },
}));

const UpdateAddOns = React.memo((props) => {
  const classes = useStyles();

  const { open, onClose, data, onUpdate } = props;

  if (Array.isArray(data) && data.length === 0) {
    return null;
  }

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = (values, actions) => {
    if (values.fuel_type === "None") {
      values.fuel_type = "";
    }
    onUpdate({ ...values, id: data.service.id }, actions);
  };

  const initialValues = {
    service: data && data.service,
    service_name: data && data?.service?.name,
    description: data && data.description,
    saved_amount: data && data.saved_amount,
  };

  const validationSchema = object().shape({
    service: object().nullable().required("Service is a required field."),
    description: string().required("Description is a required field."),
    saved_amount: number().required("Amount saved is a required field."),
  });

  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.paper }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, errors, setFieldValue, setSubmitting, isSubmitting }) => (
            <Form autoComplete="off">
              <DialogTitle
                id="alert-dialog-title"
                className={`${classes.title}`}
              >
                <span className={classes.titleText}>
                  {"UPDATE ADD ONS ITEM"}
                </span>
              </DialogTitle>
              <DialogContent dividers>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FastField
                      label="Service"
                      component={SelectServices}
                      name="service"
                      type="text"
                      variant="outlined"
                      size="small"
                      fullWidth
                      inputProps={{
                        style: {
                          textAlign: "center",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FastField
                      label="Description"
                      component={TextField}
                      name="description"
                      type="text"
                      variant="outlined"
                      size="small"
                      fullWidth
                      inputProps={{
                        style: {
                          textAlign: "center",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FastField
                      label="Saved Amount"
                      component={TextField}
                      name="saved_amount"
                      type="number"
                      variant="outlined"
                      size="small"
                      fullWidth
                      inputProps={{
                        style: {
                          textAlign: "center",
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} size="small" color="default">
                  Close
                </Button>
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  color="primary"
                  variant="contained"
                  autoFocus
                  size="small"
                  endIcon={
                    isSubmitting ? <CheckCircleIcon size="0.9rem" /> : undefined
                  }
                >
                  {isSubmitting ? "Submitting" : "Submit"}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </Fragment>
  );
});
export default UpdateAddOns;
