import { Fragment, useCallback, useState, useEffect, useRef } from "react";
import axios from "axios";
import AlertDialog from "../components/booking/AlertDialog";
import VouchersTable from "../components/vouchers/VouchersTable";
import VouchersAdd from "../components/vouchers/VouchersAdd";

// Api config
import config from "../utils/config";

const Vouchers = (props) => {
  const { token } = props;

  const tableRef = useRef();

  // ==============MODAL STATES==============
  const [packages, setPackages] = useState([]);
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [typeAlert, setTypeAlert] = useState("success");
  const [openAdd, setOpenAdd] = useState(false);

  const fetchPackages = async () => {
    try {
      let options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const {
        data: {
          data: { data },
        },
      } = await axios.get(`${config.api}/packages?is_active=1`, options);
      let packageOptions = data.map((item) => {
        return {
          value: item.service_category_id + "-" + item.id,
          label: item.name,
          service_id: item.id,
          service_category_id: item.service_category_id,
        };
      });
      setPackages(packageOptions);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPackages();
  }, []);

  const handleOpenAdd = useCallback(() => {
    setOpenAdd(true);
  }, []);

  const handleCloseAdd = useCallback(() => {
    setOpenAdd(false);
  }, []);

  const handleSubmitVoucher = useCallback(
    (values, actions) => {
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      axios
        .post(`${config.api}/vouchers`, values, options)
        .then((response) => {
          console.log(response);
          setOpenAdd(false);
          setTimeout(() => {
            setAlert(true);
            setMessage("Successfully Added");
            setTypeAlert("success");
          }, 1500);

          tableRef.current && tableRef.current.onQueryChange();
        })
        .catch((error) => {
          console.log(error);
          setOpenAdd(false);
          setAlert(true);
          setTypeAlert("error");
          setMessage("Something went wrong please try again.");
        });
    },
    [token]
  );

  // =================ALERT - (MODAL)=================
  const handleCloseAlert = useCallback(() => {
    setAlert(false);
    setMessage("");
    setTypeAlert("success");
  }, []);

  return (
    <Fragment>
      <AlertDialog
        open={alert}
        typeAlert={typeAlert}
        message={message}
        handleCloseAlert={handleCloseAlert}
      />
      <VouchersAdd
        open={openAdd}
        onOpen={handleOpenAdd}
        onClose={handleCloseAdd}
        handleSubmitVoucher={handleSubmitVoucher}
        token={props.token}
        packages={packages}
      />
      <VouchersTable tableRef={tableRef} token={token} packages={packages} />
    </Fragment>
  );
};

export default Vouchers;
