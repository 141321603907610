import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { MenuItem, TextField } from '@material-ui/core';

const MenuItemStyle = withStyles({
  root: {
    justifyContent: 'center',
  },
})(MenuItem);

const ParkingSpaceComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const parkingSpaceOptions = [
    {
      value: 'Yes, I have a covered parking space',
      label: 'Yes, I have a covered parking space',
    },
    {
      value: 'Yes, I have an uncovered parking space',
      label: 'Yes, I have an uncovered parking space',
    },
    {
      value: 'No, I don’t have a parking space',
      label: 'No, I don’t have a parking space',
    },
  ];

  return (
    <TextField
      select
      fullWidth
      error={errors.year && touched.year}
      helperText={errors && errors.year}
      {...field}
      {...props}
    >
      <MenuItemStyle selected value={field.value}>
        ----Parking Space----
      </MenuItemStyle>
      {parkingSpaceOptions.map((option) => (
        <MenuItemStyle key={option.value} value={option.value}>
          {option.value}
        </MenuItemStyle>
      ))}
    </TextField>
  );
};
export default ParkingSpaceComponent;
