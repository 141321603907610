import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { MenuItem, TextField } from "@material-ui/core";
import axios from "axios";
import config from "../../utils/config";
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

const filter = createFilterOptions();

// const MenuItemStyle = withStyles({
//   root: {
//     justifyContent: "center !important",
//   },
// })(MenuItem);

const useStyles = makeStyles((theme) => ({
  center: {
    textAlign: "center !important",
  },
}));

const SelectServiceParts = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, values, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const classes = useStyles();
  const token = localStorage.getItem('token');
  const [serviceParts, setServiceParts] = useState([]);
  const [value, setValue] = React.useState(null);

  useEffect(() => {
    async function fetchData() {
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const { data: data } = await axios.get(
        `${config.api}/services/parts`,
        options
      );
      if (values?.name) {
        setValue(values.name)
      }
      setServiceParts(data.data);
    }
    fetchData();
  }, []);

  return (
    <Autocomplete
      value={value}
      onChange={(event, newValue) => {
        if (typeof newValue === 'string') {
          setValue(newValue);
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          setValue(newValue.inputValue);
          setFieldValue('name', newValue.inputValue)
        } else {
          setValue(newValue);
          setFieldValue('name', newValue?.part_name)
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        // Suggest the creation of a new value
        if (params.inputValue !== '') {
          filtered.push({
            inputValue: params.inputValue,
            part_name: `Add "${params.inputValue}"`,
          });
        }

        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="name"
      options={serviceParts}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.part_name;
      }}
      renderOption={(option) => option.part_name}
      style={{ width: 300 }}
      freeSolo
      renderInput={(params) => (
        <TextField
          {...params}
          label="Part Name"
          variant="outlined"
          error={errors.name && touched.name}
          helperText={errors.name && touched.name && errors.name}
        />
      )}
    />
  );
};
export default SelectServiceParts;
