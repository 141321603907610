import { Fragment, useState } from "react";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import ListAltSharpIcon from "@material-ui/icons/ListAltSharp";
import AddIcon from "@material-ui/icons/Add";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { green } from "@material-ui/core/colors";
import { Formik, Form, FastField, Field, FieldArray } from "formik";
import { TextField } from "formik-material-ui";
import SelectInternalService from "../common/SelectInternalService";
import CustomSelectFuelType from "./addPackage/customSelectFuelType/CustomSelectFuelType";
import CustomSelectLiterOfOil from "./addPackage/customSelectLiterOfOil/CustomSelectLiterOfOil";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { object, number, array, string } from "yup";
import AlertDialog from "../booking/AlertDialog";
// components
import PackageCategorySelect from "../common/PackageCategorySelect";
import CustomIsActive from "../common/SelectIsActive";
import SelectOdometer from "../common/SelectOdometer";
import PackageSubTable from "./packageSubTable/PackageSubTable";
import InclusionsTable from "./addPackage/inclusions/InclusionsTable";
import AddOnsTable from "./addOns/AddOnsTable";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    backgroundColor: "#FAE700",
    color: "#000",
  },
  button: {
    color: theme.palette.grey[900],
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  icon: {
    position: "absolute",
    top: "18px",
    marginLeft: "3px",
  },
  titleText: {
    color: "rgb(63,81,181) !important",
    fontWeight: "bold !important",
  },
  buttonAdd: {
    backgroundColor: green[500],
    color: "#fff",
    "&:hover": {
      backgroundColor: green[600],
    },
  },
  add: {
    backgroundColor: green[500],
    color: "#fff",
    "&:hover": {
      backgroundColor: green[600],
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
      timeout={{ appear: 100, enter: 500, exit: 500 }}
    />
  );
});

const AddPackage = React.memo((props) => {
  const classes = useStyles();

  const { open, onOpen, onClose, isLoading, handleSubmitAddPackage } = props;

  // ==============MODAL STATES==============
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [typeAlert, setTypeAlert] = useState("success");

  const handleCloseAlert = () => {
    setAlert(false);
    setMessage("");
    setTypeAlert("success");
  };

  const handleOpen = () => {
    onOpen();
  };

  const handleClose = () => {
    onClose();
  };

  const handleCheckDuplicate = (index, name, value, lists) => {
    let vehicle_type =
      name === "vehicle_type" ? value : lists[index].vehicle_type;
    let fuel_type = name === "fuel_type" ? value : lists[index].fuel_type;
    let liter_of_oil =
      name === "liter_of_oil" ? value : lists[index].liter_of_oil;

    if (vehicle_type !== "" && fuel_type !== "" && liter_of_oil !== "") {
      var filtered = lists.filter(
        (c) =>
          c.vehicle_type === vehicle_type &&
          c.fuel_type === fuel_type &&
          c.liter_of_oil === liter_of_oil
      );
      if (filtered.length > 0) {
        setAlert(true);
        setTypeAlert("error");
        setMessage("The variant already exists.");
        return true;
      }
    }
    return false;
  };

  const handleSubmit = (values, actions) => {
    handleSubmitAddPackage(
      { ...values, package_category_id: values.package_category.id },
      actions
    );
  };

  const initialValues = {
    package_category: {},
    name: "",
    service_category_id: 1,
    fuel_type: null,
    is_public: "manual",
    variants: [
      {
        fuel_type: "",
        variant: "",
        liter_of_oil: "",
        price: 0,
      },
    ],
    inclusions: [],
    addOns: [],
  };

  const validationSchema = object().shape({
    package_category_id: object({
      id: number().nullable(true),
      is_active: string().nullable(true),
      is_public: string().nullable(true),
    }).required("Package category is required"),
    name: string().required("Package name is required"),
    is_public: string().required("This is required"),
    variants: array(
      object({
        variant: string().nullable(true),
        fuel_type: string().nullable(true),
        liter_of_oil: number().nullable(true),
        price: number().test(
          "nonZero",
          "Price must NOT be a zero.",
          (value) => value !== 0
        ),
      })
    ).required(),
    is_active: number().required("Is active is required"),
  });

  return (
    <Fragment>
      <div>
        <AlertDialog
          open={alert}
          typeAlert={typeAlert}
          message={message}
          handleCloseAlert={handleCloseAlert}
        />
        <Box mb={2} display="flex" flexDirection="row-reverse">
          <Button
            onClick={handleOpen}
            className={classes.buttonAdd}
            variant="contained"
            size="medium"
            startIcon={<AddIcon />}
          >
            Add Package
          </Button>
        </Box>

        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          TransitionComponent={Transition}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              setFieldValue,
              setSubmitting,
              isSubmitting,
              handleChange,
              handleBlur,
            }) => (
              <Form autoComplete="off">
                <AppBar className={classes.appBar}>
                  <Toolbar>
                    <IconButton
                      edge="start"
                      color="inherit"
                      onClick={handleClose}
                      aria-label="close"
                    >
                      <CloseIcon className={classes.button} />
                    </IconButton>
                    <Typography variant="h5" className={classes.title}>
                      ADD PACKAGE FORM
                      <ListAltSharpIcon className={classes.icon} />
                    </Typography>
                    <Button
                      disabled={isLoading}
                      type="submit"
                      startIcon={
                        isLoading ? (
                          <CircularProgress size="0.9rem" />
                        ) : undefined
                      }
                    >
                      {isLoading ? "Saving" : "Save"}
                    </Button>
                  </Toolbar>
                </AppBar>
                <DialogContent dividers>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <FastField
                        label="Package Category"
                        component={PackageCategorySelect}
                        name="package_category"
                        type="text"
                        variant="outlined"
                        size="small"
                        fullWidth
                        inputProps={{
                          style: {
                            textAlign: "center",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FastField
                        label="Package Name"
                        component={TextField}
                        name="name"
                        type="text"
                        variant="outlined"
                        size="small"
                        fullWidth
                        inputProps={{
                          style: {
                            textAlign: "center",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FastField
                        label="Odometer"
                        component={SelectOdometer}
                        name="odometer"
                        type="text"
                        variant="outlined"
                        size="small"
                        fullWidth
                        inputProps={{
                          style: {
                            textAlign: "center",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FastField
                        label="Hours Taken"
                        component={TextField}
                        name="hours_taken"
                        type="number"
                        variant="outlined"
                        size="small"
                        fullWidth
                        inputProps={{
                          style: {
                            textAlign: "center",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FastField
                        name="is_active"
                        label="Status"
                        variant="outlined"
                        size="small"
                        component={CustomIsActive}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FastField
                        name="is_public"
                        label="Show in Front End"
                        variant="outlined"
                        size="small"
                        component={SelectInternalService}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FastField
                        label="Description"
                        component={TextField}
                        name="description"
                        type="text"
                        variant="outlined"
                        size="small"
                        fullWidth
                        multiline
                        rows={3}
                        rowsMax={3}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FastField
                        name="guidelines"
                        label="Guidelines"
                        variant="outlined"
                        size="small"
                        component={TextField}
                        fullWidth
                        multiline
                        rows={3}
                        rowsMax={3}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InclusionsTable
                        inclusions={values.inclusions}
                        setFieldValue={setFieldValue}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <AddOnsTable
                        addOns={values.addOns}
                        setFieldValue={setFieldValue}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FieldArray name="variants">
                        {({ push, remove }) => (
                          <React.Fragment>
                            <Box
                              mb={1}
                              display="flex"
                              flexDirection="row-reverse"
                            >
                              <Box flexShrink={0}>
                                <Button
                                  variant="contained"
                                  className={classes.add}
                                  startIcon={<AddCircleIcon />}
                                  onClick={() => {
                                    push({
                                      fuel_type: "",
                                      variant: "",
                                      liter_of_oil: "",
                                      price: "0",
                                    });
                                  }}
                                  disabled={isSubmitting}
                                >
                                  Add Variant
                                </Button>
                              </Box>
                            </Box>
                            <TableContainer component={Paper}>
                              <Table size="small" aria-label="simple table">
                                <TableHead>
                                  <TableRow>
                                    <TableCell
                                      style={{
                                        fontWeight: "bold",
                                        width: "23%",
                                      }}
                                      align="center"
                                    >
                                      Fuel Type
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        fontWeight: "bold",
                                        width: "23%",
                                      }}
                                      align="center"
                                    >
                                      Liter of Oil
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        fontWeight: "bold",
                                        width: "23%",
                                      }}
                                      align="center"
                                    >
                                      Price
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        fontWeight: "bold",
                                        width: "8%",
                                      }}
                                      align="center"
                                    >
                                      Action
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {values.variants.map((value, index) => (
                                    <TableRow key={index}>
                                      <TableCell
                                        align="center"
                                        scope="row"
                                        style={{ width: "23%" }}
                                      >
                                        <Field
                                          name={`variants.${index}.fuel_type`}
                                          variant="standard"
                                          index={index}
                                          size="small"
                                          fullWidth
                                          component={CustomSelectFuelType}
                                          onChange={async (e) => {
                                            const { value } = e.target;
                                            let result =
                                              await handleCheckDuplicate(
                                                index,
                                                "fuel_type",
                                                value,
                                                values.variants
                                              );
                                            if (!result) {
                                              setFieldValue(
                                                `variants.${index}.fuel_type`,
                                                value
                                              );
                                            }
                                          }}
                                        />
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        scope="row"
                                        style={{ width: "23%" }}
                                      >
                                        <Field
                                          name={`variants.${index}.liter_of_oil`}
                                          variant="standard"
                                          index={index}
                                          size="small"
                                          fullWidth
                                          component={CustomSelectLiterOfOil}
                                          onChange={async (e) => {
                                            const { value } = e.target;
                                            let result =
                                              await handleCheckDuplicate(
                                                index,
                                                "liter_of_oil",
                                                value,
                                                values.variants
                                              );
                                            if (!result) {
                                              setFieldValue(
                                                `variants.${index}.liter_of_oil`,
                                                value
                                              );
                                            }
                                          }}
                                        />
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        scope="row"
                                        style={{ width: "23%" }}
                                      >
                                        <Field
                                          component={TextField}
                                          name={`variants.${index}.price`}
                                          type="text"
                                          variant="standard"
                                          size="small"
                                          fullWidth
                                          inputProps={{
                                            style: {
                                              textAlign: "center",
                                              textTransform: "capitalize",
                                            },
                                          }}
                                        />
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        scope="row"
                                        style={{ width: "8%" }}
                                      >
                                        <Tooltip title="Remove">
                                          <IconButton
                                            color="secondary"
                                            aria-label="remove"
                                            onClick={() => {
                                              remove(index);
                                            }}
                                            disabled={isSubmitting}
                                          >
                                            <HighlightOffIcon
                                              className={classes.largeIcon}
                                            />
                                          </IconButton>
                                        </Tooltip>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </React.Fragment>
                        )}
                      </FieldArray>
                    </Grid>
                  </Grid>
                </DialogContent>
              </Form>
            )}
          </Formik>
        </Dialog>
      </div>
    </Fragment>
  );
});
export default AddPackage;
