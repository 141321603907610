import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const attendanceFilterSlice = createSlice({
  name: "attendance-filter",
  initialState: {
    search: true,
    date_from: moment().startOf('month').format('YYYY-MM-DD hh:mm'),
    date_to: moment().endOf('month').format('YYYY-MM-DD hh:mm'),
  },
  reducers: {
    changeSearch(state) {
      state.search = true;
    },
    clearFilters(state) {
      state.date_from = null;
      state.date_to = null;
    },
    changeFilterDateRange(state, action) {
      state.date_from = action.payload.date_from;
      state.date_to = action.payload.date_to;
      state.search = false;
    },
  },
});

export const attendanceFilterActions = attendanceFilterSlice.actions;
export default attendanceFilterSlice;
