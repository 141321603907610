import { Fragment, useRef } from "react";
import RequestQuotationTable from "../components/requestQuotationTable/RequestQuotationTable";

const QuotationRequest = (props) => {
  const { token } = props;

  const tableRef = useRef();

  return (
    <Fragment>
      <RequestQuotationTable tableRef={tableRef} token={token} />
    </Fragment>
  );
};

export default QuotationRequest;
