import { Fragment, useCallback, useState, useRef } from "react";
import axios from "axios";
import AlertDialog from "../components/booking/AlertDialog";
import VehiclesTable from "../components/vehiclesTable/VehiclesTable";
import VehiclesAdd from "../components/vehiclesAdd/VehiclesAdd";

// Api config
import config from "../utils/config";

const Vehicles = (props) => {
  const { token } = props;
  
  const tableRef = useRef();

  // ==============MODAL STATES==============
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [typeAlert, setTypeAlert] = useState("success");

  const [openAdd, setOpenAdd] = useState(false);
  //   ==============ADD Vehicles==============

  const handleOpenAdd = useCallback(() => {
    setOpenAdd(true);
  }, []);

  const handleCloseAdd = useCallback(() => {
    setOpenAdd(false);
  }, []);

  const handleSubmitManualServices = useCallback((values, actions) => {
    const options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .post(`${config.api}/vehicle`, values, options)
      .then((response) => {
        setOpenAdd(false);
        setTimeout(() => {
          setAlert(true);
          setMessage("Successfully Added");
          setTypeAlert("success");
        }, 1500);

        tableRef.current && tableRef.current.onQueryChange();
      })
      .catch((error) => {
        actions.setSubmitting(false);
        setAlert(true);
        setTypeAlert("error");
        setMessage(error.response.data.data);
      });
  }, [token]);

  // =================ALERT - (MODAL)=================
  const handleCloseAlert = useCallback(() => {
    setAlert(false);
    setMessage("");
    setTypeAlert("success");
  }, []);

  return (
    <Fragment>
      <AlertDialog
        open={alert}
        typeAlert={typeAlert}
        message={message}
        handleCloseAlert={handleCloseAlert}
      />
      <VehiclesAdd
        open={openAdd}
        onOpen={handleOpenAdd}
        onClose={handleCloseAdd}
        handleSubmitManualServices={handleSubmitManualServices}
        token={props.token}
      />
      <VehiclesTable tableRef={tableRef} token={token} />
    </Fragment>
  );
};

export default Vehicles;
