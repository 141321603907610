export const emptyPackage = {
  id: '',
  name: '',
  service_category_id: '',
  description: '',
  quantity: 1,
  type: '',
  variants: {
    id: '',
    service_id: '',
    fuel_type: '',
    vehicle_type: '',
    variant: '',
    liter_of_oil: '',
    price: '0.00',
  },
};

export const emptyService = {
  id: '',
  name: '',
  remarks: '',
  quantity: 0,
  price: 0.0,
  totalPrice: 0,
  type: 'manual',
};

export const emptyParts = {
  id: '',
  part_number: '',
  name: '',
  remarks: '',
  brand_id: null,
  category_id: null,
  quantity: 1,
  price: 0.0,
  totalPrice: 0,
};

export const emptyTires = {
  sku: '',
  quantity: 1,
  price: 0.0,
  totalPrice: 0,
};