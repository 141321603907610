import React from 'react';
import MaterialTable from 'material-table';
import { tableIcons } from '../booking/TableIcons';
import GarageSubTable from './garageSubTable/GarageSubTable';

// Api config
import config from '../../utils/config';

const ServicesTable = React.memo((props) => {
  const { tableRef, token } = props;

  return (
    <MaterialTable
      icons={tableIcons}
      title={`User's Garage Table`}
      tableRef={tableRef}
      columns={[
        { title: 'Customer ID', field: 'customer_id' },
        {
          title: 'Name',
          render: (rowData) => {
            const { name, lastname } = rowData;
            return lastname === null ? name : `${name} ${lastname}`;
          },
        },
        { title: 'Contact #', field: 'phone' },
        { title: 'Email', field: 'email' },
      ]}
      data={(query) =>
        new Promise((resolve, reject) => {
          let url = config.api + '/users/vehicles';
          url += `?page=${query.page + 1}`;
          if (query.pageSize) {
            url += `&per_page=${query.pageSize}`;
          }
          if (query.search) {
            url += `&search=${query.search}`;
          }
          const options = {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          };

          fetch(url, options)
            .then((response) => response.json())
            .then((result) => {
              resolve({
                data: result.data.data,
                page: result.data.current_page - 1 || 0,
                totalCount: result.data.total,
              });
            });
        })
      }
      options={{
        headerStyle: {
          fontWeight: 'bold',
        },
        rowStyle: {
          fontSize: '.75rem',
          padding: '0px !important',
        },
        actionsCellStyle: {
          justifyContent: 'center',
          padding: '24px',
          marginBottom: '-1px',
        },
        pageSize: 30,
        pageSizeOptions: [],
        actionsColumnIndex: -1,
        tableLayout: 'auto',
        sorting: false
      }}
      detailPanel={(rowData) => (
        <GarageSubTable tableRef={tableRef} token={token} data={rowData} />
      )}
    />
  );
});

export default ServicesTable;
