import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { MenuItem, TextField } from "@material-ui/core";
import axios from "axios";
import config from "../../utils/config";

const MenuItemStyle = withStyles({
  root: {
    justifyContent: "center",
  },
})(MenuItem);

const ServiceLocationComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const token = localStorage.getItem("token");
  const [hubs, setHubs] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const {
        data: { data },
      } = await axios.get(`${config.api}/garages`, options);
      setHubs(data);
    }
    fetchData();
  }, []);

  return (
    <TextField
      fullWidth
      select
      error={errors.service_location && touched.service_location}
      helperText={errors && errors.service_location}
      {...field}
      {...props}
    >
      {hubs.map((option) => (
        <MenuItemStyle key={option.value} value={option.id}>
          {option.name}
        </MenuItemStyle>
      ))}
    </TextField>
  );
};
export default ServiceLocationComponent;
