import { Fragment } from 'react';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Formik, Form, FastField } from 'formik';
import CancelIcon from '@material-ui/icons/Cancel';
import { MenuItem, TextField as MuiTextField } from "@material-ui/core";
// Api config
import config from '../../../utils/config';

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: '#FAE700',
  },
  titleText: {
    color: 'rgb(63,81,181) !important',
    fontWeight: 'bold !important',
  },
  ul: {
    listStyleType: 'none',
    padding: '0px',
    marginTop: '3px !important',
  },
  question: {
    marginBottom: '12px !important',
  },
  label: {
    marginRight: '5px',
    fontSize: '15px !important',
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: '600',
  },
  dataQuestion: {
    fontWeight: '600',
    fontSize: '15px !important',
  },
  data: {
    fontWeight: '600',
    textTransform: 'capitalize',
    fontSize: '15px !important',
  },
}));

const MenuItemStyle = withStyles({
  root: {
    justifyContent: "center",
  },
})(MenuItem);

const BackJobDialog = React.memo((props) => {
  const classes = useStyles();

  const { token, data, open, handleTagClose, handleTagBackJob } = props;
  const [users, setUsers] = useState([]);

  useEffect(() => {
    axios
      .get(`${config.api}/users?&page=1&per_page=30`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setUsers(response.data.data.data);
        // console.log(response);
      })
      .catch((error) => {
        // console.log(error);
      });
  }, []);

  const handleClose = () => {
    handleTagClose();
  };

  const handleSubmit = (values) => {
    handleTagBackJob(values);
  };

  const initialValues = {
    id: data.id,
    is_backjob: data.is_backjob ? data.is_backjob?.id : 0,
  };

  if (Array.isArray(data) && data.length === 0) {
    return null;
  }

  const HasBackJoBoptions = ({
    field, // { name, value, onChange, onBlur }
    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
  }) => {
  const classes = useStyles();

  const modes = [
    { value: 1, label: "Yes" },
    { value: 0, label: "No" },
  ];
  return (
    <div className={classes.root}>
      <MuiTextField
        select
        error={errors.is_backjob && touched.is_backjob}
        helperText={errors && errors.is_backjob}
        {...field}
        {...props}
        inputProps={{
          style: {
            textAlign: "center",
          },
        }}
      >
        {modes.map((option) => (
          <MenuItemStyle key={option.value} value={option.value}>
            {option.label}
          </MenuItemStyle>
        ))}
      </MuiTextField>
    </div>
  );
};

  return (
    <Fragment>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xs"
          classes={{ paper: classes.paper }}
        >
          <Formik
            initialValues={initialValues}
            // validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, isSubmitting, setFieldValue }) => (
              <Form autoComplete="off">
                <DialogTitle
                  id="alert-dialog-title"
                  className={`${classes.title}`}
                >
                  <span className={classes.titleText}>Tag Backjob</span>
                </DialogTitle>
                <DialogContent dividers>
                  <Box mb={1}>
                    <FastField
                      name="is_backjob"
                      label="Has backjobs?"
                      variant="outlined"
                      size="small"
                      component={(props) => (
                        <HasBackJoBoptions {...props}/>
                      )}
                      fullWidth
                      onChange={(e) => {
                        const { value } = e.target;
                        setFieldValue('is_backjob', value);
                      }}
                    />
                  </Box>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={handleClose}
                    color="secondary"
                    startIcon={<CancelIcon />}
                    size="small"
                    variant="contained"
                    disabled={isSubmitting}
                  >
                    Close
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    color="primary"
                    variant="contained"
                    autoFocus
                    size="small"
                    endIcon={
                      isSubmitting ? (
                        <CheckCircleIcon size="0.9rem" />
                      ) : undefined
                    }
                  >
                    {isSubmitting ? 'Submitting' : 'Submit'}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>
      </div>
    </Fragment>
  );
});
export default BackJobDialog;
