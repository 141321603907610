import { Fragment } from 'react';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { green } from '@material-ui/core/colors';
import { Formik, Form, FastField } from 'formik';
import { TextField } from 'formik-material-ui';
import { object, string, number } from 'yup';
import CustomSelectFuelType from '../../../packages/addPackage/customSelectFuelType/CustomSelectFuelType';
import CustomSelectLiterOfOil from '../../../packages/addPackage/customSelectLiterOfOil/CustomSelectLiterOfOil';

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: '#FAE700',
  },
  titleText: {
    color: 'rgb(63,81,181) !important',
    fontWeight: 'bold !important',
  },
  button: {
    backgroundColor: green[500],
    color: '#fff',
    '&:hover': {
      backgroundColor: green[600],
    },
  },
  paper: {
    width: '350px',
    maxWidth: '350px',
  },
  add: {
    backgroundColor: green[500],
    color: '#fff',
    '&:hover': {
      backgroundColor: green[600],
    },
  },
}));

const AddVariant = React.memo((props) => {
  const classes = useStyles();

  const { open, onOpen, onClose, service_id, handleSubmitAdd } = props;

  const handleOpen = () => {
    onOpen();
  };

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = (values, actions) => {
    handleSubmitAdd(values, actions);
  };

  const initialValues = {
    service_id: service_id,
    fuel_type: '',
    liter_of_oil: 0,
    price: null,
  };

  const validationSchema = object().shape({
    price: number().required('Price is required!').typeError('Price must be a number'),
  });

  return (
    <Fragment>
      <div>
        <Box my={2} mr={2} display="flex" flexDirection="row-reverse">
          <Button
            onClick={handleOpen}
            className={classes.button}
            variant="contained"
            size="medium"
            startIcon={<AddIcon />}
          >
            Add Variant
          </Button>
        </Box>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          disableBackdropClick
          classes={{ paper: classes.paper }}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              setFieldValue,
              setSubmitting,
              isSubmitting,
            }) => (
              <Form autoComplete="off">
                <DialogTitle
                  id="alert-dialog-title"
                  className={`${classes.title}`}
                >
                  <span className={classes.titleText}>
                    {'ADD NEW SERVICE VARIANT'}
                  </span>
                </DialogTitle>
                <DialogContent dividers>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <FastField
                        name="fuel_type"
                        label="Fuel Type"
                        variant="outlined"
                        size="small"
                        component={CustomSelectFuelType}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FastField
                        label="Liter of oil"
                        component={CustomSelectLiterOfOil}
                        name="liter_of_oil"
                        type="number"
                        variant="outlined"
                        size="small"
                        fullWidth
                        inputProps={{
                          style: {
                            textAlign: 'center',
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FastField
                        label="Price"
                        component={TextField}
                        name="price"
                        type="number"
                        variant="outlined"
                        size="small"
                        fullWidth
                        inputProps={{
                          style: {
                            textAlign: 'center',
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} size="small" color="default">
                    Close
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    color="primary"
                    variant="contained"
                    autoFocus
                    size="small"
                    endIcon={
                      isSubmitting ? (
                        <CheckCircleIcon size="0.9rem" />
                      ) : undefined
                    }
                  >
                    {isSubmitting ? 'Submitting' : 'Submit'}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>
      </div>
    </Fragment>
  );
});
export default AddVariant;
