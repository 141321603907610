import React, { Fragment, useState } from 'react';
import MaterialTable from 'material-table';
import { useHistory } from 'react-router-dom';
// mui
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from "@material-ui/icons/Visibility";
import { makeStyles } from '@material-ui/core/styles';
import { yellow } from '@material-ui/core/colors';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
// api config
import config from '../../utils/config';

const useStyles = makeStyles((theme) => ({
  view: {
    color: yellow[500],
    "&:hover": {
      color: "#fff",
    },
  },
  sizeSmall: {
    height: '18px',
    fontSize: '12px',
  },
}));
  
  const MechanicIncentivesDashboard = (props) => {
    const { tableRef, token, month, year } = props;
    const history = useHistory();
    const classes = useStyles();

  const [loading, setLoading] = useState(false);

  // ==============MODAL STATES==============
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    typeAlert: "success",
  });

  return (
        <Fragment>
          <MaterialTable
            title={`Mechanics Incentives Dashboard - As of ${month} ${year}`}
            tableRef={tableRef}
            columns={[
                {
                    title: 'Mechanics',
                    render: (row) => {
                      return (
                        <p>{`${row.firstname}  ${row.lastname}`}</p>
                      );
                    },
                },
                {
                    title: 'Bookings',
                    render: (row) => {
                      return (
                        <p>{row?.number_of_bookings}</p>
                      );
                    },
                },
                {
                    title: 'Bookings (%)',
                    render: (row) => {
                      return (
                        <p>{row?.bookings_percentage}</p>
                      );
                    },
                },
                {
                  title: 'Bookings Incentive',
                  render: (row) => {
                    return (
                      <p>{row?.bookings_incentive}</p>
                    );
                  },
              },
                {
                    title: 'Nets Sales',
                    render: (row) => {
                      return (
                        <p>{row?.net_sales}</p>
                      );
                    },
                },
                {
                    title: 'Nets Sales (%)',
                    render: (row) => {
                      return (
                        <p>{row?.net_sales_percentage}</p>
                      );
                    },
                },
                {
                  title: 'Nets Sales Incentive',
                  render: (row) => {
                    return (
                      <p>{row?.net_sales_incentive}</p>
                    );
                  },
              },
                {
                    title: 'Total Incentives',
                    render: (row) => {
                      return (
                        <p>{row?.total_incentives}</p>
                      );
                    },
                }
            ]}
            data={(query) =>
              new Promise((resolve, reject) => {
                let url = config.api + '/mechanics/incentives/overview?';
                url += `page=${query.page + 1}`;
                if (month && year) {
                  const monthNumber = moment().month(month).format("M");
                  url += `&month=${monthNumber}&year=${year}`
                }
                if (query.pageSize) {
                  url += `&per_page=${query.pageSize}`;
                }
                if (query.search) {
                  url += `&search=${query.search}`;
                }
                const options = {
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                  },
                };
                fetch(url, options)
                  .then((response) => response.json())
                  .then((result) => {
                    resolve({
                      data: result?.data?.data,
                      page: result?.data?.current_page - 1 || 0,
                      totalCount: result?.data?.total,
                    });
                  })
              })
            }
            actions={[
              {
                icon: "view",
                tooltip: "View",
                onClick: (event, rowData) => {
                  history.push(`/mechanics-bookings/${rowData.id}?month=${month}&year=${year}`)
                }
              },
            ]}
            components={{
              Action: (props) => {
                // =====================View=====================
                if (props.action.icon === 'view') {
                    return (
                        <Tooltip title="View">
                        <IconButton
                            aria-label="view"
                            className={classes.view}
                            size="small"
                            onClick={(event) => props.action.onClick(event, props.data)}
                        >
                            <VisibilityIcon />
                        </IconButton>
                        </Tooltip>
                    );
                   }
                } 
            }}
            options={{
              headerStyle: {
                fontWeight: 'bold',
              },
              rowStyle: {
                fontSize: '.75rem',
                padding: '0px !important',
              },
              actionsCellStyle: {
                justifyContent: 'center',
                padding: '24px',
                marginBottom: '-1px',
              },
              pageSize: 15,
              pageSizeOptions: [],
              actionsColumnIndex: -1,
              tableLayout: 'auto',
              sorting: false,
              search: false
            }}
          />
        </Fragment>
    )
  }

  export default React.memo(MechanicIncentivesDashboard)