import { Fragment, useRef } from "react";
import { useParams, useLocation } from 'react-router-dom';
// component
import MechanicBookingsTable from "../components/incentives/MechanicBookingsTable";

const MechanicBookings = (props) => {
    const { id } = useParams();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const { token } = props;
    const tableRef = useRef();

    const month = searchParams.get("month");
    const year = searchParams.get("year");

    return (
      <Fragment>
        <MechanicBookingsTable token={token} tableRef={tableRef} mechanicId={id} month={month} year={year}/>
      </Fragment>
    )
}

export default MechanicBookings;