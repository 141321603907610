import { Fragment } from "react";
import React from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { Formik, Form, FastField } from "formik";
import { object, string } from "yup";
import CancelIcon from "@material-ui/icons/Cancel";
import SelectUser from "../../common/SelectUser";

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: "#FAE700",
  },
  titleText: {
    color: "rgb(63,81,181) !important",
    fontWeight: "bold !important",
  },
  ul: {
    listStyleType: "none",
    padding: "0px",
    marginTop: "3px !important",
  },
  question: {
    marginBottom: "12px !important",
  },
  label: {
    marginRight: "5px",
    fontSize: "15px !important",
    color: "rgba(0, 0, 0, 0.54)",
    fontWeight: "600",
  },
  dataQuestion: {
    fontWeight: "600",
    fontSize: "15px !important",
  },
  data: {
    fontWeight: "600",
    textTransform: "capitalize",
    fontSize: "15px !important",
  },
}));

const TagDialog = React.memo((props) => {
  const classes = useStyles();

  const { data, open, handleTagClose, handleTag } = props;

  const handleClose = () => {
    handleTagClose();
  };

  const handleSubmit = (values) => {
    handleTag(values);
  };

  const initialValues = {
    id: data.id,
    created_by: data.created_by ? data.created_by : "",
  };

  //   const validationSchema = object().shape({
  //     created_by: string().required(),
  //   });

  if (Array.isArray(data) && data.length === 0) {
    return null;
  }

  return (
    <Fragment>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          disableBackdropClick
          fullWidth
          maxWidth="xs"
          classes={{ paper: classes.paper }}
        >
          <Formik
            initialValues={initialValues}
            // validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, isSubmitting, setFieldValue }) => (
              <Form autoComplete="off">
                <DialogTitle
                  id="alert-dialog-title"
                  className={`${classes.title}`}
                >
                  <span className={classes.titleText}>REMARKS</span>
                </DialogTitle>
                <DialogContent dividers>
                  <ul className={classes.ul}>
                    <li>
                      <div style={{ display: "flex" }}>
                        <div className={classes.label}>Customer Name : </div>
                        <div className={classes.data}>
                          {" "}
                          {data.firstname} {data.lastname}
                        </div>
                      </div>
                    </li>
                    <li>
                      <div style={{ display: "flex" }}>
                        <div className={classes.label}>Appointment Date : </div>
                        <div className={classes.data}>
                          {" "}
                          {moment(data.appointment_date).format("YYYY-MM-DD")}
                        </div>
                      </div>
                    </li>
                  </ul>
                  <Box mb={1}>
                    <FastField
                      name="created_by"
                      label="Admin User"
                      variant="outlined"
                      size="small"
                      component={(props) => <SelectUser {...props} />}
                      fullWidth
                    />
                  </Box>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={handleClose}
                    color="secondary"
                    startIcon={<CancelIcon />}
                    size="small"
                    variant="contained"
                    disabled={isSubmitting}
                  >
                    Close
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    color="primary"
                    variant="contained"
                    autoFocus
                    size="small"
                    endIcon={
                      isSubmitting ? (
                        <CheckCircleIcon size="0.9rem" />
                      ) : undefined
                    }
                  >
                    {isSubmitting ? "Submitting" : "Submit"}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>
      </div>
    </Fragment>
  );
});
export default TagDialog;
