import { Fragment } from "react";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Dialog,
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { green } from "@material-ui/core/colors";
import { TextField } from "formik-material-ui";
import { Formik, Form, FastField } from "formik";
import { object, number } from "yup";

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: "#FAE700",
  },
  titleText: {
    color: "rgb(63,81,181) !important",
    fontWeight: "bold !important",
  },
  button: {
    backgroundColor: green[500],
    color: "#fff",
    "&:hover": {
      backgroundColor: green[600],
    },
  },
  paper: {
    width: "350px",
    maxWidth: "350px",
  },
  add: {
    backgroundColor: green[500],
    color: "#fff",
    "&:hover": {
      backgroundColor: green[600],
    },
  },
  inputLabel: {
    margin: theme.spacing("auto", "auto", 1, "auto"),
  },
  myContainer: {
    width: "100% !important",
  },
}));

const ScheduleSubUpdate = React.memo((props) => {
  const classes = useStyles();

  const { open, data, onClose, onUpdate } = props;

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = (values, actions) => {
    onUpdate(values, actions);
  };

  const initialValues = {
    id: data && data.id,
    max_slots: data && data.max_slots,
  };

  const validationSchema = object().shape({
    max_slots: number()
      .typeError("Max slots must be valid.")
      .min(0, "Invalid minimum.")
      .required("Required field."),
  });

  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
        classes={{ paper: classes.paper }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, errors, setFieldValue, setSubmitting, isSubmitting }) => (
            <Form autoComplete="off">
              <DialogTitle
                id="alert-dialog-title"
                className={`${classes.title}`}
              >
                <span className={classes.titleText}>
                  {"UPDATE SCHEDULE TIME FORM"}
                </span>
              </DialogTitle>
              <DialogContent dividers>
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  spacing={3}
                >
                  <Grid item xs={12}>
                    <FastField
                      component={TextField}
                      name={`max_slots`}
                      label="Max Slots"
                      variant="outlined"
                      size="small"
                      type="number"
                      inputProps={{
                        style: {
                          textAlign: "center",
                        },
                      }}
                    />
                  </Grid>
                </Grid>
                {/* <pre>{JSON.stringify({ values, errors }, null, 4)}</pre> */}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} size="small" color="default">
                  Close
                </Button>
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  color="primary"
                  variant="contained"
                  autoFocus
                  size="small"
                  endIcon={
                    isSubmitting ? <CheckCircleIcon size="0.9rem" /> : undefined
                  }
                >
                  {isSubmitting ? "Submitting" : "Submit"}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </Fragment>
  );
});
export default ScheduleSubUpdate;
