import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { MenuItem, TextField } from "@material-ui/core";

const MenuItemStyle = withStyles({
  root: {
    justifyContent: "center !important",
  },
})(MenuItem);

const useStyles = makeStyles((theme) => ({
  center: {
    textAlign: "center !important",
  },
}));

const categoriesOne = [
  { value: "emergency", label: "Emergency" },
  { value: "health", label: "Health" },
  {
    value: "found_more_affordable_service_provider",
    label: "Found more affordable service provider",
  },
  {
    value: "incorrect_duplicate_booking",
    label: "Incorrect/Duplicate booking",
  },
  { value: "no_confirmation_from_client", label: "No confirmation from client" },
  { value: "client_is_late", label: "Client is late" },
  { value: "client_no_show", label: "Client no show" },
  { value: "client_no_longer_available", label: "Client no longer available" },
];

const categoriesTwo = [
  { value: "typhoon_rains", label: "Typhoon/Rains" },
  {
    value: "we_do_not_cater_needed_service",
    label: "We do not cater needed service",
  },
  { value: "no_available_parts", label: "No available parts" },
  { value: "no_available_mechanics", label: "No Available mechanics" },
  {
    value: "incorrect_duplicate_booking",
    label: "Incorrect/Duplicate booking",
  },
  { value: "late_mechanic", label: "Late Mechanic" },
];

const CustomSelectReasonCancellation = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, values }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const classes = useStyles();
  const categories =
    values.cancellation_type === "client_cancellation"
      ? categoriesOne
      : categoriesTwo;

  return (
    <TextField
      select
      error={errors.cancellation_type && touched.cancellation_type}
      helperText={
        errors.cancellation_type &&
        touched.cancellation_type &&
        errors.cancellation_type
      }
      {...field}
      {...props}
      classes={{
        root: classes.center,
      }}
    >
      {categories.map((option) => (
        <MenuItemStyle key={option.value} value={option.value}>
          {option.label}
        </MenuItemStyle>
      ))}
    </TextField>
  );
};
export default CustomSelectReasonCancellation;
