import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { MenuItem, TextField } from "@material-ui/core";
import axios from "axios";
import config from "../../utils/config";

const MenuItemStyle = withStyles({
  root: {
    justifyContent: "center !important",
  },
})(MenuItem);

const useStyles = makeStyles((theme) => ({
  center: {
    textAlign: "center !important",
    width: "100%",
    marginTop: "1rem",
  },
}));

const SelectServiceLocation = ({ field, form, ...props }) => {
  const classes = useStyles();
  const token = localStorage.getItem("token");

  const [hubs, setHubs] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const {
        data: { data },
      } = await axios.get(`${config.api}/garages`, options);
      setHubs(data);
    }
    fetchData();
  }, []);

  const handleLocationChange = (id) => {
    props.handleLocationChange(id);
  };

  return (
    <TextField
      select
      SelectProps={{ multiple: props.isMultiple ? true : false }}
      value={props.serviceLocation}
      label="Service Location"
      {...props}
      onChange={(event) => handleLocationChange(event.target.value)}
      classes={{
        root: classes.center,
      }}
    >
      {hubs.map((option) => (
        <MenuItemStyle
          key={option.id}
          value={props.isId ? option.id : option.name}
        >
          {option.name}
        </MenuItemStyle>
      ))}
    </TextField>
  );
};
export default SelectServiceLocation;
