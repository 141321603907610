import { configureStore } from "@reduxjs/toolkit";
import bookFilterSlice from "./book-filter-slice";
import appSlice from "./app.slice";
import attendanceFilterSlice from "./attendance-filter-slice";

const store = configureStore({
  reducer: {
    bookFilter: bookFilterSlice.reducer,
    appStore: appSlice.reducer,
    attendanceFilter: attendanceFilterSlice.reducer
  },
});

export default store;
