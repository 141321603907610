import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { MenuItem, TextField } from "@material-ui/core";

const MenuItemStyle = withStyles({
  root: {
    justifyContent: "center !important",
  },
})(MenuItem);

const useStyles = makeStyles((theme) => ({
  center: {
    textAlign: "center !important",
  },
}));

const customer_type = [
  { value: "New", label: "New" },
  { value: "Retention", label: "Retention" },
  { value: "Fleet", label: "Fleet" },
  { value: "GPAC", label: "GPAC" },
];

const SelectCustomerType = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const classes = useStyles();

  return (
    <TextField
      select
      error={errors.customer_type && touched.customer_type}
      helperText={
        errors.customer_type && touched.customer_type && errors.customer_type
      }
      {...field}
      {...props}
      classes={{
        root: classes.center,
      }}
    >
      {customer_type.map((option) => (
        <MenuItemStyle key={option.value} value={option.value}>
          {option.label}
        </MenuItemStyle>
      ))}
    </TextField>
  );
};
export default SelectCustomerType;
