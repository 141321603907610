import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { MenuItem, TextField } from "@material-ui/core";

const MenuItemStyle = withStyles({
  root: {
    justifyContent: "center !important",
  },
})(MenuItem);

const useStyles = makeStyles((theme) => ({
  center: {
    textAlign: "center !important",
  },
}));

const MechanicType = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const classes = useStyles();

  const mechanic_types = [
    { id: 1, name: "Regular" },
    { id: 2, name: "Probationary" },
    { id: 3, name: "Trainee" },
  ];

  return (
    <TextField
      select
      error={errors.mechanic_type && touched.mechanic_type}
      helperText={
        errors.mechanic_type && touched.mechanic_type && errors.mechanic_type
      }
      {...field}
      {...props}
      classes={{
        root: classes.center,
      }}
    >
      {mechanic_types.map((option) => (
        <MenuItemStyle key={option.id} value={option.name}>
          {option.name}
        </MenuItemStyle>
      ))}
    </TextField>
  );
};
export default MechanicType;
