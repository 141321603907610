import React, { Fragment, useState, useEffect } from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { createMuiTheme } from "@material-ui/core/styles";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const muiTheme = createMuiTheme({
  textAlign: "center !important",
});

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: "#FAE700",
  },
  titleText: {
    color: "rgb(63,81,181) !important",
    fontWeight: "bold !important",
  },
  ul: {
    listStyleType: "none",
    padding: "0px",
    marginTop: "3px !important",
  },
  question: {
    marginBottom: "12px !important",
  },
  label: {
    marginRight: "5px",
    fontSize: "15px !important",
    color: "rgba(0, 0, 0, 0.54)",
    fontWeight: "600",
  },
  dataQuestion: {
    fontWeight: "600",
    fontSize: "15px !important",
  },
  data: {
    fontWeight: "600",
    textTransform: "capitalize",
    fontSize: "15px !important",
  },
}));

const CompleteDialog = (props) => {
  const classes = useStyles();

  const { data, open, handleCompleteClose, handleComplete, isLoading } = props;

  const [selectedDate, handleDateChange] = useState("");

  const handleSubmit = () => {
    let dateVal = moment(selectedDate).format("yyyy-MM-DD HH:mm:ss");
    let values = {
      status: 4,
      date_completed: dateVal,
    };
    handleComplete(values, data.id);
  };

  useEffect(() => {
    handleDateChange(new Date());
  }, [open, data]);

  if (Array.isArray(data) && data.length === 0) {
    return null;
  }
  
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleCompleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="xs"
        disableBackdropClick
      >
        {Object.keys(data).length > 0 ? (
          <Fragment>
            <DialogTitle id="alert-dialog-title" className={classes.title}>
              <span
                className={classes.titleText}
                style={{ textTransform: "uppercase" }}
              >
                Booking NO : {data.reference_no}
              </span>
            </DialogTitle>
            <DialogContent dividers>
              <ul className={classes.ul}>
                <li className={classes.question}>
                  <div style={{ display: "flex" }}>
                    <div className={classes.dataQuestion}>
                      Are you sure the service is complete?
                    </div>
                    <div className={classes.data}></div>
                  </div>
                </li>
                <li>
                  <div style={{ display: "flex" }}>
                    <MuiPickersUtilsProvider
                      theme={muiTheme}
                      utils={DateFnsUtils}
                    >
                      <KeyboardDatePicker
                        label="Complete Date"
                        variant="inline"
                        inputVariant="outlined"
                        size="small"
                        format="yyyy-MM-dd"
                        margin="normal"
                        id="booking_from"
                        autoOk
                        inputProps={{ readOnly: true }}
                        value={selectedDate}
                        onChange={handleDateChange}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </li>
              </ul>
            </DialogContent>
          </Fragment>
        ) : (
          <div></div>
        )}
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleCompleteClose}
            color="secondary"
            startIcon={<CancelIcon />}
            size="small"
          >
            CANCEL
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            color="primary"
            autoFocus
            endIcon={<CheckCircleIcon />}
            size="small"
            disabled={isLoading}
          >
            SUBMIT{" "}
            {isLoading && (
              <CircularProgress size={20} style={{ marginLeft: "3px" }} />
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CompleteDialog;
