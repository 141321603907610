import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { MenuItem, TextField } from "@material-ui/core";

const MenuItemStyle = withStyles({
  root: {
    justifyContent: "center !important",
  },
})(MenuItem);

const useStyles = makeStyles((theme) => ({
  center: {
    textAlign: "center !important",
  },
}));

const fuels_type = [
  { value: "Gas", label: "Gas" },
  { value: "Diesel", label: "Diesel" },
];

const CustomSelectFuelType = ({
  field, // { name, value, onChange, onBlur }
  index,
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const classes = useStyles();

  return (
    <TextField
      select
      error={
        errors.variants &&
        errors.variants[index]?.fuel_type &&
        touched.variants &&
        touched.variants[index]?.fuel_type
      }
      helperText={
        errors.variants &&
        errors.variants[index]?.fuel_type &&
        touched.variants &&
        touched.variants[index]?.fuel_type &&
        errors.variants[index]?.fuel_type
      }
      {...field}
      {...props}
      classes={{
        root: classes.center,
      }}
    >
      <MenuItemStyle value="">
        <em>None</em>
      </MenuItemStyle>
      {fuels_type.map((option) => (
        <MenuItemStyle key={option.value} value={option.value}>
          {option.label}
        </MenuItemStyle>
      ))}
    </TextField>
  );
};
export default CustomSelectFuelType;
