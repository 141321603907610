import React, { Fragment } from "react";
import moment from "moment";
import "moment-timezone";
import { makeStyles } from "@material-ui/core/styles";
import {
  IconButton,
  Typography,
  Slide,
  Toolbar,
  AppBar,
  Divider,
  Dialog,
  Box,
  Paper,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import MuiDialogContent from "@material-ui/core/DialogContent";
import ViewDialogPaymentTable from "./ViewDialogPaymentTable";
import CloseIcon from "@material-ui/icons/Close";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import { PaymentType } from "../../../utils/constant";
import { capitalizeAllWords } from "../../../utils/helper";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  service: {
    padding: theme.spacing(3),
    maxWidth: "800px",
    margin: "0 auto",
  },
  paper: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(4),
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  divider: {
    backgroundColor: "#000",
    margin: `${theme.spacing(2)}px 0`,
  },
  amount: {
    textAlign: "right",
  },
  button: {
    color: theme.palette.grey[900],
  },
  icon: {
    position: "absolute",
    top: "18px",
    marginLeft: "3px",
  },
  titleText: {
    fontWeight: "bold",
  },
  text: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    fontSize: "14px !important",
  },
  boxItem: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  listItem: {
    listStyleType: "none",
  },
  liItem: {
    marginBottom: "3px",
  },
  label: {
    fontWeight: "bold",
    fontSize: "14px !important",
    minWidth: "170px",
  },
  data: {
    fontSize: "14px !important",
    fontWeight: "normal",
  },
  liItem2: {
    marginBottom: "3px",
    lineHeight: "1.2",
  },
  label2: {
    fontWeight: "800",
    fontSize: "16px !important",
    minWidth: "25%",
    textAlign: "right",
    marginRight: "160px",
  },
  data2: {
    fontSize: "16px !important",
    fontWeight: "normal",
    minWidth: "45%",
    textAlign: "left",
  },
  appBar: {
    position: "relative",
    backgroundColor: "#FAE700",
    color: "#000",
  },
  paper2: {
    padding: theme.spacing(4),
  },
  subJOTitle: {
    fontWeight: "bold",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
      timeout={{ appear: 100, enter: 500, exit: 500 }}
    />
  );
});

const numberConverter = (number) => {
  let value = Number(parseFloat(number)).toLocaleString("en", {
    minimumFractionDigits: 2,
  });
  return value;
};

const ViewDialog = (props) => {
  const classes = useStyles();

  const { data, open, handleViewClose } = props;

  const ListItem = ({ label, value }) => (
    <li className={classes.liItem}>
      <div style={{ display: "flex" }}>
        <div className={classes.label}>{label} </div>
        <div className={classes.data}>{value}</div>
      </div>
    </li>
  );

  const mainServices = data?.appointment_services?.filter(
    (as) => !as.appointment_post_dispatch_id
  );

  const package_add_ons = data?.appointment_package_add_ons?.filter(
    (pao) => !pao.appointment_post_dispatch_id
  );

  const service_parts = data?.service_parts?.filter(
    (sp) => !sp.appointment_post_dispatch_id
  );

  const service_tires = data?.service_tires?.filter(
    (st) => !st.appointment_post_dispatch_id
  );

  const totalPaymentsMadeWithoutReservation =
    data?.payment?.length > 0
      ? data.payment
          .filter((p) => p.payment_type === null) // exclude reservation payment/downpayment because it is already deducted to total
          .reduce(
            (total, payment) => total + parseFloat(payment.amount_paid),
            0
          )
      : 0;

  const totalPayments =
    data?.payment?.length > 0
      ? data.payment.reduce(
          (total, payment) => total + parseFloat(payment.amount_paid),
          0
        )
      : 0;

  const totalAmountDue = parseFloat(data.sub_total) - parseFloat(data.discount);

  const totalBalance = Math.max(totalAmountDue - totalPayments, 0);
  const ItemRow = ({ name, price, discount, classes }) => (
    <>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Typography variant="body2" fontWeight="bold">
            {name}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body2" className={classes.amount}>
            ₱{numberConverter(price)}
          </Typography>
        </Grid>
      </Grid>

      {discount > 0 && (
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Typography variant="body2">Discount</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2" className={classes.amount}>
              {discount.toFixed(2)}
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  );

  return (
    <Fragment>
      <div>
        <Dialog
          fullScreen
          open={open}
          onClose={handleViewClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <Typography variant="h5" className={classes.title}>
                View Booking
                <CalendarTodayIcon className={classes.icon} />
              </Typography>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleViewClose}
                aria-label="close"
              >
                <CloseIcon className={classes.button} />
              </IconButton>
            </Toolbar>
          </AppBar>
          <MuiDialogContent dividers>
            {Object.keys(data).length > 0 ? (
              <div className={classes.root}>
                <Paper className={classes.paper}>
                  <Typography variant="h6" className={classes.titleText}>
                    BOOKING DETAILS
                  </Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Box mt={2} className={classes.text}>
                        <Typography
                          paragraph={true}
                          display={"inline"}
                          style={{ fontWeight: "bold" }}
                        >
                          Order Status :
                        </Typography>
                        <Typography
                          paragraph={true}
                          display={"inline"}
                          className={classes.span}
                        >
                          {" "}
                          {data.status.name}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={5}>
                      <Box className={classes.boxItem}>
                        <ul className={classes.listItem}>
                          <ListItem
                            label="Booking No.:"
                            value={data.reference_no}
                          />
                          <ListItem
                            label="Customer ID:"
                            value={data.customer_id}
                          />
                          <ListItem
                            label="Booking Date:"
                            value={moment(data.created_at).format("LLL")}
                          />
                          <ListItem
                            label="BS Number:"
                            value={data.bs_number ? data.bs_number : "N/A"}
                          />
                          <ListItem
                            label="Appointment Date:"
                            value={moment(data.appointment_date).format("LLL")}
                          />
                          {data.date_confirmed && (
                            <ListItem
                              label="Date Confirmed:"
                              value={moment(data.date_confirmed).format("LLL")}
                            />
                          )}
                          {data.date_dispatched && (
                            <ListItem
                              label="Date Dispatched:"
                              value={moment(data.date_dispatched).format("LLL")}
                            />
                          )}
                          {data.date_completed && (
                            <ListItem
                              label="Date Completed:"
                              value={moment(data.date_completed).format("LLL")}
                            />
                          )}
                          <ListItem
                            label="Mode of Payment:"
                            value={data.payment_method.replace(/_/g, " ")}
                          />
                          <ListItem
                            label="Plate Number:"
                            value={
                              data.plate_number ? data.plate_number : "N/A"
                            }
                          />
                          <ListItem label="Remarks:" value={data.remarks} />
                          <ListItem
                            label="Parking Space:"
                            value={data.parking_space}
                          />
                          <ListItem
                            label="Property Type:"
                            value={data.property_type}
                          />
                          <ListItem
                            label="Ads Source/s:"
                            value={data.ads_source}
                          />
                          {data.status.id === 6 && data.date_cancelled && (
                            <>
                              <ListItem
                                label="Date Cancelled:"
                                value={moment(data.date_cancelled).format(
                                  "LLL"
                                )}
                              />
                              {data.reason_for_cancellation && (
                                <ListItem
                                  label="Reason for Cancellation:"
                                  value={data.reason_for_cancellation.replace(
                                    /_/g,
                                    " "
                                  )}
                                />
                              )}
                            </>
                          )}
                        </ul>
                      </Box>
                    </Grid>
                    <Grid item xs={5}>
                      <Box mt={1} className={classes.boxItem}>
                        <ul className={classes.listItem}>
                          <ListItem
                            label="Customer Name:"
                            value={capitalizeAllWords(
                              `${data.name} ${data.lastname}`
                            )}
                          />
                          <ListItem label="Contact No:" value={data.phone} />
                          <ListItem
                            label="Transaction Type:"
                            value={data.transaction_type}
                          />
                          <ListItem
                            label="Service Location:"
                            value={data.service_location}
                          />
                          {/* <ListItem
                            label="Address:"
                            value={capitalizeAllWords(displayAddress(data))}
                          /> */}
                          <ListItem
                            label="Customer Type:"
                            value={capitalizeAllWords(data.customer_type)}
                          />
                          <ListItem
                            label="Exact Address:"
                            value={
                              data.landmarks
                                ? capitalizeAllWords(data.landmarks)
                                : "N/A"
                            }
                          />
                          {/* <ListItem
                            label="Vehicle:"
                            value={capitalizeAllWords(`${data.make} ${
                              data.model
                            } ${data.year}${" "}
                                ${data.engine_size} ${data.fuel_type}`)}
                          /> */}
                          <ListItem
                            label="Liter of oil:"
                            value={data.liter_of_oil}
                          />
                          <ListItem
                            label="Engine size:"
                            value={data.engine_size}
                          />
                          {(data.billing_company_name ||
                            data.billing_company_address ||
                            data.billing_company_tin) && (
                            <>
                              {data.billing_company_name && (
                                <ListItem
                                  label="Billing company name:"
                                  value={data.billing_company_name}
                                />
                              )}
                              {data.billing_company_address && (
                                <ListItem
                                  label="Billing company address:"
                                  value={data.billing_company_address}
                                />
                              )}
                              {data.billing_company_tin && (
                                <ListItem
                                  label="Billing company tin:"
                                  value={data.billing_company_tin}
                                />
                              )}
                            </>
                          )}
                        </ul>
                      </Box>
                    </Grid>
                  </Grid>
                  <Box my={2}>
                    <Divider />
                  </Box>
                  <Typography variant="h6" className={classes.titleText}>
                    ORDER SUMMARY
                  </Typography>
                  <Paper className={classes.service}>
                    <Typography variant="h6" className={classes.titleText}>
                      Main Service
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={8}>
                        <Typography
                          variant="body1"
                          className={classes.titleText}
                        >
                          Service Item/s
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          variant="body1"
                          className={`${classes.amount} ${classes.titleText}`}
                        >
                          Amount
                        </Typography>
                      </Grid>
                    </Grid>
                    {mainServices.map((service) => (
                      <ItemRow
                        key={service.service_name}
                        name={service.service_name}
                        price={service.total || service.price || 0}
                        classes={classes}
                      />
                    ))}
                    {/* Map through package add ons */}
                    {package_add_ons.map((package_add_on) => (
                      <ItemRow
                        key={package_add_on.service_name}
                        name={package_add_on.service_name}
                        price={
                          package_add_on.total || package_add_on.price || 0
                        }
                        classes={classes}
                      />
                    ))}

                    {/* Map through parts */}
                    {service_parts.map((part) => (
                      <ItemRow
                        key={part.name}
                        name={part.name}
                        price={part.totalPrice || part.price || 0}
                        classes={classes}
                      />
                    ))}

                    {/* Map through tires */}
                    {service_tires.map((tire) => (
                      <ItemRow
                        key={tire.sku}
                        name={tire.sku}
                        price={tire.total || tire.price || 0}
                        classes={classes}
                      />
                    ))}
                    {/* Discount */}
                    <Grid container spacing={2}>
                      <Grid item xs={8}>
                        <Typography variant="body2">Discount</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="body2" className={classes.amount}>
                          ₱{numberConverter(data.discount)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={8}>
                        <Typography variant="body2">Subtotal</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="body2" className={classes.amount}>
                          ₱
                          {numberConverter(
                            data.sub_total - data.discount - data.sub_jo_total
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                    {data.appointment_post_dispatch
                      .filter((apd) => apd.status_id !== 6)
                      .map((apd) => (
                        <>
                          <Typography
                            variant="body2"
                            className={classes.titleText}
                          >
                            JO #{apd.bs_number}
                          </Typography>
                          <Grid container spacing={2}>
                            <Grid item xs={8}>
                              <Typography
                                variant="body1"
                                className={classes.titleText}
                              >
                                Service Item/s
                              </Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography
                                variant="body2"
                                className={classes.amount}
                              ></Typography>
                            </Grid>
                          </Grid>

                          {/* Map through services */}
                          {apd.services.map((service) => (
                            <ItemRow
                              key={service.service_name}
                              name={service.service_name}
                              price={service.total || service.price || 0}
                              classes={classes}
                            />
                          ))}

                          {/* Map through parts */}
                          {apd.service_parts.map((part) => (
                            <ItemRow
                              key={part.name}
                              name={part.name}
                              price={part.totalPrice || part.price || 0}
                              classes={classes}
                            />
                          ))}

                          {/* Map through tires */}
                          {apd.service_tires.map((tire) => (
                            <ItemRow
                              key={tire.sku}
                              name={tire.sku}
                              price={tire.total || tire.price || 0}
                              classes={classes}
                            />
                          ))}

                          <Grid container spacing={2}>
                            <Grid item xs={8}>
                              <Typography variant="body2">Subtotal</Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography
                                variant="body2"
                                className={classes.amount}
                              >
                                ₱{numberConverter(apd.total)}
                              </Typography>
                            </Grid>
                          </Grid>
                        </>
                      ))}
                    <Divider className={classes.divider} />
                    <Grid container spacing={2}>
                      <Grid item xs={8}>
                        <Typography variant="body1">
                          Total Amount Due
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="body1" className={classes.amount}>
                          ₱{numberConverter(totalAmountDue)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider className={classes.divider} />
                    {/* Payment */}
                    {data?.payment &&
                      data?.payment?.map((payment) => (
                        <Grid container spacing={2}>
                          <Grid item xs={8}>
                            <Typography variant="body2" fontWeight="bold">
                              {payment.payment_type
                                ? PaymentType[payment.payment_type]
                                : `Payment #${payment.payment_ref}`}
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography
                              variant="body2"
                              className={classes.amount}
                            >
                              ₱{numberConverter(payment.amount_paid)}
                            </Typography>
                          </Grid>
                        </Grid>
                      ))}
                    <Grid container spacing={2}>
                      <Grid item xs={8}>
                        <Typography variant="body1">Total Balance</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="body1" className={classes.amount}>
                          ₱{numberConverter(totalBalance)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Paper>
              </div>
            ) : (
              <div></div>
            )}
          </MuiDialogContent>
        </Dialog>
      </div>
    </Fragment>
  );
};

export default ViewDialog;
