import React, { Fragment } from "react";
import moment from "moment";
import "moment-timezone";
import { makeStyles } from "@material-ui/core/styles";
import {
  IconButton,
  Typography,
  Slide,
  Toolbar,
  AppBar,
  Divider,
  Dialog,
  Box,
  Paper,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import MuiDialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  paper: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(4),
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  button: {
    color: theme.palette.grey[900],
  },
  icon: {
    position: "absolute",
    top: "18px",
    marginLeft: "3px",
  },
  titleText: {
    fontWeight: "bold",
  },
  text: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    fontSize: "14px !important",
  },
  boxItem: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  listItem: {
    listStyleType: "none",
  },
  liItem: {
    marginBottom: "3px",
  },
  label: {
    fontWeight: "bold",
    fontSize: "14px !important",
    minWidth: "170px",
  },
  data: {
    fontSize: "14px !important",
    fontWeight: "normal",
  },
  liItem2: {
    marginBottom: "3px",
    lineHeight: "1.2",
  },
  label2: {
    fontWeight: "800",
    fontSize: "16px !important",
    minWidth: "25%",
    textAlign: "right",
    marginRight: "160px",
  },
  data2: {
    fontSize: "16px !important",
    fontWeight: "normal",
    minWidth: "45%",
    textAlign: "left",
  },
  appBar: {
    position: "relative",
    backgroundColor: "#FAE700",
    color: "#000",
  },
  paper2: {
    padding: theme.spacing(4),
    paddingRight: theme.spacing(10),
    paddingLeft: theme.spacing(10),
  },
  table: {
    width: 650,
    minWidth: 650,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
      timeout={{ appear: 100, enter: 500, exit: 500 }}
    />
  );
});

const numberConverter = (number) => {
  let value = Number(parseFloat(number)).toLocaleString("en", {
    minimumFractionDigits: 2,
  });
  return value;
};

const displayAddress = (data) => {
  if (data.barangay && data.municipality && data.province) {
    return `${data.address}, ${data.barangay}, ${data.municipality}, ${data.province}, ${data.zip_code}`;
  }
  return data.address;
};

const ViewQuotation = (props) => {
  const classes = useStyles();

  const { data, open, handleViewClose } = props;

  return (
    <Fragment>
      <div>
        <Dialog
          fullScreen
          open={open}
          onClose={handleViewClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <Typography variant="h5" className={classes.title}>
                View Quotation
                <CalendarTodayIcon className={classes.icon} />
              </Typography>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleViewClose}
                aria-label="close"
              >
                <CloseIcon className={classes.button} />
              </IconButton>
            </Toolbar>
          </AppBar>
          <MuiDialogContent dividers>
            {Object.keys(data).length > 0 ? (
              <div className={classes.root}>
                <Paper className={classes.paper}>
                  <Typography variant="h6" className={classes.titleText}>
                    QUOTATION DETAILS
                  </Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={5}>
                      <Box className={classes.boxItem}>
                        <ul className={classes.listItem}>
                          <li className={classes.liItem}>
                            <div style={{ display: "flex" }}>
                              <div className={classes.label}>
                                Estimate NO.:{" "}
                              </div>
                              <div className={classes.data}>
                                {data.estimate_id}
                              </div>
                            </div>
                          </li>
                          <li className={classes.liItem}>
                            <div style={{ display: "flex" }}>
                              <div className={classes.label}>
                                Plate Number :
                              </div>
                              <div
                                className={classes.data}
                                style={{ textTransform: "capitalize" }}
                              >
                                {data.plate_number ? data.plate_number : "N/A"}
                              </div>
                            </div>
                          </li>
                          <li className={classes.liItem}>
                            <div style={{ display: "flex" }}>
                              <div className={classes.label}>Remarks : </div>
                              <div
                                className={classes.data}
                                style={{
                                  width: "12rem !important",
                                  wordBreak: "break-word !important",
                                  textTransform: "capitalize",
                                }}
                              >
                                {data.remarks}
                              </div>
                            </div>
                          </li>
                        </ul>
                      </Box>
                    </Grid>
                    <Grid item xs={5}>
                      <Box mt={1} className={classes.boxItem}>
                        <ul className={classes.listItem}>
                          <li className={classes.liItem}>
                            <div style={{ display: "flex" }}>
                              <div className={classes.label}>
                                Customer Name :{" "}
                              </div>
                              <div
                                className={classes.data}
                                style={{ textTransform: "capitalize" }}
                              >
                                {data.name} {data.lastname || ""}
                              </div>
                            </div>
                          </li>
                          <li className={classes.liItem}>
                            <div style={{ display: "flex" }}>
                              <div className={classes.label}>Contact No : </div>
                              <div className={classes.data}>
                                {data.contact_number}
                              </div>
                            </div>
                          </li>
                          <li className={classes.liItem}>
                            <div style={{ display: "flex" }}>
                              <div className={classes.label}>Address : </div>
                              <div
                                className={classes.data}
                                style={{ textTransform: "capitalize" }}
                              >
                                {displayAddress(data)}
                              </div>
                            </div>
                          </li>
                          <li className={classes.liItem}>
                            <div style={{ display: "flex" }}>
                              <div className={classes.label}>
                                Exact Address :{" "}
                              </div>
                              <div
                                className={classes.data}
                                style={{ textTransform: "capitalize" }}
                              >
                                {data.landmarks ? data.landmarks : "N/A"}
                              </div>
                            </div>
                          </li>
                          <li className={classes.liItem}>
                            <div style={{ display: "flex" }}>
                              <div className={classes.label}>Vehicle : </div>
                              <div
                                className={classes.data}
                                style={{ textTransform: "capitalize" }}
                              >
                                {data.make} {data.model} {data.year}{" "}
                                {data.engine_size} {data.fuel_type}
                              </div>
                            </div>
                          </li>
                          <li className={classes.liItem}>
                            <div style={{ display: "flex" }}>
                              <div className={classes.label}>
                                Liter of Oil :{" "}
                              </div>
                              <div
                                className={classes.data}
                                style={{ textTransform: "capitalize" }}
                              >
                                {data.liter_of_oil}
                              </div>
                            </div>
                          </li>
                          <li className={classes.liItem}>
                            <div style={{ display: "flex" }}>
                              <div className={classes.label}>
                                Engine Size :{" "}
                              </div>
                              <div
                                className={classes.data}
                                style={{ textTransform: "capitalize" }}
                              >
                                {data.engine_size}
                              </div>
                            </div>
                          </li>
                        </ul>
                      </Box>
                    </Grid>
                  </Grid>
                  <Box my={2}>
                    <Divider />
                  </Box>
                  <Typography variant="h6" className={classes.titleText}>
                    QUOTATION SUMMARY
                  </Typography>
                  <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                  >
                    <Grid item xs={1}></Grid>
                    <Grid item xs={10}>
                      <Paper className={classes.paper2}>
                        <Typography
                          variant="h6"
                          component="div"
                          style={{ fontWeight: "bold" }}
                        >
                          QUOTATION DETAILS
                        </Typography>
                        <TableContainer>
                          <Table
                            size="small"
                            className={classes.table}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  style={{
                                    fontWeight: "bold",
                                    borderBottom: "2px solid rgb(82 81 81)",
                                  }}
                                >
                                  Services item
                                </TableCell>
                                <TableCell
                                  style={{
                                    fontWeight: "bold",
                                    borderBottom: "2px solid rgb(82 81 81)",
                                  }}
                                >
                                  Remarks
                                </TableCell>
                                <TableCell
                                  style={{
                                    fontWeight: "bold",
                                    borderBottom: "2px solid rgb(82 81 81)",
                                    textAlign: "right",
                                  }}
                                >
                                  Price
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {data?.quotation_services.map((row) => (
                                <TableRow key={row.id}>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    style={{ width: "50%" }}
                                  >
                                    {row.service_name}
                                  </TableCell>
                                  <TableCell style={{ width: "25%" }}>
                                    {row?.remarks || ""}
                                  </TableCell>
                                  <TableCell
                                    style={{ width: "25%", textAlign: "right" }}
                                  >
                                    ₱{" "}
                                    {row.total
                                      ? numberConverter(row.total)
                                      : numberConverter(row.price)}
                                  </TableCell>
                                </TableRow>
                              ))}
                              {data?.service_parts?.length > 0 && (
                                <>
                                  <TableRow>
                                    <TableCell
                                      style={{
                                        fontWeight: "bold",
                                        borderTop: "2px solid rgb(82 81 81)",
                                      }}
                                      component="th"
                                      scope="row"
                                    >
                                      Parts
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        borderTop: "2px solid rgb(82 81 81)",
                                      }}
                                    ></TableCell>
                                    <TableCell
                                      style={{
                                        borderTop: "2px solid rgb(82 81 81)",
                                      }}
                                    ></TableCell>
                                  </TableRow>
                                  {data.service_parts.map((row) => (
                                    <TableRow key={row.id}>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        style={{ width: "50%" }}
                                      >
                                        {row.part_name}
                                      </TableCell>
                                      <TableCell style={{ width: "25%" }}>
                                        {row.remarks}
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          width: "25%",
                                          textAlign: "right",
                                        }}
                                      >
                                        ₱{" "}
                                        {row.total
                                          ? numberConverter(row.total)
                                          : numberConverter(row.price)}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </>
                              )}
                              {data?.service_tires?.length > 0 && (
                                <>
                                  <TableRow>
                                    <TableCell
                                      style={{
                                        fontWeight: "bold",
                                        borderTop: "2px solid rgb(82 81 81)",
                                      }}
                                      component="th"
                                      scope="row"
                                    >
                                      Tires
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        borderTop: "2px solid rgb(82 81 81)",
                                      }}
                                    ></TableCell>
                                    <TableCell
                                      style={{
                                        borderTop: "2px solid rgb(82 81 81)",
                                      }}
                                    ></TableCell>
                                  </TableRow>
                                  {data.service_tires.map((row) => (
                                    <TableRow key={row.id}>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        style={{ width: "50%" }}
                                      >
                                        {row.sku}
                                      </TableCell>
                                      <TableCell
                                        style={{ width: "25%" }}
                                      ></TableCell>
                                      <TableCell
                                        style={{
                                          width: "25%",
                                          textAlign: "right",
                                        }}
                                      >
                                        ₱{" "}
                                        {row.total
                                          ? numberConverter(row.total)
                                          : numberConverter(row.price)}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </>
                              )}
                              <TableRow>
                                <TableCell
                                  style={{
                                    fontWeight: "bold",
                                    borderTop: "2px solid rgb(82 81 81)",
                                  }}
                                  component="th"
                                  scope="row"
                                >
                                  Sub Total
                                </TableCell>
                                <TableCell
                                  style={{
                                    borderTop: "2px solid rgb(82 81 81)",
                                  }}
                                ></TableCell>
                                <TableCell
                                  style={{
                                    borderTop: "2px solid rgb(82 81 81)",
                                    textAlign: "right",
                                  }}
                                >
                                  ₱ {numberConverter(data.sub_total)}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  style={{ fontWeight: "bold" }}
                                  component="th"
                                  scope="row"
                                >
                                  Convenience Fee
                                </TableCell>
                                <TableCell></TableCell>
                                <TableCell style={{ textAlign: "right" }}>
                                  ₱ {data.service_fee}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  style={{ fontWeight: "bold" }}
                                  component="th"
                                  scope="row"
                                >
                                  Discount
                                </TableCell>
                                <TableCell></TableCell>
                                <TableCell style={{ textAlign: "right" }}>
                                  ₱ {data?.discount || null}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  style={{
                                    fontWeight: "bold",
                                    borderTop: "2px solid rgb(82 81 81)",
                                  }}
                                  component="th"
                                  scope="row"
                                >
                                  TOTAL INVOICE
                                </TableCell>
                                <TableCell
                                  style={{
                                    borderTop: "2px solid rgb(82 81 81)",
                                  }}
                                ></TableCell>
                                <TableCell
                                  style={{
                                    borderTop: "2px solid rgb(82 81 81)",
                                    textAlign: "right",
                                  }}
                                >
                                  ₱ {numberConverter(data?.total)}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Paper>
                    </Grid>
                    <Grid item xs={1}></Grid>
                  </Grid>
                </Paper>
              </div>
            ) : (
              <div></div>
            )}
          </MuiDialogContent>
        </Dialog>
      </div>
    </Fragment>
  );
};

export default ViewQuotation;
