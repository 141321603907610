import React, { useState, Fragment } from 'react';
import '../../styles/login.css';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Paper, Container, Grid, Card, CardActionArea, CardActions, CardContent, CardMedia, Button, Typography, FormControl, FormLabel, FormHelperText, Input, InputLabel, TextField, CircularProgress } from '@material-ui/core';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const LoginForm = (props) => {

    const { isLoading, initialValues, validationSchema, onSubmit, classes, classes2 } = props;
    return(
      <Fragment>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} >
          { (props) => (
              <Form className={classes2.root}>
                <Grid container spacing={1} >
                  <Grid item xs={3}  align='right'>
                    <FormControl style={{textAlign: 'center', verticalAlign: 'middle',lineHeight: '90px'  }}>
                      <Typography className='form-label-custom' paragraph={true} style={{margin:'0px'}}>Email :</Typography>
                    </FormControl>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControl fullWidth>
                      <Field
                        as={TextField}
                        name='email'
                        size="small"
                        fullWidth
                        error={props.errors.email && props.touched.email}
                        helperText={<ErrorMessage name='email'/>}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}  align='right'>
                    <FormControl>
                      <Typography className='form-label-custom' paragraph={true} style={{margin:'0px'}}>Password :</Typography>
                    </FormControl>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControl fullWidth>
                        <Field
                          as={TextField}
                          autoComplete='off'
                          name='password'
                          size="small"
                          type='password'
                          fullWidth
                          error={props.errors.password && props.touched.password}
                          helperText={<ErrorMessage name='password'/>}
                        />
                    </FormControl>
                  </Grid>
                  <Grid item xs={3} />
                  <Grid item xs={9}>
                    <FormControl>
                        <Button type='submit' size='medium' variant='contained' color='primary' disabled={isLoading}>
                          LOGIN  {isLoading && <CircularProgress size={20} style={{marginLeft : '3px'}} />}
                        </Button>
                    </FormControl>
                  </Grid>
                </Grid>
              </Form>
            )
          }
        </Formik>
      </Fragment>
    )
}

export default LoginForm;
