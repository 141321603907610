import React, { Fragment, useState, useCallback } from "react";
import MaterialTable from "material-table";
import axios from "axios";
import { useSelector } from "react-redux";
// mui
import { makeStyles } from "@material-ui/core/styles";
import { green, yellow } from "@material-ui/core/colors";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import moment from "moment";

// api config
import config from "../../utils/config";

import useModal from "../../hooks/use-modal";

import AlertDialog from "../alertDialog/AlertDialog";
import AddLeave from "./AddLeave";

import Filters from "./Filters";

const useStyles = makeStyles((theme) => ({
  view: {
    color: yellow[500],
    "&:hover": {
      color: "#fff",
    },
  },
  update: {
    "&:hover": {
      backgroundColor: "rgba(255, 253, 231,1.0)",
    },
  },
  dashboard: {
    color: green[500],
    "&:hover": {
      backgroundColor: "rgba(232, 245, 233,1.0)",
    },
  },
  sizeSmall: {
    height: "18px",
    fontSize: "12px",
  },
  root: {
    textAlign: "center !important",
  },
}));

const Leaves = (props) => {
  const { tableRef, token, mechanics } = props;
  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  const attendanceFilter = useSelector((state) => state.attendanceFilter);

  // ==============MODAL STATES==============
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    typeAlert: "success",
  });

  // Alert Dialog
  const handleCloseAlert = useCallback(
    (event, reason) => {
      if (reason === "clickaway") {
        return;
      }

      setAlert({
        ...alert,
        open: false,
        message: "",
      });
    },
    [alert]
  );

  // Add leaves
  const {
    modalState: modalStateAdd,
    modalData: modalDataAdd,
    openHandler: modalOpenAdd,
    closeHandler: modalCloseAdd,
  } = useModal();

  const handleChange = useCallback(
    (data, event) => {
      try {
        setLoading(true);
        const payload = data;
        payload.status = event.target.value;
        axios.put(
          `${config.api}/mechanics/${data?.mechanic_id}/leaves/${data?.id}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setLoading(false);
        setAlert({
          ...alert,
          open: true,
          message: "Successfully added",
          typeAlert: "success",
        });
        tableRef.current && tableRef.current.onQueryChange();
      } catch (error) {
        console.log(error);
        setLoading(false);
        setAlert({
          ...alert,
          open: true,
          message: "Something went wrong please try again.",
          typeAlert: "error",
        });
      }
    },
    [tableRef, token]
  );

  const handleAddSubmit = useCallback(
    async (data) => {
      try {
        setLoading(true);

        // Ensure date formatting is correct and not undefined
        const date_from = moment
          .tz(data?.date_from, "Asia/Manila")
          .format("YYYY-MM-DD");
        const date_to = moment
          .tz(data?.date_to, "Asia/Manila")
          .format("YYYY-MM-DD");

        if (!date_from || !date_to) {
          throw new Error("Invalid date values");
        }

        const payload = {
          mechanic_id: data?.mechanic_id,
          date_from,
          date_to,
          reason: data?.reason,
          type: data?.type,
          source: "web",
          status: "Pending",
        };

        // Make sure mechanic_id and reason are valid (if required)
        if (!payload.mechanic_id || !payload.reason) {
          throw new Error("Missing required fields");
        }

        const response = await axios.post(
          `${config.api}/mechanics/${data?.mechanic_id}/leaves`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Check if the response is successful (status code 2xx)
        if (response.status >= 200 && response.status < 300) {
          setAlert({
            ...alert,
            open: true,
            message: "Successfully requested",
            typeAlert: "success",
          });
          tableRef.current && tableRef.current.onQueryChange();
        } else {
          // Handle non-2xx responses, if needed
          throw new Error("Server returned an error: " + response.statusText);
        }
      } catch (error) {
        setLoading(false);
        modalCloseAdd();
        // Handle the error message more specifically
        const errorMessage = error.response
          ? error.response.data?.message ||
            "Something went wrong. Please try again."
          : error.message || "Unknown error occurred.";

        setAlert({
          ...alert,
          open: true,
          message: errorMessage,
          typeAlert: "error",
        });
      } finally {
        setLoading(false); // Always set loading to false after the request
        modalCloseAdd();
      }
    },
    [alert, tableRef, token]
  );

  return (
    <Fragment>
      {/* Alert Dialog */}
      <AlertDialog
        open={alert.open}
        typeAlert={alert.typeAlert}
        message={alert.message}
        handleCloseAlert={handleCloseAlert}
      />
      {/* =================Add Dialog - (MODAL)================= */}
      <AddLeave
        token={token}
        data={modalDataAdd}
        loading={loading}
        open={modalStateAdd}
        handleUpdateClose={modalCloseAdd}
        mechanics={mechanics}
        handleAddSubmit={handleAddSubmit}
      />
      <Filters handleOpen={modalOpenAdd} isAddLeave />
      <MaterialTable
        title={`Mechanics Leaves`}
        tableRef={tableRef}
        columns={[
          {
            title: "Date Requested",
            render: (row) => {
              return moment(row?.created_at).format("ddd, MMM Do, hh:mm a");
            },
          },
          {
            title: "Mechanic",
            render: (row) => {
              return (
                <p>{`${row.mechanic.firstname}  ${row.mechanic.lastname}`}</p>
              );
            },
          },
          {
            title: "Date From",
            render: (row) => {
              return moment(row?.datetime_from).format("ddd, MMM Do YYYY");
            },
          },
          {
            title: "Date To",
            render: (row) => {
              return moment(row?.datetime_to).format("ddd, MMM Do YYYY");
            },
          },
          {
            title: "Reason",
            field: "reason",
          },
          {
            title: "Type",
            field: "type",
          },
          {
            title: "Status",
            render: (row) => {
              return (
                <FormControl fullWidth>
                  <Select
                    id="simple-select"
                    onChange={(event) => handleChange(row, event)}
                    value={row.status}
                    classes={{
                      root: classes.root,
                    }}
                  >
                    <MenuItem value="Reject">Reject</MenuItem>
                    <MenuItem value="Approve">Approve</MenuItem>
                  </Select>
                </FormControl>
              );
            },
          },
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            let url = config.api + "/mechanics/leaves?";
            url += `page=${query.page + 1}`;
            if (query.pageSize) {
              url += `&per_page=${query.pageSize}`;
            }
            if (query.search) {
              url += `&search=${query.search}`;
            }
            if (attendanceFilter.date_from && attendanceFilter.date_to) {
              const dateStart = moment(attendanceFilter.date_from).format(
                "YYYY-MM-DD"
              );
              const notToday = dateStart != moment().format("YYYY-MM-DD");
              if (notToday) {
                url += `&date_from=${moment(attendanceFilter.date_from).format(
                  "YYYY-MM-DD"
                )}&date_to=${moment(attendanceFilter.date_to).format(
                  "YYYY-MM-DD"
                )}`;
              }
            }
            const options = {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            };
            fetch(url, options)
              .then((response) => response.json())
              .then((result) => {
                resolve({
                  data: result?.data?.data,
                  page: result?.data?.current_page - 1 || 0,
                  totalCount: result?.data?.total,
                });
              });
          })
        }
        options={{
          headerStyle: {
            fontWeight: "bold",
          },
          rowStyle: {
            fontSize: ".75rem",
            padding: "0px !important",
          },
          actionsCellStyle: {
            justifyContent: "center",
            padding: "24px",
            marginBottom: "-1px",
          },
          pageSize: 30,
          pageSizeOptions: [],
          actionsColumnIndex: -1,
          tableLayout: "auto",
          sorting: false,
        }}
      />
    </Fragment>
  );
};

export default Leaves;
