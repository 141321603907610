import React, { Fragment, useRef, useState, useCallback } from 'react';
import AlertDialog from '../components/alertDialog/AlertDialog';
import { Box } from '@material-ui/core';
import Filters from '../components/assignMechanic/Filters'
import AssignMechanicTable from '../components/assignMechanic/AssignMechanicTable';

const AssignMechanic = (props) => {
  const { token } = props;

  const tableRef = useRef();

  // ==============MODAL STATES==============
  const [alert, setAlert] = useState({
    open: false,
    message: '',
    typeAlert: 'success',
  });

  // Alert Dialog
  const handleCloseAlert = useCallback(
    (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }

      setAlert({
        ...alert,
        open: false,
        message: '',
      });
    },
    [alert]
  );

  return (
    <Fragment>
      {/* Alert Dialog */}
      <AlertDialog
        open={alert.open}
        typeAlert={alert.typeAlert}
        message={alert.message}
        handleCloseAlert={handleCloseAlert}
      />

      <Box mb={1}>
        <Filters />
      </Box>
      <Box mb={1}>
        <AssignMechanicTable token={token} tableRef={tableRef} />
      </Box>
    </Fragment>
  );
};

export default AssignMechanic;
