export const numberConverter = (number) => {
  // Replace any commas in the input string with an empty string
  const numberString = number.toString().replace(/,/g, "");

  // Parse the number string as a float and round to two decimal places
  const parsedNumber = parseFloat(numberString).toFixed(2);

  // Return the parsed number as a number type
  return Number(parsedNumber);
};

export function numberWithCommas(x) {
  if (x === null || x === undefined) {
    return "";
  }

  if (typeof x === "number") {
    x = x.toString();
  } else if (typeof x === "string") {
    if (isNaN(Number(x.replace(/,/g, "")))) {
      throw new TypeError("The input string should be a valid number");
    }
  } else {
    throw new TypeError("The input should be a number or a string");
  }

  // Remove existing commas (if any) before formatting
  x = x.replace(/,/g, "");

  return x.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
