import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { MenuItem, TextField } from "@material-ui/core";

const MenuItemStyle = withStyles({
  root: {
    justifyContent: "center !important",
  },
})(MenuItem);

const customer_type = [
  { value: "reservation_fee", label: "Reservation Fee" },
  { value: "downpayment_for_parts", label: "Downpayment for parts" },
  { value: "cancellation_fee", label: "Cancellation Fee" },
];

const SelectPaymentType = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  return (
    <TextField select {...field} {...props} fullWidth>
      {customer_type.map((option) => (
        <MenuItemStyle key={option.value} value={option.value}>
          {option.label}
        </MenuItemStyle>
      ))}
    </TextField>
  );
};
export default SelectPaymentType;
