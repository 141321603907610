import React, { useEffect } from 'react';

const numberConverter = (number) => {
  let value = Number(parseFloat(number)).toLocaleString('en', {
    minimumFractionDigits: 2
  });
  return value;
}


const CustomTiresTotalPrice = ({
  field, // { name, value, onChange, onBlur }
  form: { values, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {

  const { tires } = values
  const { index } = props

  useEffect(() => {

    if (index >= 0 && index < tires.length) {
      const tire = tires[index];
      const total = parseFloat(tire.price) * parseInt(tire.quantity);
      // Ensure the final total is not negative
      const totalFinal = Math.max(0, total);
      if (!isNaN(totalFinal)) {
        setFieldValue(`tires.${props.index}.totalPrice`, totalFinal);
      }
    }

  }, [tires])

  return (
      <React.Fragment>
        <input {...props} {...field} style={{display : 'none'}} />
        <span>₱{field.value ? numberConverter(+field.value) : '0.00'}</span>
      </React.Fragment>
    )
};
export default CustomTiresTotalPrice;
