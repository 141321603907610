import { createSlice } from "@reduxjs/toolkit";

const appSlice = createSlice({
  name: "app store",
  initialState: {
    header_name: "BOOKING OVERVIEW",
  },
  reducers: {
    changeHeaderName(state, action) {
      state.header_name = action.payload.header_name;
    },
  },
});

export const appSliceAtions = appSlice.actions;
export default appSlice;
