import React, {
  useEffect,
  useState,
  Fragment,
  useRef,
  useCallback,
  useMemo,
} from "react";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import axios from "axios";
import moment from "moment";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  IconButton,
  Typography,
  Slide,
  Chip,
  Select,
  FormControl,
  Input,
  MenuItem,
  Toolbar,
  AppBar,
  Divider,
  Dialog,
  Button,
  Box,
  Paper,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  TextField as TextFieldMUI,
  InputLabel,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Tooltip from "@material-ui/core/Tooltip";
import CloseIcon from "@material-ui/icons/Close";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ListAltSharpIcon from "@material-ui/icons/ListAltSharp";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DatePicker, TimePicker } from "formik-material-ui-pickers";
import { TextField } from "formik-material-ui";
import { green } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";
import {
  Formik,
  Form,
  Field,
  FastField,
  FieldArray,
  useFormikContext,
} from "formik";
import { object, date, number, array, string } from "yup";
import CustomProvinceComponent from "../../booking/addBooking/CustomProvinceComponent";
import CustomMunicipalityComponent from "../../booking/addBooking/CustomMunicipalityComponent";
import CustomBarangayComponent from "../../booking/addBooking/CustomBarangayComponent";
import CustomMakeComponent from "../../booking/addBooking/CustomMakeComponent";
import CustomModelComponent from "../../booking/addBooking/CustomModelComponent";
import CustomYearComponent from "../../booking/addBooking/CustomYearComponent";
import CustomTransmissionComponent from "../../booking/addBooking/CustomTransmissionComponent";
import CustomFuelComponent from "../../booking/addBooking/CustomFuelComponent";
import CustomPaymentMethodComponent from "../../booking/addBooking/CustomPaymentMethodComponent";
import CustomServiceFeeComponent from "../../booking/addBooking/CustomServiceFeeComponent";
import CustomServiceFee2Component from "../../booking/addBooking/CustomServiceFee2Component";
import CustomTotalComponent from "../../booking/addBooking/CustomTotalComponent";
import CustomOtherServicesTotalPrice from "../../booking/addBooking/CustomOtherServicesTotalPrice";
import CustomDiscountComponent from "../../booking/addBooking/CustomDiscountComponent";
import CustomServiceLocationComponent from "../../booking/addBooking/CustomServiceLocationComponent";
import ParkingSpaceComponent from "../../booking/addBooking/ParkingSpaceComponent";
import CustomPartsAutocompleteComponent from "../../booking/addBooking/CustomPartsAutocompleteComponent";
import CustomPartsTotalPrice from "../../booking/addBooking/CustomPartsTotalPrice";
import CustomTiresAutocompleteComponent from "../../booking/addBooking/CustomTiresAutocompleteComponent";
import SelectPropertyType from "../../common/SelectPropertyType";
import AlertDialog from "../../alertDialog/AlertDialog";
import CustomTransactionTypeComponent from "../../booking/addBooking/CustomTransactionTypeComponent";

// Api config
import config from "../../../utils/config";
import CustomTiresTotalPrice from "../../booking/addBooking/CustomTiresTotalPrice";
import SelectCustomerType from "../../common/SelectCustomerType";

const muiTheme = createMuiTheme({
  textAlign: "center !important",
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, adsSource, theme) {
  return {
    fontWeight:
      adsSource.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  inputMultiple: {
    minWidth: "250px",
    height: "45px",
    border: "1.5px solid #ddd",
    borderRadius: "5px",
  },
  paper: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(4),
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  button: {
    color: theme.palette.grey[900],
  },
  house: {
    marginTop: "8px",
  },
  icon: {
    position: "absolute",
    top: "18px",
    marginLeft: "3px",
  },
  titleText2: {
    fontWeight: "bold",
    marginBottom: "20px",
  },
  listItem: {
    listStyleType: "none",
    margin: "0px !important",
  },
  liItem: {
    marginBottom: "3px",
  },
  label: {
    fontWeight: "bold",
    fontSize: "14px !important",
    minWidth: "160px",
    lineHeight: "2.5",
    letterSpacing: "1.2px",
  },
  data: {
    fontSize: "14px !important",
    fontWeight: "normal",
  },
  label2: {
    fontWeight: "bold",
    fontSize: "14px !important",
    minWidth: "160px",
    lineHeight: "5.0",
    letterSpacing: "1.2px",
  },
  appBar: {
    position: "relative",
    backgroundColor: "#FAE700",
    color: "#000",
  },
  gridCustom: {
    textAlign: "center !important",
  },
  gridCustom2: {
    maxWidth: "7.0% !important",
  },
  gridCustom3: {
    maxWidth: "52.5% !important",
  },
  addressDisabled: {
    minWidth: "499px !important",
  },
  remarks: {
    minWidth: "715px !important",
  },
  asterisk: {
    color: "red !important",
    fontSize: "1.3rem",
    lineHeight: "0 !important",
  },
  asterisk2: {
    position: "relative",
    bottom: "5px",
    left: "2px",
    color: "red !important",
    fontSize: "0.8rem !important",
  },
  disabledRoot: {
    "& .MuiOutlinedInput-input": {
      color: "#000",
    },
  },
  largeIcon: {
    width: "30px !important",
    height: "30px !important",
  },
  add: {
    backgroundColor: green[500],
    color: "#fff",
    "&:hover": {
      backgroundColor: green[600],
    },
  },
  inputCustomField: {
    width: "500px !important",
  },
}));

const useStyles2 = makeStyles((theme) => ({
  input: {
    textAlign: "center !important",
  },
  root: {
    overflowX: "unset",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
      timeout={{ appear: 100, enter: 500, exit: 500 }}
    />
  );
});

const emptyPackage = {
  id: "",
  name: "",
  quantity: 1,
  price: 0.0,
  total: 0.0,
  type: "",
};
const emptyServices = {
  id: "",
  name: "",
  remarks: "",
  quantity: 1,
  price: 0.0,
  totalPrice: 0,
  type: "manual",
};

const emptyParts = {
  id: "",
  part_number: "",
  name: "",
  remarks: "",
  brand_id: null,
  category_id: null,
  quantity: 1,
  price: 0.0,
  totalPrice: 0,
};

export const emptyTires = {
  sku: "",
  quantity: 1,
  price: 0.0,
  totalPrice: 0,
};

const numberConverter = (number) => {
  let value = Number(parseFloat(number)).toLocaleString("en", {
    minimumFractionDigits: 2,
  });
  return value;
};

const AddQuotation = (props) => {
  const { open, isLoading, onClose, tableRef } = props;

  const token = localStorage.getItem("token");

  let manualListInitial = useMemo(() => {
    return [];
  }, []);

  const initialValues = {
    address: "",
    name: "",
    lastname: "",
    email: "",
    contact_number: "",
    province: "",
    municipality: "",
    barangay: "",
    zip_code: "",
    landmarks: "",
    service_fee: 0.0,
    coverAddress: "cover",
    distance_in_km: "",
    subTotal: "",
    total: "",
    make: "",
    model: "",
    year: "",
    parking_space: "",
    plate_number: "",
    transmission: "",
    mileage: "",
    fuel_type: "Gas",
    liter_of_oil: "",
    engine_size: "",
    servicesList: [],
    services: [],
    tires: [],
    manualServicesList: manualListInitial,
    manualServices: [],
    discounts: "0.00",
    remarks: "",
    models: [],
    provinces: [],
    municipalities: [],
    barangays: [],
    parts: [],
  };

  const [inputValue, setInputValue] = React.useState("");
  const [optionsAuto, setOptionsAuto] = React.useState([]);

  // ==============MODAL STATES==============
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    typeAlert: "success",
  });

  const [loading, setLoading] = useState(false);

  const formRef = useRef();
  const classes = useStyles();
  const classes2 = useStyles2();
  const theme = useTheme();

  // Alert Dialog
  const handleCloseAlert = useCallback(
    (event, reason) => {
      if (reason === "clickaway") {
        return;
      }

      setAlert({
        ...alert,
        open: false,
        message: "",
      });
    },
    [alert]
  );

  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const fetchDataModel = (brand) => {
    return new Promise((resolve, reject) => {
      let request;
      request = axios.get(
        config.api + "/vehicles/search?make=" + brand,
        options
      );
      resolve(request);
    });
  };

  const fetchDataLiter = (brandModel, fuelType) => {
    return new Promise((resolve, reject) => {
      let request;
      request = axios.get(
        `${config.api}/vehicles/search?model=${brandModel}&fuel_type=${fuelType}`,
        options
      );
      resolve(request);
    });
  };

  const fetchDataServices = (make, model, liter_of_oil = 0, fuel_type) => {
    return new Promise((resolve, reject) => {
      let request;
      request = axios.get(
        `${config.api}/services?make=${make}&model=${model}&liter_of_oil=${liter_of_oil}&fuel_type=${fuel_type}&is_public=all`,
        options
      );
      resolve(request);
    });
  };

  const handleSubmitCustom = (data) => {
    const submittedValues = { ...data };
    let combinedServices = submittedValues.services; // this is the packages

    let manualServices = submittedValues.manualServices; // this is the services

    if (manualServices.length > 0) {
      let transformedManualServices = manualServices.map((item) => {
        return {
          id: item.id,
          name: item.name,
          remarks: item.remarks,
          quantity: item.quantity,
          price: item.price,
          total: item.totalPrice,
          type: "manual",
        };
      });
      combinedServices = [...combinedServices, ...transformedManualServices];
    }

    delete submittedValues.servicesList;
    delete submittedValues.manualServicesList;
    delete submittedValues.manualServices;
    delete submittedValues.models;

    const dataToSave = {
      ...submittedValues,
      services: combinedServices,
      sub_total: data.subTotal,
      discount: data.discounts,
    };
    setLoading(true);

    axios
      .post(`${config.api}/quotations`, dataToSave, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        tableRef.current && tableRef.current.onQueryChange();
        setAlert({
          ...alert,
          open: true,
          typeAlert: "success",
          message: "Successfully added.",
        });
      })
      .catch(() => {
        setAlert({
          ...alert,
          open: true,
          typeAlert: "error",
          message: "Something went wrong please try again.",
        });
      })
      .finally(() => {
        setLoading(false);
        onClose();
      });
  };

  const validationSchema = object().shape({
    landmarks: string().nullable(),
    make: string().required("Make is required."),
    model: string().required("Model is required."),
    year: string().nullable(),
    plate_number: string().nullable(),
    transmission: string().nullable(),
    mileage: string().typeError("Mileage must be valid.").nullable(),
    fuel_type: string().required("Fuel type is required."),
    liter_of_oil: string().required("Liter of oil is required."),
    engine_size: number().typeError("Engine size must be valid.").nullable(),
    province: string().required("Province is required."),
    municipality: string().required("Municipality/City is required."),
    barangay: string().required("Barangay is required."),
    services: array(
      object({
        id: number().nullable(true),
        name: string().required("Please fill up this field."),
        quantity: number()
          .required("Quantity is required.")
          .min(1, "Please input at least 1 item."),
        price: string().nullable(true),
        total: string().nullable(true),
        type: string().nullable(true),
      })
    ).nullable(),
    manualServices: array(
      object({
        id: number().nullable(true),
        name: string().required("Please fill up this field."),
        remarks: string().nullable(true),
        quantity: number()
          .required("Quantity is required.")
          .min(1, "Please input at least 1 item."),
        price: number()
          .nullable(true)
          .transform((value, originalValue) => {
            // Handle the case when the value is "-"
            const parsedValue = Number(originalValue);
            return isNaN(parsedValue) ? undefined : parsedValue;
          })
          .when("$price", (price, schema) => {
            return price
              ? schema.positive("Price must be a positive number")
              : schema;
          }),
        totalPrice: number().required(""),
        type: string().required(),
      })
    ).nullable(),
    remarks: string().nullable(),
  });

  useEffect(() => {
    const options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    async function fetchData() {
      // ========Services========
      const request = await axios.get(
        `${config.api}/services/all?limit=10000`,
        options
      );
      let items = request.data.data.data;

      items.forEach((item) => {
        let data = {
          id: item.id,
          name: item.name,
          remarks: item?.remarks || "",
          quantity: 0,
          price: 0,
          totalPrice: 0,
          type: "manual",
        };
        manualListInitial.push(data);
      });
      return request;
      // setInitialValues({ ...initialValues, manualServices: manualListInitial });
    }

    // const fetchProvinces = async () => {
    //   const { data } = await axios.get(config.api + "/provinces");
    //   provincesList.push(data);
    //   return data;
    // };

    fetchData();
    // fetchProvinces();
  }, [manualListInitial, open]);

  useEffect(() => {
    let active = true;

    if (optionsAuto.length > 0) return;

    (async () => {
      const response = await axios.get(
        `${config.api}/users/customers`,
        options
      );
      const users = response.data.data;

      let customers = [];
      if (active) {
        for (let i in users) {
          customers.push(users[i]);
        }
        setOptionsAuto(customers);
      }
    })();

    return () => {
      active = false;
    };
  }, [options, open]);

  const filterOptions = (optionsAuto, { inputValue }) => {
    const normalizedInput = inputValue.toLowerCase();
    return optionsAuto.filter(
      (option) =>
        option.customer_id.toLowerCase().includes(normalizedInput) ||
        (option.email &&
          option.email.toLowerCase().includes(normalizedInput)) ||
        (option.name && option.name.toLowerCase().includes(normalizedInput)) ||
        (option.lastname &&
          option.lastname.toLowerCase().includes(normalizedInput))
    );
  };

  return (
    <Fragment>
      <div>
        <AlertDialog
          open={alert.open}
          typeAlert={alert.typeAlert}
          message={alert.message}
          handleCloseAlert={handleCloseAlert}
        />
        <Dialog
          fullScreen
          open={open}
          onClose={() => {
            onClose();
          }}
          TransitionComponent={Transition}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            innerRef={formRef}
            onSubmit={handleSubmitCustom}
          >
            {({
              values,
              errors,
              touched,
              isSubmitting,
              setFieldValue,
              handleChange,
            }) => (
              <Form autoComplete="off">
                <AppBar className={classes.appBar}>
                  <Toolbar>
                    <IconButton
                      edge="start"
                      color="inherit"
                      onClick={onClose}
                      aria-label="close"
                    >
                      <CloseIcon className={classes.button} />
                    </IconButton>
                    <Typography variant="h5" className={classes.title}>
                      Create Quotation
                      <ListAltSharpIcon className={classes.icon} />
                    </Typography>
                    <Button
                      disabled={loading}
                      type="submit"
                      startIcon={
                        loading ? <CircularProgress size="0.9rem" /> : undefined
                      }
                    >
                      {loading ? "Saving" : "Save"}
                    </Button>
                  </Toolbar>
                </AppBar>
                <MuiDialogContent dividers>
                  <div className={classes.root}>
                    <Paper className={classes.paper}>
                      <Box my={2}>
                        <Divider />
                      </Box>
                      <Typography variant="h6" className={classes.titleText2}>
                        CLIENT INFO
                      </Typography>
                      <Grid container spacing={3}>
                        <Grid item xs={3}>
                          <Autocomplete
                            className={classes2.customInput}
                            size="small"
                            inputValue={inputValue}
                            onInputChange={(event, newInputValue) => {
                              setInputValue(newInputValue);
                            }}
                            filterOptions={filterOptions}
                            id="controllable-states-demo"
                            options={optionsAuto}
                            renderOption={(option) => (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                {option.customer_id && (
                                  <div style={{ fontWeight: "700" }}>
                                    {option.customer_id}
                                  </div>
                                )}
                                <small>{`${option.name}${
                                  option.lastname ? ` ${option.lastname}` : ""
                                } ${
                                  option.email ? `| ${option.email}` : ""
                                }`}</small>
                              </div>
                            )}
                            getOptionLabel={(option) =>
                              option.customer_id || ""
                            }
                            renderInput={(params) => (
                              <TextFieldMUI
                                fullWidth
                                {...params}
                                label="Customer ID"
                                variant="outlined"
                                autoComplete="off"
                              />
                            )}
                            onChange={async (event, newValue) => {
                              const {
                                customer_id,
                                name,
                                address,
                                email,
                                phone,
                                lastname,
                                landmarks,
                                province,
                                municipality,
                                barangay,
                              } = newValue || {};
                              console.log("newValues: ", newValue);

                              const c_name = name || "";
                              const first_name = name || "";
                              const last_name = lastname || "";

                              setFieldValue(`customer_id`, customer_id);
                              setFieldValue(`c_name`, c_name);
                              setFieldValue(`name`, first_name);
                              setFieldValue(`lastname`, last_name);
                              setFieldValue(`address`, address || "");
                              setFieldValue(`email`, email || "");
                              setFieldValue(`contact_number`, phone || "");
                              setFieldValue(`landmarks`, landmarks || "");
                              setFieldValue(`province`, province || "");
                              setFieldValue(`municipality`, municipality || "");
                              setFieldValue(`barangay`, barangay || "");

                              if (province && municipality && barangay) {
                                const {
                                  data: { fee_amount },
                                } = await axios.get(
                                  `${config.api}/home-service-fee/${province}/${municipality}/${barangay}`
                                );

                                setFieldValue(`service_fee`, fee_amount || "");
                              }
                              if (address === "") {
                                setFieldValue(`distance_in_km`, "");
                              }
                              setFieldValue("customer_type", "Retention");
                            }}
                          />
                        </Grid>
                        <Grid item xs={3} className={classes.gridCustom}>
                          <FastField
                            label="First Name"
                            name="name"
                            fullWidth
                            type="text"
                            variant="outlined"
                            component={TextField}
                            size="small"
                            inputProps={{
                              style: {
                                textAlign: "center",
                                // textTransform: "capitalize",
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={3} className={classes.gridCustom}>
                          <FastField
                            fullWidth
                            label="Last Name"
                            name="lastname"
                            type="text"
                            variant="outlined"
                            component={TextField}
                            size="small"
                            inputProps={{
                              style: {
                                textAlign: "center",
                                // textTransform: "capitalize",
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={3} className={classes.gridCustom}>
                          <FastField
                            fullWidth
                            label="Email"
                            name="email"
                            variant="outlined"
                            component={TextField}
                            size="small"
                            InputProps={{
                              classes: {
                                root: classes.disabledRoot,
                              },
                            }}
                            inputProps={{ style: { textAlign: "center" } }}
                          />
                        </Grid>
                        <Grid item xs={3} className={classes.gridCustom}>
                          <FastField
                            fullWidth
                            label="Contact Number"
                            name="contact_number"
                            variant="outlined"
                            component={TextField}
                            size="small"
                            inputProps={{ style: { textAlign: "center" } }}
                          />
                        </Grid>
                        <Grid item xs={3} className={classes.house}>
                          <FastField
                            fullWidth
                            name="address"
                            component={TextField}
                            label="House #, Street"
                            size="small"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={3} className={classes.house}>
                          <FastField
                            fullWidth
                            label="Province"
                            component={CustomProvinceComponent}
                            name="province"
                            type="text"
                            variant="outlined"
                            size="small"
                            onChange={async (e) => {
                              setFieldValue("province", e.target.value);
                              setFieldValue("municipality", "");
                              setFieldValue("barangay", "");
                              // setInitialValues({
                              //   ...initialValues,
                              //   province: e.target.value,
                              //   municipality: "",
                              //   barangay: "",
                              // });
                            }}
                          />
                        </Grid>
                        <Grid item xs={3} className={classes.house}>
                          <Field
                            fullWidth
                            label="Municipality/City"
                            component={CustomMunicipalityComponent}
                            name="municipality"
                            type="text"
                            variant="outlined"
                            size="small"
                            onChange={async (e) => {
                              setFieldValue("municipality", e.target.value);
                              setFieldValue("barangay", "");
                            }}
                            // inputProps={{ style: { textAlign: 'center' } }}
                          />
                        </Grid>
                        <Grid item xs={3} className={classes.house}>
                          <Field
                            fullWidth
                            label="Barangay/Zip Code"
                            component={CustomBarangayComponent}
                            name="barangay"
                            type="text"
                            variant="outlined"
                            size="small"
                            onChange={async (e) => {
                              setFieldValue("barangay", e.target.value);
                              const {
                                data: { fee_amount },
                              } = await axios.get(
                                `${config.api}/home-service-fee/${values.province}/${values.municipality}/${e.target.value}`
                              );
                              const { data: address } = await axios.get(
                                `${config.api}/addressByName?province=${values.province}&municipality=${values.municipality}&barangay=${e.target.value}`
                              );
                              // setFieldValue("service_fee", fee_amount);
                              setFieldValue(
                                "zip_code",
                                address?.municipality?.[0]?.barangay?.[0]
                                  ?.zip_code?.[0]?.zip_code
                              );
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={8} className={classes.house}>
                          <FastField
                            fullWidth
                            name="landmarks"
                            component={TextField}
                            label="Exact Address"
                            size="small"
                            variant="outlined"
                            // classes={{
                            //   root: classes.inputCustomField,
                            // }}
                          />
                        </Grid>
                      </Grid>
                      <Box my={2}>
                        <Divider />
                      </Box>
                      <Typography variant="h6" className={classes.titleText2}>
                        VEHICLE INFO
                      </Typography>
                      <Grid container direction="row" spacing={3}>
                        <Grid item xs={3} className={classes.gridCustom}>
                          <FastField
                            name="make"
                            label="Make"
                            fullWidth
                            variant="outlined"
                            size="small"
                            component={CustomMakeComponent}
                            onChange={async (e) => {
                              const { value } = e.target;
                              setFieldValue("make", value);
                              setFieldValue("model", "");
                              setFieldValue("liter_of_oil", "");
                              setFieldValue("servicesList", []);
                              setFieldValue("services", []);
                              const _models = await fetchDataModel(value);
                              setFieldValue("models", _models.data.data);
                            }}
                          />
                        </Grid>
                        <Grid item xs={3} className={classes.gridCustom}>
                          <Field
                            name="model"
                            label="Model"
                            fullWidth
                            variant="outlined"
                            size="small"
                            component={CustomModelComponent}
                            onChange={async (e) => {
                              const { value } = e.target;
                              setFieldValue("model", value);
                              setFieldValue("liter_of_oil", "");
                              setFieldValue("servicesList", []);
                              setFieldValue("services", []);

                              let brandModel = `${values.make} ${value}`;

                              const _literVal = await fetchDataLiter(
                                brandModel,
                                values.fuel_type
                              );
                              let liter_of_oil = _literVal.data.data;
                              let optionsItems = [];

                              if (liter_of_oil) {
                                setFieldValue(
                                  "liter_of_oil",
                                  liter_of_oil.liter_of_oil
                                );

                                const _services = await fetchDataServices(
                                  values.make,
                                  value,
                                  liter_of_oil.liter_of_oil,
                                  values.fuel_type
                                );
                                let items = _services.data.data;
                                if (Array.isArray(items) && items.length > 0) {
                                  items.forEach((item) => {
                                    if (
                                      item.packages &&
                                      Array.isArray(item.packages)
                                    ) {
                                      item.packages.forEach((service) => {
                                        if (service?.variants) {
                                          optionsItems.push(service);
                                        }
                                      });
                                    }
                                  });
                                }
                              } else {
                                setFieldValue("liter_of_oil", "");
                              }

                              setFieldValue("servicesList", optionsItems);
                            }}
                          />
                        </Grid>
                        <Grid item xs={3} className={classes.gridCustom}>
                          <FastField
                            name="year"
                            label="Year"
                            variant="outlined"
                            size="small"
                            component={CustomYearComponent}
                          />
                        </Grid>
                        <Grid item xs={3} className={classes.gridCustom}>
                          <FastField
                            fullWidth
                            name="plate_number"
                            label="Plate Number"
                            variant="outlined"
                            size="small"
                            component={TextField}
                            inputProps={{
                              style: {
                                textAlign: "center",
                                textTransform: "uppercase",
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={3} className={classes.gridCustom}>
                          <FastField
                            name="transmission"
                            label="Transmission"
                            variant="outlined"
                            size="small"
                            component={CustomTransmissionComponent}
                            inputProps={{
                              style: {
                                textAlign: "center",
                                textTransform: "capitalize",
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={3} className={classes.gridCustom}>
                          <FastField
                            fullWidth
                            name="mileage"
                            label="Mileage"
                            variant="outlined"
                            size="small"
                            component={TextField}
                            onChange={(evt) => {
                              const { value } = evt.target;
                              const inputValue = value.replace(/[^0-9]/g, "");
                              const formattedValue =
                                Number(inputValue).toLocaleString();
                              setFieldValue(`mileage`, formattedValue);
                            }}
                            inputProps={{
                              style: {
                                textAlign: "center",
                                textTransform: "capitalize",
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={3} className={classes.gridCustom}>
                          <Field
                            name="fuel_type"
                            label="Fuel Type"
                            variant="outlined"
                            size="small"
                            component={CustomFuelComponent}
                            fullWidth
                            onChange={async (e) => {
                              const { value } = e.target;
                              setFieldValue("fuel_type", value);
                              setFieldValue("liter_of_oil", "");
                              setFieldValue("servicesList", []);
                              setFieldValue("services", []);

                              let brandModel = `${values.make} ${values.model}`;
                              const _literVal = await fetchDataLiter(
                                brandModel,
                                value
                              );
                              let liter_of_oil = _literVal.data.data;
                              let optionsItems = [];

                              if (liter_of_oil) {
                                setFieldValue(
                                  "liter_of_oil",
                                  liter_of_oil.liter_of_oil
                                );
                                const _services = await fetchDataServices(
                                  values.make,
                                  values.model,
                                  liter_of_oil.liter_of_oil,
                                  value
                                );
                                let items = _services.data.data;
                                if (Array.isArray(items) && items.length > 0) {
                                  items.forEach((item) => {
                                    if (
                                      item.packages &&
                                      Array.isArray(item.packages)
                                    ) {
                                      item.packages.forEach((service) => {
                                        if (service?.variants) {
                                          optionsItems.push(service);
                                        }
                                      });
                                    }
                                  });
                                }
                              } else {
                                setFieldValue("liter_of_oil", "");
                              }

                              setFieldValue("servicesList", optionsItems);
                            }}
                          />
                        </Grid>
                        <Grid item xs={3} className={classes.gridCustom}>
                          <FastField
                            fullWidth
                            name="liter_of_oil"
                            label="Liter of Oil"
                            variant="outlined"
                            size="small"
                            component={TextField}
                            inputProps={{
                              readOnly: "readOnly",
                              style: { textAlign: "center" },
                            }}
                          />
                        </Grid>
                        <Grid item xs={3} className={classes.gridCustom}>
                          <FastField
                            fullWidth
                            name="engine_size"
                            label="Engine Size"
                            variant="outlined"
                            size="small"
                            onChange={(evt) => {
                              const { value } = evt.target;
                              const inputValue = value.replace(/[^0-9.]/g, "");
                              let splitValue = inputValue
                                .split(".")
                                .slice(0, 4);
                              let finalValue = "";

                              if (splitValue.length === 1) {
                                const joined = splitValue.join("");
                                const lengthOfString = joined.length;
                                finalValue = joined;
                                if (joined > 20) {
                                  finalValue = `${joined}.0`;
                                }
                                if (lengthOfString === 3) {
                                  const firstTwoCharacters = joined.substring(
                                    0,
                                    2
                                  );
                                  const thirdCharacter = joined.charAt(2);
                                  finalValue = `${firstTwoCharacters}.${thirdCharacter}`;
                                }
                                if (lengthOfString === 2) {
                                  const firstCharacter = joined.substring(0, 1);
                                  const secondCharacter = joined.charAt(1);
                                  finalValue = `${firstCharacter}.${secondCharacter}`;
                                }
                              } else if (splitValue.length === 2) {
                                const joined = splitValue.join("");
                                const lengthOfString = joined.length;
                                finalValue = joined;
                                if (lengthOfString === 3) {
                                  const firstTwoCharacters = joined.substring(
                                    0,
                                    2
                                  );
                                  const thirdCharacter = joined.charAt(2);
                                  if (firstTwoCharacters > 20) {
                                    const firstCharacter = joined.substring(
                                      0,
                                      1
                                    );
                                    const secondCharacter = joined.charAt(1);
                                    finalValue = `${firstCharacter}.${secondCharacter}`;
                                  } else {
                                    finalValue = `${firstTwoCharacters}.${thirdCharacter}`;
                                  }
                                } else if (lengthOfString === 4) {
                                  const firstTwoCharacters = joined.substring(
                                    0,
                                    2
                                  );
                                  const thirdAndFourthCharacters =
                                    joined.charAt(2);
                                  finalValue = `${firstTwoCharacters}.${thirdAndFourthCharacters}`;
                                }
                              }
                              setFieldValue("engine_size", finalValue);
                            }}
                            component={TextField}
                            inputProps={{
                              style: {
                                textAlign: "center",
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={3} className={classes.gridCustom}>
                          <FastField
                            fullWidth
                            name="property_type"
                            label="Property Type"
                            variant="outlined"
                            size="small"
                            component={SelectPropertyType}
                            inputProps={{
                              style: {
                                textAlign: "center",
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={3} className={classes.gridCustom}>
                          <FastField
                            fullWidth
                            name="parking_space"
                            label="Parking Space"
                            variant="outlined"
                            size="small"
                            component={ParkingSpaceComponent}
                            inputProps={{
                              style: {
                                textAlign: "center",
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Box my={4}>
                        <Divider />
                      </Box>
                      <FieldArray name="services">
                        {({ push, remove }) => (
                          <React.Fragment>
                            <Box display="flex">
                              <Box p={1} width="100%">
                                <Typography
                                  variant="h6"
                                  className={classes.titleText2}
                                >
                                  PACKAGES
                                </Typography>
                              </Box>
                              <Box p={1} flexShrink={0}>
                                <Button
                                  variant="contained"
                                  className={classes.add}
                                  startIcon={<AddCircleIcon />}
                                  onClick={() => {
                                    push(emptyPackage);
                                  }}
                                >
                                  Add Package
                                </Button>
                              </Box>
                            </Box>
                            <Grid
                              container
                              direction="row"
                              justify="space-evenly"
                              alignItems="center"
                              spacing={3}
                            >
                              <Grid item xs={10} className={classes.gridCustom}>
                                <TableContainer component={Paper}>
                                  <Table size="small" aria-label="simple table">
                                    <TableHead>
                                      <TableRow>
                                        <TableCell
                                          style={{
                                            fontWeight: "bold",
                                            width: "5%",
                                          }}
                                          align="center"
                                        >
                                          ACTIONS
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            fontWeight: "bold",
                                            width: "75%",
                                          }}
                                          align="center"
                                        >
                                          SERVICE PACKAGE
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            fontWeight: "bold",
                                            width: "10%",
                                          }}
                                        >
                                          QUANTITY
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            fontWeight: "bold",
                                            width: "10%",
                                          }}
                                        >
                                          TOTAL PRICE
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {values.services.map((value, index) => (
                                        <TableRow key={index}>
                                          <TableCell
                                            align="center"
                                            scope="row"
                                            style={{ width: "5%" }}
                                          >
                                            <Tooltip title="Remove">
                                              <IconButton
                                                color="secondary"
                                                aria-label="remove"
                                                onClick={() => {
                                                  remove(index);
                                                }}
                                              >
                                                <HighlightOffIcon
                                                  className={classes.largeIcon}
                                                />
                                              </IconButton>
                                            </Tooltip>
                                          </TableCell>
                                          <TableCell
                                            scope="row"
                                            style={{ width: "75%" }}
                                          >
                                            <Autocomplete
                                              getOptionLabel={(option) =>
                                                option.name
                                              }
                                              disableClearable
                                              size="small"
                                              classes={classes2}
                                              value={values.services[index]}
                                              options={[
                                                ...values.services,
                                                ...values.servicesList,
                                              ].filter(
                                                (v, i, a) =>
                                                  a.findIndex(
                                                    (t) => t.id === v.id
                                                  ) === i
                                              )}
                                              filterSelectedOptions
                                              onChange={(event, newValue) => {
                                                if (newValue) {
                                                  var serviceCollection =
                                                    values.services;
                                                  var filtered =
                                                    serviceCollection.filter(
                                                      (c) =>
                                                        c.id === newValue.id
                                                    );

                                                  if (filtered.length === 0) {
                                                    setFieldValue(
                                                      `services.${index}`,
                                                      {
                                                        ...newValue,
                                                        quantity: 1,
                                                      }
                                                    );
                                                  } else {
                                                    setAlert({
                                                      ...alert,
                                                      open: true,
                                                      message:
                                                        "The package already exists.",
                                                      typeAlert: "error",
                                                    });
                                                  }
                                                }
                                              }}
                                              renderInput={(params) => (
                                                <TextFieldMUI
                                                  error={
                                                    errors.services &&
                                                    typeof errors.services[
                                                      index
                                                    ] != "undefined"
                                                      ? true
                                                      : false
                                                  }
                                                  helperText={
                                                    errors.services &&
                                                    typeof errors.services[
                                                      index
                                                    ] != "undefined"
                                                      ? errors.services[index]
                                                          .name
                                                      : ""
                                                  }
                                                  variant="standard"
                                                  name={`services.${index}.name`}
                                                  fullWidth
                                                  {...params}
                                                />
                                              )}
                                            />
                                          </TableCell>
                                          <TableCell
                                            scope="row"
                                            style={{ width: "10%" }}
                                          >
                                            <Field
                                              name={`services.${index}.quantity`}
                                              fullWidth
                                              variant="standard"
                                              size="small"
                                              component={TextField}
                                              inputProps={{
                                                style: {
                                                  textAlign: "center",
                                                },
                                              }}
                                            />
                                          </TableCell>
                                          <TableCell
                                            scope="row"
                                            style={{ width: "10%" }}
                                          >
                                            ₱
                                            {values?.services?.[index]?.variants
                                              ? numberConverter(
                                                  values.services[index]
                                                    .variants.price
                                                )
                                              : "0.00"}
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                      {values.services.length <= 0 &&
                                      errors.services === undefined ? (
                                        <TableRow>
                                          <TableCell
                                            scope="row"
                                            colSpan={3}
                                            align="center"
                                            style={{
                                              color: "#ccc",
                                              fontStyle: "italic",
                                            }}
                                          >
                                            No package selected.
                                          </TableCell>
                                        </TableRow>
                                      ) : null}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Grid>
                            </Grid>
                          </React.Fragment>
                        )}
                      </FieldArray>
                      <Box my={4}>
                        <Divider />
                      </Box>
                      <FieldArray name="manualServices">
                        {({ push, remove }) => (
                          <React.Fragment>
                            <Box display="flex">
                              <Box p={1} width="100%">
                                <Typography
                                  variant="h6"
                                  className={classes.titleText2}
                                >
                                  SERVICES
                                </Typography>
                              </Box>
                              <Box p={1} flexShrink={0}>
                                <Button
                                  variant="contained"
                                  className={classes.add}
                                  startIcon={<AddCircleIcon />}
                                  onClick={() => {
                                    push(emptyServices);
                                  }}
                                >
                                  Add Service
                                </Button>
                              </Box>
                            </Box>
                            <Grid
                              container
                              direction="row"
                              justify="space-evenly"
                              alignItems="center"
                              spacing={3}
                            >
                              <Grid item xs={10} className={classes.gridCustom}>
                                <TableContainer component={Paper}>
                                  <Table size="small" aria-label="simple table">
                                    <TableHead>
                                      <TableRow>
                                        <TableCell
                                          style={{
                                            fontWeight: "bold",
                                            width: "5%",
                                          }}
                                          align="center"
                                        >
                                          ACTIONS
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            fontWeight: "bold",
                                            width: "45%",
                                          }}
                                          align="center"
                                        >
                                          SERVICE ITEMS
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            fontWeight: "bold",
                                            width: "20%",
                                          }}
                                          align="center"
                                        >
                                          REMARKS
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            fontWeight: "bold",
                                            width: "20%",
                                          }}
                                          align="center"
                                        >
                                          QUANTITY
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            fontWeight: "bold",
                                            width: "20%",
                                          }}
                                          align="center"
                                        >
                                          PRICE
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            fontWeight: "bold",
                                            width: "10%",
                                          }}
                                        >
                                          TOTAL PRICE
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {values.manualServices.map(
                                        (value, index) => (
                                          <TableRow key={index}>
                                            <TableCell
                                              align="center"
                                              scope="row"
                                              style={{ width: "5%" }}
                                            >
                                              <Tooltip title="Remove">
                                                <IconButton
                                                  color="secondary"
                                                  aria-label="remove"
                                                  onClick={() => {
                                                    remove(index);
                                                  }}
                                                >
                                                  <HighlightOffIcon
                                                    className={
                                                      classes.largeIcon
                                                    }
                                                  />
                                                </IconButton>
                                              </Tooltip>
                                            </TableCell>
                                            <TableCell
                                              scope="row"
                                              style={{ width: "45%" }}
                                            >
                                              <Autocomplete
                                                getOptionLabel={(option) =>
                                                  option.name
                                                }
                                                disableClearable
                                                size="small"
                                                classes={classes2}
                                                value={
                                                  values.manualServices[index]
                                                }
                                                options={[
                                                  ...values.manualServices,
                                                  ...values.manualServicesList,
                                                ].filter(
                                                  (v, i, a) =>
                                                    a.findIndex(
                                                      (t) => t.name === v.name
                                                    ) === i
                                                )}
                                                filterSelectedOptions
                                                onChange={(event, newValue) => {
                                                  if (newValue) {
                                                    var serviceCollection =
                                                      values.manualServices;
                                                    var filtered =
                                                      serviceCollection.filter(
                                                        (c) =>
                                                          c.name ===
                                                          newValue.name
                                                      );

                                                    if (filtered.length === 0) {
                                                      setFieldValue(
                                                        `manualServices.${index}`,
                                                        {
                                                          ...newValue,
                                                          quantity: 1,
                                                        }
                                                      );
                                                    } else {
                                                      setAlert({
                                                        ...alert,
                                                        open: true,
                                                        message:
                                                          "The service already exists.",
                                                        typeAlert: "error",
                                                      });
                                                    }
                                                  }
                                                }}
                                                renderInput={(params) => (
                                                  <TextFieldMUI
                                                    error={
                                                      errors.manualServices &&
                                                      typeof errors
                                                        .manualServices[
                                                        index
                                                      ] != "undefined"
                                                        ? true
                                                        : false
                                                    }
                                                    helperText={
                                                      errors.manualServices &&
                                                      typeof errors
                                                        .manualServices[
                                                        index
                                                      ] != "undefined"
                                                        ? errors.manualServices[
                                                            index
                                                          ].name
                                                        : ""
                                                    }
                                                    variant="standard"
                                                    name={`manualServices.${index}.name`}
                                                    fullWidth
                                                    {...params}
                                                  />
                                                )}
                                              />
                                            </TableCell>
                                            <TableCell
                                              scope="row"
                                              style={{ width: "20%" }}
                                            >
                                              <Field
                                                name={`manualServices.${index}.remarks`}
                                                fullWidth
                                                variant="standard"
                                                size="small"
                                                component={TextField}
                                                inputProps={{
                                                  style: {
                                                    textAlign: "center",
                                                  },
                                                }}
                                              />
                                            </TableCell>
                                            <TableCell
                                              scope="row"
                                              style={{ width: "20%" }}
                                            >
                                              <Field
                                                name={`manualServices.${index}.quantity`}
                                                fullWidth
                                                variant="standard"
                                                size="small"
                                                component={TextField}
                                                inputProps={{
                                                  style: {
                                                    textAlign: "center",
                                                  },
                                                }}
                                              />
                                            </TableCell>
                                            <TableCell
                                              scope="row"
                                              style={{ width: "20%" }}
                                            >
                                              <Field
                                                name={`manualServices.${index}.price`}
                                                fullWidth
                                                variant="standard"
                                                size="small"
                                                component={TextField}
                                                inputProps={{
                                                  style: {
                                                    textAlign: "center",
                                                  },
                                                }}
                                              />
                                            </TableCell>
                                            <TableCell
                                              scope="row"
                                              style={{ width: "10%" }}
                                            >
                                              <Field
                                                name={`manualServices.${index}.totalPrice`}
                                                component={
                                                  CustomOtherServicesTotalPrice
                                                }
                                                index={index}
                                              />
                                            </TableCell>
                                          </TableRow>
                                        )
                                      )}
                                      {values.manualServices.length <= 0 ? (
                                        <TableRow>
                                          <TableCell
                                            scope="row"
                                            colSpan={5}
                                            align="center"
                                            style={{
                                              color: "#ccc",
                                              fontStyle: "italic",
                                            }}
                                          >
                                            No services selected.
                                          </TableCell>
                                        </TableRow>
                                      ) : null}
                                      {typeof errors.manualServices ===
                                      "string" ? (
                                        <TableRow>
                                          <TableCell
                                            scope="row"
                                            colSpan={5}
                                            align="center"
                                            style={{
                                              color: "#ccc",
                                              fontStyle: "italic",
                                            }}
                                          >
                                            No services selected.
                                          </TableCell>
                                        </TableRow>
                                      ) : null}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Grid>
                            </Grid>
                          </React.Fragment>
                        )}
                      </FieldArray>
                      <Box my={4}>
                        <Divider />
                      </Box>
                      <FieldArray name="parts">
                        {({ push, remove }) => (
                          <React.Fragment>
                            <Box display="flex">
                              <Box p={1} width="100%">
                                <Typography
                                  variant="h6"
                                  className={classes.titleText2}
                                >
                                  PARTS
                                </Typography>
                              </Box>
                              <Box p={1} flexShrink={0}>
                                <Button
                                  variant="contained"
                                  className={classes.add}
                                  startIcon={<AddCircleIcon />}
                                  onClick={() => {
                                    push(emptyParts);
                                  }}
                                >
                                  Add Parts
                                </Button>
                              </Box>
                            </Box>
                            <Grid
                              container
                              direction="row"
                              justify="space-evenly"
                              alignItems="center"
                              spacing={3}
                            >
                              <Grid item xs={10} className={classes.gridCustom}>
                                <TableContainer
                                  component={Paper}
                                  className={classes2.root}
                                >
                                  <Table size="small" aria-label="simple table">
                                    <TableHead>
                                      <TableRow>
                                        <TableCell
                                          style={{
                                            fontWeight: "bold",
                                            width: "5%",
                                          }}
                                          align="center"
                                        >
                                          ACTIONS
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            fontWeight: "bold",
                                            width: "45%",
                                          }}
                                          align="center"
                                        >
                                          PART ITEMS
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            fontWeight: "bold",
                                            width: "20%",
                                          }}
                                          align="center"
                                        >
                                          REMARKS
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            fontWeight: "bold",
                                            width: "20%",
                                          }}
                                          align="center"
                                        >
                                          QUANTITY
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            fontWeight: "bold",
                                            width: "20%",
                                          }}
                                          align="center"
                                        >
                                          PRICE
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            fontWeight: "bold",
                                            width: "10%",
                                          }}
                                        >
                                          TOTAL PRICE
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {values.parts.map((value, index) => (
                                        <TableRow key={index}>
                                          <TableCell
                                            align="center"
                                            scope="row"
                                            style={{ width: "5%" }}
                                          >
                                            <Tooltip title="Remove">
                                              <IconButton
                                                color="secondary"
                                                aria-label="remove"
                                                onClick={() => {
                                                  remove(index);
                                                }}
                                              >
                                                <HighlightOffIcon
                                                  className={classes.largeIcon}
                                                />
                                              </IconButton>
                                            </Tooltip>
                                          </TableCell>
                                          <TableCell
                                            scope="row"
                                            style={{ width: "45%" }}
                                          >
                                            <CustomPartsAutocompleteComponent
                                              key={index}
                                              index={index}
                                              values={values}
                                              setFieldValue={setFieldValue}
                                              errors={errors}
                                              classes2={classes2}
                                              setAlert={setAlert}
                                              alert={alert}
                                            />
                                          </TableCell>
                                          <TableCell
                                            scope="row"
                                            style={{ width: "20%" }}
                                          >
                                            <Field
                                              name={`parts.${index}.remarks`}
                                              fullWidth
                                              variant="standard"
                                              size="small"
                                              component={TextField}
                                              inputProps={{
                                                style: {
                                                  textAlign: "center",
                                                },
                                              }}
                                            />
                                          </TableCell>
                                          <TableCell
                                            scope="row"
                                            style={{ width: "20%" }}
                                          >
                                            <Field
                                              name={`parts.${index}.quantity`}
                                              fullWidth
                                              variant="standard"
                                              size="small"
                                              component={TextField}
                                              inputProps={{
                                                style: {
                                                  textAlign: "center",
                                                },
                                              }}
                                            />
                                          </TableCell>
                                          <TableCell
                                            scope="row"
                                            style={{ width: "20%" }}
                                          >
                                            <Field
                                              name={`parts.${index}.price`}
                                              fullWidth
                                              variant="standard"
                                              size="small"
                                              component={TextField}
                                              inputProps={{
                                                style: {
                                                  textAlign: "center",
                                                },
                                              }}
                                            />
                                          </TableCell>
                                          <TableCell
                                            scope="row"
                                            style={{ width: "10%" }}
                                          >
                                            <Field
                                              name={`parts.${index}.totalPrice`}
                                              component={CustomPartsTotalPrice}
                                              index={index}
                                            />
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                      {values.parts.length <= 0 ? (
                                        <TableRow>
                                          <TableCell
                                            scope="row"
                                            colSpan={5}
                                            align="center"
                                            style={{
                                              color: "#ccc",
                                              fontStyle: "italic",
                                            }}
                                          >
                                            No parts selected.
                                          </TableCell>
                                        </TableRow>
                                      ) : null}
                                      {typeof errors.parts === "string" ? (
                                        <TableRow>
                                          <TableCell
                                            scope="row"
                                            colSpan={5}
                                            align="center"
                                            style={{
                                              color: "#ccc",
                                              fontStyle: "italic",
                                            }}
                                          >
                                            No parts selected.
                                          </TableCell>
                                        </TableRow>
                                      ) : null}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Grid>
                            </Grid>
                          </React.Fragment>
                        )}
                      </FieldArray>
                      <Box my={4}>
                        <Divider />
                      </Box>
                      <FieldArray name="tires">
                        {({ push, remove }) => (
                          <React.Fragment>
                            <Box display="flex">
                              <Box p={1} width="100%">
                                <Typography
                                  variant="h6"
                                  className={classes.titleText2}
                                >
                                  TIRES
                                </Typography>
                              </Box>
                              <Box p={1} flexShrink={0}>
                                <Button
                                  variant="contained"
                                  className={classes.add}
                                  startIcon={<AddCircleIcon />}
                                  onClick={() => {
                                    push(emptyTires);
                                  }}
                                >
                                  Add Tires
                                </Button>
                              </Box>
                            </Box>
                            <Grid
                              container
                              direction="row"
                              justify="space-evenly"
                              alignItems="center"
                              spacing={3}
                            >
                              <Grid item xs={10} className={classes.gridCustom}>
                                <TableContainer
                                  component={Paper}
                                  className={classes2.root}
                                >
                                  <Table size="small" aria-label="simple table">
                                    <TableHead>
                                      <TableRow>
                                        <TableCell
                                          style={{
                                            fontWeight: "bold",
                                            width: "5%",
                                          }}
                                          align="center"
                                        >
                                          ACTIONS
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            fontWeight: "bold",
                                            width: "45%",
                                          }}
                                          align="center"
                                        >
                                          TIRE ITEMS
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            fontWeight: "bold",
                                            width: "20%",
                                          }}
                                          align="center"
                                        >
                                          QUANTITY
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            fontWeight: "bold",
                                            width: "20%",
                                          }}
                                          align="center"
                                        >
                                          PRICE
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            fontWeight: "bold",
                                            width: "10%",
                                          }}
                                        >
                                          TOTAL PRICE
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {values.tires.map((value, index) => (
                                        <TableRow key={index}>
                                          <TableCell
                                            align="center"
                                            scope="row"
                                            style={{ width: "5%" }}
                                          >
                                            <Tooltip title="Remove">
                                              <IconButton
                                                color="secondary"
                                                aria-label="remove"
                                                onClick={() => {
                                                  remove(index);
                                                }}
                                              >
                                                <HighlightOffIcon
                                                  className={classes.largeIcon}
                                                />
                                              </IconButton>
                                            </Tooltip>
                                          </TableCell>
                                          <TableCell
                                            scope="row"
                                            style={{ width: "45%" }}
                                          >
                                            <CustomTiresAutocompleteComponent
                                              key={index}
                                              index={index}
                                              values={values}
                                              setFieldValue={setFieldValue}
                                              errors={errors}
                                              classes2={classes2}
                                              setAlert={setAlert}
                                              alert={alert}
                                            />
                                          </TableCell>
                                          <TableCell
                                            scope="row"
                                            style={{ width: "20%" }}
                                          >
                                            <Field
                                              name={`tires.${index}.quantity`}
                                              fullWidth
                                              variant="standard"
                                              size="small"
                                              component={TextField}
                                              inputProps={{
                                                style: {
                                                  textAlign: "center",
                                                },
                                              }}
                                            />
                                          </TableCell>
                                          <TableCell
                                            scope="row"
                                            style={{ width: "20%" }}
                                          >
                                            <Field
                                              name={`tires.${index}.price`}
                                              fullWidth
                                              variant="standard"
                                              size="small"
                                              component={TextField}
                                              inputProps={{
                                                style: {
                                                  textAlign: "center",
                                                },
                                              }}
                                            />
                                          </TableCell>
                                          <TableCell
                                            scope="row"
                                            style={{ width: "10%" }}
                                          >
                                            <Field
                                              name={`tires.${index}.totalPrice`}
                                              component={CustomTiresTotalPrice}
                                              index={index}
                                            />
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                      {values.tires.length <= 0 ? (
                                        <TableRow>
                                          <TableCell
                                            scope="row"
                                            colSpan={5}
                                            align="center"
                                            style={{
                                              color: "#ccc",
                                              fontStyle: "italic",
                                            }}
                                          >
                                            No tires selected.
                                          </TableCell>
                                        </TableRow>
                                      ) : null}
                                      {typeof errors.tires === "string" ? (
                                        <TableRow>
                                          <TableCell
                                            scope="row"
                                            colSpan={5}
                                            align="center"
                                            style={{
                                              color: "#ccc",
                                              fontStyle: "italic",
                                            }}
                                          >
                                            No tires selected.
                                          </TableCell>
                                        </TableRow>
                                      ) : null}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Grid>
                            </Grid>
                          </React.Fragment>
                        )}
                      </FieldArray>
                      <Box mt={3}>
                        <Grid
                          container
                          direction="row"
                          justify="space-evenly"
                          alignItems="center"
                          spacing={3}
                        >
                          <Grid item xs={10} className={classes.gridCustom}>
                            <TableContainer component={Paper}>
                              <Table size="small" aria-label="simple table">
                                <TableHead>
                                  <TableRow></TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow>
                                    <TableCell
                                      scope="row"
                                      colSpan={4}
                                      style={{
                                        fontWeight: "bold",
                                        width: "90%",
                                      }}
                                      align="right"
                                    >
                                      Convenience Fee :
                                    </TableCell>
                                    <TableCell
                                      align="left"
                                      style={{ width: "10%" }}
                                    >
                                      <Field
                                        name="service_fee"
                                        component={CustomServiceFeeComponent}
                                        token={token}
                                      />
                                      <Field
                                        name="service_fee"
                                        component={CustomServiceFee2Component}
                                      />
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      scope="row"
                                      colSpan={4}
                                      style={{
                                        fontWeight: "bold",
                                        width: "90%",
                                      }}
                                      align="right"
                                    >
                                      Discount :
                                    </TableCell>
                                    <TableCell
                                      align="left"
                                      style={{ width: "10%" }}
                                    >
                                      {/* ₱{numberConverter(values.discount)} */}
                                      <FastField
                                        name="discounts"
                                        component={CustomDiscountComponent}
                                      />
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      scope="row"
                                      colSpan={4}
                                      style={{
                                        fontWeight: "bold",
                                        width: "90%",
                                      }}
                                      align="right"
                                    >
                                      Total Amount :
                                    </TableCell>
                                    <TableCell
                                      align="left"
                                      style={{ width: "10%" }}
                                    >
                                      ₱
                                      <Field
                                        name="total"
                                        component={CustomTotalComponent}
                                      />
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        </Grid>
                      </Box>
                    </Paper>
                  </div>
                </MuiDialogContent>
              </Form>
            )}
          </Formik>
        </Dialog>
      </div>
    </Fragment>
  );
};

export default AddQuotation;
