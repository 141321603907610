import React, { Fragment } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import "react-datepicker/dist/react-datepicker.css";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  Grid,
  Box,
  InputLabel,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Dialog,
  FormControl,
  Typography,
  Divider,
  FormHelperText,
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { green } from "@material-ui/core/colors";
import { Formik, Form, FastField, ErrorMessage } from "formik";
import { TextField } from "formik-material-ui";
import { object, date, array, number, ref } from "yup";
import ServiceLocationDropdown from "./ServiceLocationDropdown";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    border: "1px solid #e8e8e8",
  },
  inputLabel: {
    margin: theme.spacing("auto", "auto", 1, "auto"),
  },
  title: {
    backgroundColor: "#FAE700",
  },
  titleText: {
    color: "rgb(63,81,181) !important",
    fontWeight: "bold !important",
  },
  button: {
    backgroundColor: green[500],
    color: "#fff",
    "&:hover": {
      backgroundColor: green[600],
    },
  },
  paper: {
    width: "700px",
    maxWidth: "700px",
  },
  add: {
    backgroundColor: green[500],
    color: "#fff",
    "&:hover": {
      backgroundColor: green[600],
    },
  },
}));

const useStyles2 = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  form: {
    width: 800,
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    minWidth: 60,
  },
  type: {
    fontSize: "14px",
    fontWeight: "600",
  },
  formControl: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
      marginRight: theme.spacing(0),
      marginBottom: theme.spacing(1),
    },
  },
  search: {
    maxWidth: 130,
  },
  input: {
    textAlign: "center !important",
  },
}));

const formatTime = (time) => {
  const [hours, minutes, seconds] = time.split(":").map(Number);
  const date = new Date();
  date.setHours(hours, minutes, seconds, 0);

  const options = { hour: "numeric", minute: "numeric", hour12: true };
  return date.toLocaleString("en-US", options);
};

const ViewSchedule = React.memo((props) => {
  const classes = useStyles();
  const classes2 = useStyles2();

  const { open, onOpen, onClose, onUpdate, data } = props;
  console.log("dataaaa schedule: ", data);

  const handleOpen = () => {
    onOpen();
  };

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = (values, actions) => {
    onUpdate(values, actions);
  };
  const initialValues = data;

  const validationSchema = object().shape({
    schedule_from: date()
      .typeError("Schedule from time is required.")
      .required("Schedule from time is required."),
    schedule_to: date()
      .typeError("Schedule from time is required.")
      .min(
        ref("schedule_from"),
        "End date shall not be earlier than Start date."
      )
      .required("Schedule to time is required."),
    schedule_times: array(
      object({
        max_slots: number()
          .typeError("Max slots must be valid.")
          .min(0, "Invalid minimum.")
          .required("Required field."),
      })
    ).required(),
    garage_id: number().required("Garage is required"),
  });

  return (
    <Fragment>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          disableBackdropClick
          classes={{ paper: classes.paper }}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              setFieldValue,
              setSubmitting,
              isSubmitting,
            }) => (
              <Form
                autoComplete="off"
                style={{
                  display: "contents",
                }}
              >
                <DialogTitle
                  id="alert-dialog-title"
                  className={`${classes.title}`}
                >
                  <span className={classes.titleText}>
                    {"EDIT SCHEDULE FORM"}
                  </span>
                </DialogTitle>
                <DialogContent dividers>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <Grid item xs={12} style={{ marginBottom: "1.5rem" }}>
                        <FastField
                          label="Garage"
                          component={ServiceLocationDropdown}
                          name="garage_id"
                          type="text"
                          variant="outlined"
                          size="small"
                        />
                        <ErrorMessage
                          name="garage_id"
                          component="div"
                          style={{ color: "red" }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Box>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              margin="normal"
                              id="date-from"
                              label="Date From"
                              minDate={new Date(data.schedule_from)}
                              format="MM/dd/yyyy"
                              value={new Date(values.schedule_from)}
                              onChange={(date) => {
                                setFieldValue(`schedule_from`, date);
                              }}
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                            />
                          </MuiPickersUtilsProvider>
                          {errors.schedule_from && (
                            <FormHelperText
                              id="component-error-text"
                              style={{ color: "#f44336" }}
                            >
                              {errors.schedule_from}
                            </FormHelperText>
                          )}
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box mt={2}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              margin="normal"
                              id="date-to"
                              label="Date To"
                              format="MM/dd/yyyy"
                              minDate={new Date(values.schedule_from)}
                              value={new Date(values.schedule_to)}
                              onChange={(date) => {
                                setFieldValue(`schedule_to`, date);
                              }}
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                            />
                          </MuiPickersUtilsProvider>
                          {errors.schedule_to && (
                            <FormHelperText
                              id="component-error-text"
                              style={{ color: "#f44336" }}
                            >
                              {errors.schedule_to}
                            </FormHelperText>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                    <Divider
                      orientation="vertical"
                      flexItem
                      style={{ marginRight: "-1px" }}
                    />
                    <Grid item xs={8}>
                      <Box mb={2}>
                        <InputLabel
                          htmlFor="my-input"
                          className={classes.inputLabel}
                        >
                          Schedule slots
                        </InputLabel>
                      </Box>
                      <Grid container item xs={12}>
                        {values.schedule_times.map((value, index) => (
                          <Grid item xs={6} key={index}>
                            {" "}
                            {/* Adjust xs={6} for responsiveness as needed */}
                            <Box mb={2}>
                              <div className={classes2.root}>
                                <div className={classes2.form}>
                                  <FormControl
                                    className={classNames(
                                      classes2.formControl,
                                      classes2.text
                                    )}
                                  >
                                    <Typography
                                      variant="body1"
                                      className={classes2.type}
                                    >
                                      {formatTime(value.time)}
                                    </Typography>
                                  </FormControl>
                                  <FormControl
                                    className={classNames(
                                      classes2.formControl,
                                      classes2.search
                                    )}
                                  >
                                    <FastField
                                      component={TextField}
                                      name={`schedule_times.${index}.max_slots`}
                                      variant="outlined"
                                      size="small"
                                      type="number"
                                      inputProps={{
                                        style: {
                                          textAlign: "center",
                                          height: "8px",
                                        },
                                      }}
                                    />
                                  </FormControl>
                                </div>
                              </div>
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button
                    disabled={isSubmitting}
                    onClick={handleClose}
                    size="small"
                    color="default"
                  >
                    Close
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    color="primary"
                    variant="contained"
                    autoFocus
                    size="small"
                    endIcon={
                      isSubmitting ? (
                        <CheckCircleIcon size="0.9rem" />
                      ) : undefined
                    }
                  >
                    {isSubmitting ? "Submitting" : "Submit"}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>
      </div>
    </Fragment>
  );
});
export default ViewSchedule;
