import React, { Fragment, useCallback, useState, useRef } from "react";
import "moment-timezone";
import moment from "moment";
import axios from "axios";
import MaterialTable from "material-table";
import { makeStyles } from "@material-ui/core/styles";
import { tableIcons } from "../../booking/TableIcons";
import {
  IconButton,
  Slide,
  Toolbar,
  AppBar,
  Dialog,
  Typography,
} from "@material-ui/core";
import MuiDialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import CancelIcon from "@material-ui/icons/Cancel";
import Tooltip from "@material-ui/core/Tooltip";
import Chip from "@material-ui/core/Chip";
import useModal from "../../../hooks/use-modal";
import AlertDialog from "../../booking/AlertDialog";
import MechanicLeaveDelete from "./mechanicLeaveDelete/MechanicLeaveDelete";

// Api config
import config from "../../../utils/config";

const useStyles = makeStyles((theme) => ({
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  button: {
    color: theme.palette.grey[900],
  },
  icon: {
    position: "absolute",
    top: "18px",
    marginLeft: "3px",
  },
  appBar: {
    position: "relative",
    backgroundColor: "#FAE700",
    color: "#000",
  },
  table: {
    display: "block",
    width: "80%",
    margin: "5% auto 0 auto !important",
  },
  datepicker: { zIndex: "10000 !important" },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
      timeout={{ appear: 100, enter: 500, exit: 500 }}
    />
  );
});

const MechanicLeaveTable = (props) => {
  const classes = useStyles();

  const { open, token, onClose, data } = props;
  const tableRef = useRef();

  // ==============MODAL STATES==============
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [typeAlert, setTypeAlert] = useState("success");

  const {
    modalState: modalStateDelete,
    modalData: modalDataDelete,
    openHandler: modalOpenDelete,
    closeHandler: modalCloseDelete,
  } = useModal();

  const handleDeleteLog = useCallback(
    async (id) => {
      console.log(id);

      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        const resp = await axios.delete(`${config.api}/logs/${id}`, options);
        tableRef.current && tableRef.current.onQueryChange();
        modalCloseDelete();
        setAlert(true);
        setMessage("Successfully Deleted");
        setTypeAlert("success");
      } catch (err) {
        // Handle Error Here
        console.error(err);
        tableRef.current && tableRef.current.onQueryChange();
        modalCloseDelete();
        setAlert(true);
        setTypeAlert("error");
        setMessage("Something went wrong please try again.");
      }
    },
    [modalCloseDelete, token, tableRef]
  );

  // =================ALERT - (MODAL)=================
  const handleCloseAlert = useCallback(() => {
    setAlert(false);
    setMessage("");
    setTypeAlert("success");
  }, []);

  return (
    <Fragment>
      {/* =================Alert - (MODAL)================= */}
      <AlertDialog
        open={alert}
        typeAlert={typeAlert}
        message={message}
        handleCloseAlert={handleCloseAlert}
      />

      {/* =================DELETE - (MODAL)================= */}
      <MechanicLeaveDelete
        open={modalStateDelete}
        data={modalDataDelete}
        onClose={modalCloseDelete}
        onDelete={handleDeleteLog}
      />

      <Dialog
        fullScreen
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h5" className={classes.title}>
              Leave History
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon className={classes.button} />
            </IconButton>
          </Toolbar>
        </AppBar>
        <MuiDialogContent dividers>
          <div className={classes.table}>
            {Array.isArray(data) && data.length === 0 ? null : (
              <MaterialTable
                icons={tableIcons}
                title={`${data && data?.firstname} ${data && data?.lastname}`}
                tableRef={tableRef}
                columns={[
                  {
                    title: "Date Applied",
                    render: (client) => {
                      return moment(client.created_at).format("YYYY-MM-DD");
                    },
                  },
                  {
                    title: "Leave From",
                    render: (client) => {
                      return moment(client.leave_from).format("YYYY-MM-DD");
                    },
                  },
                  {
                    title: "Leave To",
                    render: (client) => {
                      return moment(client.leave_to).format("YYYY-MM-DD");
                    },
                  },
                  // {
                  //   title: "Status",
                  //   render: (client) => {
                  //     let status = client.status;
                  //     return (
                  //       <Chip
                  //         classes={{ sizeSmall: classes.sizeSmall }}
                  //         size="small"
                  //         label={status}
                  //         color={`${status === "ongoing" ? "secondary" : ""}`}
                  //       />
                  //     );
                  //   },
                  // },
                ]}
                data={(query) =>
                  new Promise((resolve, reject) => {
                    let url = config.api + `/logs/mechanic/${data.id}?`;
                    url += `page=${query.page + 1}`;
                    if (query.pageSize) {
                      url += `&per_page=${query.pageSize}`;
                    }
                    if (query.search) {
                      url += `&search=${query.search}`;
                    }
                    const options = {
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                      },
                    };
                    fetch(url, options)
                      .then((response) => response.json())
                      .then((result) => {
                        resolve({
                          data: result.data.data,
                          page: result.data.current_page - 1 || 0,
                          totalCount: result.data.total,
                        });
                      });
                  })
                }
                actions={[
                  {
                    icon: "delete",
                    tooltip: "Delete",
                    onClick: (event, rowData) => {
                      modalOpenDelete(rowData);
                    },
                  },
                ]}
                components={{
                  Action: (props) => {
                    // =====================Delete=====================
                    if (props.action.icon === "delete") {
                      let today = new Date();

                      let year = today.getFullYear();
                      let month = today.getMonth();
                      let day = today.getDate();

                      let today_c = new Date(year, month, day, 0, 0, 0, 0);
                      let today_final = moment(today_c).format(
                        "yyyy-MM-DD HH:mm:ss"
                      );
                      if (props.data.status === "ongoing") {
                        if (
                          today_final >= props.data.leave_from &&
                          today_final <= props.data.leave_to
                        ) {
                          return "";
                        } else {
                          return (
                            <Tooltip title="Delete">
                              <IconButton
                                aria-label="delete"
                                color="secondary"
                                size="small"
                                className={classes.delete}
                                onClick={(event) =>
                                  props.action.onClick(event, props.data)
                                }
                              >
                                <CancelIcon />
                              </IconButton>
                            </Tooltip>
                          );
                        }
                      } else {
                        return "";
                      }
                    }
                  },
                }}
                options={{
                  headerStyle: {
                    fontWeight: "bold",
                  },
                  rowStyle: {
                    fontSize: ".75rem",
                    padding: "0px !important",
                  },
                  actionsCellStyle: {
                    justifyContent: "center",
                    padding: "8px",
                    marginBottom: "-1px",
                  },
                  pageSize: 10,
                  pageSizeOptions: [],
                  actionsColumnIndex: -1,
                  tableLayout: "auto",
                  search: false,
                }}
              />
            )}
          </div>
        </MuiDialogContent>
      </Dialog>
    </Fragment>
  );
};

export default MechanicLeaveTable;
