import React from 'react';
import MaterialTable from 'material-table';
import { tableIcons } from '../../booking/TableIcons';
import VisibilityIcon from '@material-ui/icons/Visibility';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
// components
import ViewDialog from './ViewDialog';
// modal
import useModal from '../../../hooks/use-modal';

const MainSubTable = React.memo((props) => {
  const { data } = props;

  // View Dialog
  const {
    modalState: modalStateView,
    modalData: modalDataView,
    openHandler: modalOpenView,
    closeHandler: modalCloseView,
  } = useModal();

  return (
    <>
      {/* /* =================View Dialog - (MODAL)================= */}
      <ViewDialog
        data={modalDataView}
        open={modalStateView}
        handleViewClose={modalCloseView}
      />
      <MaterialTable
        icons={tableIcons}
        title={`User Vehicles`}
        columns={[
          {
						title: 'Model',
						render: (rowData) => {
							const { make, model } = rowData;
							return make === null ? model : `${make} ${model}`;
						},
					},
          { title: 'Year', field: 'year' },
          { title: 'Engine Size', field: 'engine_size' },
          { title: 'Fuel Type', field: 'fuel_type' },
        ]}
        data={data && data.vehicles}
        actions={[
          {
            icon: 'view',
            tooltip: 'View History',
            onClick: (event, rowData) => {
              console.log('rowData: ', rowData);
              modalOpenView(rowData);
            },
          },
        ]}
        components={{
          Action: (props) => {
            // =====================View=====================
            if (props.action.icon === 'view') {
              return (
                <Tooltip title="View">
                  <IconButton
                    aria-label="view"
                    size="small"
                    onClick={(event) => props.action.onClick(event, props.data)}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>
              );
            }
          },
        }}
        options={{
          headerStyle: {
            fontWeight: 'bold',
          },
          rowStyle: {
            fontSize: '.75rem',
            padding: '0px !important',
          },
          actionsCellStyle: {
            justifyContent: 'center',
            padding: '24px',
            marginBottom: '-1px',
          },
          pageSize: 5,
          pageSizeOptions: [],
          actionsColumnIndex: -1,
        }}
      />
    </>
  );
});

export default MainSubTable;
