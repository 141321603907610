import React, { Fragment, useState, useCallback } from 'react';
import MaterialTable from 'material-table';
import { useHistory } from 'react-router-dom';
import axios from "axios";
// mui
import IconButton from '@material-ui/core/IconButton';
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { makeStyles } from '@material-ui/core/styles';
import { green, yellow } from '@material-ui/core/colors';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Tooltip from '@material-ui/core/Tooltip';
import { AppBar, Tabs, Tab, Box } from '@material-ui/core';
import moment from 'moment';
// api config
import config from '../../utils/config';

import useModal from "../../hooks/use-modal";

import Attendances from './Attendances';
import AlertDialog from "../alertDialog/AlertDialog";
import TabPanel from './TabPanel';
import Leaves from './Leaves';

const useStyles = makeStyles((theme) => ({
    appBar: {
      //backgroundColor: '#fae700', 
      //color: '#000',
      marginBottom: '20px'
    },
}));

const MechanicsAttendance = (props) => {
    const classes = useStyles();
    
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Fragment>
            <Box>
                <AppBar position="static"  className={classes.appBar}>
                    <Tabs value={value} onChange={handleChange}>
                        <Tab label="Attendances" />
                        <Tab label="Leaves" />
                    </Tabs>
                </AppBar>
                <TabPanel value={value} index={0}>
                    <Attendances {...props}/>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Leaves {...props} />
                </TabPanel>
            </Box>
        </Fragment>
    )

}

export default React.memo(MechanicsAttendance)