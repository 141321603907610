import { useRef } from 'react';
import GarageTable from '../components/garage/GarageTable';

const OtherServices = (props) => {
  const { token } = props;

  const tableRef = useRef();

  return <GarageTable tableRef={tableRef} token={token} />;
};

export default OtherServices;
