import { Fragment } from "react";
import React, { useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { green } from "@material-ui/core/colors";
import { Formik, Form, FastField } from "formik";
import { object, string, number } from "yup";
import { TextField } from "formik-material-ui";
import SelectPartCategory from "./SelectPartCategory";
import AlertDialog from "../booking/AlertDialog";
import CustomAddPartBrandComponent from "../booking/addBooking/CustomAddPartBrandComponent";

import config from "../../utils/config";
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: "#FAE700",
  },
  titleText: {
    color: "rgb(63,81,181) !important",
    fontWeight: "bold !important",
  },
  button: {
    backgroundColor: green[500],
    color: "#fff",
    "&:hover": {
      backgroundColor: green[600],
    },
  },
  paper: { maxWidth: "350px" },
}));

const FormAddParts = React.memo((props) => {
  const classes = useStyles();

  const { open, onOpen, onClose } = props;

  // ==============MODAL STATES==============
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState('');
  const [typeAlert, setTypeAlert] = useState('success');

  const handleOpen = () => {
    onOpen();
  };

  const handleClose = () => {
    onClose();
  };

  const handleCloseAlert = useCallback(() => {
    setAlert(false);
    setMessage('');
    setTypeAlert('success');
  }, []);

  const handleSubmit = async (values, {setSubmitting}) => {
    try {
      const {data: { data }} = await axios.post(config.goparts_api + '/parts', values);
      props.handleAddPartSubmit(data);
    } catch (error) {
      setSubmitting(false)
      if (error?.response?.data) {
        for (const key in error.response.data) {
          setAlert(true);
          setTypeAlert('error');
          setMessage(error.response.data[key]);
        }
      }
    }
  };

  const initialValues = {
    category_id: "",
    part_number: "",
    brand_id: "",
    name: "",
    price: 0.0,
    totalPrice: 0,
  };

  const validationSchema = object().shape({
    category_id: string().required("Category is required."),
    brand_id: string().required("Brand is required."),
    part_number: string().required("Part number is required."),
    name: string().required("Part name is required."),
  });

  return (
    <Fragment>
      <div>
        <AlertDialog
          open={alert}
          typeAlert={typeAlert}
          message={message}
          handleCloseAlert={handleCloseAlert}
        />
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          disableBackdropClick
          classes={{ paper: classes.paper }}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue, isSubmitting }) => (
              <Form autoComplete="off">
                <DialogTitle
                  id="alert-dialog-title"
                  className={`${classes.title}`}
                >
                  <span className={classes.titleText}>
                    {"ADD PART FORM"}
                  </span>
                </DialogTitle>
                <DialogContent dividers>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <FastField
                        label="Category"
                        component={SelectPartCategory}
                        name="category_id"
                        type="text"
                        variant="outlined"
                        size="small"
                        fullWidth
                        onChange={async (e) => {
                          const { value } = e.target;
                          setFieldValue("category_id", value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FastField
                        label="Brand"
                        component={CustomAddPartBrandComponent}
                        name="brand_id"
                        type="text"
                        variant="outlined"
                        size="small"
                        fullWidth
                        onChange={async (e) => {
                          const { value } = e.target;
                          setFieldValue("brand_id", value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FastField
                        label="Part Number"
                        component={TextField}
                        name="part_number"
                        type="text"
                        variant="outlined"
                        size="small"
                        fullWidth
                        inputProps={{
                          style: {
                            textAlign: "center",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FastField
                        label="Part Name"
                        component={TextField}
                        name="name"
                        type="text"
                        variant="outlined"
                        size="small"
                        fullWidth
                        inputProps={{
                          style: {
                            textAlign: "center",
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} size="small" color="default">
                    Close
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    color="primary"
                    variant="contained"
                    autoFocus
                    size="small"
                    endIcon={
                      isSubmitting ? (
                        <CheckCircleIcon size="0.9rem" />
                      ) : undefined
                    }
                  >
                    {isSubmitting ? "Submitting" : "Submit"}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>
      </div>
    </Fragment>
  );
});
export default FormAddParts;
