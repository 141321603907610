import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { MenuItem, TextField } from '@material-ui/core';

const yearOptions = () => {
  const year = new Date().getFullYear() + 1;
  let options = [];
  for (var i = year; i >= 1992; i--) {
    options.push({ value: i, label: i });
  }
  return options;
};

const MenuItemStyle = withStyles({
  root: {
    justifyContent: 'center',
  },
})(MenuItem);

const CustomYearComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const years = yearOptions();

  return (
    <TextField
      select
      fullWidth
      error={errors.year && touched.year}
      helperText={errors && errors.year}
      {...field}
      {...props}
    >
      <MenuItemStyle selected value={field.value}>
        ----Select Year----
      </MenuItemStyle>
      {years.map((option) => (
        <MenuItemStyle key={option.value} value={option.value}>
          {option.value}
        </MenuItemStyle>
      ))}
    </TextField>
  );
};
export default CustomYearComponent;
