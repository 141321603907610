import React, { Fragment, useCallback, useState, useRef } from "react";
import MaterialTable from "material-table";
import { makeStyles } from "@material-ui/core/styles";
import { tableIcons } from "../../booking/TableIcons";
import { green, yellow } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@material-ui/icons/Cancel";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import useModal from "../../../hooks/use-modal";
// components
import AddOns from "./AddOns";
import UpdateAddOns from "./UpdateAddOns";

const useStyles = makeStyles((theme) => ({
  view: {
    backgroundColor: yellow["A200"],
    color: "#000",
    "&:hover": {
      color: "#fff",
    },
  },
  update: {
    color: yellow["A700"],
    maxHeight: "2px !important",
    "&:hover": {
      background: "none !important",
    },
  },
  delete: {
    maxHeight: "2px !important",
    "&:hover": {
      background: "none !important",
    },
  },
  add: {
    color: green[500],
    "&:hover": {
      backgroundColor: "rgba(232, 245, 233,1.0)",
    },
  },
  sizeSmall: {
    height: "18px",
    fontSize: "12px",
  },
}));

const AddOnsTable = React.memo((props) => {
  const classes = useStyles();

  const { addOns, setFieldValue } = props;

  const [openAdd, setOpenAdd] = useState(false);

  const {
    modalState: modalStateUpdate,
    modalData: modalDataUpdate,
    openHandler: modalOpenUpdate,
    closeHandler: modalCloseUpdate,
  } = useModal();

  // //   ==============Add Package (Modal)==============
  const handleOpenAdd = useCallback(() => {
    setOpenAdd(true);
  }, []);

  const handleCloseAdd = useCallback(() => {
    setOpenAdd(false);
  }, []);

  const handleSubmitAdd = async (data, actions) => {
    actions.setSubmitting(false);
    handleCloseAdd();
    setFieldValue("addOns", [...addOns, data]);
  };

  const removeInclusion = (data) => {
    const currentAddOns = [...addOns];
    const updatedAddOns = currentAddOns.filter(
      (inc) => inc.service.id !== data.service.id
    );
    setFieldValue("addOns", updatedAddOns);
  };

  const handleUpdatePackage = (data) => {
    const updatedAddOns = addOns.map((addOn) =>
      addOn.service.id === data.id
        ? { ...addOn, ...data, id: data.service.id }
        : addOn
    );
    setFieldValue("addOns", updatedAddOns);
    modalCloseUpdate();
  };

  return (
    <Fragment>
      {/* =================ADD PACKAGE ADD ONS- (MODAL)================= */}
      <AddOns
        open={openAdd}
        onOpen={handleOpenAdd}
        onClose={handleCloseAdd}
        handleSubmitAdd={handleSubmitAdd}
      />
      {/* =================UPDATE PACKAGE INCLUSION- (MODAL)================= */}
      <UpdateAddOns
        open={modalStateUpdate}
        data={modalDataUpdate}
        onClose={modalCloseUpdate}
        onUpdate={handleUpdatePackage}
      />
      <MaterialTable
        icons={tableIcons}
        title={`Add Ons`}
        columns={[
          {
            title: "Service Item",
            field: "service.name",
          },
          { title: "Description", field: "description" },
          { title: "Saved Amount", field: "saved_amount" },
        ]}
        data={addOns}
        actions={[
          {
            icon: "update",
            tooltip: "Update",
            onClick: (event, rowData) => {
              modalOpenUpdate(rowData);
            },
          },
          {
            icon: "delete",
            tooltip: "Delete",
            onClick: (event, rowData) => {
              removeInclusion(rowData);
            },
          },
        ]}
        components={{
          Action: (props) => {
            // =====================Update=====================
            if (props.action.icon === "update") {
              return (
                <Tooltip title="Update">
                  <IconButton
                    aria-label="update"
                    color="secondary"
                    size="small"
                    className={classes.update}
                    onClick={(event) => props.action.onClick(event, props.data)}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              );
            }
            // =====================Delete=====================
            if (props.action.icon === "delete") {
              return (
                <Tooltip title="Delete">
                  <IconButton
                    aria-label="delete"
                    color="secondary"
                    size="small"
                    className={classes.delete}
                    onClick={(event) => props.action.onClick(event, props.data)}
                  >
                    <CancelIcon />
                  </IconButton>
                </Tooltip>
              );
            }
          },
        }}
        options={{
          headerStyle: {
            fontWeight: "bold",
          },
          rowStyle: {
            fontSize: ".75rem",
            padding: "0px !important",
          },
          actionsCellStyle: {
            justifyContent: "center",
            padding: "24px",
            marginBottom: "-1px",
          },
          pageSize: 5,
          pageSizeOptions: [],
          actionsColumnIndex: -1,
        }}
      />
    </Fragment>
  );
});

export default AddOnsTable;
