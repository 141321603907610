import { Fragment, useState, useEffect } from 'react';
import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { MenuItem, TextField } from "@material-ui/core";
import { green } from '@material-ui/core/colors';
import { Formik, Form, FastField } from 'formik';
import * as Yup from 'yup';
import { DateTimePicker } from "formik-material-ui-pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from 'moment';

const MenuItemStyle = withStyles({
    root: {
      justifyContent: "center !important",
    },
})(MenuItem);

const useStyles = makeStyles((theme) => ({
    title: {
        backgroundColor: '#FAE700',
    },
    titleText: {
        color: 'rgb(63,81,181) !important',
        fontWeight: 'bold !important',
    },
    button: {
        backgroundColor: green[500],
        color: '#fff',
        '&:hover': {
            backgroundColor: green[600],
        },
    },
    paper: { maxWidth: '20%' },
}));

const UpdateAttendance = React.memo((props) => {
    const classes = useStyles();

    const { open, handleUpdateClose, data, mechanics } = props;

    const [initialValues, setInitialValues] = useState({
        mechanic_id: null,
        time_in: null,
        time_out: null
    })

    useEffect(() => {
        if (data) {
            const values = {
                mechanic_id: data?.mechanic_id,
                time_in: moment(data?.time_in),
                time_out: moment(data?.time_out),
                id: data?.id
            }
            setInitialValues(values)
        }
    }, [data])

    const handleClose = () => {
        handleUpdateClose();
    };

    // Define validation schema
    const validationSchema = Yup.object({
        mechanic_id: Yup.number().required('Mechanic is required'),
        time_in: Yup.date()
            .required('Time in is required')
            .nullable()
            .typeError('Invalid datetime'),
        time_out: Yup.date()
            .required('Time out is required')
            .nullable()
            .typeError('Invalid datetime')
            .test('is-not-equal', 'Time in and Time out must not be the same', function (value) {
            const { time_in } = this.parent;
            return time_in ? value !== time_in : true;
            })
            .test('is-later', 'Time out must be at least one hour after Time in', function (value) {
            const { time_in } = this.parent;
                return time_in ? value > new Date(time_in.getTime() + 60 * 60 * 1000) : true;
            })
            .test('is-same-date', 'Time out must be on the same day as Time in', function (value) {
                const { time_in } = this.parent;
                return time_in && value && new Date(time_in).toDateString() === new Date(value).toDateString();
            }),
    });


    const handleSubmit = (values) => {
        // Handle form submission
        props.handleUpdateSubmit(values);
    };

    return (
        <Fragment>
            <div>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    classes={{ paper: classes.paper }}
                >
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ values, errors, isSubmitting }) => (
                            <Form autoComplete="off">
                                <DialogTitle
                                    id="alert-dialog-title"
                                    className={`${classes.title}`}
                                >
                                    <span className={classes.titleText}>
                                        {'MECHANIC ATTENDANCE'}
                                    </span>
                                </DialogTitle>
                                <input type="hidden" name="id" />
                                <DialogContent dividers>

                                    <Grid container spacing={3}>

                                        <Grid item xs={12} sm={12}>
                                            <FastField 
                                               fullWidth
                                               label="Mechanic"
                                               component={( { field, form: { touched, errors } }, props) => (
                                                  <TextField 
                                                     select 
                                                     fullWidth
                                                     error={errors[field?.name] && touched[field?.name]}
                                                     {...field}
                                                     {...props}
                                                     variant='outlined'
                                                     size="small"
                                                     name='mechanic_id'
                                                  >
                                                        {mechanics?.map((option) => (
                                                            <MenuItemStyle key={option.id} value={option.id}>
                                                               {`${option.firstname} ${option.lastname}`}
                                                            </MenuItemStyle>
                                                        ))}
                                                  </TextField>  
                                               )}
                                               size="small"
                                               inputVariant="outlined"
                                               name="mechanic_id"
                                               variant="outlined"
                                               inputProps={{
                                                   style: { textAlign: "center" },
                                               }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <MuiPickersUtilsProvider
                                                utils={DateFnsUtils}
                                            >
                                                <FastField
                                                    fullWidth
                                                    label="Time In"
                                                    component={DateTimePicker}
                                                    size="small"
                                                    inputVariant="outlined"
                                                    name="time_in"
                                                    variant="inline"
                                                    autoOk
                                                    inputProps={{
                                                        style: { textAlign: "center" },
                                                    }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <MuiPickersUtilsProvider
                                                utils={DateFnsUtils}
                                            >
                                                <FastField
                                                    fullWidth
                                                    label="Time Out"
                                                    component={DateTimePicker}
                                                    size="small"
                                                    inputVariant="outlined"
                                                    name="time_out"
                                                    variant="inline"
                                                    autoOk
                                                    inputProps={{
                                                        style: { textAlign: "center" },
                                                    }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <FastField
                                                fullWidth
                                                label="Remarks"
                                                component={TextField}
                                                multiline
                                                size="small"
                                                name="remarks"
                                                variant="outlined"
                                                rows={2}
                                                rowsMax={3}
                                            />
                                        </Grid>


                                    </Grid>

                                    <DialogActions>
                                        <Button onClick={handleClose} size="small" color="default">
                                            Close
                                        </Button>
                                        <Button
                                            disabled={isSubmitting}
                                            type="submit"
                                            color="primary"
                                            variant="contained"
                                            autoFocus
                                            size="small"
                                            endIcon={
                                                isSubmitting ? (
                                                    <CheckCircleIcon size="0.9rem" />
                                                ) : undefined
                                            }
                                        >
                                            {isSubmitting ? 'Submitting' : 'Submit'}
                                        </Button>
                                    </DialogActions>
                                </DialogContent>
                            </Form>
                        )}
                    </Formik>
                </Dialog>
            </div>
        </Fragment>
    );
});

export default UpdateAttendance;
