import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { MenuItem, TextField } from "@material-ui/core";
import axios from "axios";
import config from "../../utils/config";
import { useSelector, useDispatch } from "react-redux";
import { bookFilterActions } from "../../store/book-filter-slice";

const MenuItemStyle = withStyles({
  root: {
    justifyContent: "center !important",
  },
})(MenuItem);

const useStyles = makeStyles((theme) => ({
  center: {
    textAlign: "center !important",
    width: "100%",
    marginTop: "1rem",
  },
}));

const SelectMechanics = ({ ...props }) => {
  const classes = useStyles();
  const token = localStorage.getItem("token");

  const [mechanics, setMechanics] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const {
        data: { data },
      } = await axios.get(`${config.api}/mechanics/all`, options);
      setMechanics(data);
    }
    fetchData();
  }, []);

  const dispatch = useDispatch();
  const mechanicBookFilter = useSelector((state) => state.bookFilter.mechanic);

  const handleChange = (event) => {
    dispatch(
      bookFilterActions.setMechanic({
        mechanic: event.target.value,
      })
    );
  };

  return (
    <TextField
      select
      // label="Mechanics"
      {...props}
      classes={{
        root: classes.center,
      }}
      value={mechanicBookFilter}
      onChange={handleChange}
    >
      {mechanics.map((option) => (
        <MenuItemStyle key={option.id} value={option.id}>
          {option.firstname} {option.lastname}
        </MenuItemStyle>
      ))}
    </TextField>
  );
};
export default SelectMechanics;
