import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { MenuItem, TextField } from "@material-ui/core";

const MenuItemStyle = withStyles({
  root: {
    justifyContent: "center !important",
  },
})(MenuItem);

const useStyles = makeStyles((theme) => ({
  center: {
    textAlign: "center !important",
  },
}));

const property_type = [
  { value: "Condo | Apartment", label: "Condo | Apartment" },
  { value: "House | Townhouse", label: "House | Townhouse" },
  { value: "Small Business | Store", label: "Small Business | Store" },
  { value: "Office Building", label: "Office Building" },
  { value: "Warehouse | Storage", label: "Warehouse | Storage" },
];

const SelectPropertyType = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const classes = useStyles();

  return (
    <TextField
      select
      error={errors.property_type && touched.property_type}
      helperText={
        errors.property_type && touched.property_type && errors.property_type
      }
      {...field}
      {...props}
      classes={{
        root: classes.center,
      }}
    >
      {property_type.map((option) => (
        <MenuItemStyle key={option.value} value={option.value}>
          {option.label}
        </MenuItemStyle>
      ))}
    </TextField>
  );
};
export default SelectPropertyType;
