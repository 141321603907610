import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import { MenuItem, TextField } from '@material-ui/core';

// Api config
import config from '../../../utils/config';

const MenuItemStyle = withStyles({
  root: {
    justifyContent: 'center',
  },
})(MenuItem);

const CustomMunicipalityComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, values }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const [municipalities, setMunicipalities] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const { data: municipalities } = await axios.get(
        `${config.api}/municipality/${values.province}`
      );
      if (values.province) setMunicipalities(municipalities);
    }
    fetchData();
  }, [props.token, values.province]);

  return (
    <TextField
      select
      fullWidth
      error={errors.municipality && touched.municipality}
      helperText={
        errors.municipality && touched.municipality ? errors.municipality : ''
      }
      {...field}
      {...props}
    >
      {municipalities?.map((option) => (
        <MenuItemStyle
          key={option.municipality_id}
          value={option.municipality_name}
        >
          {option.municipality_name}
        </MenuItemStyle>
      ))}
    </TextField>
  );
};
export default CustomMunicipalityComponent;
