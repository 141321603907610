import React, { useEffect } from 'react';

const numberConverter = (number) => {
  let value = Number(parseFloat(number)).toLocaleString('en', {
    minimumFractionDigits: 2
  });
  return value;
}


const CustomPriceTotalPrice = ({
  field, // { name, value, onChange, onBlur }
  form: { values, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {

  const { parts } = values
  const { index } = props

  useEffect(() => {

    if (index >= 0 && index < parts.length) {
      const part = parts[index];
      const total = parseFloat(part.price) * parseInt(part.quantity);
      // Ensure the final total is not negative
      const totalFinal = Math.max(0, total);
      if (!isNaN(totalFinal)) {
        setFieldValue(`parts.${props.index}.totalPrice`, totalFinal);
      }
    }

  }, [parts])

  return (
      <React.Fragment>
        <input {...props} {...field} style={{display : 'none'}} />
        <span>₱{field.value ? numberConverter(+field.value) : '0.00'}</span>
      </React.Fragment>
    )
};
export default CustomPriceTotalPrice;
