import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { MenuItem, TextField } from '@material-ui/core';

const MenuItemStyle = withStyles({
  root: {
    justifyContent: 'center',
  },
})(MenuItem);

const CustomModelComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, values }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  return (
    <TextField
      fullWidth
      select
      error={errors.model && touched.model}
      helperText={errors.model && touched.model ? errors.model : ''}
      {...field}
      {...props}
      value={values.models.length > 0 ? field.value : ''}
    >
      {values.models.map((option) => (
        <MenuItemStyle key={option.name} value={option.name}>
          {option.name}
        </MenuItemStyle>
      ))}
    </TextField>
  );
};
export default CustomModelComponent;
