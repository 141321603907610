import React from 'react';
import { StyleSheet, Document, Page, View } from '@react-pdf/renderer';
import HeaderLogo from './HeaderLogo';
import Checklist from './Checklist';
import CustomerVehicleDetails from './CustomerVehicleDetails';
import MechanicRemarks from './MechanicRemarks';
import ReferencePhotos from './ReferencePhotos';
import CustomerAgreement from './CustomerAgreement';
import Recommendations from './Recommendations';

const styles = StyleSheet.create({
  viewer: {
    width: '100%',
    height: '100%',
  },
  page: {
    padding: 16,
    fontSize: 9,
    textTransform: 'uppercase',
  },
  row: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginRight: -8,
    marginLeft: 4,
  },
  column: {
    flexBasis: 1,
    flexGrow: 1,
    marginRight: 8,
    marginLeft: -4,
  },
});

const PDFDocument = ({ content }) => {
  return (
    <Document>
      <Page size={{ width: 8 * 72, height: 13 * 72 }} style={styles.page} wrap>
        <HeaderLogo
          bsNumber={content.bs_number}
          date={content.appointment_date}
        />
        <CustomerVehicleDetails content={content} />
        <Checklist
          content={content.inspection}
          tireSize={content.extended_information?.['tire_size']}
        />
        <View break style={styles.row}>
          <View style={styles.column}>
            <MechanicRemarks content={content.extended_information?.remarks} />
          </View>
          <View style={styles.column}>
            <Recommendations
              content={content.extended_information?.needed_services}
              itemServices={content.inspection}
            />
          </View>
        </View>
        <ReferencePhotos content={content}/>
        <CustomerAgreement
          content={content.extended_information?.esignature_photo_url}
        />
      </Page>
    </Document>
  );
};

export default PDFDocument;
