import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { MenuItem, TextField } from "@material-ui/core";

const MenuItemStyle = withStyles({
  root: {
    justifyContent: "center !important",
  },
})(MenuItem);

const useStyles = makeStyles((theme) => ({
  center: {
    textAlign: "center !important",
  },
}));

const categories = [
  { value: "client_cancellation", label: "Client Cancellation" },
  { value: "mechanigo_cancellation", label: "Mechanigo Cancellation" },
];

const CustomSelectTypeCancellation = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const classes = useStyles();

  return (
    <TextField
      select
      error={errors.cancellation_type && touched.cancellation_type}
      helperText={errors.cancellation_type && touched.cancellation_type && errors.cancellation_type}
      {...field}
      {...props}
      classes={{
        root: classes.center,
      }}
    >
      {categories.map((option) => (
        <MenuItemStyle key={option.value} value={option.value}>
          {option.label}
        </MenuItemStyle>
      ))}
    </TextField>
  );
};
export default CustomSelectTypeCancellation;
