import React, { useState, useEffect } from "react";
import { TextField } from "@material-ui/core";
import axios from "axios";
import config from "../../../utils/config";
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

const filter = createFilterOptions();

const CustomAddPartBrandComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, values, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const token = localStorage.getItem('token');
  const [brands, setBrands] = useState([]);
  const [value, setValue] = React.useState(null);

  useEffect(() => {
    async function fetchData() {
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const { data: data } = await axios.get(
        `${config.goparts_api}/products/brands?include_inactive=true`,
        options
      );
      const sortedData = data.data.sort((a, b) => a.name.localeCompare(b.name));
      setBrands(sortedData);
    }
    fetchData();
  }, []);

  return (
    <Autocomplete
      value={value}
      onChange={async (event, newValue) => {
        if (typeof newValue === 'string') {
          setValue(newValue);
        } else if (newValue && newValue.inputValue) {
          const { data: { data } } = await axios.post(
            `${config.goparts_api}/brand`,
            {name: newValue.inputValue, description: '', is_active: 0}
          );
          // Create a new value from the user input
          setValue(newValue.inputValue);
          setFieldValue('brand_id', data.id)
        } else {
          setValue(newValue);
          setFieldValue('brand_id', newValue?.name)
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        // Suggest the creation of a new value
        if (params.inputValue !== '' && filtered.length === 0) {
          filtered.push({
            inputValue: params.inputValue,
            name: `Add "${params.inputValue}"`,
          });
        }

        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="brand_id"
      options={brands}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.name;
      }}
      renderOption={(option) => option.name}
      style={{ width: 300 }}
      freeSolo
      renderInput={(params) => (
        <TextField
          {...params}
          label="Brand"
          variant="outlined"
          error={errors.name && touched.name}
          helperText={errors.name && touched.name && errors.name}
        />
      )}
    />
  );
};
export default CustomAddPartBrandComponent;
