import React, { Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { bookFilterActions } from "../../../store/book-filter-slice";
import customClasses from "./AppointmentTimePicker.module.css";
import "date-fns";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Box, Button } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import AccessTime from "@material-ui/icons/AccessTime";
import SelectMechanics from "../../common/SelectMechanics";

const muiTheme = createMuiTheme({
  textAlign: "center !important",
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    border: "1px solid #e8e8e8",
    borderTop: "none",
    borderBottom: "none",
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  grid: {
    position: "relative",
  },
  title: {
    display: "flex",
    alignItems: "center",
  },
  text: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    textAlign: "left",
    marginTop: "20px !important",
    fontSize: "0.845rem !important",
    whiteSpace: "normal",
    letterSpacing: "0.02857em",
    fontWeight: "bold !important",
    color: "black !important",
    opacity: 0.7,
  },
  dateCustom: {
    textAlign: "center !important",
  },
  button: {
    marginTop: theme.spacing(3),
  },
}));

const AppointmentTimePicker = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const appointmentTime = useSelector(
    (state) => state.bookFilter.appointmentTime
  );
  const handleChange = (stringDatetime) => {
    const value = moment(stringDatetime)
      .startOf("hour")
      .format("YYYY-MM-DDTHH:mm:ss.sssZ");
    dispatch(
      bookFilterActions.changeAppointmentTime({
        appointmentTime: value,
      })
    );
  };

  return (
    <Fragment>
      <div className={classes.root}>
        <Grid
          container
          spacing={1}
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          <Grid container item xs={12} spacing={3}>
            {/*1st*/}
            <Grid item xs={3} className={classes.grid}>
              <Box ml={2}>
                <Typography
                  className={classes.text}
                  paragraph={true}
                  style={{ margin: 0 }}
                >
                  Appointment Time :
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box>
                <MuiPickersUtilsProvider theme={muiTheme} utils={DateFnsUtils}>
                  <Grid container>
                    <KeyboardTimePicker
                      variant="inline"
                      views={["hours"]}
                      margin="normal"
                      id={customClasses.appointment_time}
                      autoOk
                      value={appointmentTime}
                      onChange={handleChange}
                      inputProps={{ readOnly: true }}
                      keyboardIcon={<AccessTime />}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Box>
            </Grid>
            <Grid item xs={1} className={classes.title}>
              <Box ml={2}>
                <Typography
                  paragraph={true}
                  style={{
                    margin: 0,
                    fontSize: "0.845rem",
                    fontWeight: "bold",
                    opacity: 0.7,
                    color: "#000",
                  }}
                >
                  Mechanics:
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box mr={10}>
                <Grid container>
                  <SelectMechanics />
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
        </Grid>
      </div>
    </Fragment>
  );
};

export default AppointmentTimePicker;
