import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { MenuItem, TextField } from "@material-ui/core";

const MenuItemStyle = withStyles({
  root: {
    justifyContent: "center",
  },
})(MenuItem);

const RoleComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, values }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const roles = [
    { value: "admin_user", label: "admin_user" },
    // { value: 'mechanic_user', label: 'mechanic_user' },
    { value: "super_admin", label: "super_admin" },
  ];
  const userRole = localStorage.getItem("userRole");
  const toUpdateUserRole = values.user_type;

  return (
    <TextField
      select
      fullWidth
      error={errors.role && touched.role}
      helperText={errors && errors.role}
      {...field}
      {...props}
      disabled={
        toUpdateUserRole === "super_admin" &&
        (userRole === "admin_user" || userRole === "mechanic_user")
      }
    >
      {roles.map((option) => (
        <MenuItemStyle
          key={option.value}
          value={option.value}
          disabled={
            option.value === "super_admin" &&
            (userRole === "admin_user" || userRole === "mechanic_user")
          }
        >
          {option.value}
        </MenuItemStyle>
      ))}
    </TextField>
  );
};
export default RoleComponent;
