// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#BookingDatePicker_booking_from__3a9oI,\n#BookingDatePicker_booking_to__q4aji {\n  text-align: center !important;\n}\n", "",{"version":3,"sources":["webpack://src/components/booking/bookingDatePicker/BookingDatePicker.module.css"],"names":[],"mappings":"AAAA;;EAEE,6BAA6B;AAC/B","sourcesContent":["#booking_from,\n#booking_to {\n  text-align: center !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"booking_from": "BookingDatePicker_booking_from__3a9oI",
	"booking_to": "BookingDatePicker_booking_to__q4aji"
};
export default ___CSS_LOADER_EXPORT___;
