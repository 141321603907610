import { Fragment, useCallback, useState, useRef } from 'react';
import axios from 'axios';
import AdsSourcesTable from '../components/adsSources/AdsSourcesTable';
import AddAdsSrouce from '../components/adsSources/AddAdsSource';
import AlertDialog from '../components/booking/AlertDialog';

// Api config
import config from '../utils/config';

const AdsSource = (props) => {
  const { token } = props;

  const tableRef = useRef();

  // ==============ADD Ads Source STATES==============
  const [openAdd, setOpenAdd] = useState(false);

  const handleOpenAdd = useCallback(() => {
    setOpenAdd(true);
  }, []);

  const handleCloseAdd = useCallback(() => {
    setOpenAdd(false);
  }, []);

  // ==============MODAL STATES==============
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState('');
  const [typeAlert, setTypeAlert] = useState('success');

  const handleSubmitAdsSource = useCallback(
    (values) => {
      const options = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };

      axios
        .post(`${config.api}/ads-sources`, values, options)
        .then((response) => {
          setOpenAdd(false);
          setTimeout(() => {
            setAlert(true);
            setMessage('Successfully Added');
            setTypeAlert('success');
          }, 1500);

          tableRef.current && tableRef.current.onQueryChange();
        })
        .catch((error) => {
          console.log(error);
          setOpenAdd(false);
          setAlert(true);
          setTypeAlert('error');
          setMessage('Something went wrong please try again.');
        });
    },
    [token]
  );

  // =================ALERT - (MODAL)=================
  const handleCloseAlert = useCallback(() => {
    setAlert(false);
    setMessage('');
    setTypeAlert('success');
  }, []);

  return (
    <Fragment>
      <AlertDialog
        open={alert}
        typeAlert={typeAlert}
        message={message}
        handleCloseAlert={handleCloseAlert}
      />
      <AddAdsSrouce
        open={openAdd}
        onOpen={handleOpenAdd}
        onClose={handleCloseAdd}
        handleSubmitAdsSource={handleSubmitAdsSource}
        token={props.token}
      />
      <AdsSourcesTable tableRef={tableRef} token={token} />
    </Fragment>
  );
};

export default AdsSource;
