import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { MenuItem, TextField } from '@material-ui/core';

const MenuItemStyle = withStyles({
  root: {
    justifyContent: 'center',
  },
})(MenuItem);

const CustomFuelComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const fuels = [
    { value: 'Gas', label: 'Gas' },
    { value: 'Diesel', label: 'Diesel' },
  ];
  return (
    <TextField
      fullWidth
      select
      error={errors.fuel_type && touched.fuel_type}
      helperText={errors && errors.fuel_type}
      {...field}
      {...props}
    >
      {fuels.map((option) => (
        <MenuItemStyle key={option.value} value={option.value}>
          {option.value}
        </MenuItemStyle>
      ))}
    </TextField>
  );
};
export default CustomFuelComponent;
