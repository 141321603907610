// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#AppointmentDatePicker_appointment_from__SWmm8,\n#AppointmentDatePicker_appointment_to__28Xpb {\n  text-align: center !important;\n}\n", "",{"version":3,"sources":["webpack://src/components/booking/appointmentDatePicker/AppointmentDatePicker.module.css"],"names":[],"mappings":"AAAA;;EAEE,6BAA6B;AAC/B","sourcesContent":["#appointment_from,\n#appointment_to {\n  text-align: center !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appointment_from": "AppointmentDatePicker_appointment_from__SWmm8",
	"appointment_to": "AppointmentDatePicker_appointment_to__28Xpb"
};
export default ___CSS_LOADER_EXPORT___;
